import { HttpRestService } from "@/services/http/HttpRestService";

export class BoardingRepository {  
   static listaInsumos(urlParams) {
        return HttpRestService.get(`/volume/detalhes/${urlParams.id}`);
    }

    static listaVolumes(urlParams) {
        return HttpRestService.get(`/coleta/${urlParams}/volumes`);
    }

    static detailsOfManifest(urlParams) {
        return HttpRestService.get(`/coleta/detalhes/${urlParams}/manifesto`);
    }
}
