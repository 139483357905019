export default {
  getListingMonPedidos(state) {
    return state.listingMonPedidos;
  },
  getMonPedidosPagination(state) {
    return state.monPedidosPagination;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getHaveFilters(state) {
    return state.haveFilters;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
  getPedidosReenvio(state) {
    return state.pedidosReenvio;
  },
  getReenvioDialog(state) {
    return state.reenvioDialog;
  },
};
