import { DriversRepository } from "../../../repositories/defaultUserRepositories/DriversRepository";

export default {
  async drivers({ commit, state }) {
    const urlParams = state.urlParams;
    commit("setIsLoading", true);
    commit("setListingDrivers", []);
    let url = `/motorista/listar?page=${urlParams.page}&perPage=${10}`;
    if (urlParams.search) {
      url = url.concat(`&search=${urlParams.search}`);
    }
    if (urlParams.transportadora && !urlParams.transportadoraId) {
      url = url.concat(`&transportadora=${urlParams.transportadora}`);
    }
    if (urlParams.transportadoraId) {
      url = url.concat(`&transportadora_id=${urlParams.transportadoraId}`);
    }
    const response = await DriversRepository.requestDrivers(url);
    if (response.status >= 200 && response.status < 300) {
      commit("setIsLoading", false);
      commit("setListingDrivers", response.data.data.list);
      commit("setDriversPagination", response.data.data.pagination);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async shippingsCompany({ commit }) {
    const response = await DriversRepository.listTransportadora();
    if (response.status >= 200 && response.status < 300) {
      commit("setListingCompanys", response.data.data);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async createDrivers({ commit, dispatch }, payload) {
    commit("setIsLoading", true);
    const response = await DriversRepository.createDrivers(payload);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("setIsLoading", false);
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      var urlParams = {
        page: 1,
        search: "",
        initialDate: "",
        finalDate: "",
        status: "",
      };
      commit("setListingDrivers", []);
      await dispatch("drivers", urlParams);
      return response;
    } else if (response.status === 400 && response.data.data.cpf) {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.data.cpf,
      };
      commit("setIsLoading", false);
      commit("notifications/setErrorNotification", errorObj, { root: true });
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("setIsLoading", false);
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async editDrivers({ commit, dispatch }, payload) {
    commit("setIsLoading", true);
    const response = await DriversRepository.editDrivers(payload).then(
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          let successObj = {
            successStatus: true,
            successMessage: response.data.message,
          };
          commit("setIsLoading", false);
          commit("notifications/setSuccessNotification", successObj, {
            root: true,
          });
          var urlParams = {
            page: 1,
            search: "",
            initialDate: "",
            finalDate: "",
            status: "",
          };
          commit("setListingDrivers", []);
          dispatch("drivers", urlParams);
        } else {
          let errorObj = {
            errorStatus: true,
            errorMessage: response.data.message,
          };
          commit("setIsLoading", false);
          commit("notifications/setErrorNotification", errorObj, {
            root: true,
          });
        }
      }
    );
  },

  async sendSignature({ commit }, modelSendSignature) {
    let successObj = {
      successStatus: true,
      successMessage: "Assinatura coletada com sucesso!",
    };
    const response = await DriversRepository.sendSignature(modelSendSignature);
    if (response.status >= 200 && response.status < 300) {
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },
};
