<template>
  <default-modal v-model="dialogModel">
    <v-container class="py-0">
      <template v-if="pickup.tipoColeta === 'EMBARQUE_ENCAMINHAMENTO'">
        <v-form ref="form" @submit.prevent>
          <v-card-title class="justify-center font-weight-bold"
            >Preencher Lacre</v-card-title
          >
          <v-card-subtitle
            ><span
              >Coleta
              <span>{{ pickup.coletaId }}</span>
            </span></v-card-subtitle
          >
          <v-row dense>
            <v-col
              ><default-text-field
                class="mb-n2"
                v-model="sealCode"
                label="Lacre"
                :hide-details="false"
                :rules="[rules.required]"
                @keyup.enter="finishPickup"
                autofocus
            /></v-col>
          </v-row>
        </v-form>
      </template>
      <template v-else>
        <v-card-title class="justify-center font-weight-bold"
          >Você deseja finalizar esta coleta?</v-card-title
        >
        <v-card-subtitle
          ><span
            >Coleta
            <span>{{ pickup.coletaId }}</span>
          </span></v-card-subtitle
        >
      </template>
      <v-col cols="8" offset="2"
        ><default-button @click="finishPickup" :loading="isLoading"
          >Finalizar Coleta</default-button
        ></v-col
      >
    </v-container>
  </default-modal>
</template>

<script>
import { mapActions } from "vuex";
import DefaultButton from "../../defaultComponents/DefaultButton.vue";
import DefaultModal from "../../defaultComponents/DefaultModal.vue";
import DefaultTextField from "../../defaultComponents/DefaultTextField.vue";

export default {
  name: "FinishPickup",
  data() {
    return {
      sealCode: "",
      isLoading: false,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    pickup: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  components: {
    DefaultButton,
    DefaultModal,
    DefaultTextField,
  },
  methods: {
    ...mapActions("expedition", ["finalizaColeta"]),
    async finishPickup() {
      this.isLoading = true;
      if (this.isFormValid) {
        const body = {
          coletaId: this.pickup.coletaId,
          ...(this.sealCode ? { lacre: this.sealCode } : {}),
        };
        await this.finalizaColeta(body);
      }
      this.isLoading = false;
    },
  },
  computed: {
    dialogModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    rules() {
      return {
        required: (input) =>
          !!input || "Insira o código do lacre para finalizar a coleta",
      };
    },
    isFormValid() {
      return this.$refs?.form?.validate() ?? true;
    },
    body() {
      return {
        coletaId: this.pickup.coletaId,
        ...(this.sealCode ? { lacre: this.sealCode } : {}),
      };
    },
  },
};
</script>

<style scoped></style>
