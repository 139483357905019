<template>
  <default-modal v-model="dialogModel" width="650">
    <v-container class="pb-0" ref="container">
      <div v-intersect="onIntersectingHeader">
        <v-card-title class="justify-center py-0">
          <span class="font-weight-bold">Pedidos da Coleta</span>
        </v-card-title>
        <v-row dense class="mb-3">
          <v-col cols="12" align="start">
            <span class="d-block mb-n1 font-weight-bold">Coleta</span>
            <span class="h5 text-uppercase">{{ pickupId }}</span>
          </v-col>
          <v-col cols="12" align="start">
            <span class="d-block mb-n1 font-weight-bold">Transportadora</span>
            <span class="h5 text-uppercase">{{ carrier }}</span>
          </v-col>
          <v-col cols="12" align="start">
            <span class="d-block mb-n1 font-weight-bold">Data e Hora</span>
            <span class="h5 text-uppercase">{{
              date | formatDateWithHour
            }}</span>
          </v-col>
          <v-col cols="5">
            <default-text-field
              v-model="order"
              @keyup.enter="filter"
              label="Pedido"
            >
            </default-text-field>
          </v-col>
          <v-col cols="5">
            <v-select
              v-model="volumeStatus"
              :items="volumeStatuses"
              color="primary"
              background-color="base"
              outlined
              dense
              label="Status do Volume"
              hide-details
            >
              <template v-slot:selection="{ item }">
                <span>{{ item.text }}</span>
                <v-icon
                  v-for="(color, index) in item.colors"
                  :key="index"
                  :color="color"
                  >mdi-circle-medium</v-icon
                >
              </template>
              <template v-slot:item="{ item }">
                <span>{{ item.text }}</span>
                <v-icon
                  v-for="(color, index) in item.colors"
                  :key="index"
                  :color="color"
                  >mdi-circle-medium</v-icon
                >
              </template>
            </v-select>
          </v-col>
          <v-col cols="2">
            <default-button @click="filter">Filtrar</default-button>
          </v-col>
        </v-row>
      </div>
      <default-table-head>
        <v-col cols="6" class="border-right">Pedido</v-col>
        <v-col cols="6">Volumes</v-col>
      </default-table-head>
      <template v-if="!getIsLoadingPickupOrders">
        <template v-if="getPickupOrders.length > 0">
          <default-table-body-row
            align="center"
            v-for="(order, index) in getPickupOrders"
            :key="'order' + index"
          >
            <v-col cols="6" class="primary--text font-weight-bold">{{
              order.numeroPedido
            }}</v-col>
            <v-col cols="6" class="text-start">
              <div
                v-for="(volume, index) in order.volumes"
                :key="'volume' + index"
              >
                <v-icon size="10" :color="volume.status | formatStatusColor"
                  >mdi-circle</v-icon
                >{{ " " }}
                <span
                  :inner-html.prop="volume.codigoVolume | formatVolume"
                ></span>
                -
                <span class="overline">{{ volume.status | formatStatus }}</span>
              </div>
            </v-col>
          </default-table-body-row>
          <v-col>
            <default-pagination
              v-model="currentPage"
              :length="getPickupOrdersPagination.lastPage"
              @input="changePage"
            />
          </v-col>
        </template>
      </template>
      <v-col v-else>
        <default-spinner />
      </v-col>
    </v-container>
    <div
      justify="center"
      style="position: sticky; bottom: 0"
      class="base"
      align="center"
    >
      <v-divider />
      <v-col cols="4">
        <default-button @click="dialogModel = false">Fechar</default-button>
      </v-col>

      <span
        v-show="!isHeaderVisible"
        style="position: absolute !important; bottom: 12px; right: 12px"
        @click="$refs.container.scrollIntoView()"
      >
        <back-to-top />
      </span>
    </div>
  </default-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultButton from "../../defaultComponents/DefaultButton.vue";
import DefaultTextField from "../../defaultComponents/DefaultTextField.vue";
import DefaultModal from "../../defaultComponents/DefaultModal.vue";
import DefaultTableHead from "../../defaultComponents/DefaultTableHead.vue";
import DefaultTableBodyRow from "../../defaultComponents/DefaultTableBodyRow.vue";
import DefaultPagination from "../../defaultComponents/DefaultPagination.vue";
import DefaultSpinner from "../../defaultComponents/DefaultSpinner.vue";
import BackToTop from "../../defaultComponents/BackToTop.vue";

export default {
  name: "PickupOrders",
  data() {
    return {
      volumeStatuses: [
        { text: "Pendente", value: [1, 0, 0], colors: ["pink"] },
        { text: "Recebido", value: [0, 1, 0], colors: ["green"] },
        { text: "Todos", value: [0, 0, 1], colors: ["pink", "green"] },
      ],
      isHeaderVisible: true,
    };
  },
  components: {
    DefaultButton,
    DefaultModal,
    DefaultPagination,
    DefaultTableHead,
    DefaultTableBodyRow,
    DefaultTextField,
    DefaultSpinner,
    BackToTop,
  },
  props: {
    value: {},
    carrier: {
      required: true,
    },
    pickupId: {
      required: true,
    },
    date: {
      required: true,
    },
  },
  methods: {
    ...mapActions("expedition", [
      "setShowVolumesByStatus",
      "pickupOrdersRecebimento",
      "setPickupOrdersUrlParams",
      "setCurrentPageToFirstPage",
      "setIsPickupOrdersModalOpen",
    ]),
    changePage() {
      this.pickupOrdersRecebimento(this.pickupId);
    },
    filter() {
      this.setCurrentPageToFirstPage();
      this.pickupOrdersRecebimento(this.pickupId);
    },
    onIntersectingHeader(entries) {
      if (entries.length > 1) {
        return;
      }
      this.isHeaderVisible = entries[0].isIntersecting;
    },
  },
  computed: {
    ...mapGetters("expedition", [
      "getPickupOrders",
      "getPickupOrdersPagination",
      "getPickupOrdersUrlParams",
      "getIsLoadingPickupOrders",
      "getShowVolumesByStatus",
    ]),
    dialogModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    volumeStatus: {
      get() {
        return this.getShowVolumesByStatus;
      },
      set(newValue) {
        this.setShowVolumesByStatus(newValue);
      },
    },
    currentPage: {
      get() {
        return this.getPickupOrdersUrlParams.page;
      },
      set(newValue) {
        this.setPickupOrdersUrlParams({
          ...this.getPickupOrdersUrlParams,
          page: newValue,
        });
      },
    },
    order: {
      get() {
        return this.getPickupOrdersUrlParams.numeroPedido;
      },
      set(newValue) {
        this.setPickupOrdersUrlParams({
          ...this.getPickupOrdersUrlParams,
          numeroPedido: newValue,
        });
      },
    },
  },
  filters: {
    formatStatus(status) {
      const statuses = {
        IMPORTADO: "PENDENTE",
      };
      return statuses[status] ?? status;
    },
    formatStatusColor(status) {
      const statusColors = {
        IMPORTADO: "pink",
        RECEBIDO: "green",
      };
      return statusColors[status] ?? "transparent";
    },
  },
};
</script>

<style scoped></style>
