<template>
  <v-container>
    <remessas-header />
    <remessas-list />
  </v-container>
</template>

<script>
import remessasHeader from "../../components/defaultUserComponents/remessas/remessasHeader.vue";
import remessasList from "../../components/defaultUserComponents/remessas/remessasList.vue";
export default {
  components: { remessasHeader, remessasList },
};
</script>
