<template>
  <v-dialog v-if="enabledSync" v-model="enabledSync" max-width="330">
    <v-card>
      <v-card-title class="justify-center">
        <h3 class="mb-6 navy--text">Atenção</h3>
      </v-card-title>
      <v-card-text class="text-h6">
        Deseja confirmar a finalização da coleta?
      </v-card-text>

      <v-card-actions class="justify-centerb">
        <div class="my-4">
          <!-- <v-hover v-slot="{ hover }">
                <v-btn
                  block
                  large
                  rounded
                  @click="$emit('close')"
                  :color="hover ? 'red darken-1' : 'red'"
                  class="white--text text-truncate"
                  style="text-transform: none !important; font-weight: bolder"
                >
                  Cancelar</v-btn
                >
              </v-hover> -->
          <v-btn
            color="red darken-1"
            class="mr-1"
            text
            large
            @click="$emit('close')"
          >
            Cancelar
          </v-btn>
          <!-- <v-hover v-slot="{ hover }">
                <v-btn
                  block
                  large
                  rounded
                  @click="$emit('confirma-finalizar')"
                  :color="hover ? 'green darken-1' : 'green'"
                  class="white--text text-truncate"
                  style="text-transform: none !important; font-weight: bolder"
                >
                  Confirmar</v-btn
                >
              </v-hover> -->
          <v-btn
            color="green darken-1"
            class="mr-1"
            text
            large
            @click="$emit('confirma-finalizar')"
          >
            Confirmar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EmbarqueColetaDialogConfirmaFinalizarColeta",
  data: () => ({
    loading: false,
  }),
  props: {
    enabled: { type: Boolean, required: true },
  },
  watch: {
    enabledSync(enabledSync) {
      if (enabledSync) {
        this.loadVolumesDetalhes();
      }
    },
  },
  computed: {
    enabledSync: {
      get() {
        return this.enabled;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>
<style lang="scss"></style>
