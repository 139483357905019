export default {
  getListingTransportadoras(state) {
    return state.listingTransportadoras;
  },
  getFilteredTransportadoras(state) {
    return state.filteredTransportadoras;
  },
  getFilter: (state, getters) => (search) => {
    if (search == "") {
      return (state.filteredTransportadoras = state.listingTransportadoras);
    } else {
      return (state.filteredTransportadoras =
        getters.getListingTransportadoras.filter(({ nomeFantasia }) =>
          nomeFantasia
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              search
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )
        ));
    }
  },
  getListEmailTransportadora(state) {
    return state.listingEmailTransportadora;
  },
};
