export default {
  getListingExpedition(state) {
    return state.listingExpedition;
  },

  getExpeditionPagination(state) {
    return state.expeditionPagination;
  },

  getCollectRequests(state) {
    return state.collectionRequest;
  },

  getForklifts(state) {
    return state.forklifts;
  },

  getDocks(state) {
    return state.docks;
  },

  getDrivers(state) {
    return state.drivers;
  },

  getProtocolDetails(state) {
    return state.protocolDetails;
  },

  getIsLoading(state) {
    return state.isLoading;
  },

  getColetaDetalhes(state) {
    return state.coletaDetalhes;
  },

  getUrlParams(state) {
    return state.urlParams;
  },

  getVolumesComErros(state) {
    return state.volumesComErros;
  },

  getModalVolumesComErros(state) {
    return state.modalVolumesComErros;
  },

  getDataExpeditionCurrentDay(state) {
    return state.dataExpeditionCurrentDay;
  },

  getDataExpeditionInicial(state) {
    return state.dataExpeditionInicial;
  },

  getDataExpeditionFinal(state) {
    return state.dataExpeditionFinal;
  },

  getDataFaturamentoInicial(state) {
    return state.dataFaturamentoInicial;
  },

  getDataFaturamentoFinal(state) {
    return state.dataFaturamentoFinal;
  },

  getListExpeditionUserList(state) {
    return state.listExpeditionUserList;
  },

  getListExpeditionUserListByColetaId(state) {
    return state.listExpeditionUserListByColetaId;
  },

  getListingManifest(state) {
    return state.listingManifest;
  },

  getCheckedList(state) {
    return state.checkedList;
  },

  getProtocolData(state) {
    return state.protocolDataList;
  },

  getPickupOrders(state) {
    return state.pickupOrders;
  },

  getPickupOrdersPagination(state) {
    return state.pickupOrdersPagination;
  },

  getPickupOrdersUrlParams(state) {
    return state.pickupOrdersUrlParams;
  },

  getIsLoadingPickupOrders(state) {
    return state.isLoadingPickupOrders;
  },

  getShowVolumesByStatus(state) {
    return [
      state.pickupOrdersUrlParams.pendente,
      state.pickupOrdersUrlParams.embarcado,
      state.pickupOrdersUrlParams.emAndamento,
    ];
  },

  getIsPickupOrdersModalOpen(state) {
    return state.isPickupOrdersModalOpen;
  },

  getDetailsPedido(state) {
    return state.detailsPedido;
  },

  getIsLoadingPickupDetails(state) {
    return state.isLoadingPickupDetails;
  },
};
