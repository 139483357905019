import { HttpRestService } from "@/services/http/HttpRestService";

export class PanelsRepository {
  static paineis(url) {
    return HttpRestService.get(url);
  }
  static getPalletAmount(tipo_insumo){
    return HttpRestService.get(`/pallet/listar/configuracao?tipo_insumo=${tipo_insumo}`)
  }
  static getPalletListing(){
    return HttpRestService.get('/pallet/listar')
  }
  static updateConfigPallet(data){
    return HttpRestService.post('/pallet/alterar/configuracao', data)
  }
}
