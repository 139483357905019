<template>
  <div class="mt-3">
    <v-row no gutters class="justify-start mt-3">
      <v-col cols="12" sm="16" md="12" lg="4" xl="5">
        <v-text-field
          placeholder="Transportadora"
          v-model="urlParams.transportadora"
          clearable
          dense
          solo
          flat
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3" xl="5">
        <v-text-field
          placeholder="Coleta"
          v-model="urlParams.coleta"
          clearable
          dense
          solo
          flat
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3" xl="5">
        <v-text-field
          placeholder="Placa"
          v-model="urlParams.placa"
          clearable
          dense
          solo
          flat
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="2" xl="2">
        <v-row no-gutters>
          <v-hover v-slot="{ hover }">
            <v-btn
              rounded
              block
              :loading="isLoadingBtn"
              :color="hover ? 'secondary' : 'primary'"
              class="top navy--text text-truncate"
              style="text-transform: none !important; font-weight: bolder"
              @click="doASearch"
              >Filtrar</v-btn
            >
          </v-hover>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  data: () => ({
    isLoadingBtn: false,
    menu: false,
    reRender: 0,
    searchIcon: "mdi-magnify",
    hover: true,
    urlParams: {
      page: 1,
      transportadora: "",
      coleta: "",
      placa: "",
    },
  }),
  computed: {
    ...mapGetters("reception", ["getDataInicial", "getReceptionPagination"]),
    isCurrentPage() {
      return this.$store.getters["drivers/getReceptionPagination"].currentPage;
    },
    dataInicial: {
      get() {
        return this.formatDate(this.getDataInicial.dataInicial);
      },
      set(dataInicial) {
        this.setDataInicial({
          dataInicial,
        });
      },
    },
    dataSemFiltroInicial: {
      get() {
        return this.getDataInicial.dataInicial;
      },
      set(dataSemFiltroInicial) {
        this.setDataSemFiltro({
          dataSemFiltroInicial,
        });
      },
    },
  },
  methods: {
    ...mapActions("reception", [
      "receptionList",
      "setLoadingReception",
      "setDataInicial",
      "setUrlParams",
    ]),
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async doASearch() {
      this.setLoadingReception(true);
      const urlParams = {
        page: 1,
        transportadora: this.urlParams.transportadora.toUpperCase(),
        placa: this.urlParams.placa.toUpperCase(),
        coleta: this.urlParams.coleta,
      };
      await this.receptionList(urlParams);
      this.setLoadingReception(false);
    },
  },
  created() {
    this.dataInicial = "";
  },
};
</script>

<style scoped></style>
