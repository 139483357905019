export default {
  pickups: [],
  isLoadingPickups: false,
  pagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  urlParams: {
    page: 1,
    perPage: 10,
    unidade: 2,
    codigo: "",
    search: "",
    initialDate: "",
    finalDate: "",
  },
  isLoadingBiparVolume: false,
  isStartShippingModalOpen: false,
};
