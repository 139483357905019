import { HttpRestService } from "@/services/http/HttpRestService";

export class ColetaTransportadoraRepository {
  static requestColeta(url) {
    return HttpRestService.get(url);
  }

  static listDocks() {
    return HttpRestService.get(`/docas/listar`);
  }

  static requestListDriversAndVehicles(transportadoraId) {
    return HttpRestService.get(
      `/transportadora/veiculo/motorista/${transportadoraId}`
    );
  }

  static updateVehicle(body) {
    return HttpRestService.post(`/veiculo/editar/veiculo/coleta`, body);
  }

  static updateDriver(body) {
    return HttpRestService.post(`/motorista/editar/motorista/coleta`, body);
  }
}
