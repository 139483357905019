export default {
  getListingAgePedidos(state) {
    return state.listingAgePedidos;
  },
  getAgePedidosPagination(state) {
    return state.agePedidosPagination;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getHaveFilters(state) {
    return state.haveFilters;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
  getPedidosReenvio(state) {
    return state.pedidosReenvio;
  },
  getReenvioDialog(state) {
    return state.reenvioDialog;
  },
};
