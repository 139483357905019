export default {
  setListingDrivers(state, response) {
    state.listingDrivers = response;
  },
  setDriversPagination(state, response) {
    state.driversPagination.total = response.total;
    state.driversPagination.perPage = response.perPage;
    state.driversPagination.currentPage = response.currentPage;
    state.driversPagination.lastPage = response.lastPage;
    state.driversPagination.nextPage = response.nextPage;
  },
  setIsLoading(state, response) {
    state.isLoading = response;
  },
  setListingCompanys(state, response) {
    state.listingCompanys = response;
  },
  setUrlParams(state, urlParams) {
    state.urlParams.page = urlParams.page;
    state.urlParams.search = urlParams.search;
    state.urlParams.transportadora = urlParams.transportadora;
    state.urlParams.transportadoraId = urlParams.transportadoraId;
  },
};
