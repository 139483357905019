export default {
  isLoading: false,
  listingPrevisaoPatio: [],
  filteredPrevisaoPatio: [],
  isLoadingSearch: false,
  urlParams: {
    page: 1,
    search: "",
  },
};
