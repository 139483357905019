export default {
  getListingPerfis(state) {
    return state.listingPerfis;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getWhiteList(state) {
    return state.whiteList;
  },
  getWhiteListEdit(state) {
    return state.whiteListEdit;
  },

  getFilteredPerfis(state) {
    return state.filteredPerfis;
  },

  getFilter: (state, getters) => (search) => {
    if (search == "") {
      return (state.filteredPerfis = state.listingPerfis);
    } else {
      return (state.filteredPerfis = getters.getListingPerfis.filter(
        ({ roleName }) => roleName.toLowerCase().includes(search.toLowerCase())
      ));
    }
  },
};
