export default {
  getListaInsumos(state) {
    return state.listaInsumos;
  },
  getListaVolumes(state) {
    return state.listaVolumes;
  },
  getDetailsOfManifest(state){
    return state.detailsOfManifest;
  }
};
