import PickupReception from "../../../repositories/defaultUserRepositories/PickupReceptionRepository";
import { ErrorNotifier } from "../../../services/messages/ErrorNotifier";
import state from "./state";

export default {
  async pickups({ state, commit, dispatch }) {
    try {
      commit("setIsLoadingPickups", true);
      commit("setPickups", []);
      const response = await PickupReception.getPickups(state.urlParams);
      if (response.status === 200) {
        const pickups = response.data.data.list;
        const pagination = response.data.data.pagination;
        commit("setPickups", pickups);
        commit("setPagination", pagination);
        return;
      }
      throw response;
    } catch (error) {
      dispatch("errorMessage", error);
    } finally {
      commit("setIsLoadingPickups", false);
    }
  },

  errorMessage({ commit, dispatch }, payload) {
    ErrorNotifier.run(commit, dispatch, payload);
  },

  setUrlParams({ commit }, payload) {
    commit("setUrlParams", payload);
  },

  setCurrentPageToFirstPage({ commit, state }) {
    commit("setUrlParams", { ...state.urlParams, page: 1 });
  },

  resetUrlParams({ commit }) {
    const originalUrlParams = state.urlParams;
    commit("setUrlParams", originalUrlParams);
  },

  async biparVolume({ commit, dispatch }, body) {
    commit("setIsLoadingBiparVolume", true);
    const data = {
      recebimentoId: body.recebimentoId,
      codigoVolume: `${body.volume}`,
    };
    const response = await PickupReception.biparVolume(data);
    if (response.status >= 200 && response.status < 300) {
      commit("setIsLoadingBiparVolume", false);
      return true;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("setIsLoadingBiparVolume", false);
      commit("notifications/setErrorNotification", errorObj, { root: true });
      dispatch("expedition/reloadColetaDetalhesByColetaId", recebimentoId);
      return false;
    }
  },
};
