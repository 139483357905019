<template>
  <v-container>
    <reception-vehicle-header />
    <reception-vehicle-list />
  </v-container>
</template>

<script>
import ReceptionVehicleHeader from "../../components/defaultUserComponents/receptionVehicle/ReceptionVehicleHeader.vue";
import ReceptionVehicleList from "../../components/defaultUserComponents/receptionVehicle/ReceptionVehicleList.vue";

export default {
  components: {
    ReceptionVehicleHeader,
    ReceptionVehicleList,
  },
  data() {
    return {};
  },
};
</script>
