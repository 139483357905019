<template>
  <v-card class="overflow-hidden">
    <v-card-title class="justify-center">
      <span class="text-h6 font-weight-bold navy--text"
        >Detalhes da Remessa</span
      >
    </v-card-title>
    <v-card-text>
      <v-data-table
        aria-label="Coletas"
        :headers="headers"
        :items="coletasMapped"
        v-model="selectedToRemove"
        class="elevation-1"
        disable-pagination
        hide-default-footer
        show-select
        item-class="primary"
        :single-select="false"
        item-key="numeroPedido"
        dense
      >
        <!-- <template v-slot:[`item.action`]="{ item }">
          <v-icon small @click="deleteItem(item)" color="primary">
            mdi-delete
          </v-icon>
        </template> -->
      </v-data-table>
      <div class="my-4">
        <v-row>
          <v-col>
            <v-hover v-slot="{ hover }">
              <v-btn
                :disabled="!habilitarBtnRemoverPedido"
                rounded
                :color="hover ? 'secondary' : 'primary'"
                class="navy--text text-truncate"
                style="text-transform: none !important; font-weight: bolder"
                @click="deleteItem"
                >Remover Pedido</v-btn
              >
            </v-hover>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RemessaColetaCadastroDetalhes",
  props: {
    coletas: { type: Array, required: true },
    pedidos: { type: Array, required: true },
    pedidosTransportadoraFilha: { type: Array, required: true },
    tiposVeiculo: { type: Array, required: true },
  },
  data: () => ({
    selectedToRemoveList: [],
    headers: [
      { text: "Coleta", value: "coleta", sortable: true, align: "start" },
      { text: "Doca", value: "doca", sortable: true, align: "start" },
      {
        text: "Tipo veículo",
        value: "tipoVeiculo",
        sortable: true,
        align: "start",
      },
      {
        text: "Núm. pedido",
        value: "numeroPedido",
        sortable: true,
        align: "start",
      },
      {
        text: "Tipo pedido",
        value: "tipoPedido",
        sortable: true,
        align: "start",
      },
      { text: "Área", value: "area", sortable: true, align: "start" },
      {
        text: "Qtd. caminhões",
        value: "quantidadeCaminhoesAlocados",
        sortable: true,
        align: "start",
      },
      {
        text: "Qtd. volumes",
        value: "quantidadeVolumes",
        sortable: true,
        align: "start",
      },
      {
        text: "Qtd. painéis",
        value: "quantidadePaineis",
        sortable: true,
        align: "start",
      },
      {
        text: "Data produção",
        value: "dataProducao",
        sortable: true,
        align: "start",
      },
    ],
  }),
  computed: {
    ...mapGetters("remessas", [
      "getRemessaCadastro",
      "getDataRemessa",
      "getDataColeta",
      "getListaRemessas",
      "getTipoColeta",
    ]),
    showRedespacho() {
      return this.getTipoColeta === "EMBARQUE_REDESPACHO" ? true : false;
    },
    coletasMapped() {
      if (this.showRedespacho) {
        return this.coletas
          .map((c) => ({
            ...c,
            pedidos: c.pedidos.map((pedidoId) =>
              this.pedidosTransportadoraFilha.find(
                (p) => p.pedidoId === pedidoId
              )
            ),
          }))
          .map((coleta, i) => {
            const pedidos = coleta.pedidos.map((pedido) => ({
              ...pedido,
              ...coleta,
              coleta: i + 1,
              doca: coleta.docaId ?? "-",
              tipoVeiculo: this.tiposVeiculo.find(
                ({ tipoVeiculoId }) => tipoVeiculoId === coleta.tipoVeiculoId
              ).descricao,
            }));
            for (const p of pedidos) {
              delete p.pedidos;
            }
            return pedidos;
          })
          .reduce((a, b) => a.concat(b), []);
      } else {
        return this.coletas
          .map((c) => ({
            ...c,
            pedidos: c.pedidos.map((pedidoId) =>
              this.pedidos.find((p) => p.pedidoId === pedidoId)
            ),
          }))
          .map((coleta, i) => {
            const pedidos = coleta.pedidos.map((pedido) => ({
              ...pedido,
              ...coleta,
              coleta: i + 1,
              doca: coleta.docaId ?? "-",
              tipoVeiculo: this.tiposVeiculo.find(
                ({ tipoVeiculoId }) => tipoVeiculoId === coleta.tipoVeiculoId
              ).descricao,
            }));
            for (const p of pedidos) {
              delete p.pedidos;
            }
            return pedidos;
          })
          .reduce((a, b) => a.concat(b), []);
      }
    },
    selectedToRemove: {
      get() {
        return this.selectedToRemoveList;
      },
      set(itemSelected) {
        this.selectedToRemoveList = itemSelected;
      },
    },
    habilitarBtnRemoverPedido() {
      return this.selectedToRemoveList.length > 0;
    },
  },
  methods: {
    deleteItem() {
      let item = this.selectedToRemoveList;
      for (let i = 0; i < item.length; i++) {
        this.removeItemFromColetasMapped(item[i]);
        this.$emit("delete-item", item[i]);
      }
      // this.$emit("delete-item", item);
    },
    removeItemFromColetasMapped(item) {
      const index = this.coletasMapped.findIndex(
        (c) => c.pedidoId === item.pedidoId
      );
      this.coletasMapped.splice(index, 1);
    },
  },
};
</script>
<style scoped>
.h {
  font-weight: bolder;
}
.list-header {
  background-color: #0f2733;
  color: white;
  font-weight: bolder;
  min-height: 30px;
}
.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
.list-card {
  color: black;
  font-weight: normal;
}

.list-card:hover {
  background-color: #ffdfcc;
  color: black;
}
::v-deep
  .theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  color: white;
}
</style>
