import { HttpRestService } from "@/services/http/HttpRestService";

export class TransportadoraRepository {
  static requestTransportadora() {
    return HttpRestService.get("/transportadora/pedidos");
  }

  static updateTransportadora(modelUpdateTransportadora) {
    return HttpRestService.post(
      `/transportadora/email/criar`,
      modelUpdateTransportadora
    );
  }

  static listEmailTransportadora(transportadoraId) {
    return HttpRestService.get(
      `/transportadora/email/listar/${transportadoraId}`
    );
  }

  static resendUp(modelResend) {
    return HttpRestService.post(`/transportadora/enviar/email`, modelResend);
  }
}
