<template>
  <div class="mt-3">
    <v-row class="mx-auto my-auto" align="center">
      <v-col cols="2" sm="2" md="2" lg="2" xl="2" class="text-start">
        <v-hover v-slot="{ hover }">
          <v-btn
            fab
            small
            elevation="3"
            color="primary"
            class="text-truncate"
            :class="hover ? 'base--text' : 'navy--text'"
            @click="backToTransportadora"
            style="text-transform: none !important; font-weight: bolder"
          >
            <v-icon> mdi-arrow-left-bold </v-icon>
          </v-btn>
        </v-hover>
      </v-col>
      <v-col sm="6" md="6" lg="6" xl="6" cols="6" class="text-start">
        <span class="text-truncate navy--text">
          Transportadora:
          <b>
            {{
              this.$route.params.nomeFantasia ||
              this.getTransportadoraData.nomeFantasia
            }}</b
          >
        </span>
      </v-col>
      <v-col sm="4" md="4" lg="4" xl="4" cols="4" class="text-start">
        <span class="text-truncate navy--text">
          CNPJ:
          <b>{{
            this.$route.params.cnpj || this.getTransportadoraData.cnpj
          }}</b>
        </span>
      </v-col>
    </v-row>
    <v-row class="mx-auto my-auto">
      <v-col cols="11" sm="9" md="3" lg="3" xl="3">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          label="Buscar Número"
          v-model="urlParams.search"
          clear-icon="mdi-close-circle"
          clearable
          dense
          solo
          flat
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="3" md="2" lg="2" xl="2">
        <v-select
          :items="filterType"
          v-model="urlParams.selecaoTipo"
          label="Tipo"
          placeholder="Tipo"
          item-text="text"
          item-value="value"
          dense
          clearable
          solo
          flat
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="2" lg="2" xl="2">
        <v-select
          v-model="urlParams.selecaoStatus"
          label="Status"
          placeholder="Status"
          :items="items"
          item-text="text"
          item-value="value"
          dense
          clearable
          solo
          flat
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="2" lg="2" xl="2">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              ref="dataRemessa"
              prepend-inner-icon="mdi-calendar"
              label="Data"
              placeholder="Data"
              v-model="dataFormatada"
              v-bind="attrs"
              v-on="on"
              dense
              solo
              readonly
              flat
              clearable
              clear-icon="mdi-close-circle"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dataDaColeta"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="1" lg="1" xl="1" class="pb-1">
        <v-hover v-slot="{ hover }">
          <v-btn
            rounded
            block
            @click="search"
            color="primary"
            class="text-truncate"
            :loading="isLoadingBt"
            :class="hover ? 'base--text' : 'navy--text'"
            max-width="160px"
            style="text-transform: none !important; font-weight: bolder"
          >
            Filtrar
          </v-btn>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="pb-1">
        <v-hover v-slot="{ hover }">
          <v-btn
            rounded
            block
            color="primary"
            class="text-truncate"
            :class="hover ? 'base--text' : 'navy--text'"
            style="text-transform: none !important; font-weight: bolder"
            @click="abrirTelaCriacaoRemessas"
          >
            Criar Remessa
          </v-btn>
        </v-hover>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogCreateRemessa"
      scrollable
      max-width="820"
      transition="dialog-bottom-transition"
    >
      <remessa-cadastro
        @closeCadastrarRemessa="closeCadastrarRemessa"
        :key="reRender"
        :transportadoraId="transportadoraId"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RemessaCadastro from "@/views/defaultUserViews/RemessaCadastro.vue";
export default {
  components: { RemessaCadastro },
  data: () => ({
    dataFormatada: "",
    dataDaColeta: "",
    reRender: 0,
    dialogCreateRemessa: false,
    isLoading: false,
    hover: true,
    menu1: false,
    transportadoraId: null,
    isLoadingBt: false,
    urlParams: {
      page: 1,
      search: "",
      selecaoStatus: "",
      selecaoDate: "",
      selecaoTipo: 0,
    },
    items: [
      { value: "PENDENTE_ENVIO_EMAIL", text: "Pendente" },
      { value: "FALHA_ENVIO_EMAIL", text: "Falha Email" },
      { value: "EMAIL_ENVIADO", text: "Email Enviado" },
      { value: "QUANTIDADE_VEICULOS_DIVERGENTE", text: "Veículo Divergente" },
      { value: "DIVERGENCIA_HORARIO_DATA", text: "Tempo Divergente" },
      { value: "CONFIRMADO_PELA_TRANSPORTADORA", text: "Confirmado" },
      { value: "EM_ANDAMENTO", text: "Em Andamento" },
      { value: "CONCLUIDO", text: "Concluído" },
      { value: "CANCELADO", text: "Cancelado" },
    ],
    filterType: [
      { value: 1, text: "Remessa" },
      { value: 2, text: "Coleta" },
    ],
  }),
  computed: {
    ...mapGetters("remessas", [
      "getListaRemessas",
      "getRemessasPagination",
      "getTransportadoraData",
      "getDataRemessa",
      "setDataSemFiltro",
    ]),
    isCurrentPage() {
      return this.$store.getters["remessas/getRemessasPagination"].currentPage;
    },
    dataInicio: {
      get() {
        return this.getListaRemessas.dataInicio;
      },
      set(dataInicio) {
        this.setRemessaCadastro({
          ...this.getListaRemessas,
          dataInicio,
        });
      },
    },
    dataRemessa: {
      get() {
        return this.formatDate(this.getDataRemessa.dataRemessa);
      },
      set(dataRemessa) {
        this.setDataRemessa({
          dataRemessa,
        });
      },
    },
    dataSemFiltro: {
      get() {
        return this.getDataRemessa.dataRemessa;
      },
      set(dataSemFiltro) {
        this.setDataSemFiltro({
          dataSemFiltro,
        });
      },
    },
  },
  methods: {
    ...mapActions("remessas", [
      "listarRemessas",
      "setDataRemessa",
      "setIsLoading",
      "setUrlParams",
    ]),
    async search() {
      this.setIsLoading(true);
      const urlParams = {
        page: 1,
        search: this.urlParams.search,
        selecaoStatus: this.urlParams.selecaoStatus,
        selecaoDate: this.dataDaColeta,
        transportadoraId: this.$route.params.transportadoraId,
        selecaoTipo: this.urlParams.selecaoTipo,
      };
      await this.setUrlParams(urlParams);
      await this.listarRemessas();
      this.setIsLoading(false);
    },
    abrirTelaCriacaoRemessas() {
      this.reRender += 1;
      this.dialogCreateRemessa = true;
      this.transportadoraId = this.$route.params;
      // this.$router.replace({
      //   name: "Cadastro de Remessas",
      //   params: { transportadoraId },
      // });
    },
    backToTransportadora() {
      const { transportadoraId } = this.$route.params;
      this.$router.replace({
        name: "Transportadoras",
        params: { transportadoraId },
      });
    },

    async closeCadastrarRemessa() {
      this.dialogCreateRemessa = false;
      await this.search();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    disform() {
      if (this.dataFormatada == "") return this.dataDaColeta == "";
    },
  },
  watch: {
    dataDaColeta(newValue, oldValue) {
      if (newValue.length > 0) {
        this.dataFormatada = this.formatDate(newValue);
      }
    },
    dataFormatada(newValue, oldValue) {
      if (newValue == null) {
        this.dataDaColeta = "";
      }
    },
  },
};
</script>

<style scoped>
.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
</style>
