import { PatioRepository } from "../../../repositories/defaultUserRepositories/PatioRepository";
import { ErrorNotifier } from "../../../services/messages/ErrorNotifier";

export default {
  async confPatio({ commit, state, dispatch }) {
    commit("setListingConfPatio", []);

    try {
      const urlParams = state.urlParams;
      let url = `/baia/patio/listar?page=${urlParams.page}&perPage=${50}`;
      if (urlParams.search) {
        url = url.concat(`&tipoBaia=${urlParams.search}`);
      }
      const response = await PatioRepository.requestConfPatio(url);
      if (response.status >= 200 && response.status < 300) {
        commit("setListingConfPatio", response.data.data.list);
        commit("setConfPatioPagination", response.data.data.pagination);
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async patioCreation({ commit, dispatch }, body) {
    try {
      const response = await PatioRepository.createConfPatio(body);
      if (response.status >= 200 && response.status < 300) {
        let successObj = {
          successStatus: true,
          successMessage: response.data.message,
        };
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async patioEdit({ commit, dispatch }, body) {
    try {
      const response = await PatioRepository.patioEdit(body);
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        let successObj = {
          successStatus: true,
          successMessage: response.data.message,
        };
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },

  errorMessage({ commit, dispatch }, value) {
    ErrorNotifier.run(commit, dispatch, value);
    return;
  },

  checkToken({ dispatch }, value) {
    if (value.data.status === "O token é inválido.") {
      value.data.message = "O token é inválido.";
      value.status = "O token é inválido.";
      dispatch("errorMessage", value);
      return;
    }
    return;
  },
};
