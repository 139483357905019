export default {
  setListaInsumos(state, response) {
    state.listaInsumos = response;
  },
  setListaVolumes(state, response) {
    state.listaVolumes = response;
  },
  setDetailsOfManifest(state, response){
    state.detailsOfManifest = response;
  }
};
