import { HttpRestService } from "@/services/http/HttpRestService";

export class ProtocolsRepository {  
   static requestProtocols() {
        return HttpRestService.get("/coleta/listar?perPage=10&page=1");
    }

    static cancelProtocol(coletaId) {
        return HttpRestService.post(`/protocolo/cancelar`, coletaId);
    }

    static generateProtocol(payload) {
        return  HttpRestService.post("/enviar/protocolo/email", payload);
    }


}
