import { HttpRestService } from "@/services/http/HttpRestService";

export class RemessasRepository {
  static requestRemessas(url) {
    return HttpRestService.get(url);
  }

  static requestColetaByRemessa(url) {
    return HttpRestService.get(url);
  }

  static requestCadastrarRemessa(payload) {
    return HttpRestService.post("/remessa", payload);
  }

  static requestCadastrarRedespacho(payload) {
    return HttpRestService.post("/remessa-encaminhamento", payload);
  }

  static requestConfirmDivergence(payload) {
    return HttpRestService.post("/remessa/resolver/divergencia", payload);
  }

  static requestConfirmDivergenceHorario(payload) {
    return HttpRestService.post(
      "/remessa/resolver/divergencia/horario-data ",
      payload
    );
  }

  static requestDataParaCadastroRemessa(transportadoraId) {
    return HttpRestService.get(
      `/transportadora/${transportadoraId}/remessa/cadastro`
    );
  }
  //Divergência de veículos
  static getDetalhesDaDivergencia(remessaId) {
    return HttpRestService.get(`/remessa/detalhes/divergencia/${remessaId}`);
  }
  //Divergencia de horário
  static getDetalhesDaDivergenciaHorario(remessaId) {
    return HttpRestService.get(`/remessa/listar-hora/divergencia/${remessaId}`);
  }
  static requestTransportadorasFilhas(transportadoraId) {
    return HttpRestService.get(
      `/redespacho/transportadora?transportadoraInicialId=${transportadoraId}`
    );
  }

  static pedidosTransportadoraFilha(transportadoraId) {
    return HttpRestService.get(
      `/transportadora/${transportadoraId}/remessa-encaminhamento/cadastro`
    );
  }
}
