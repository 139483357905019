export default {
  getListingPaineis(state) {
    return state.listingPaineis;
  },
  getListingPaineisCubagem(state) {
    return state.listingPaineisCubagem;
  },
  getPaineisPagination(state) {
    return state.paineisPagination;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getPalletsListing(state) {
    return state.listingPallets;
  },
  getPalletsAmount(state) {
    return state.listingPalletsAmount;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
};
