<template>
  <v-container>
    <v-row>
      <v-col>
        <v-img
          src="../../assets/Imagem 404.svg"
          class="mt-0 mb-5 justify-center"
          contain
          height="24rem"
        />
        <v-row class="justify-center">
          <span class="font-weight-bold text-h4 navy--text mt-5"
            >Página não encontrada.</span
          >
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "NotFoundView",
};
</script>
<style>
</style>