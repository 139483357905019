import { HttpRestService } from "@/services/http/HttpRestService";

export default class PickupReception {
  static getPickups(urlParams) {
    return HttpRestService.get(`/recebimento/coletas`, urlParams);
  }

  static biparVolume(data) {
    return HttpRestService.post("/recebimento/coleta/bipagem", data);
  }
}
