<template>
  <v-card class="overflow-y-auto">
    <v-card-title class="justify-center">
      <span class="text-h6 font-weight-bold navy--text"
        >Detalhes da Coleta - {{ transportadoraNome }}
      </span>
      <span class="text-h6 font-weight-bold navy--text"> </span>
    </v-card-title>
    <div v-if="!isLoading">
      <v-card-text>
        <v-container>
          <v-row justify="space-around">
            <v-col cols="5" align="start">
              <span class="text-body-1 font-weight-bold navy--text mx-1"
                >Hora Inicial:</span
              >
              <span>{{
                getDataEditColeta.dataInicioEmbarque | formatHour
              }}</span>
            </v-col>
            <v-col cols="5" align="start">
              <span class="text-body-1 font-weight-bold navy--text mx-1"
                >Hora Final:</span
              >
              <span>{{ getDataEditColeta.dataFimEmbarque | formatHour }}</span>
            </v-col>
          </v-row>
          <v-row justify="space-around">
            <v-col cols="5" align="start">
              <span class="text-body-1 font-weight-bold navy--text mx-1"
                >Data da Coleta:</span
              >
              <span>{{
                getDataEditColeta.dataInicioEmbarque | formatDate
              }}</span>
            </v-col>

            <v-col cols="5" align="start">
              <span
                class="font-weight-bold"
                style="width: 100%"
                :color="docaSelected.docaCor"
              >
                <span class="text-body-1 font-weight-bold navy--text mx-1"
                  >Doca:</span
                >
                <v-icon class="mx-1" :color="docaSelected.docaCor"
                  >mdi-circle</v-icon
                >
                Doca - {{ getDataEditColeta.docaCodigo }}
              </span>
            </v-col>
          </v-row>
          <v-row justify="space-around">
            <v-col cols="5" align="start">
              <span class="text-body-1 font-weight-bold navy--text mx-1"
                >Tipo do Veículo:</span
              >
              <span>{{ getDataEditColeta.tipoVeiculoNome }}</span>
            </v-col>
            <v-col cols="5" align="start" class="text-start">
              <div
                class="base_dark rounded-xl pa-1 pr-2"
                style="position: fixed"
              >
                <span class="navy--text">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="ma-2" color="navy" v-bind="attrs" v-on="on"
                        >mdi-truck-outline</v-icon
                      >
                    </template>
                    <span>Comprimento do Caminhão</span>
                  </v-tooltip>
                  <span class="font-weight-bold"
                    >{{ areaTruckSelected.toFixed(2) }}m
                  </span>
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row justify="start" class="mx-4 my-3">
            <v-col cols="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar Num. Pedido"
                outlined
                clearable
                dense
                solo
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :search="search"
                ref="table"
                aria-label="Pedidos"
                v-model="pedidosModel"
                :headers="headers"
                :items="pedidos"
                item-key="numeroPedido"
                class="elevation-1"
                disable-pagination
                hide-default-footer
                dense
              >
                <template v-slot:[`item.dataProducao`]="{ item }">
                  {{ item.dataProducao | formatDate }}
                  {{ item.dataProducao | formatHour }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="54"
      ></v-progress-circular>
    </v-col>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DetailsColetaDialog",
  data: () => ({
    search: "",
    dataFormatada: "",
    dataDaColeta: "",
    transportadoraNome: "",
    menu1: false,
    formHasErrors: false,
    errorMessages: "",
    pedidosModel: [],
    //Loading
    editButtonLoading: false,
    isLoading: false,
    //BodyStart
    checkboxArea: false,
    areaTruckSelected: 0,
    horaInicio: "",
    horaFim: "",
    docaSelected: { docaCor: "" },
    veiculoSelected: {
      area: 0,
      tipoVeiculoId: 0,
      descricao: "",
      criadoEm: "",
    },
    coleta: {},
    pedidos: [],
    //BodySend
    urlParams: {
      listaPedidosId: [],
      transportadoraId: 0,
      remessaId: 0,
      tipoVeiculoId: 0,
      docaId: 0,
      dataInicioColeta: "",
      dataFimColeta: "",
      coletaId: 0,
    },
    //BodyTable
    headers: [
      {
        text: "Núm. pedido",
        value: "numeroPedido",
        sortable: true,
        align: "start",
      },
      {
        text: "NF",
        value: "numeroNota",
        sortable: true,
        align: "start",
      },
      {
        text: "Cliente",
        value: "clienteNome",
        sortable: false,
        align: "start",
      },
      { text: "UF", value: "uf", sortable: true, align: "start" },
      { text: "Cidade", value: "cidade", sortable: true, align: "start" },
    ],
    coletaCadastroPedidos: [],
  }),
  mounted() {
    this.clearData();
    this.getDataForModal();
  },
  computed: {
    ...mapGetters({
      getDataEditColeta: "coleta/getDataParaEditColeta",
      getDataColeta: "remessas/getDataColeta",
    }),
  },

  methods: {
    ...mapActions({
      dataEditColeta: "coleta/dataParaEditarColeta",
      setDataColeta: "remessas/setDataColeta",
    }),

    async getDataForModal() {
      this.isLoading = true;
      await this.dataEditColeta(this.$attrs.coletaId);
      await this.listarDadosParaCadastro();
      this.isLoading = false;
    },

    async listarDadosParaCadastro() {
      //Get de dados
      const response = await this.getDataEditColeta;
      const {
        pedidos,
        pedidosPendentes,
        docaId,
        docaCor,
        tipoVeiculoId,
        tipoVeiculoColeta,
        transportadoraNomeFantasia,
        dataInicioEmbarque,
        dataFimEmbarque,
      } = response;
      //Set de dados
      const pedidosJaSelecionados = pedidos.map((p) => ({
        pedidoId: ~~p.pedidoId,
        numeroPedido: p.numeroPedido,
        uf: p.uf,
        cidade: p.cidade,
        numeroNota: p.numeroNota,
        clienteNome: p.clienteNome,
        quantidadeCaminhoesAlocados: ~~p.quantidadeCaminhoesAlocados,
        quantidadePaineis: ~~p.quantidadePaineis,
        quantidadeVolumes: ~~p.quantidadeVolumesTotal,
        area: +p.area || 0.0,
        dataProducao: p.dataProducao || "0000-00-00 00:00:00",
        enderecoEntrega: p.enderecoEntrega || "",
      }));
      this.pedidos.push(...pedidosJaSelecionados);
      this.docaSelected.docaId = docaId;
      this.docaSelected.docaCor = docaCor;
      this.veiculoSelected.tipoVeiculoId = tipoVeiculoColeta;
      switch (tipoVeiculoColeta) {
        case 1:
          this.veiculoSelected.area = 15;
          this.veiculoSelected.tipoVeiculoId = tipoVeiculoColeta;
          break;
        case 2:
          this.veiculoSelected.area = 8;
          this.veiculoSelected.tipoVeiculoId = tipoVeiculoColeta;
          break;
        default:
          this.veiculoSelected.area = 7;
          this.veiculoSelected.tipoVeiculoId = tipoVeiculoColeta;
          break;
      }
      this.areaTruckSelected = +this.veiculoSelected.area;
      this.dataDaColeta = dataInicioEmbarque.split(" ")[0];
      this.dataFormatada = this.formatDate(dataInicioEmbarque.split(" ")[0]);
      this.horaInicio = dataInicioEmbarque.split(" ")[1];
      this.horaFim = dataFimEmbarque.split(" ")[1];
      this.transportadoraNome = transportadoraNomeFantasia;
    },

    async clearData() {
      this.pedidos = [];
      this.areaTruckSelected = 0;
      this.coletaCadastroPedidos = [];
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  },
  watch: {
    name() {
      this.errorMessages = "";
    },
    dataDaColeta(newValue, oldValue) {
      this.dataFormatada = this.formatDate(newValue);
      this.dataDaColeta = newValue;
    },
  },
};
</script>
<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
.h {
  font-weight: bolder;
}
::v-deep
  .theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  color: white;
}
</style>
