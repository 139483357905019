export default {
  getListingVehicles(state) {
    return state.listingVehicles;
  },

  getVehiclesPagination(state) {
    return state.vehiclesPagination;
  },

  getVehiclesType(state) {
    return state.vehiclesType;
  },

  getTransportadoras(state) {
    return state.transportadoras;
  },

  getIsLoading(state) {
    return state.isLoading;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
  // getFilteredVehicles(state) {
  //   return state.filteredVehicles;
  // },
  // getFilter: (state, getters) => (filter) => {
  //   if (filter == "") {
  //     return (state.filteredVehicles = state.listingVehicles);
  //   } else {
  //     return (state.filteredVehicles = getters.getListingVehicles.filter(
  //       ({ placa }) => placa.toLowerCase().includes(filter.toLowerCase())
  //     ));
  //   }
  // },
};
