export default {
  isLoading: false,
  listingVehicles: [],
  vehiclesPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  vehiclesType: [],
  transportadoras: [],
  filteredVehicles: [],
  isLoadingSearch: false,
  urlParams: {
    page: 1,
    search: "",
    transportadora: "",
    transportadoraId: "",
  },
};
