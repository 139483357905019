<template>
  <v-card
    v-if="coleta.coletaStatus !== 'COLETA_FINALIZADA'"
    class="base_dark text--text mb-5"
  >
    <div>
      <v-container>
        <v-row class="justify-center">
          <v-col align="start" class="mt-5">
            <p><b>Despachante:</b> {{ despachante }}</p>
            <p><b>Empilhador:</b> {{ coleta.empilhadorNome }}</p>
            <p>
              <b>Transportadora:</b>
              {{ coleta.transportadoraNomeFantasia || "" }}
            </p>
            <p>
              <b>Data:</b> {{ coleta.dataInicioEmbarque || "" | formatDate }}
            </p>
            <v-col class="base text--navy rounded text-break" md="6">
              <p class="pa-0 ma-0">
                <b>Hora inicial: </b
                >{{ coleta.dataInicioEmbarque | formatHour }} <b>Hora final: </b
                >{{ coleta.dataFimEmbarque | formatHour }}
              </p>
            </v-col>
            <!-- <div class="pt-3">
              <span
                elevation="0"
                class="text-wrap secondary--text font-weight-bold"
              >
                {{ socketIsConnected ? "CONECTADO" : "DESCONECTADO" }}</span
              >
            </div> -->
          </v-col>
          <v-col align="start" class="mt-5 pb-5">
            <p>
              <b>Coleta:</b> <b class="primary--text">{{ coleta.coletaId }}</b>
            </p>
            <p>
              <b>Doca:</b>
              <b :style="{ color: coleta.docaCor }">
                {{ coleta.docaCodigo || "" }}
              </b>
              <v-icon :style="{ color: coleta.docaCor }" size="20"
                >mdi-checkbox-blank-circle
              </v-icon>
            </p>
            <p><b>Motorista:</b> {{ coleta.motoristaNome || "" }}</p>
            <p><b>Placa:</b> {{ coleta.veiculoPlaca || "" }}</p>

            <v-col
              class="base text--navy rounded text-break"
              md="6"
              align-self="end"
            >
              <p class="pa-0 ma-0">
                <b>Pedidos:</b> {{ coleta.quantidadePedidos || "" }}
                <b>Volumes:</b> {{ relacaoVolumes || "" }}
              </p>
            </v-col>
            <!-- <p class="pa-0 my-3">
              <b>WebSocket:</b>
              <b v-if="socketIsConnected" style="color: #2cab31" class="bold">
                CONECTADO
              </b>
              <b v-else style="color: #f44336" class="bold"> DESCONECTADO </b>
            </p> -->
          </v-col>
          <v-col class="">
            <div class="pa-4">
              <v-hover v-slot="{ hover }">
                <v-btn
                  block
                  rounded
                  :loading="isLoadingReload"
                  @click="atualizarListagemVolumes"
                  :class="hover ? 'base--text' : 'navy--text'"
                  class="navy--text text-truncate"
                  style="text-transform: none !important; font-weight: bolder"
                  >Atualizar Listagem de Volumes
                </v-btn>
              </v-hover>
            </div>
            <div class="pa-4">
              <v-hover v-slot="{ hover }">
                <v-btn
                  block
                  rounded
                  @click="coletarAssinatura"
                  :disabled="!coleta.permiteColetaAssinaturaMotorista"
                  :color="hover ? 'primary' : 'secondary'"
                  class="navy--text text-truncate"
                  style="text-transform: none !important; font-weight: bolder"
                >
                  Ass. Responsável pela Coleta</v-btn
                >
              </v-hover>
            </div>
            <div class="pa-4">
              <v-hover v-slot="{ hover }">
                <v-btn
                  block
                  rounded
                  @click="finalizarColeta"
                  :disabled="
                    coleta.coletaStatus == 'COLETA_FINALIZADA' ||
                    !coleta.permiteFinalizarColeta ||
                    coleta.assinaturaMotoristaUrl == ''
                  "
                  :color="hover ? 'primary' : 'secondary'"
                  class="navy--text text-truncate"
                  style="text-transform: none !important; font-weight: bolder"
                >
                  Finalizar Remessa</v-btn
                >
              </v-hover>
            </div>
            <div class="pa-4">
              <v-hover v-slot="{ hover }">
                <v-btn
                  block
                  rounded
                  @click="openPickupOrdersModal"
                  :color="hover ? 'primary' : 'secondary'"
                  class="navy--text text-truncate"
                  style="text-transform: none !important; font-weight: bolder"
                >
                  Pedidos da Coleta</v-btn
                >
              </v-hover>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <pickup-orders
      v-model="isPickupOrdersModalOpen"
      :pickup-id="getColetaDetalhes.coletaId"
      :carrier="getColetaDetalhes.transportadoraNomeFantasia"
      :date="getColetaDetalhes.dataInicioEmbarque"
      :key="'modalKey' + modalKey"
    />
  </v-card>
</template>

<script>
import { coletasSocketService } from "@/services/socket/coletasSocketService";
import { mapState, mapActions, mapGetters } from "vuex";
import PickupOrders from "../../defaultUserComponents/embarqueCooktop/PickupOrders.vue";

export default {
  name: "EmbarqueColetaCabecalhoSuperior",
  components: {
    PickupOrders,
  },
  data: () => ({
    status: true,
    despachante: "",
    modalKey: 0,
  }),
  props: {
    coletaId: { type: Number },
    coleta: {
      type: Object,
      required: true,
      validator: (c) => {
        return (
          1 &&
          c.dataInicioEmbarque !== undefined &&
          c.dataFimEmbarque !== undefined &&
          c.coletaStatus !== undefined &&
          c.despachanteId !== undefined &&
          c.despachanteNome !== undefined &&
          c.empilhadorNome !== undefined &&
          c.transportadoraId !== undefined &&
          c.transportadoraNomeFantasia !== undefined &&
          c.motoristaId !== undefined &&
          c.motoristaCpf !== undefined &&
          c.motoristaNome !== undefined &&
          c.veiculoId !== undefined &&
          c.veiculoPlaca !== undefined &&
          c.tipoVeiculoId !== undefined &&
          c.tipoVeiculoNome !== undefined &&
          c.docaId !== undefined &&
          c.coletaId !== undefined &&
          c.docaCor !== undefined &&
          c.docaCodigo !== undefined &&
          c.quantidadeVolumesEmbarcados !== undefined &&
          c.quantidadeVolumesTotal !== undefined &&
          c.permiteColetaAssinaturaMotorista !== undefined &&
          c.permiteFinalizarColeta !== undefined &&
          c.assinaturaMotoristaUrl !== undefined &&
          c.quantidadePedidos !== undefined &&
          c.despachanteLista !== undefined
        );
      },
    },
  },
  created() {
    this.setIsPickupOrdersModalOpen(false);
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  },
  mounted() {
    this.returnUser();
  },
  computed: {
    ...mapState("expedition", ["isLoadingReload"]),
    ...mapGetters("login", ["getUserName"]),
    ...mapGetters("expedition", [
      "getColetaDetalhes",
      "getIsPickupOrdersModalOpen",
    ]),
    relacaoVolumes() {
      return `${this.coleta.quantidadeVolumesEmbarcados}/${this.coleta.quantidadeVolumesTotal}`;
    },
    socketIsConnected() {
      return coletasSocketService.isConnected();
    },
    isPickupOrdersModalOpen: {
      get() {
        return this.getIsPickupOrdersModalOpen;
      },
      set(newValue) {
        this.setIsPickupOrdersModalOpen(newValue);
      },
    },
  },
  methods: {
    ...mapActions("expedition", [
      "capturaBipagem",
      "setIsLoading",
      "volumesComErros",
      "setIsPickupOrdersModalOpen",
      "setPickupOrdersUrlParams",
      "pickupOrders",
    ]),

    coletarAssinatura() {
      this.$emit("coletar-assinatura");
    },
    finalizarColeta() {
      this.$emit("finalizar-coleta");
    },
    atualizarListagemVolumes() {
      console.log("atualizarListagemVolumes");
      this.$emit("atualizar-lista");
    },
    async capturarBipagem() {
      this.isLoadingBtn = true;
      const capturarCamera = {
        doca_id: this.coleta.docaId,
        coleta_id: this.coleta.coletaId,
      };
      await this.capturaBipagem(capturarCamera);
      this.$emit("listUsers");
      this.$emit("closeCreateUserDialog");
      let response = await this.volumesComErros(this.coleta.coletaId);
      if (response) {
        this.$emit("checar-volume-com-erro");
      }
      this.isLoadingBtn = false;
    },
    returnUser() {
      let user = this.getUserName;
      let name = this.coleta.despachanteLista;
      name.forEach((element) => {
        if (element.despachanteNome == user) {
          return (this.despachante = user);
        }
      });
      return;
    },
    openPickupOrdersModal() {
      const defaultPickupOrdersUrlParams = {
        page: 1,
        perPage: 10,
        numeroPedido: "",
        pendente: 0,
        embarcado: 1,
        emAndamento: 0,
      };
      this.setPickupOrdersUrlParams(defaultPickupOrdersUrlParams);
      this.modalKey++;
      this.setIsPickupOrdersModalOpen(true);
      this.pickupOrders(this.getColetaDetalhes.coletaId);
    },
  },
};
</script>

<style scoped>
.cordecima {
  background: #ffd1b2;
}
</style>
