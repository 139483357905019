import { HttpRestService } from "@/services/http/HttpRestService";

export class DriversRepository {  
   static requestDrivers(url) {
        return HttpRestService.get(url);
    }

    static listTransportadora() {
        return HttpRestService.get(`/transportadora/listar`);
    }

    static createDrivers(payload) {
        return HttpRestService.post(`/motorista/save`, payload); 
    }

    static  editDrivers(payload) {
        return HttpRestService.put(`/motorista/editar`, payload);
    }

    static sendSignature(payload) {
        return HttpRestService.post(`/motorista/assinatura/save`, payload);
    }
}
