export default {
  setListingUser(state, response) {
    state.listingUsers = response;
  },
  setFilteredUsers(state, response) {
    state.filteredUsers = response;
  },

  setListPerfis(state, response) {
    state.listPerfis = response;
  },

  setUsersPagination(state, response) {
    state.usersPagination.total = response.total;
    state.usersPagination.perPage = response.perPage;
    state.usersPagination.currentPage = response.currentPage;
    state.usersPagination.lastPage = response.lastPage;
    state.usersPagination.nextPage = response.nextPage;
  },

  setIsLoading(state, value) {
    state.isLoading = value;
  },

  setTransportadoraEmails(state, value) {
    state.transportadoraEmails = value;
  },
};
