import { PrevisaoPatioRepository } from "../../../repositories/defaultUserRepositories/PrevisaoPatioRepository";
import { ErrorNotifier } from "../../../services/messages/ErrorNotifier";

export default {
  async previsaoPatio({ commit, dispatch }, date) {
    commit("setIsLoading", true);
    commit("setListingPrevisaoPatio", []);
    try {
      const response = await PrevisaoPatioRepository.requestPrevisaoPatio(date);
      commit("setIsLoading", false);
      if (response.status >= 200 && response.status < 300) {
        commit("setListingPrevisaoPatio", response.data.data);
        commit("setIsLoading", false);
        return;
      } else {
        commit("setIsLoading", false);
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },

  errorMessage({ commit, dispatch }, value) {
    ErrorNotifier.saveStringErrorNotification(commit, dispatch, value);
    return;
  },
  checkToken({ dispatch }, value) {
    if (value.data.status === "O token é inválido.") {
      value.data.message = "O token é inválido.";
      value.status = "O token é inválido.";
      dispatch("errorMessage", value);
      return;
    }
    return;
  },
};
