<template>
  <v-card class="overflow-y-auto">
    <v-card-title
      class="justify-center"
      ref="title"
      v-intersect="onIntersectingHeader"
    >
      <span class="text-h6 font-weight-bold navy--text"
        >Edição de Coleta - {{ transportadoraNome }}
      </span>
      <span class="text-h6 font-weight-bold navy--text"> </span>
    </v-card-title>
    <div v-if="!isLoading">
      <v-card-text>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-row justify="space-around">
              <v-col cols="5">
                <v-text-field
                  ref="horaInicio"
                  :error-messages="errorMessages"
                  v-model="horaInicio"
                  :rules="rules.hora"
                  label="Hora Inicial"
                  type="time"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  ref="horaFim"
                  :error-messages="errorMessages"
                  type="time"
                  v-model="horaFim"
                  :rules="rules.hora"
                  label="Hora Final"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col cols="5">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      ref="dataDaColeta"
                      :error-messages="errorMessages"
                      :rules="rules.data"
                      placeholder="Data da Coleta"
                      outlined
                      readonly
                      dense
                      solo
                      flat
                      v-bind="attrs"
                      v-on="on"
                      v-model="dataFormatada"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dataDaColeta"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="5">
                <v-select
                  ref="docaSelected"
                  :error-messages="errorMessages"
                  class="text-center"
                  v-model="docaSelected"
                  :items="listDocas"
                  :rules="rules.doca"
                  :disabled="showRedespacho"
                  item-text="docaCodigo"
                  item-value="docaId"
                  :color="docaSelected.docaCor"
                  return-object
                  label="Doca"
                  outlined
                  dense
                  required
                >
                  <template v-slot:item="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      Doca - {{ item.docaCodigo }} -
                      <v-icon :color="item.docaCor">mdi-circle</v-icon>
                    </span>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      Doca - {{ item.docaCodigo }}
                    </span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon :color="docaSelected.docaCor">mdi-circle</v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col cols="5">
                <v-autocomplete
                  ref="veiculoSelected"
                  :error-messages="errorMessages"
                  v-model="areaTruckSync"
                  :items="listOfVehiclesType"
                  :rules="rules.veiculoRules"
                  item-text="descricao"
                  item-value="tipoVeiculoId"
                  return-object
                  label="Tipo de Veículo"
                  outlined
                  dense
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="5">
                <v-select
                  ref="editMotive"
                  v-model="selectedEditMotive"
                  label="Motivo da Edição"
                  outlined
                  dense
                  required
                  :items="selectableEditMotives"
                  item-text="label"
                  :rules="[rules.requiresEditMotive]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row justify="start" class="mx-4 my-3">
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Número Pedido"
                  outlined
                  clearable
                  dense
                  solo
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-select
                  v-model="selectedFilterPedidos"
                  :items="tiposDePedidos"
                  :readonly="this.coletaCadastroPedidosSync.length > 0"
                  ref="selectOrderTypeFilter"
                  @mouseup="onTypeAlertMessage"
                  label="Filtrar por pedidos"
                  dense
                  small-chips
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-select
                  v-model="selectedTipoColeta"
                  :items="tipoColetaSelecionada"
                  label="Tipo Coleta"
                  dense
                  small-chips
                  hide-details
                  disabled
                  clearable
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  :search="search"
                  @item-selected="evaluateIfPedidoWasAlreadySelected"
                  @toggle-select-all="
                    evaluateIfSomePedidoWasAlreadyBeenSelected
                  "
                  ref="table"
                  aria-label="Pedidos"
                  v-model="coletaCadastroPedidosSync"
                  :headers="headers"
                  :key="reRender"
                  :items="pedidosFilter"
                  :single-select="false"
                  item-key="numeroPedido"
                  show-select
                  class="elevation-1"
                  disable-pagination
                  hide-default-footer
                  dense
                >
                  <template v-slot:[`item.dataProducao`]="{ item }">
                    {{ item.dataProducao | formatDate }}
                    {{ item.dataProducao | formatHour }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row
              justify="center"
              v-show="this.areaPedidosSelected > this.areaTruckSelected"
              class="pa-0 ma-0"
            >
              <v-col cols="6" class="pa-0 ma-0">
                <v-checkbox
                  v-model="checkboxArea"
                  label="Estou ciente que o Comprimento dos pedidos selecionados é maior que o Comprimento do veículo."
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <div
        justify="center"
        style="position: sticky; bottom: 0; overflow: hidden !important"
        class="base"
      >
        <v-divider />
        <v-row
          justify="center"
          class="py-3"
        >
          <v-col cols="4">
            <default-button
              @click="editColeta"
              :loading="editButtonLoading"
              :disabled="!habilitateButton"
              >Editar Coleta</default-button
            >
          </v-col>
          <v-col cols="4">
            <span class="base_dark rounded-xl pa-2 pr-2">
              <span class="navy--text">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ma-2"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-truck-outline</v-icon
                    >
                  </template>
                  <span>Comprimento do Caminhão</span>
                </v-tooltip>
                <span class="font-weight-bold"
                  >{{ areaTruckSelected.toFixed(2) }}m
                </span>
              </span>
              <span class="navy--text">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ma-2"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-package-variant-closed-check
                    </v-icon>
                  </template>
                  <span>Comprimento dos Pedidos</span>
                </v-tooltip>
                <span class="font-weight-bold"
                  >{{ areaPedidosSelected.toFixed(2) }}m
                </span>
              </span>
            </span>
          </v-col>
          <span
            v-show="!isHeaderVisible"
            style="position: absolute !important; bottom: 12px; right: 12px"
            @click="$refs.title.scrollIntoView()"
          >
            <back-to-top />
          </span>
        </v-row>
      </div>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="54"
      ></v-progress-circular>
    </v-col>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import BackToTop from "../../defaultComponents/BackToTop.vue";
import DefaultButton from "../../defaultComponents/DefaultButton.vue";

export default {
  name: "EditRemessaModal",
  components: {
    BackToTop,
    DefaultButton,
  },
  data: () => ({
    selectedFilterPedidos: [],
    selectedTipoColeta: [],
    tiposDePedidos: [
      {
        value: ["web", "ploomes"],
        text: "Web + Ploomes",
      },
      {
        value: ["express", "lote", "lote-fixador"],
        text: "Ágil",
      },
    ],
    tipoColetaSelecionada: [
      {
        value: "EMBARQUE_PADRAO",
        text: "Padrao",
      },
      {
        value: "EMBARQUE_DINAMICO",
        text: "Dinamico",
      },
      {
        value: "EMBARQUE_ENCAMINHAMENTO",
        text: "Encaminhamento",
      },
      {
        value: "EMBARQUE_REDESPACHO",
        text: "Redespacho",
      },
    ],
    reRender: 0,
    pedidosFilter: [],
    search: "",
    dataFormatada: "",
    dataDaColeta: "",
    transportadoraNome: "",
    menu1: false,
    formHasErrors: false,
    errorMessages: "",
    //Loading
    editButtonLoading: false,
    isLoading: false,
    //BodyStart
    checkboxArea: false,
    areaTruckSelected: 0,
    areaPedidosSelected: 0,
    horaInicio: "",
    horaFim: "",
    docaSelected: { docaCor: "" },
    veiculoSelected: {
      area: 0,
      tipoVeiculoId: 0,
      descricao: "",
      criadoEm: "",
    },
    coleta: {},
    pedidos: [],
    //BodySend
    urlParams: {
      listaPedidosId: [],
      transportadoraId: 0,
      remessaId: 0,
      tipoVeiculoId: 0,
      docaId: 0,
      dataInicioColeta: "",
      dataFimColeta: "",
      coletaId: 0,
      tipoColeta: "",
      motivoEdicao: "",
    },
    //Rules
    rules: {
      hora: [(v) => !!v || "Hora é obrigatório"],
      data: [(v) => !!v || "Data é obrigatório"],
      doca: [(v) => !!v || "Doca é obrigatório"],
      veiculoRules: [(v) => !!v || "O campo Veículo é obrigatório"],
      pedidoRules: [(v) => !!v || "Tipo de Pedido é obrigatório"],
      requiresEditMotive: (value) => !!value || "Campo obrigatório",
    },
    //BodyTable
    headers: [
      {
        text: "Tipo pedido",
        value: "tipoPedido",
        sortable: false,
        align: "start",
      },
      {
        text: "Núm. pedido",
        value: "numeroPedido",
        sortable: false,
        align: "start",
      },
      { text: "Compr. (m)", value: "area", sortable: true, align: "start" },
      {
        text: "Qtd. caminhões",
        value: "quantidadeCaminhoesAlocados",
        sortable: true,
        align: "start",
      },
      {
        text: "Qtd. volumes",
        value: "quantidadeVolumes",
        sortable: true,
        align: "start",
      },
      {
        text: "Qtd. painéis",
        value: "quantidadePaineis",
        sortable: true,
        align: "start",
      },
      {
        text: "Data produção",
        value: "dataProducao",
        sortable: true,
        align: "start",
      },
    ],
    coletaCadastroPedidos: [],
    selectedEditMotive: "",
    selectableEditMotives: [
      {
        label: "Adicionar Pedido",
        value: 1,
      },
      {
        label: "Remanejar Pedido",
        value: 2,
      },
      {
        label: "Retirar Pedido",
        value: 3,
      },
    ],
    isHeaderVisible: true,
  }),
  mounted() {
    this.clearData();
    this.getDataForModal();
  },
  computed: {
    ...mapGetters({
      getDataEditColeta: "coleta/getDataParaEditColeta",
      pedidosSelected: "remessas/getPedidosSelected",
      remessaCadastroGet: "remessas/getRemessaCadastro",
      listOfVehiclesType: "vehicles/getVehiclesType",
      listDocas: "coleta/getDocks",
      getDataColeta: "remessas/getDataColeta",
    }),

    showRedespacho() {
      return this.selectedTipoColeta === "EMBARQUE_REDESPACHO" ? true : false;
    },

    form() {
      return {
        horaInicio: this.horaInicio,
        horaFim: this.horaFim,
        dataDaColeta: this.dataDaColeta,
        docaSelected: this.docaSelected.docaId,
        veiculoSelected: this.veiculoSelected.tipoVeiculoId,
        table: this.coletaCadastroPedidosSync,
      };
    },

    coletaCadastroPedidosSync: {
      get() {
        return this.pedidosSelected;
      },
      set(coletaCadastroPedidos) {
        this.calculateAreaPedidos(coletaCadastroPedidos);
        this.setPedidosSelected(coletaCadastroPedidos);
        this.remessaCadastroSet({
          ...this.remessaCadastroGet,
          pedidos: this.pedidosSelected,
        });
      },
    },

    areaTruckSync: {
      get() {
        return this.veiculoSelected;
      },
      set(areaTruck) {
        switch (areaTruck.tipoVeiculoId) {
          /* carreta: 15 x 2,6 m
        truck: 8 x 2,6 m
        toco: 8 x 2,6 m*/
          case 1:
            this.veiculoSelected.area = 15;
            this.veiculoSelected.tipoVeiculoId = areaTruck.tipoVeiculoId;
            break;
          case 2:
            this.veiculoSelected.area = 8;
            this.veiculoSelected.tipoVeiculoId = areaTruck.tipoVeiculoId;
            break;
          default:
            this.veiculoSelected.area = 7;
            this.veiculoSelected.tipoVeiculoId = areaTruck.tipoVeiculoId;
            break;
        }
        this.areaTruckSelected = +this.veiculoSelected.area;
      },
    },

    habilitateButton() {
      if (
        this.areaTruckSelected > this.areaPedidosSelected &&
        this.coletaCadastroPedidosSync.length &&
        this.selectedTipoColeta
      ) {
        return true;
      } else {
        if (this.checkboxArea && this.coletaCadastroPedidosSync.length) {
          return true;
        } else {
          return false;
        }
      }
    },
  },

  methods: {
    ...mapActions({
      actionListOfVehicles: "vehicles/listVehiclesType",
      dataEditColeta: "coleta/dataParaEditarColeta",
      setPedidosSelected: "remessas/setPedidosSelected",
      remessaCadastroSet: "remessas/setRemessaCadastro",
      zeragemDataCadastro: "coleta/setDataColetaParaCadastro",
      postEditarColeta: "coleta/editarColeta",
      listOfDocks: "coleta/listDocks",
      setDataColeta: "remessas/setDataColeta",
      setTypeAlertMessage: "remessas/setTypeAlertMessage",
    }),

    onTypeAlertMessage() {
      this.$refs["selectOrderTypeFilter"].isReadonly === false
        ? ""
        : this.setTypeAlertMessage();
    },

    async getDataForModal() {
      this.isLoading = true;
      await this.dataEditColeta(this.$attrs.coletaId);
      await this.actionListOfVehicles();
      await this.listOfDocks();
      await this.listarDadosParaCadastro();
      this.isLoading = false;
    },

    calculateAreaPedidos(pedido) {
      if (pedido) this.areaPedidosSelected = 0;
      for (var p = 0; p < pedido.length; p++) {
        this.areaPedidosSelected += pedido[p].area; //TODO: alterar para area
      }
    },

    async listarDadosParaCadastro() {
      //Get de dados
      const response = await this.getDataEditColeta;
      const {
        pedidos,
        pedidosPendentes,
        docaId,
        docaCor,
        tipoVeiculoId,
        tipoVeiculoColeta,
        transportadoraNomeFantasia,
        dataInicioEmbarque,
        dataFimEmbarque,
        tipoColeta,
      } = response;
      //Set de dados
      const pedidosJaSelecionados = pedidos.map((p) => ({
        pedidoId: ~~p.pedidoId,
        numeroPedido: p.numeroPedido,
        tipoPedido: p.tipoPedido,
        quantidadeCaminhoesAlocados: ~~p.quantidadeCaminhoesAlocados,
        quantidadePaineis: ~~p.quantidadePaineis,
        quantidadeVolumes: ~~p.quantidadeVolumesTotal,
        area: +p.area || 0.0,
        dataProducao: p.dataProducao || "0000-00-00 00:00:00",
        enderecoEntrega: p.enderecoEntrega || "",
      }));
      this.setPedidosSelected(pedidosJaSelecionados);
      this.pedidos.push(...pedidosJaSelecionados);
      for (var p = 0; p < pedidosJaSelecionados.length; p++) {
        this.areaPedidosSelected += pedidosJaSelecionados[p].area; //TODO: alterar para area
      }
      if (pedidosPendentes) {
        this.pedidos.push(
          ...pedidosPendentes.map((p) => ({
            pedidoId: ~~p.pedidoId,
            numeroPedido: p.pedidoNumero,
            tipoPedido: p.tipoPedido,
            quantidadeCaminhoesAlocados: ~~p.quantidadeCaminhoesAlocados,
            quantidadePaineis: ~~p.quantidadePaineis,
            quantidadeVolumes: ~~p.quantidadeVolumesTotal,
            area: +p.area || 0.0,
            dataProducao: p.dataProducao || "0000-00-00 00:00:00",
            enderecoEntrega: p.enderecoEntrega || "",
          }))
        );
      }
      this.docaSelected.docaId = docaId;
      this.docaSelected.docaCor = docaCor;
      this.veiculoSelected.tipoVeiculoId = tipoVeiculoColeta;
      switch (tipoVeiculoColeta) {
        case 1:
          this.veiculoSelected.area = 15 * 2.6;
          this.veiculoSelected.tipoVeiculoId = tipoVeiculoColeta;
          break;
        default:
          this.veiculoSelected.area = 8 * 2.6;
          this.veiculoSelected.tipoVeiculoId = tipoVeiculoColeta;
          break;
      }
      this.areaTruckSelected = +this.veiculoSelected.area;
      this.dataDaColeta = dataInicioEmbarque.split(" ")[0];
      this.dataFormatada = this.formatDate(dataInicioEmbarque.split(" ")[0]);
      this.horaInicio = dataInicioEmbarque.split(" ")[1];
      this.horaFim = dataFimEmbarque.split(" ")[1];
      this.transportadoraNome = transportadoraNomeFantasia;
      this.selectedTipoColeta = tipoColeta;
    },

    async editColeta() {
      this.editButtonLoading = true;
      this.formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) {
          this.formHasErrors = true;
          this.editButtonLoading = false;
        }
        this.$refs.form.validate(true);
      });
      if (!this.formHasErrors && this.$refs.form.validate()) {
        this.insertDataToBody();
        const response = await this.postEditarColeta(this.urlParams);
        if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
          this.$emit("coleta");
          this.$emit("closeEditColeta");
        } else {
          this.createButtonLoading = false;
        }
      }
      this.editButtonLoading = false;
    },

    async clearData() {
      this.pedidos = [];
      this.docaSelected = {};
      this.veiculoSelected = {};
      this.areaPedidosSelected = 0;
      this.areaTruckSelected = 0;
      this.coletaCadastroPedidos = [];
      await this.setPedidosSelected([]);
      await this.remessaCadastroSet({
        ...this.remessaCadastroGet,
        pedidos: [],
      });
      this.setTipoColeta = "";
    },

    insertDataToBody() {
      this.urlParams.remessaId = parseInt(this.$route.params.remessaId);
      this.urlParams.transportadoraId = parseInt(
        this.$route.params.transportadoraId
      );
      this.urlParams.docaId = this.docaSelected.docaId;
      this.urlParams.dataInicioColeta = this.dataDaColeta.concat(
        " " + this.horaInicio
      );
      this.urlParams.dataFimColeta = this.dataDaColeta.concat(
        " " + this.horaFim
      );
      this.urlParams.tipoVeiculoId = this.veiculoSelected.tipoVeiculoId;
      this.urlParams.listaPedidosId = this.pedidosSelected.map(
        (p) => p.pedidoId
      );
      this.urlParams.coletaId = this.$attrs.coletaId;
      this.urlParams.tipoColeta = this.selectedTipoColeta;
      this.urlParams.motivoEdicao = this.selectedEditMotive;
    },

    evaluateIfPedidoWasAlreadySelected(event) {
      if (~~event.item.quantidadeCaminhoesAlocados > 0) {
        this.mostrarMensagemErro(
          "Esse pedido já foi selecionado antes para outra coleta. Certifique-se de que você tem certeza que deseja fazer isso."
        );
      }
    },

    evaluateIfSomePedidoWasAlreadyBeenSelected(event) {
      const pedidosComMaisDeUmCaminhao = event.items
        .filter(
          ({ quantidadeCaminhoesAlocados }) => quantidadeCaminhoesAlocados > 0
        )
        .map(({ numeroPedido }) => numeroPedido);
      if (pedidosComMaisDeUmCaminhao.length) {
        const numerosPedido = pedidosComMaisDeUmCaminhao.join(",");
        this.mostrarMensagemErro(
          `Os pedidos ${numerosPedido} estão com mais de um caminhão alocados. Certifique-se de que você tem certeza que deseja fazer isso.`
        );
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    filtroTipoPedido(arrayTipoPedido) {
      this.pedidosFilter = [];
      this.pedidosFilter = this.pedidos.filter((p) => {
        if (arrayTipoPedido.includes(p.tipoPedido)) {
          return p;
        }
        if (
          !this.selectedFilterPedidos.includes(
            this.getDataEditColeta.pedidos[0].tipoPedido
          )
        ) {
          this.setPedidosSelected([]);
        }
      });
      this.reRender += 1;
    },
    onIntersectingHeader(entries) {
      if (entries.length > 1) {
        return;
      }
      this.isHeaderVisible = entries[0].isIntersecting;
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  },
  watch: {
    selected(selected) {
      this.$emit(
        "selected",
        selected.map(({ pedidoId }) => pedidoId)
      );
    },
    name() {
      this.errorMessages = "";
    },
    dataDaColeta(newValue, oldValue) {
      this.dataFormatada = this.formatDate(newValue);
      this.dataDaColeta = newValue;
    },
    getDataEditColeta: {
      deep: true,
      immediate: true,
      handler() {
        const tipoSelecionado = this.getDataEditColeta.pedidos[0].tipoPedido;
        tipoSelecionado === "web" || tipoSelecionado === "ploomes"
          ? (this.selectedFilterPedidos = this.tiposDePedidos[0].value)
          : (this.selectedFilterPedidos = this.tiposDePedidos[1].value);
      },
    },
    selectedFilterPedidos: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.filtroTipoPedido(newValue);
      },
    },
    pedidos: {
      deep: true,
      immediate: true,
      handler() {
        this.filtroTipoPedido(this.selectedFilterPedidos);
      },
    },
  },
};
</script>
<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
.h {
  font-weight: bolder;
}
::v-deep
  .theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  color: white;
}
</style>
