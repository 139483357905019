export default {
  getListingProtocols(state) {
    return state.listingProtocols;
  },

  getProtocolsPagination(state) {
    return state.protocolsPagination;
  },
  
  getCheckedList(state){
    return state.checkedList
  }
};
