import Vue from "vue";

Vue.filter("formatDateWithHour", function (date) {
  if (date instanceof Date) {
    let [year, month, day, hours, minutes, seconds] = [
      date.getFullYear(),
      date.getMonth(),
      date.getDay(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ];
    const f = (d) => (d < 10 ? `0${d}` : d);
    return `${f(day)}/${f(month)}/${year} ${hours}:${minutes}:${seconds}`;
  }
  try {
    const dateStr = date.match(/\d{4}-\d{2}-\d{2}/)[0];
    const dateArr = dateStr.split("-");
    const timeStr = date.match(/\d{2}:\d{2}:\d{2}/)[0];
    const timeArr = timeStr.split(":");
    return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]} - ${timeArr[0]}:${timeArr[1]}`;
  } catch (e) {
    return "";
  }
});

Vue.filter("formatVolume", (volume) => {
  const orderWithoutVolume = volume.slice(0, -4);
  const volumeWithoutOrder = volume.slice(-4);
  return `${orderWithoutVolume}<span class="text-decoration-underline">${volumeWithoutOrder}</span>`;
});
