<template>
  <v-container>
    <list-coletas-header />
    <list-coletas />
  </v-container>
</template>

<script>
import ListColetasHeader from "../../components/defaultUserComponents/listColetas/ListColetasHeader.vue";
import ListColetas from "../../components/defaultUserComponents/listColetas/ListColetas.vue";

export default {
  components: {
    ListColetasHeader,
    ListColetas,
  },
};
</script>

<style scoped></style>
