export default {
  getListingPallets(state) {
    return state.listingPallets;
  },
  getPalletsPagination(state) {
    return state.palletsPagination;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
};
