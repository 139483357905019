import BahiaBranch from "../../../repositories/defaultUserRepositories/BahiaBranchRepository";
import { ExpeditionRepository } from "../../../repositories/defaultUserRepositories/ExpeditionRepository";
import { ErrorNotifier } from "../../../services/messages/ErrorNotifier";
import state from "./state";
import router from "@/router";

export default {
  async pickups({ state, commit, dispatch }) {
    try {
      commit("setIsLoadingPickups", true);
      commit("setPickups", []);
      const response = await BahiaBranch.getPickups(state.urlParams);
      if (response.status === 200) {
        const pickups = response.data.data.list;
        const pagination = response.data.data.pagination;
        commit("setPickups", pickups);
        commit("setPagination", pagination);
        return;
      }
      throw response;
    } catch (error) {
      dispatch("errorMessage", error);
    } finally {
      commit("setIsLoadingPickups", false);
    }
  },
  errorMessage({ commit, dispatch }, payload) {
    ErrorNotifier.run(commit, dispatch, payload);
  },
  setUrlParams({ commit }, payload) {
    commit("setUrlParams", payload);
  },
  setCurrentPageToFirstPage({ commit, state }) {
    commit("setUrlParams", { ...state.urlParams, page: 1 });
  },
  resetUrlParams({ commit }) {
    const originalUrlParams = state.urlParams;
    commit("setUrlParams", originalUrlParams);
  },
  async biparVolume({ commit, dispatch }, body) {
    commit("setIsLoadingBiparVolume", true);
    const data = {
      coletaId: body.coletaId,
      volumes: [`${body.volume}`],
    };
    const response = await BahiaBranch.biparVolume(data);
    if (response.status >= 200 && response.status < 300) {
      commit("setIsLoadingBiparVolume", false);
      return true;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("setIsLoadingBiparVolume", false);
      commit("notifications/setErrorNotification", errorObj, { root: true });
      dispatch("expedition/reloadColetaDetalhesByColetaId", recebimentoId);
      return false;
    }
  },
  async finalizaColeta({ commit }, coletaId) {
    try {
      const response = await BahiaBranch.finalizarColeta(coletaId);
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      if (response.status >= 200 && response.status < 300) {
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        return response;
      } else {
        let errorObj = {
          errorStatus: true,
          errorMessage: response.data.message,
        };
        commit("notifications/setErrorNotification", errorObj, { root: true });
      }
      return response;
    } catch (e) {
      return null;
    }
  },

  async receptionDetails({ commit }, body) {
    const response = await ExpeditionRepository.receptionDetails(body);
    if (response.status >= 200 && response.status < 300) {
      return true;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
      return;
    }
  },

  setIsStartShippingModalOpen({ commit }, payload) {
    commit("setIsStartShippingModalOpen", payload);
  },

  async checkProtocol({ commit, dispatch }, { protocol, pickupId }) {
    try {
      const body = {
        protocolo: protocol,
        coleta_id: pickupId,
      };
      const response = await BahiaBranch.checkProtocol(body);
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      if (response.status >= 200 && response.status < 300) {
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        return true;
        // const belongsToCurrentPickup =
        //   pickupId === response.data.data[0].coletaId;
        // if (belongsToCurrentPickup) {
        //   commit("setIsStartShippingModalOpen", false);
        //   router.push(`/coletas-agendadas/filial-bahia/${pickupId}/embarque`);
        //   return;
        // }
        // throw {
        //   data: {
        //     message: `Esse protocolo não pertence à coleta ${pickupId}`,
        //   },
        //   status: 400,
        // };
      }
      throw response;
    } catch (error) {
      dispatch("errorMessage", error);
    }
  },
};
