var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"app"}},[_c('div',{staticClass:"background",staticStyle:{"height":"100%"}},[(
        !['Login'].includes(_vm.$route.name) &&
        !['RegistroTransportadora'].includes(_vm.$route.name) &&
        !['CadastroDeUsuario'].includes(_vm.$route.name)
      )?_c('navbar-component',{attrs:{"name":_vm.$route.name}}):_vm._e(),(
        !['Login'].includes(_vm.$route.name) &&
        !['RegistroTransportadora'].includes(_vm.$route.name) &&
        !['CadastroDeUsuario'].includes(_vm.$route.name)
      )?_c('sidebar-component'):_vm._e(),_c('div',[_c('v-main',[_c('router-view')],1)],1)],1),_c('div',{staticClass:"overflow-hidden float-end teste"},[_c('error-alert'),_c('success-alert'),_c('notification-component')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }