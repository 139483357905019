<template>
    <v-card>
        <v-card-title class="justify-center">
          <v-row>
            <v-col>
              <span class="text-h6 font-weight-bold navy--text"
                >Detalhes da Divergência</span
              >
            </v-col>
          </v-row>
        </v-card-title>
          <div v-if="!isLoading">
            <div v-if="getDetalhesDivergenciaHorario.length">
              <v-card-text>
                <div class="mb-5 text-start">
                  <span class="font-weight-bold navy--text"> Remessa: </span>
                  <span class="text-wrap secondary--text font-weight-bold">
                    {{ remessasId }}
                  </span>
                </div>
                <v-row class="list-header mx-auto mb-2">
                  <v-col cols="3" align="start" class="border-right">
                    <span class="">Coletas</span>
                  </v-col>
                  <v-col cols="5" align="center" class="border-right">
                    <span class="">Data da coleta</span>
                  </v-col>
                  <v-col cols="4" align="center" class="">
                      <span class="">Veículo</span>
                    </v-col>
                </v-row>
                <v-row
                  class="list-card base_dark text--text mx-auto my-auto mt-2"
                >
                  <v-col cols="3" align="start">
                    <div class="text-wrap">
                      <span>{{ getDetalhesDivergenciaHorario[0].coletaId }}</span>
                    </div>
                  </v-col>
                  <v-col cols="5" align="center">
                    <div class="text-wrap secondary--text font-weight-bold">
                      <span>
                        {{ getDetalhesDivergenciaHorario[0].dataHoraDivergencia | formatDate }}
                        {{ getDetalhesDivergenciaHorario[0].dataHoraDivergencia | formatHour }}
  
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="4" align="center">
                      <div class="text-wrap">
                        <span>{{ getDetalhesDivergenciaHorario[0].tipoVeiculo }}</span>
                      </div>
                    </v-col>
                </v-row>
              </v-card-text>
            </div>
            <div v-else>
              <v-row class="py-6" justify="center">
                <v-col cols="6" align="center">
                  <span>Não foi possível carregar os detalhes da coleta.</span>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-col v-else>
            <v-progress-circular
              color="primary"
              indeterminate
              size="54"
            ></v-progress-circular>
          </v-col>
      </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default{
    name:"DivergenceTime",
    data: () => ({
    isLoading: false,
  }),
    props: {
    remessasId: {
      required: true,
    },
  },
  mounted() {
    this.getListDetalhes();
  },
  computed:{
    ...mapGetters("remessas", ["getDetalhesDivergenciaHorario"]),
  },
  methods:{
    ...mapActions("remessas", ["detalhesDivergenciaHorario"]),
   async getListDetalhes(){
      this.isLoading = true;
      await this.detalhesDivergenciaHorario(this.remessasId);
      this.isLoading = false;
    },
  },
  filters:{
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  }

}
</script>
<style>
.cancelled-row {
    color: rgba(149, 149, 149, 0.6);
  }
  .list-header {
    background-color: #0f2733;
    color: white;
    font-weight: bolder;
    min-height: 30px;
  }
  .border-right {
    border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
  }
  .list-card {
    color: black;
    font-weight: normal;
  }
  .list-card:hover {
    background-color: #ffdfcc;
    color: black;
  }
  .base-error {
    border-bottom: 0.1rem solid #ff5c01 !important;
  }
</style>