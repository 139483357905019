<template>
  <default-fab
    :tooltip-text="status.tooltip"
    :color="status.color"
    ><span
      class="white--text"
      style="font-weight: bold; font-size: 25px"
      >{{ status.letter }}</span
    ></default-fab
  >
</template>

<script>
import DefaultFab from "./DefaultFab.vue";
export default {
  name: "OrderStatus",
  components: {
    DefaultFab,
  },
  props: {
    orderStatus: {},
  },
  computed: {
    status() {
      const orderStatuses = {
        BLOQUEADO: {
          color: "pink",
          letter: "B",
          tooltip: "Pedido Bloqueado",
        },
        DESBLOQUEADO: {
          color: "blue",
          letter: "D",
          tooltip: "Pedido Desbloqueado",
        },
        LIBERADO: {
          color: "green",
          letter: "L",
          tooltip: "Pedido Liberado",
        },
      };
      return orderStatuses[this.orderStatus];
    },
  },
};
</script>

<style scoped></style>
