import { HttpRestService } from "@/services/http/HttpRestService";

export default class BahiaBranch {
  static getPickups(urlParams) {
    return HttpRestService.get(`/coleta/listar`, urlParams);
  }
  static biparVolume(data) {
    return HttpRestService.post("/redespacho/bipagem/volume", data);
  }
  static finalizarColeta(coletaId) {
    return HttpRestService.post(`/redespacho/finalizar/embarque/${coletaId}`);
    // return HttpRestService.post(`/coleta/finalizar/redespacho/${coletaId}`);
  }
  static checkProtocol(protocol) {
    return HttpRestService.post(`/redespacho/iniciar/embarque`, protocol);
    // return HttpRestService.get(`/protocolo/detalhes/${protocol}`);
  }
}
