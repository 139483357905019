export default {
  isLoading: false,
  listingMonPatio: [],
  monPatioPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  filteredMonPatio: [],
  isLoadingSearch: false,
  urlParams: {
    page: 1,
    pedido: "",
    transportadora: "",
  },
  baiaDetails: [],
  isLoadingBaiaList: false,
};
