<template>
  <v-tooltip top :disabled="disableTooltip" v-bind="$attrs">
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-btn
          v-on="{ ...on, ...$listeners }"
          v-bind="{ ...attrs, ...$attrs }"
          fab
          elevation="2"
          small
          :color="
            !color
              ? isTransportadora
                ? 'primaryTransportadora'
                : 'primary'
              : color
          "
          :class="
            isTransportadora
              ? hover
                ? 'primary--text'
                : 'base--text'
              : hover
              ? 'base--text'
              : 'navy--text'
          "
        >
          <slot></slot>
        </v-btn>
      </v-hover>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "DefaultFab",
  props: {
    tooltipText: {
      type: String,
      required: true,
    },
    isTransportadora: {
      type: Boolean,
      default: false,
    },
    disableTooltip: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
