<template>
  <div>
    <v-card class="overflow-hidden">
      <v-card-title class="justify-center">
        <h3 class="mb-6 navy--text">Atenção</h3>
      </v-card-title>
      <v-card-text class="text-h6">
        Deseja pausar a coleta N° {{ this.coletaId }}?
      </v-card-text>

      <v-card-actions class="justify-space-between">
        <v-row>
          <v-col>
            <v-btn
              color="red"
              text
              large
              @click="$emit('closeConfirmPauseCollectionDialog')"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              color="green"
              text
              large
              @click="onPauseCollection()"
              :loading="isLoadingButton"
            >
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ConfirmPauseCollectionDialog",
  components: {},
  data: () => ({
    isLoadingButton: false,
  }),
  props: {
    coletaId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    ...mapActions("expedition", ["pauseCollection"]),

    async onPauseCollection() {
      const modelPauseCollection = {
        coletaId: this.coletaId,
      };
      await this.pauseCollection(modelPauseCollection);
      this.$emit("closeConfirmPauseCollectionDialog");
      this.$emit("listExpedition");
    },
  },
};
</script>
<style lang="scss"></style>
