import { HttpRestService } from "@/services/http/HttpRestService";

export class PedidosRepository {
  static requestPedidos(urlParams) {
    return HttpRestService.get(`/pedido/listar`, urlParams);
  }
  static cargaRequests(numeroPedido) {
    return HttpRestService.get(`/carga/listar/${numeroPedido}`);
  }
  static relatorioCarga(pedidoId) {
    return HttpRestService.get(`/pedido/relatorio/${pedidoId}`);
  }
  static changeStatusPedido(pedidoId) {
    return HttpRestService.post(`/pedido/liberar/${pedidoId}`);
  }
}
