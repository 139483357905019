import { ProtocolsRepository } from "../../../repositories/defaultUserRepositories/ProtocolsRepository";

export default {
 async protocols({ commit }){
  const response = await ProtocolsRepository.requestProtocols();
  if (response.status >= 200 && response.status < 300) {
    commit("setListingProtocols", response.data.data.list);
    commit("setProtocolsPagination", response.data.data.pagination);
    return;
  }
  let errorObj = {
    errorStatus: true,
    errorMessage: response.data.message,
  };
  commit("notifications/setErrorNotification", errorObj, { root: true });
 },

 async cancelProtocol({ commit }, coletaId) {
  commit("setIsLoadingButton", true);
    const response = await ProtocolsRepository.cancelProtocol(coletaId);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Protocolo cancelado com sucesso.",
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      commit("setIsLoadingButton", false);
      this.protocols();
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("setIsLoadingButton", false);
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },  

  checkListAdd({commit}, payload){
    commit("addCheckedList" , payload)
 },

 checkListRemove({commit}, payload){
  commit("removeCheckedList" , payload)
  },
 
 async generateProtocol({commit}, payload){
  commit("setIsLoadingCreate", true);
  const response = await ProtocolsRepository.generateProtocol(payload);
  if (response.status >= 200 && response.status < 300) {
    let successObj = {
      successStatus: true,
      successMessage: response.data.message,
    };
    commit("notifications/setSuccessNotification", successObj, {
      root: true,
    });
    commit("cleanCheckedList");
    commit("setIsLoadingCreate", false);
    return;
  }
  let errorObj = {
    errorStatus: true,
    errorMessage: response.data.message,
  };
  commit("setIsLoadingCreate", false);
  commit("notifications/setErrorNotification", errorObj, { root: true });
 }
 
};
