import { RemessasRepository } from "../../../repositories/defaultUserRepositories/RemessasRepository";

/**
 * @typedef {Object} Doca
 * @property {Number} docaId
 * @property {Number} codigo
 * @property {String} cor
 */

/**
 * @typedef {Object} TipoVeiculo
 * @property {Number} tipoVeiculoId
 * @property {String} descricao
 */

/**
 * @typedef {Object} Pedido
 * @property {Number} pedidoId
 * @property {Number} numeroPedido
 * @property {Number} area
 * @property {Number} quantidadeCaminhoesAlocados
 * @property {Number} quantidadeVolumes
 * @property {Number} quantidadePaineis
 */

/**
 * @typedef {Object} RemessaDadosParaCadastro
 * @property {Doca[]} docas
 * @property {TipoVeiculo[]} tiposVeiculo
 * @property {Pedido[]} pedidos
 */

export default {
  async listarRemessas({ commit, state }) {
    commit("setListaRemessas", []);
    commit("setRemessasPagination", []);
    const urlParams = state.urlParams;
    const transportadoraId = urlParams.transportadoraId;
    const page = urlParams.page;
    const search = urlParams.search;
    const selecaoStatus = urlParams.selecaoStatus;
    const selecaoDate = urlParams.selecaoDate;
    const selecaoTipo = urlParams.selecaoTipo;
    let url = `/transportadora/${transportadoraId}/remessas?page=${page}&perPage=${10}`;
    if (selecaoTipo === 0 && search || selecaoTipo === null && search) {
      url = url.concat(`&search=${search}`);
    }
    if (selecaoTipo === 1 && search) {
      url = url.concat(`&search=${search}`);
    }
    if (selecaoTipo === 2 && search) {
      url = url.concat(`&coletaId=${search}`);
    }
    if (selecaoStatus) {
      url = url.concat(`&selecaoStatus=${selecaoStatus}`);
    }
    if (selecaoDate) {
      url = url.concat(`&selecaoDate=${selecaoDate}`);
    }
    const response = await RemessasRepository.requestRemessas(url);
    if (response.status >= 200 && response.status < 300) {
      commit("setListaRemessas", response.data.data.list);
      commit("setRemessasPagination", response.data.data.pagination);
      return response;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
      return response;
    }
  },

  /**
   * @param commit
   * @param {Number} transportadoraId
   * @returns {Promise<{transportadoraNome: String, pedidos: [{quantidadeVolumes: number, pedidoId: number, quantidadePaineis: number, numeroPedido: number, quantidadeCaminhoesAlocados: number, tipoPedido: String}], docas: [{cor: string, docaId: number, codigo: string}], tiposVeiculo: [{tipoVeiculoId: Number, descricao: String]}>}
   */
  async listDataParaCadastroRemessa({ commit }, transportadoraId) {
    try {
      const { data, status } =
        await RemessasRepository.requestDataParaCadastroRemessa(
          transportadoraId
        );
      if (status !== 200) return null;
      const { pedidos, docas, tipo_veiculo, transportadoraNome } = data.data;
      return {
        transportadoraNome: transportadoraNome || "",
        pedidos: pedidos.map((p) => ({
          pedidoId: p.pedidoId,
          numeroPedido: p.pedidoNumero,
          tipoPedido: p.tipoPedido,
          quantidadeCaminhoesAlocados: p.quantidadeCaminhoesAlocados,
          quantidadePaineis: p.quantidadePaineis,
          quantidadeVolumes: p.quantidadeVolumes,
          area: +p.area || 0.0,
          dataProducao: p.dataProducao || "0000-00-00 00:00:00",
          enderecoEntrega: p.enderecoEntrega || "",
        })),
        docas: docas.map((d) => ({
          docaId: ~~d.docaId,
          cor: d.docaCor || "",
          codigo: ~~d.docaCodigo,
        })),
        tiposVeiculo: tipo_veiculo.map((t) => ({
          tipoVeiculoId: ~~t.tipoVeiculoId,
          descricao: t.descricao || "",
        })),
      };
    } catch (e) {
      console.error({ e });
      return null;
    }
  },

  async cadastrarRemessa({ commit }, request) {
    const response = await RemessasRepository.requestCadastrarRemessa(request);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return true;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, {
        root: true,
      });
      return true;
    }
  },

  async successCadastro({ commit }, message) {
    let successObj = {
      successStatus: true,
      successMessage: message,
    };
    commit("notifications/setSuccessNotification", successObj, {
      root: true,
    });
  },

  async mostrarMensagemErro({ commit }, message) {
    let errorObj = {
      errorStatus: true,
      errorMessage: message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async setRemessaCadastro({ commit }, remessaCadastro) {
    commit("setRemessaCadastro", remessaCadastro);
  },

  async setPedidosSelected({ commit }, pedidosSelected) {
    commit("setPedidosSelected", pedidosSelected);
  },

  setIsLoadingSearch({ commit }, value) {
    commit("setIsLoadingSearch", value);
  },

  setTransportadoraData({ commit }, value) {
    commit("setTransportadoraData", value);
  },

  setDataRemessa({ commit }, value) {
    commit("setDataRemessa", value);
  },

  setDataColeta({ commit }, value) {
    commit("setDataColeta", value);
  },
  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },

  async setTypeAlertMessage({ commit }) {
    let errorObj = {
      errorStatus: true,
      errorMessage: "Tipo de pedido já foi definido pela Coleta anterior",
    };
    commit("notifications/setErrorNotification", errorObj, {
      root: true,
    });
  },
};
