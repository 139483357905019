export default {
  setListaColeta(state, response) {
    state.listaColeta = response;
  },
  setColetaPagination(state, response) {
    state.coletasPagination.total = response.total;
    state.coletasPagination.perPage = response.perPage;
    state.coletasPagination.currentPage = response.currentPage;
    state.coletasPagination.lastPage = response.lastPage;
    state.coletasPagination.nextPage = response.nextPage;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setColetaCadastro(state, coletaCadastro) {
    state.coletaCadastro = coletaCadastro;
  },
  setDataParaColetaCadastro(state, dataParaColetaCadastro) {
    state.dataParaColetaCadastro = dataParaColetaCadastro;
  },
  setDataParaEditColeta(state, dataParaEditColeta) {
    state.dataParaEditColeta = dataParaEditColeta;
  },
  setListDrivers(state, value) {
    state.listDrivers = value;
  },
  setListVehicles(state, value) {
    state.listVehicles = value;
  },
  setDocks(state, docks) {
    state.docks = docks;
  }

};
