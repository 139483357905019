<template>
  <div height="100%" style="overflow-x: hidden">
    <v-card class="pb-5">
      <v-card-title class="just">
        <v-row>
          <v-col cols="9" offset="1">
            <span class="text-h6 font-weight-bold navy--text">E-mails</span>
          </v-col>
          <v-col cols="1" align="center" v-if="!isLoadingModal">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    fab
                    small
                    elevation="3"
                    :disabled="
                      listEmail.length > 0 && listEmail.at(-1).email === ''
                    "
                    @click="addFieldListEmail()"
                    color="primary"
                    class="text-truncate"
                    :class="hover ? 'base--text' : 'navy--text'"
                    style="text-transform: none !important; font-weight: bolder"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <span v-if="listEmail.length">Adicionar Campo</span>
              <span v-else>Adicionar E-mail</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>
      <div v-if="!isLoadingModal">
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            v-if="listEmail.length"
          >
            <v-row
              class=""
              v-for="(email, i) in listEmail"
              :key="i"
              align="start"
            >
              <v-col cols="9" class="pb-0" offset="1">
                <v-text-field
                  ref="emailRules"
                  v-model="email.email"
                  :rules="emailRules"
                  outlined
                  dense
                  required
                  validate-on-blur
                  :autofocus="!getListEmailTransportadora.length"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        fab
                        small
                        elevation="3"
                        color="primary"
                        variant="outlined"
                        @click="removeEmailField(email)"
                        :disabled="listEmail.length === 1"
                        class="text-truncate"
                        :class="hover ? 'base--text' : 'navy--text'"
                        style="
                          text-transform: none !important;
                          font-weight: bolder;
                        "
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-hover>
                  </template>
                  <span>Excluir Campo</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-form>
          <div v-else>
            <v-col class="pa-0">
              <div class="text-body-1 navy--text pa-0">
                <p class="ma-0">A transportadora não pode ficar sem e-mail.</p>
                <p class="ma-0">Cadastre um agora.</p>
                <div>
                  <v-icon
                    @click="addFieldListEmail()"
                    size="50px"
                    color="primary"
                    >mdi-email-plus-outline</v-icon
                  >
                </div>
              </div>
            </v-col>
          </div>
        </v-card-text>
      </div>
      <v-col v-else>
        <v-progress-circular
          color="primary"
          indeterminate
          size="54"
        ></v-progress-circular>
      </v-col>
    </v-card>
    <v-row
      class="py-0 base"
      v-if="!listEmail.length == 0"
      color="danger"
      justify="center"
      style="background-color: ; position: sticky; bottom: 0"
    >
      <v-col cols="6">
        <v-hover v-slot="{ hover }">
          <v-btn
            @click="updateEmailList()"
            :disabled="listEmail.length == 0 || compareListEmail"
            :loading="isLoadingBtn"
            :color="hover ? 'secondary' : 'primary'"
            block
            rounded
            class="navy--text text-truncate p-0 mb-0"
            style="text-transform: none !important; font-weight: bolder"
            >Salvar</v-btn
          >
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    valid: true,
    isLoadingModal: false,
    isLoadingBtn: false,
    emailRules: [
      (value) =>
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ||
        "Formato de e-mail inválido",
    ],
    listEmail: [],
    stringifiedListEmail: [],
  }),
  props: {
    transportadora: { type: Object, required: true },
  },
  watch: {
    transportadora: {
      handler(newVal) {
        this.email = newVal.email;
      },
      immediate: true,
    },
    listEmail: {
      handler(newVal) {
        this.listEmail = newVal;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("transportadora", ["getListEmailTransportadora"]),
    compareListEmail() {
      return JSON.stringify(this.listEmail) === this.stringifiedListEmail;
    },
  },
  methods: {
    ...mapActions("transportadora", [
      "updateTransportadora",
      "setIsLoading",
      "listEmailTransportadora",
    ]),

    async updateEmailList() {
      const emailList = [];
      for (let i = 0; i < this.listEmail.length; i++) {
        emailList.push(this.listEmail[i].email);
      }
      if (this.listEmail.length == 0) {
        emailList.push("");
      }
      const bodyEmail = {
        transportadoraId: this.transportadora.transportadoraId,
        email: [...new Set(emailList)],
      };

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (
        Object.values(emailList).some(
          (emailList) => !emailRegex.test(emailList)
        )
      ) {
        this.$refs.form.validate(false);
        return;
      }
      this.isLoadingBtn = true;

      let response = await this.updateTransportadora(bodyEmail);
      if (response.status >= 200 && response.status < 300) {
        this.$emit("transportadoras");
        this.$emit("closeTransportadoraDialog");
        this.isLoadingBtn = false;
      }
      this.isLoadingBtn = false;
    },
    addFieldListEmail() {
      if (this.listEmail.length == 0) {
        this.listEmail.push({
          email: "",
          id: this.listEmail.length + 1,
          transportadoraId: this.transportadora.transportadoraId,
        });
      } else {
        this.listEmail.push({
          email: "",
          id: this.listEmail[this.listEmail.length - 1].id + 1,
          transportadoraId: this.transportadora.transportadoraId,
        });
      }
    },

    removeEmailField(email) {
      this.listEmail.splice(this.listEmail.indexOf(email), 1);
    },
    async insertListEmail() {
      await this.listEmail.push(...this.getListEmailTransportadora);
      this.stringifiedListEmail = JSON.stringify(this.listEmail);
    },
  },
  mounted() {
    this.isLoadingModal = true;
    this.listEmailTransportadora(this.transportadora.transportadoraId).then(
      () => {
        this.insertListEmail();
        this.isLoadingModal = false;
      }
    );
  },
};
</script>
<style scoped>
.h {
  font-weight: bolder;
}
.list-header {
  background-color: #0f2733;
  color: white;
  font-weight: bolder;
}
.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
.list-card {
  color: black;
  font-weight: normal;
}
.list-card:hover {
  background-color: #ffdfcc;
  color: black;
}
</style>
