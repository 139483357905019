import { render, staticRenderFns } from "./StartShipping.vue?vue&type=template&id=bc5b1a0e&scoped=true&"
import script from "./StartShipping.vue?vue&type=script&lang=js&"
export * from "./StartShipping.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc5b1a0e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardSubtitle,VCardTitle,VCol,VContainer,VForm,VRow})
