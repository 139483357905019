export default {
  setListaRemessas(state, response) {
    state.listaRemessas = response;
  },
  setColetaByRemessa(state, response) {
    state.coletaByRemessa = response;
  },
  setRemessasPagination(state, response) {
    state.remessasPagination.total = response.total;
    state.remessasPagination.perPage = response.perPage;
    state.remessasPagination.currentPage = response.currentPage;
    state.remessasPagination.lastPage = response.lastPage;
    state.remessasPagination.nextPage = response.nextPage;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setRemessaCadastro(state, remessaCadastro) {
    state.remessaCadastro = remessaCadastro;
  },
  setRemessaDadosParaCadastro(state, remessaDadosParaCadastro) {
    state.remessaDadosParaCadastro = remessaDadosParaCadastro;
  },
  setPedidosSelected(state, pedidosSelected) {
    state.pedidosSelected = pedidosSelected;
  },
  setIsLoadingSearch(state, value) {
    state.isLoadingSearch = value;
  },
  setTransportadoraData(state, value) {
    state.transportadoraData = value;
  },
  setDataRemessa(state, value) {
    state.dataRemessa = value;
  },
  setDataColeta(state, value) {
    state.dataColeta = value;
  },
  setTipoColeta(state, value) {
    state.tipoColeta = value;
  },
  setIsLoading(state, response) {
    state.isLoading = response;
  },
  setDetalhesDivergencia(state, response) {
    state.detalhesDivergencia = response;
  },
  setDetalhesDivergenciaHorario(state, response) {
    state.detalhesDivergenciaHorario = response;
  },
  setUrlParams(state, urlParams) {
    state.urlParams.page = urlParams.page;
    state.urlParams.search = urlParams.search;
    state.urlParams.selecaoStatus = urlParams.selecaoStatus;
    state.urlParams.selecaoDate = urlParams.selecaoDate;
    state.urlParams.transportadoraId = urlParams.transportadoraId;
    state.urlParams.selecaoTipo = urlParams.selecaoTipo;
  },
  setTransportadorasFilhas(state, response) {
    state.transportadorasFilhas = response;
  },
  setPedidosTransportadoraFilha(state, response) {
    state.pedidosTransportadoraFilha = response;
  },
  setTransportadoraFilhaId(state, response) {
    state.transportadoraFilhaId = response;
  },
};
