export default {
  setListingPedidos(state, response) {
    state.listingPedidos = response;
  },
  setPedidosPagination(state, response) {
    state.pedidosPagination.total = response.total;
    state.pedidosPagination.perPage = response.perPage;
    state.pedidosPagination.currentPage = response.currentPage;
    state.pedidosPagination.lastPage = response.lastPage;
    state.pedidosPagination.nextPage = response.nextPage;
  },
  setIsLoading(state, response) {
    state.isLoading = response;
  },
  setDataInicial(state, response) {
    state.dataInicial = response;
  },
  setDataFinal(state, response) {
    state.dataFinal = response;
  },
  setCargaRequests(state, response) {
    state.cargaRequests = response;
  },
  setRelatorioCarga(state, response) {
    state.relatorioCarga = response;
  },
  setUrlParams(state, urlParams) {
    state.urlParams.page = urlParams.page;
    state.urlParams.dataInicio = urlParams.dataInicio;
    state.urlParams.dataFinal = urlParams.dataFinal;
    state.urlParams.coletaId = urlParams.coletaId;
    state.urlParams.numeroPedido = urlParams.numeroPedido;
    state.urlParams.pedidoWeb = urlParams.pedidoWeb;
    state.urlParams.transportadoraNome = urlParams.transportadoraNome;
    state.urlParams.tempoPatio = urlParams.tempoPatio;
  },
  haveFilters(state, response) {
    state.haveFilters = response;
  },
};
