export default {
  getListingUser(state) {
    return state.listingUsers;
  },

  getUsersPagination(state) {
    return state.usersPagination;
  },

  getIsLoading(state) {
    return state.isLoading;
  },

  getListPerfis(state) {
    //Não remover sem antes consultar o time de desenvolvimento
    state.listPerfis = state.listPerfis.filter(
      (item) => item.roleName !== "Financeiro"
    );
    return state.listPerfis;
  },

  getTransportadoraEmails(state) {
    return state.transportadoraEmails;
  },
};
