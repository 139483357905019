<template>
  <v-card class="overflow-y-auto">
    <v-card-title class="justify-center">
      <span class="text-h6 font-weight-bold navy--text"
        >Cadastro de Coleta - {{ transportadoraNome }}
      </span>
      <span class="text-h6 font-weight-bold navy--text"> </span>
    </v-card-title>
    <div v-if="!isLoading">
      <v-card-text>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-row justify="space-around">
              <v-col cols="5">
                <v-text-field
                  ref="horaInicio"
                  :error-messages="errorMessages"
                  v-model="horaInicio"
                  :rules="rules.hora"
                  label="Hora Inicial"
                  type="time"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  ref="horaFim"
                  :error-messages="errorMessages"
                  type="time"
                  v-model="horaFim"
                  :rules="rules.hora"
                  label="Hora Final"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col cols="5">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      ref="dataColeta"
                      :error-messages="errorMessages"
                      :rules="rules.data"
                      placeholder="Data da Coleta"
                      outlined
                      readonly
                      dense
                      solo
                      flat
                      v-bind="attrs"
                      v-on="on"
                      v-model="dataFormatada"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dataDaColeta"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="5">
                <v-select
                  ref="docaSelected"
                  :error-messages="errorMessages"
                  class="text-center"
                  v-model="docaSelected"
                  :items="listDocas"
                  :rules="rules.doca"
                  item-text="docaCodigo"
                  item-value="docaId"
                  :item-color="docaSelected.docaCor"
                  :color="docaSelected.docaCor"
                  return-object
                  label="Doca"
                  outlined
                  dense
                  required
                >
                  <template v-slot:item="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      Doca - {{ item.docaCodigo }} -
                      <v-icon :color="item.docaCor">mdi-circle</v-icon>
                    </span>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      Doca - {{ item.docaCodigo }}
                    </span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon :color="docaSelected.docaCor">mdi-circle</v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col cols="5">
                <v-autocomplete
                  ref="veiculoSelected"
                  :error-messages="errorMessages"
                  v-model="areaTruckSync"
                  :items="listOfVehiclesType"
                  :rules="rules.veiculoRules"
                  item-text="descricao"
                  item-value="tipoVeiculoId"
                  return-object
                  label="Tipo de Veículo"
                  outlined
                  dense
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="5" class="text-start">
                <div
                  class="base_dark rounded-xl pa-1 pr-2"
                  style="position: fixed"
                >
                  <span class="navy--text">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ma-2"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-truck-outline</v-icon
                        >
                      </template>
                      <span>Comprimento do Caminhão</span>
                    </v-tooltip>
                    <span class="font-weight-bold"
                      >{{ areaTruckSelected.toFixed(2) }}m
                    </span>
                  </span>
                  <span class="navy--text">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ma-2"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-package-variant-closed-check
                        </v-icon>
                      </template>
                      <span>Comprimento dos Pedidos</span>
                    </v-tooltip>
                    <span class="font-weight-bold"
                      >{{ areaPedidosSelected.toFixed(2) }}m
                    </span>
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row justify="space-around" align="center" class="mx-4 my-3">
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Pesquisar Num. Pedido"
                  outlined
                  clearable
                  dense
                  solo
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="selectedFilterPedidos"
                  :items="tiposDePedidos"
                  :readonly="this.coletaCadastroPedidosSync.length > 0"
                  ref="selectOrderTypeFilter"
                  @mouseup="onTypeAlertMessage"
                  label="Filtrar por pedidos"
                  dense
                  small-chips
                  hide-details
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  @item-selected="evaluateIfPedidoWasAlreadySelected"
                  @toggle-select-all="
                    evaluateIfSomePedidoWasAlreadyBeenSelected
                  "
                  ref="table"
                  aria-label="Pedidos"
                  v-model="coletaCadastroPedidosSync"
                  :headers="headers"
                  :items="pedidosFilter"
                  :search="search"
                  :key="reRender"
                  :single-select="false"
                  item-key="numeroPedido"
                  show-select
                  class="elevation-1"
                  disable-pagination
                  hide-default-footer
                  dense
                />
              </v-col>
            </v-row>
            <v-row
              justify="center"
              v-show="this.areaPedidosSelected > this.areaTruckSelected"
              class="pa-0 ma-0"
            >
              <v-col cols="6" class="pa-0 ma-0">
                <v-checkbox
                  v-model="checkboxArea"
                  label="Estou ciente que o Comprimento dos pedidos selecionados é maior que o Comprimento do veículo."
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="6">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    @click="createColeta"
                    :loading="createButtonLoading"
                    :color="hover ? 'secondary' : 'primary'"
                    :disabled="!habilitateButton"
                    block
                    rounded
                    class="navy--text text-truncate"
                    style="text-transform: none !important; font-weight: bolder"
                    >Criar Coleta</v-btn
                  >
                </v-hover>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="54"
      ></v-progress-circular>
    </v-col>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  name: "CreateColeta",
  data: () => ({
    // tipos de pedidos
    selectedFilterPedidos: ["web", "ploomes"],
    tiposDePedidos: [
      {
        value: ["web", "ploomes"],
        text: "Web + Ploomes",
      },
      {
        value: ["express", "lote", "lote-fixador"],
        text: "Ágil",
      },
    ],
    reRender: 0,
    pedidosFilter: [],
    search: "",
    dataDaColeta: "",
    dataFormatada: "",
    transportadoraNome: "",
    menu1: false,
    formHasErrors: false,
    errorMessages: "",
    //Loading
    createButtonLoading: false,
    isLoading: false,
    //BodyStart
    checkboxArea: false,
    areaTruckSelected: 0,
    areaPedidosSelected: 0,
    listTipoVeiculo: [],
    horaInicio: "",
    horaFim: "",
    listDocas: [],
    docaSelected: { docaCor: "" },
    veiculoSelected: {
      area: 0,
      tipoVeiculoId: 0,
      descricao: "",
      criadoEm: "",
    },
    coleta: {},
    pedidos: [],
    //BodySend
    urlParams: {
      listaPedidosId: [],
      transportadoraId: 0,
      remessaId: 0,
      tipoVeiculoId: 0,
      docaId: 0,
      dataInicioColeta: "",
      dataFimColeta: "",
    },
    //Rules
    rules: {
      hora: [(v) => !!v || "Hora é obrigatório"],
      data: [(v) => !!v || "Data é obrigatório"],
      doca: [(v) => !!v.docaId || "Doca é obrigatório"],
      veiculoRules: [(v) => !!v.descricao || "O campo Veículo é obrigatório"],
      pedidoRules: [(v) => !!v || "Tipo de Pedido é obrigatório"],
    },
    //BodyTable
    headers: [
      {
        text: "Tipo pedido",
        value: "tipoPedido", // add tipo de pedido
        sortable: false,
        filterable: true,
        align: "start",
      },
      {
        text: "Núm. pedido",
        value: "numeroPedido",
        sortable: false,
        align: "start",
      },
      { text: "Compr. (m)", value: "area", sortable: true, align: "start" },
      {
        text: "Qtd. caminhões",
        value: "quantidadeCaminhoesAlocados",
        sortable: true,
        align: "start",
      },
      {
        text: "Qtd. volumes",
        value: "quantidadeVolumes",
        sortable: true,
        align: "start",
      },
      {
        text: "Qtd. painéis",
        value: "quantidadePaineis",
        sortable: true,
        align: "start",
      },
      {
        text: "Data produção",
        value: "dataProducao",
        sortable: true,
        align: "start",
      },
    ],
    coletaCadastroPedidos: [],
  }),

  mounted() {
    this.clearData();
    this.getDataForModal();
  },

  computed: {
    ...mapGetters({
      dataColetaCadastro: "coleta/getDataParaColetaCadastro",
      pedidosSelected: "remessas/getPedidosSelected",
      remessaCadastroGet: "remessas/getRemessaCadastro",
      listOfVehiclesType: "vehicles/getVehiclesType",
      getDataColeta: "remessas/getDataColeta",
    }),
    //...mapGetters("remessas", ["getDataColeta"]),

    form() {
      return {
        horaInicio: this.horaInicio,
        horaFim: this.horaFim,
        dataColeta: this.dataColeta,
        docaSelected: this.docaSelected.docaId,
        veiculoSelected: this.veiculoSelected.tipoVeiculoId,
        table: this.coletaCadastroPedidosSync,
      };
    },

    coletaCadastroPedidosSync: {
      get() {
        return this.pedidosSelected;
      },
      set(coletaCadastroPedidos) {
        this.calculateAreaPedidos(coletaCadastroPedidos);
        this.setPedidosSelected(coletaCadastroPedidos);
        this.remessaCadastroSet({
          ...this.remessaCadastroGet,
          pedidos: this.pedidosSelected,
        });
      },
    },

    areaTruckSync: {
      get() {
        return this.veiculoSelected;
      },
      set(areaTruck) {
        switch (areaTruck.tipoVeiculoId) {
          /* carreta: 15 x 2,6 m
        truck: 8 x 2,6 m
        toco: 8 x 2,6 m*/
          case 1:
            this.veiculoSelected.area = 15;
            this.veiculoSelected.tipoVeiculoId = areaTruck.tipoVeiculoId;
            break;
          case 2:
            this.veiculoSelected.area = 8;
            this.veiculoSelected.tipoVeiculoId = areaTruck.tipoVeiculoId;
            break;
          default:
            this.veiculoSelected.area = 7;
            this.veiculoSelected.tipoVeiculoId = areaTruck.tipoVeiculoId;
            break;
        }
        this.areaTruckSelected = +this.veiculoSelected.area;
      },
    },

    habilitateButton() {
      if (
        this.areaTruckSelected > this.areaPedidosSelected &&
        this.coletaCadastroPedidosSync.length
      ) {
        return true;
      } else {
        if (this.checkboxArea && this.coletaCadastroPedidosSync.length) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    ...mapActions({
      dataParaColetaDeCadastro: "coleta/dataParaColetaCadastro",
      setPedidosSelected: "remessas/setPedidosSelected",
      remessaCadastroSet: "remessas/setRemessaCadastro",
      zeragemDataCadastro: "coleta/setDataColetaParaCadastro",
      postCadastrarColeta: "coleta/cadastrarColeta",
      setDataColeta: "remessas/setDataColeta",
      setTypeAlertMessage: "remessas/setTypeAlertMessage",
    }),

    onTypeAlertMessage() {
      this.$refs["selectOrderTypeFilter"].isReadonly === false
        ? ""
        : this.setTypeAlertMessage();
    },

    async getDataForModal() {
      this.isLoading = true;
      await this.dataParaColetaDeCadastro(this.$route.params.transportadoraId);
      await this.listarDadosParaCadastro();
      this.isLoading = false;
    },

    calculateAreaPedidos(pedido) {
      if (pedido) this.areaPedidosSelected = 0;
      for (var p = 0; p < pedido.length; p++) {
        this.areaPedidosSelected += pedido[p].area;
      }
    },

    async listarDadosParaCadastro() {
      //Get de dados
      const response = await this.dataColetaCadastro;
      const { pedidos, docas, tipo_veiculo, transportadoraNome } = response;
      //Set de dados
      if (pedidos) {
        this.pedidos = pedidos.map((p) => ({
          pedidoId: ~~p.pedidoId,
          numeroPedido: p.pedidoNumero,
          tipoPedido: p.tipoPedido,
          quantidadeCaminhoesAlocados: ~~p.quantidadeCaminhoesAlocados,
          quantidadePaineis: ~~p.quantidadePaineis,
          quantidadeVolumes: ~~p.quantidadeVolumes,
          area: +p.area || 0.0,
          dataProducao: p.dataProducao || "0000-00-00 00:00:00",
          enderecoEntrega: p.enderecoEntrega || "",
        }));
      }
      this.listDocas = docas;
      this.listTipoVeiculo = tipo_veiculo;
      this.transportadoraNome = transportadoraNome;
    },

    async createColeta() {
      this.createButtonLoading = true;
      this.formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) {
          this.formHasErrors = true;
          this.createButtonLoading = false;
        }
        this.$refs.form.validate(true);
      });
      if (!this.formHasErrors) {
        this.insertDataToBody();
        const response = await this.postCadastrarColeta(this.urlParams);
        console.log(response);
        if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
          this.createButtonLoading = false;
          this.closeButton();
        } else {
          this.createButtonLoading = false;
        }
      }
    },

    closeButton() {
      this.$emit("closeCreateColeta");
    },

    async clearData() {
      this.pedidos = [];
      this.listDocas = [];
      this.listTipoVeiculo = [];
      this.docaSelected = {};
      this.veiculoSelected = {};
      this.areaPedidosSelected = 0;
      this.areaTruckSelected = 0;
      this.transportadoraNome = "";
      this.coletaCadastroPedidos = [];
      await this.setPedidosSelected([]);
      await this.remessaCadastroSet({
        ...this.remessaCadastroGet,
        pedidos: [],
      });
    },

    insertDataToBody() {
      this.urlParams.remessaId = parseInt(this.$route.params.remessaId);
      this.urlParams.transportadoraId = parseInt(
        this.$route.params.transportadoraId
      );
      this.urlParams.docaId = this.docaSelected.docaId;
      this.urlParams.dataInicioColeta = this.dataColeta.concat(
        " " + this.horaInicio + ":00"
      );
      this.urlParams.dataFimColeta = this.dataColeta.concat(
        " " + this.horaFim + ":00"
      );
      this.urlParams.tipoVeiculoId = this.veiculoSelected.tipoVeiculoId;
      this.urlParams.listaPedidosId = this.pedidosSelected.map(
        (p) => p.pedidoId
      );
    },

    evaluateIfPedidoWasAlreadySelected(event) {
      if (~~event.item.quantidadeCaminhoesAlocados > 0) {
        this.mostrarMensagemErro(
          "Esse pedido já foi selecionado antes para outra coleta. Certifique-se de que você tem certeza que deseja fazer isso."
        );
      }
    },

    evaluateIfSomePedidoWasAlreadyBeenSelected(event) {
      const pedidosComMaisDeUmCaminhao = event.items
        .filter(
          ({ quantidadeCaminhoesAlocados }) => quantidadeCaminhoesAlocados > 0
        )
        .map(({ numeroPedido }) => numeroPedido);
      if (pedidosComMaisDeUmCaminhao.length) {
        const numerosPedido = pedidosComMaisDeUmCaminhao.join(",");
        this.mostrarMensagemErro(
          `Os pedidos ${numerosPedido} estão com mais de um caminhão alocados. Certifique-se de que você tem certeza que deseja fazer isso.`
        );
      }
    },
    formatDate(date) {
      if (date == null || date == undefined || date == "") return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    filtroTipoPedido(arrayTipoPedido) {
      this.pedidosFilter = [];
      this.setPedidosSelected([]);
      this.pedidosFilter = this.pedidos.filter((p) => {
        if (arrayTipoPedido.includes(p.tipoPedido)) {
          return p;
        }
      });
      this.reRender += 1;
    },
  },

  watch: {
    selectedFilterPedidos: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.filtroTipoPedido(newValue);
      },
    },
    pedidos: {
      deep: true,
      immediate: true,
      handler() {
        this.filtroTipoPedido(this.selectedFilterPedidos);
      },
    },
    selected(selected) {
      this.$emit(
        "selected",
        selected.map(({ pedidoId }) => pedidoId)
      );
    },
    name() {
      this.errorMessages = "";
    },
    dataDaColeta(newValue, oldValue) {
      this.dataFormatada = this.formatDate(newValue);
      this.dataColeta = newValue;
    },
  },
  created() {
    this.setDataColeta("");
  },
};
</script>
<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
::v-deep
  .theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  color: white;
}
</style>
