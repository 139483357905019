export default {
  setListingTransportadoras(state, response) {
    state.listingTransportadoras = response;
  },
  setFilteredTransportadoras(state, response) {
    state.filteredTransportadoras = response;
  },
  setIsLoading(state, response) {
    state.isLoading = response;
  },
  setListingEmailTransportadora(state, response) {
    state.listingEmailTransportadora = response;
  },
};
