<template>
  <default-fab
    v-bind="$attrs"
    v-on="$listeners"
    :top="false"
    :left="true"
    tooltip-text="Voltar ao Topo"
  >
    <v-icon>mdi-arrow-up-bold</v-icon>
  </default-fab>
</template>

<script>
import DefaultFab from "./DefaultFab.vue";
export default {
  name: "BackToTop",
  components: {
    DefaultFab,
  },
};
</script>

<style scoped></style>