<template>
  <div class="mt-3 mb-10">
    <v-row no gutters class="justify-space-between align-center mt-3">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          label="Buscar Transportadora"
          hide-details="auto"
          v-model="search"
          dense
          clearable
          solo
          flat
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "TransportadorasListHeader",
  data: () => ({
    dialog: false,
    search: "",
  }),
  watch: {
    search(search) {
      this.getFilter(search);
    },
  },
  computed: {
    ...mapGetters("transportadora", ["getListingTransportadoras", "getFilter"]),
  },
  methods: {
    ...mapActions("transportadora", ["transportadoras"]),
  },
};
</script>
<style></style>
