<template>
  <div class="mt-3">
    <v-row class="justify-center my-3 mx-2 text--text">
      <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="text-start">
        <b class="text-truncate">
          Transportadora:
          {{
            this.$route.params.nomeFantasia ||
            this.getTransportadoraData.nomeFantasia
          }}
        </b>
      </v-col>
      <v-col cols="6" sm="6" md="4" lg="4" xl="4">
        <b class="text-truncate">
          CNPJ:
          {{ this.$route.params.cnpj || this.getTransportadoraData.cnpj }}
        </b>
      </v-col>
      <v-col cols="6" sm="2" md="2" lg="2" xl="2">
        <b>
          Remessa:
          <b class="secondary--text">
            {{
              this.$route.params.remessaId ||
              this.getTransportadoraData.remessaId
            }}</b
          >
        </b>
      </v-col>
    </v-row>
    <v-row no gutters class="mx-auto justify-center">
      <v-col cols="2" sm="2" md="1" lg="1" xl="1">
        <v-hover v-slot="{ hover }">
          <v-btn
            fab
            small
            elevation="3"
            color="primary"
            class="text-truncate"
            :class="hover ? 'base--text' : 'navy--text'"
            @click="backToRemessas"
            style="text-transform: none !important; font-weight: bolder"
          >
            <v-icon> mdi-arrow-left-bold </v-icon>
          </v-btn>
        </v-hover>
      </v-col>
      <v-col cols="10" sm="10" md="8" lg="8" xl="8">
        <v-text-field
          label="Pesquisar Coleta"
          :append-icon="searchIcon"
          v-model="urlParams.search"
          @click:append="search"
          @keydown.enter.prevent="search"
          dense
          solo
          flat
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" sm="6" md="3" lg="3" xl="3">
        <v-row no-gutters class="">
          <v-hover v-slot="{ hover }">
            <v-btn
              rounded
              block
              :color="hover ? 'secondary' : 'primary'"
              class="navy--text text-truncate"
              style="text-transform: none !important; font-weight: bolder"
              @click="openCreateColetaDialog"
              :disabled="isRemessaConcluida || isDisabledStatusId"
              >Cadastrar Coleta</v-btn
            >
          </v-hover>
        </v-row>
        <v-dialog
          v-model="dialogCreateColeta"
          scrollable
          max-width="720"
          transition="dialog-bottom-transition"
        >
          <create-coleta
            :key="reRender"
            @closeCreateColeta="closeCreateColeta"
          />
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CreateColeta from "./CreateColeta.vue";
export default {
  name: "RemessaEditListHeader",
  components: {
    CreateColeta,
  },
  data: () => ({
    reRender: 0,
    searchIcon: "mdi-magnify",
    setIsLoadingSearch: false,
    dialogCreateColeta: false,
    urlParams: {
      page: 1,
      search: "",
    },
  }),
  computed: {
    ...mapGetters("remessas", ["getTransportadoraData"]),
    isRemessaConcluida() {
      return this.$route.query.remessaStatus === "CONCLUIDO";
    },
    isDisabledStatusId() {
      return (
        this.$route.query.remessaStatusId >= 5 &&
        this.$route.query.remessaStatusId <= 8
      );
    },
  },
  methods: {
    ...mapActions("coleta", ["coleta", "setIsLoading"]),

    async search() {
      this.searchIcon = "mdi-loading mdi-spin";
      this.setIsLoadingSearch = true;
      this.urlParams.remessa = this.$route.params.remessaId;
      await this.coleta(this.urlParams);
      this.setIsLoadingSearch = false;
      this.searchIcon = "mdi-magnify";
    },
    openCreateColetaDialog() {
      this.reRender += 1;
      this.dialogCreateColeta = true;
    },

    closeCreateColeta() {
      this.dialogCreateColeta = false;
    },

    backToRemessas() {
      this.$router.push({
        name: "Remessas da Transportadora",
        params: {
          transportadoraId: this.$route.params.transportadoraId,
          cnpj: this.$route.params.cnpj,
          nomeFantasia: this.$route.params.nomeFantasia,
        },
      });
    },
  },
};
</script>
<style scoped>
::v-deep
  .theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  color: white;
}
</style>
