<template>
  <div v-if="!isLoading">
    <v-card-text>
      <div v-if="getDetailsOfManifest.length">
        <v-container
          class="base_dark text--text"
          v-for="details in getDetailsOfManifest"
          :key="details.coletaId"
        >
          <v-row class="justify-center">
            <v-col align="start">
              <p><b>Despachante:</b>{{ details.despachanteNome || "" }}</p>
              <p><b>Empilhador:</b>XXXXX</p>
              <p>
                <b>Transportadora:</b>{{ details.transportadoraNome || "" }}
              </p>
              <p><b>Data:</b>{{ details.horaInicioPrevisto || "" }}</p>
              <p><b>Hora:</b>{{ details.horaInicioPrevisto || "" }}</p>
            </v-col>
            <v-col align="start">
              <p><b>Manifesto:</b>{{ details.cubagem || "" }}</p>
              <p><b>Motorista:</b>{{ details.motoristaNome || "" }}</p>
              <p><b>Placa:</b>{{ details.placaVeículo || "" }}</p>
              <p><b>Pedidos:</b>{{ details.totalPedidos || "" }}</p>
            </v-col>
            <v-col>
              <div class="pa-4">
                <v-btn
                  block
                  rounded
                  :class="hover ? 'base--text' : 'navy--text'"
                  class="navy--text text-truncate"
                  style="text-transform: none !important; font-weight: bolder"
                  >Filtrar
                </v-btn>
              </div>
              <div class="pa-4">
                <v-btn
                  block
                  rounded
                  disabled
                  :color="hover ? 'primary' : 'secondary'"
                  class="navy--text text-truncate"
                  style="text-transform: none !important; font-weight: bolder"
                >
                  Coletar Ass. Motorista</v-btn
                >
              </div>
              <div class="pa-4">
                <v-btn
                  block
                  rounded
                  :color="hover ? 'primary' : 'secondary'"
                  class="navy--text text-truncate"
                  style="text-transform: none !important; font-weight: bolder"
                >
                  Imprimir Manifesto</v-btn
                >
              </div>
            </v-col>
          </v-row>
          <v-row class="display: flex">
            <v-col class="cordecima rounded ml-4 mb-5 text-break" md="2">
              <p class="pa-0 ma-0">
                <b>Volumes:</b>{{ details.totalVolumes || "" }}
              </p>
            </v-col>
            <v-col class="cordecima rounded ml-4 mb-5 text-break" md="2">
              <p class="pa-0 ma-0"><b>Baia:</b>{{ details.baia || "" }}</p>
            </v-col>
            <v-col class="cordecima rounded ml-4 mb-5 text-break" md="2">
              <p class="pa-0 ma-0">
                <b>00:00:00:</b>{{ details.horaFinalPrevisto || "" }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-else>
        <v-container>
          <v-row
            class="box-element justify-center align-center my-auto mt-3 rounded-lg"
            rounded
          >
            <v-col class="align-self-center ma-5" cols="4">
              <span
                >Não foi possível carregar os detalhes do manifesto. Favor
                entrar em contato com Suporte</span
              >
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card-text>
    <v-card-text>
      <v-row class="list-header mx-auto my-auto mt-3">
        <v-col
          align="start"
          class="border-right d-none d-sm-none d-md-block d-lg-block d-xl-block"
          v-for="(title, i) in titles"
          :title="title.label"
          :key="i"
          md="2"
        >
          <div class="text-truncate text-center">{{ title.label }}</div>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
          class="justify-center text-center"
        >
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block"
            >Status</span
          >
        </v-col>
      </v-row>
      <div v-if="getListaVolumes.length">
        <v-row
          class="list-card base_dark text--text mx-auto my-auto mt-2"
          v-for="(boarding, i) in getListaVolumes"
          :key="i"
        >
          <v-col align="center"
            ><span class="d-md-none d-lg-none d-xl-none"><b>Nº Nota</b></span>
            <div class="text-truncate">
              {{ boarding.numeroNotaFiscal }}
            </div></v-col
          >
          <v-col align="center"
            ><span class="d-md-none d-lg-none d-xl-none"><b>Nº Pedido</b></span>
            <div class="text-truncate">{{ boarding.pedidoId }}</div></v-col
          >
          <v-col align="center"
            ><span class="d-md-none d-lg-none d-xl-none"><b>Volumes</b></span>
            <div class="text-truncate">{{ boarding.volumeId }}</div></v-col
          >
          <v-col align="center"
            ><span class="d-md-none d-lg-none d-xl-none"><b>Cubagem:</b></span>
            <div class="text-truncate">{{ boarding.cubagem }}</div></v-col
          >
          <v-col align="center"
            ><span class="d-md-none d-lg-none d-xl-none"><b>Status</b></span>
            <div class="text-truncate">{{ boarding.volumeStatus }}</div>
          </v-col>

          <v-col align="center">
            <div>
              <v-dialog :v-model="dialog[boarding.coletaId]" max-width="520">
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      block
                      rounded
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      class="text-truncate"
                      :class="hover ? 'base--text' : 'navy--text'"
                      max-width="160px"
                      style="
                        text-transform: none !important;
                        font-weight: bolder;
                      "
                    >
                      Mais Detalhes
                    </v-btn>
                  </v-hover>
                </template>
                <boarding-details :id="boarding.volumeId" />
              </v-dialog>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row
          class="box-element justify-center align-center my-auto mt-3 rounded-lg"
          rounded
        >
          <v-col class="align-self-center ma-5" cols="4">
            <span
              >Não foi possível carregar a listagem do manifesto. Favor entrar
              em contato com Suporte</span
            >
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </div>
  <v-col v-else>
    <v-progress-circular
      color="primary"
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-col>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BoardingDetails from "./BoardingDetails.vue";
export default {
  components: {
    BoardingDetails,
  },
  data: () => ({
    dialog: false,
    isLoading: false,
    hover: true,
    titles: [
      { label: "Nº Nota" },
      { label: "Nº Pedido" },
      { label: "Volumes" },
      { label: "Cubagem" },
    ],
  }),
  mounted() {
    this.listItens();
  },
  computed: {
    ...mapGetters("boarding", ["getListaVolumes", "getDetailsOfManifest"]),
  },
  methods: {
    ...mapActions("boarding", ["listaVolumes", "detailsOfManifest"]),
    async listItens() {
      await this.detailsOfManifest(1);
      await this.listaVolumes(1);
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.list-header {
  background-color: #0f2733;
  color: white;
  font-weight: bolder;
  min-height: 30px;
}
.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
.list-card {
  color: black;
  font-weight: normal;
}

.list-card:hover {
  background-color: #ffdfcc;
  color: black;
}
.lado {
  display: inline-block;
}
.cordecima {
  background: #ffd1b2;
}
</style>
