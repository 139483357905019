import { TransportadoraRepository } from "../../../repositories/defaultUserRepositories/TransportadoraRepository";

export default {
  async transportadoras({ commit }) {
    commit("setIsLoading", true);
    const response = await TransportadoraRepository.requestTransportadora();
    if (response.status >= 200 && response.status < 300) {
      commit("setListingTransportadoras", response.data.data);
      commit("setFilteredTransportadoras", response.data.data);
      commit("setIsLoading", false);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
    commit("setIsLoading", false);
  },
  async updateTransportadora({ commit }, modelUpdateTransportadora) {
    const response = await TransportadoraRepository.updateTransportadora(
      modelUpdateTransportadora
    );
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },
  async listEmailTransportadora({ commit }, transportadoraId) {
    commit("setListingEmailTransportadora", []);
    const response = await TransportadoraRepository.listEmailTransportadora(
      transportadoraId
    );
    if (response.status >= 200 && response.status < 300) {
      commit("setListingEmailTransportadora", response.data.data.data);
      return response;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async resendUp({ commit }, modelResend) {
    const response = await TransportadoraRepository.resendUp(modelResend);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },
};
