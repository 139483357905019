import { MonitoramentoPatioRepository } from "../../../repositories/defaultUserRepositories/MonitoramentoPatioRepository";
import { ErrorNotifier } from "../../../services/messages/ErrorNotifier";

export default {
  async baiaDetails({ commit, dispatch }, baiaId) {
    commit("setIsLoadingBaiaList", true);
    commit("setBaiaDetails", []);
    let url = `/baia/patio/monitoramento/${baiaId}/detalhes`;
    const response = await MonitoramentoPatioRepository.requestMonPatio(url);
    if (response.status >= 200 && response.status < 300) {
      const mapResponse = response.data.data.map((pedido) => {
        return {
          ...pedido,
          check: false,
        };
      });
      commit("setBaiaDetails", mapResponse);
      commit("setIsLoadingBaiaList", false);
    } else {
      commit("setIsLoadingBaiaList", false);

      await dispatch("errorMessage", response.data.message);
    }
  },
  async updatePedidoBaia({ commit, dispatch }, request) {
    try {
      const response = await MonitoramentoPatioRepository.updatePedidoBaia(
        request
      );

      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        let successObj = {
          successStatus: true,
          successMessage: response.data.message,
        };
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        return response;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async monPatio({ commit, state }) {
    commit("setIsLoading", true);
    const urlParams = state.urlParams;
    let url = `/baia/patio/monitoramento/listar?page=${
      urlParams.page
    }&perPage=${50}`;
    if (urlParams.pedido) {
      url = url.concat(`&pedidoId=${urlParams.pedido}`);
    }
    if (urlParams.transportadora) {
      url = url.concat(`&transportadora=${urlParams.transportadora}`);
    }
    const response = await MonitoramentoPatioRepository.requestMonPatio(url);
    if (response.status >= 200 && response.status < 300) {
      commit("setListingMonPatio", response.data.data.list);
      commit("setIsLoading", false);
      return;
    } else {
      commit("setIsLoading", false);
      let errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro na listagem",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async patioCreation({ commit }, modelcreateMonPatio) {
    try {
      const response = await MonitoramentoPatioRepository.createMonPatio(
        modelcreateMonPatio
      );
      if (response.status >= 200 && response.status < 300) {
        let successObj = {
          successStatus: true,
          successMessage: "Baia Configurada com sucesso!",
        };
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async monPatioEdit({ commit, dispatch }, body) {
    try {
      const response = await MonitoramentoPatioRepository.monPatioEdit(body);
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        let successObj = {
          successStatus: true,
          successMessage: response.data.message,
        };
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },

  errorMessage({ commit, dispatch }, value) {
    ErrorNotifier.run(commit, dispatch, value);
    return;
  },

  checkToken({ dispatch }, value) {
    if (value.data.status === "O token é inválido.") {
      value.data.message = "O token é inválido.";
      value.status = "O token é inválido.";
      dispatch("errorMessage", value);
      return;
    }
    return;
  },
};
