export default {
  listingPedidos: [],
  cargaRequests: [],
  relatorioCarga: [],
  pedidosPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  isLoading: false,
  urlParams: {
    page: 1,
    dataInicio: "",
    dataFinal: "",
    coletaId: "",
    numeroPedido: "",
    pedidoWeb: "",
    transportadoraNome: "",
    tempoPatio: "",
  },
  haveFilters: false,
};
