import { HttpRestService } from "@/services/http/HttpRestService";

export default class OrderBlocking {
  static getOrders(urlParams) {
    return HttpRestService.get(`/bloqueio/listar`, urlParams);
  }

  static getBlockingReasons() {
    return HttpRestService.get(`/bloqueio/tipo-bloqueio/listar`);
  }

  static postUnblockingOrder(body) {
    return HttpRestService.post(`/bloqueio/pedido/desbloquear`, body);
  }

  static postBlockingOrder(body) {
    return HttpRestService.post(`/bloqueio/pedido/bloquear`, body);
  }

  static getBlockingDetails(body) {
    return HttpRestService.get(
      `/bloqueio/pedido/detalhes/${body.orderId}/${body.bloqueiodId}`
    );
  }
}
