import { PerfisRepository } from "../../../repositories/defaultUserRepositories/PerfisRepository";

export default {
  async perfis({ commit }) {
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro na listagem",
    };
    const response = await PerfisRepository.requestPerfis();
    if (response.status >= 200 && response.status < 300) {
      commit("setListingPerfis", response.data.data);
      commit("setFilteredPerfis", response.data.data);
      return;
    }
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },
  async whiteList({ commit }) {
    commit("setWhiteList", []);
    const response = await PerfisRepository.whiteList();
    if (response.status >= 200 && response.status < 300) {
      commit("setWhiteList", response.data.data);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro na aplicação",
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },
  async whiteListEdit({ commit }) {
    commit("setWhiteListEdit", []);
    const response = await PerfisRepository.whiteListEdit();
    if (response.status >= 200 && response.status < 300) {
      commit("setWhiteListEdit", response.data.data);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro na aplicação",
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async createPerfil({ commit }, modelCreatePerfil) {
    const response = await PerfisRepository.createPerfil(modelCreatePerfil);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Perfil criado com sucesso!",
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async updatePerfil({ commit }, modelUpdatePerfil) {
    const response = await PerfisRepository.updatePerfil(modelUpdatePerfil);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Perfil editado com sucesso!",
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
};
