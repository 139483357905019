export default {
  getListingDrivers(state) {
    return state.listingDrivers;
  },

  getDriversPagination(state) {
    return state.driversPagination;
  },
  getListingCompanys(state) {
    return state.listingCompanys;
  },

  getIsLoading(state) {
    return state.isLoading;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
};
