export default {
  getListingPedidos(state) {
    return state.listingPedidos;
  },
  getPedidosPagination(state) {
    return state.pedidosPagination;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getDataInicial(state) {
    return state.dataInicial;
  },
  getDataFinal(state) {
    return state.dataFinal;
  },
  getCargaRequests(state) {
    return state.cargaRequests;
  },
  getRelatorioCarga(state) {
    return state.relatorioCarga;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
  getHaveFilters(state) {
    return state.haveFilters;
  },
};
