export default {
  getPickups(state) {
    return state.pickups;
  },
  getIsLoadingPickups(state) {
    return state.isLoadingPickups;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
  getPagination(state) {
    return state.pagination;
  },
};
