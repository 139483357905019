var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"overflow-x":"hidden"},attrs:{"height":"100%"}},[_c('v-card',{staticClass:"pb-5"},[_c('v-card-title',{staticClass:"just"},[_c('v-row',[_c('v-col',{attrs:{"cols":"9","offset":"1"}},[_c('span',{staticClass:"text-h6 font-weight-bold navy--text"},[_vm._v("E-mails")])]),(!_vm.isLoadingModal)?_c('v-col',{attrs:{"cols":"1","align":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-truncate",class:hover ? 'base--text' : 'navy--text',staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"fab":"","small":"","elevation":"3","disabled":_vm.listEmail.length > 0 && _vm.listEmail.at(-1).email === '',"color":"primary"},on:{"click":function($event){return _vm.addFieldListEmail()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)})]}}],null,false,564693360)},[(_vm.listEmail.length)?_c('span',[_vm._v("Adicionar Campo")]):_c('span',[_vm._v("Adicionar E-mail")])])],1):_vm._e()],1)],1),(!_vm.isLoadingModal)?_c('div',[_c('v-card-text',[(_vm.listEmail.length)?_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},_vm._l((_vm.listEmail),function(email,i){return _c('v-row',{key:i,attrs:{"align":"start"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"9","offset":"1"}},[_c('v-text-field',{ref:"emailRules",refInFor:true,attrs:{"rules":_vm.emailRules,"outlined":"","dense":"","required":"","validate-on-blur":"","autofocus":!_vm.getListEmailTransportadora.length},model:{value:(email.email),callback:function ($$v) {_vm.$set(email, "email", $$v)},expression:"email.email"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-truncate",class:hover ? 'base--text' : 'navy--text',staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"fab":"","small":"","elevation":"3","color":"primary","variant":"outlined","disabled":_vm.listEmail.length === 1},on:{"click":function($event){return _vm.removeEmailField(email)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("Excluir Campo")])])],1)],1)}),1):_c('div',[_c('v-col',{staticClass:"pa-0"},[_c('div',{staticClass:"text-body-1 navy--text pa-0"},[_c('p',{staticClass:"ma-0"},[_vm._v("A transportadora não pode ficar sem e-mail.")]),_c('p',{staticClass:"ma-0"},[_vm._v("Cadastre um agora.")]),_c('div',[_c('v-icon',{attrs:{"size":"50px","color":"primary"},on:{"click":function($event){return _vm.addFieldListEmail()}}},[_vm._v("mdi-email-plus-outline")])],1)])])],1)],1)],1):_c('v-col',[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"54"}})],1)],1),(!_vm.listEmail.length == 0)?_c('v-row',{staticClass:"py-0 base",staticStyle:{"background-color":"","position":"sticky","bottom":"0"},attrs:{"color":"danger","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"navy--text text-truncate p-0 mb-0",staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"disabled":_vm.listEmail.length == 0 || _vm.compareListEmail,"loading":_vm.isLoadingBtn,"color":hover ? 'secondary' : 'primary',"block":"","rounded":""},on:{"click":function($event){return _vm.updateEmailList()}}},[_vm._v("Salvar")])]}}],null,false,3860599458)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }