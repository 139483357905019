import { UsersRepository } from "../../../repositories/defaultUserRepositories/UsersRepository";

export default {
  async users({ commit }, urlParams) {
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro na listagem",
    };
    let url = `/users?page=${urlParams.page}&perPage=${10}`;
    if (urlParams.search) {
      url = url.concat(`&userName=${urlParams.search}`);
    }
    const response = await UsersRepository.requestUsers(url);
    if (response.status >= 200 && response.status < 300) {
      commit("setListingUser", response.data.data.list);
      commit("setUsersPagination", response.data.data.pagination);
      return;
    }
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async createUser({ commit }, modelCreateUser) {
    const response = await UsersRepository.createUser(modelCreateUser);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
      return;
    }
  },

  async createTransportadora({ commit }, modelCreateTransportadora) {
    const response = await UsersRepository.createTransportadora(
      modelCreateTransportadora
    );
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
      return;
    }
  },

  async transportadoraEmails({ commit }, modelTransportadoraEmails) {
    const response = await UsersRepository.transportadoraEmails(
      modelTransportadoraEmails
    );
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("setTransportadoraEmails", response.data.data);
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
      return;
    }
  },

  async updateUser({ commit }, modelUpdateUser) {
    const response = await UsersRepository.updateUser(modelUpdateUser);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Usuário editado com sucesso!",
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: "Já existe um Usuário com este login",
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async enableUser({ commit }, userId) {
    const response = await UsersRepository.enableUser(userId);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async disableUser({ commit }, userId) {
    const response = await UsersRepository.disableUser(userId);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async listPerfis({ commit }) {
    const response = await UsersRepository.listPerfis();

    if (response.status >= 200 && response.status < 300) {
      commit("setListPerfis", response.data.data);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro na aplicação",
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
};
