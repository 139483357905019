export default {
  getListingConfPatio(state) {
    return state.listingConfPatio;
  },

  getConfPatioPagination(state) {
    return state.confPatioPagination;
  },

  getIsLoading(state) {
    return state.isLoading;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
};
