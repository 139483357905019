<template>
  <v-container>
    <transportadoras-list-header />
    <transportadoras-list />
  </v-container>
</template>

<script>
import TransportadorasList from "../../components/defaultUserComponents/transportadora/TransportadoraList.vue";
import TransportadorasListHeader from "../../components/defaultUserComponents/transportadora/TransportadorasListHeader.vue";
export default {
  name: "TransportadorasPage",
  components: { TransportadorasList, TransportadorasListHeader },
  data: () => ({}),
};
</script>

<style scoped></style>
