/**
 * @typedef {Object} ColetaCadastro
 * @property {String} dataInicio
 * @property {String} dataFinal
 * @property {Number} docaId
 * @property {Number[]} pedidos
 * @property {Number} tipoVeiculoId
 */

/**
 * @typedef {Object} RemessaCadastro
 * @property {String} data
 * @property {ColetaCadastro[]} coletas
 */

export default {
  getListaRemessas(state) {
    return state.listaRemessas;
  },
  getRemessasPagination(state) {
    return state.remessasPagination;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  /**
   * @returns {RemessaCadastro}
   */
  getRemessaCadastro(state) {
    return state.remessaCadastro;
  },
  getPedidosSelected(state) {
    return state.pedidosSelected;
  },
  getTransportadoraData(state) {
    return state.transportadoraData;
  },
  getDataRemessa(state) {
    return state.dataRemessa;
  },
  getDataColeta(state) {
    return state.dataColeta;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
};
