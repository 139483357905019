export default {
  setListingVehicles(state, response) {
    state.listingVehicles = response;
  },
  setFilteredVehicles(state, response) {
    state.filteredVehicles = response;
  },

  setVehiclesPagination(state, response) {
    state.vehiclesPagination.total = response.total;
    state.vehiclesPagination.perPage = response.perPage;
    state.vehiclesPagination.currentPage = response.currentPage;
    state.vehiclesPagination.lastPage = response.lastPage;
    state.vehiclesPagination.nextPage = response.nextPage;
  },

  setVehiclesType(state, response) {
    state.vehiclesType = response;
  },

  setTransportadoras(state, response) {
    state.transportadoras = response;
  },

  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setUrlParams(state, urlParams) {
    state.urlParams.page = urlParams.page;
    state.urlParams.search = urlParams.search;
    state.urlParams.transportadora = urlParams.transportadora;
    state.urlParams.transportadoraId = urlParams.transportadoraId;
  },
};
