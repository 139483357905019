export default {
  setListingPerfis(state, response) {
    state.listingPerfis = response;
  },
  setFilteredPerfis(state, response) {
    state.filteredPerfis = response;
  },
  setWhiteList(state, response) {
    state.whiteList = response;
  },
  setWhiteListEdit(state, response) {
    state.whiteListEdit = response;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
};
