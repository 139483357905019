export default {
  isLoading: false,
  listingPallets: [],
  palletsPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  urlParams: {
    page: 1,
    ativo: false,
    tipoInsumoId: null,
  },
};
