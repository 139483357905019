<template>
  <v-hover v-slot="{ hover }">
    <v-btn
      v-on="$listeners"
      v-bind="$attrs"
      :color="
        !color
          ? isTransportadora
            ? 'primaryTransportadora'
            : 'primary'
          : color
      "
      block
      rounded
      :class="
        isTransportadora
          ? hover
            ? 'primary--text'
            : 'base--text'
          : hover
          ? 'base--text'
          : 'navy--text'
      "
      class="font-weight-bold"
      style="text-transform: none"
    >
      <slot></slot>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: "DefaultButton",
  props: {
    isTransportadora: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
