var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('v-row',{staticClass:"justify-center align-center mt-3",attrs:{"no":"","gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3","lg":"3","xl":"3"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-text-field',{attrs:{"placeholder":"Transportadora","clearable":"","dense":"","solo":"","flat":""},model:{value:(_vm.urlParams.search),callback:function ($$v) {_vm.$set(_vm.urlParams, "search", $$v)},expression:"urlParams.search"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3","lg":"3","xl":"3"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-text-field',{attrs:{"placeholder":"Buscar Coletas","clearable":"","dense":"","solo":"","flat":""},model:{value:(_vm.urlParams.codigo),callback:function ($$v) {_vm.$set(_vm.urlParams, "codigo", $$v)},expression:"urlParams.codigo"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"4"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-2"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"dataExpeditionInicial",attrs:{"label":"Data Inicial","placeholder":"Inicial","readonly":"","clearable":"","dense":"","solo":"","flat":""},model:{value:(_vm.dataExpeditionInicial),callback:function ($$v) {_vm.dataExpeditionInicial=$$v},expression:"dataExpeditionInicial"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.dataExpeditionInicial),callback:function ($$v) {_vm.dataExpeditionInicial=$$v},expression:"dataExpeditionInicial"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"dataExpeditionFinal",attrs:{"label":"Data Final","placeholder":"Final","readonly":"","clearable":"","dense":"","solo":"","flat":""},model:{value:(_vm.dataExpeditionFinal),callback:function ($$v) {_vm.dataExpeditionFinal=$$v},expression:"dataExpeditionFinal"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.dataExpeditionFinal),callback:function ($$v) {_vm.dataExpeditionFinal=$$v},expression:"dataExpeditionFinal"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"2","lg":"2","xl":"2"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"top navy--text text-truncate",staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"rounded":"","block":"","loading":_vm.isLoadingBtn,"color":hover ? 'secondary' : 'primary'},on:{"click":_vm.doASearch}},[_vm._v("Filtrar")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }