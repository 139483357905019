<template>
  <v-card class="overflow-hidden">
    <div>
      <div class="justify-center mt-4">
        <span class="text-h6 font-weight-bold navy--text"
          >Detalhes do Protocolo - Coleta:
          <span class="secondary--text font-weight-bold">{{
            this.coletaId
          }}</span></span
        >
      </div>

      <div v-if="!isLoadingModal">
        <div v-if="getProtocolData.length">
          <v-card-text>
            <v-row class="list-header mx-auto">
              <v-col
                cols="2"
                align="start"
                class="justify-center border-right text-truncate"
              >
                <span> Data Inicio </span>
              </v-col>
              <v-col
                cols="3"
                align="start"
                class="justify-center text-truncate border-right"
              >
                <span>Motorista</span>
              </v-col>
              <v-col
                cols="2"
                align="start"
                class="justify-center text-truncate border-right"
              >
                <span>Veículo</span>
              </v-col>
              <v-col
                cols="2"
                align="start"
                class="justify-center text-truncate border-right"
              >
                <span>Placa</span>
              </v-col>
              <v-col
                cols="3"
                align="start"
                class="justify-center text-truncate"
              >
                <span>transportadora</span>
              </v-col>
            </v-row>
            <v-row
              class="list-card base_dark text--text mx-auto my-auto mt-2"
              v-for="(protocolo, i) in getProtocolData"
              :key="i"
            >
              <v-col cols="2" align="start">
                <div
                  class="text-truncate"
                  :title="protocolo.dataIniciarEmbarque"
                >
                  {{ protocolo.dataIniciarEmbarque | formatDate }} -
                  {{ protocolo.dataIniciarEmbarque | formatHour }}
                </div>
              </v-col>
              <v-col cols="3" align="start">
                <div
                  class="text-truncate secondary--text font-weight-bold"
                  :title="protocolo.motoristaNome"
                >
                  <span> {{ protocolo.motoristaNome }}</span>
                </div>
              </v-col>
              <v-col cols="2" align="start">
                <div class="text-truncate" :title="protocolo.tipoVeiculo">
                  {{ protocolo.tipoVeiculo }}
                </div>
              </v-col>
              <v-col cols="2" align="start">
                <div class="text-truncate" :title="protocolo.placaVeiculo">
                  {{ protocolo.placaVeiculo }}
                </div>
              </v-col>
              <v-col cols="3" align="start">
                <div class="text-truncate" :title="protocolo.transportadora">
                  {{ protocolo.transportadora }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
              <v-col cols="3" align="center">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    block
                    rounded
                    large
                    color="primary"
                    class="text-truncate"
                    @click="closeModel"
                    :class="hover ? 'base--text' : 'navy--text'"
                    style="text-transform: none !important; font-weight: bolder"
                    >Sair</v-btn
                  >
                </v-hover>
              </v-col>
            </v-row>
          </v-card-actions>
        </div>
        <div v-else>
          <v-row class="py-6" justify="center">
            <v-col cols="6" align="center">
              <span>Não foi possível carregar os detalhes do protocolo.</span>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-col v-else>
        <v-progress-circular
          color="primary"
          indeterminate
          size="54"
        ></v-progress-circular>
      </v-col>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CollectionDetails",
  data: () => ({
    isLoadingModal: false,
    loadingButton: false,
  }),
  props: {
    coletaId: { required: true, Number },
  },
  mounted() {
    this.startDetails();
  },
  computed: {
    ...mapGetters("expedition", ["getProtocolData"]),
  },
  methods: {
    ...mapActions("expedition", ["protocolData"]),

    async startDetails() {
      this.isLoadingModal = true;
      await this.protocolData(this.coletaId);
      this.isLoadingModal = false;
    },
    closeModel() {
      this.loadingButton = !false;
      this.$emit("closeModalProtocol");
      this.loadingButton = false;
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  },
};
</script>
<style scoped>
.h {
  font-weight: bolder;
}
.list-header {
  background-color: #0f2733;
  color: white;
  font-weight: bolder;
  min-height: 30px;
}
.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
.list-card {
  color: black;
  font-weight: normal;
}

.list-card:hover {
  background-color: #ffdfcc;
  color: black;
}
</style>
