/**
 * @typedef {Object} ColetaCadastro
 * @property {String} dataInicio
 * @property {String} dataFinal
 * @property {Number} docaId
 * @property {Number[]} pedidos
 * @property {Number} tipoVeiculoId
 */

/**
 * @typedef {Object} RemessaCadastro
 * @property {String} data
 * @property {ColetaCadastro[]} coletas
 */

/**
 * @typedef {Object} Doca
 * @property {Number} docaId
 * @property {Number} codigo
 * @property {String} cor
 */

/**
 * @typedef {Object} TipoVeiculo
 * @property {Number} tipoVeiculoId
 * @property {String} descricao
 */

/**
 * @typedef {Object} Pedido
 * @property {Number} pedidoId
 * @property {Number} numeroPedido
 * @property {Number} area
 * @property {Number} quantidadeCaminhoesAlocados
 * @property {Number} quantidadeVolumes
 * @property {Number} quantidadePaineis
 */

/**
 * @typedef {Object} RemessaDadosParaCadastro
 * @property {Doca[]} docas
 * @property {TipoVeiculo[]} tiposVeiculo
 * @property {Pedido[]} pedidos
 */

/**
 * @typedef {String} tipoColeta
 */

export default {
  listaRemessas: [],
  /**
   * @type {RemessaCadastro}
   */
  remessaCadastro: {},
  /**
   * @type {RemessaDadosParaCadastro}
   */
  remessaDadosParaCadastro: {},
  remessasPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  pedidosSelected: [],
  transportadoraData: {},
  dataRemessa: "",
  dataColeta: "",
  isLoading: false,
  coletaByRemessa: [],
  detalhesDivergencia: {},
  detalhesDivergenciaHorario: {},
  tipoColeta: "",
  urlParams: {
    page: 1,
    search: "",
    selecaoStatus: "",
    selecaoDate: "",
    transportadoraId: "",
    selecaoTipo: 0,
  },
  transportadorasFilhas: [],
  pedidosTransportadoraFilha: [],
  transportadoraFilhaId: 0,
};
