import { HttpRestService } from "@/services/http/HttpRestService";

export class DashboardRepository {
  static getDispatchMonitoring() {
    return HttpRestService.get(`/coleta/acompanhamento/expedicao`);
  }

  static getDocks() {
    return HttpRestService.get(`/coleta/acompanhamento/docas`);
  }

  static getDailyOrders() {
    return HttpRestService.get(`/pedido/acompanhamento/sou-out`);
  }

  static requestIndividualPerformance() {
    return HttpRestService.get("/coleta/conferente/acompanhamento");
  }

  static getDuoPickups() {
    return HttpRestService.get(`/coleta/empilhador/acompanhamento`);
  }

  static requestOrdersPerShippingCompany() {
    return HttpRestService.get("/dashboard/pedidos/embarcados/transportadoras");
  }

  static requestStatusOfTrucksOnArrival() {
    return HttpRestService.get("/dashboard/entrada/status");
  }

  static getCarrierOrders() {
    return HttpRestService.get(`/transportadora/acompanhamento`);
  }
  static shippingByCarrier() {
    return HttpRestService.get(
      "/dashboard/pedidos/despachados/transportadoras"
    );
  }
  static lateCollections() {
    return HttpRestService.get("/dashboard/coletas/atrasadas");
  }
}
