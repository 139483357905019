<template>
  <div>
    <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <v-row class="list-header mx-auto my-auto mt-3" align="center">
        <v-col
          cols="12"
          sm="2"
          md="2"
          lg="2"
          xl="2"
          align="center"
          class="justify-center border-right"
        >
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            Data
          </span>
        </v-col>
        <v-col
          cols="12"
          sm="3"
          md="3"
          lg="3"
          xl="3"
          align="center"
          class="justify-center border-right"
        >
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            Transportadora
          </span>
        </v-col>
        <v-col
          cols="3"
          sm="2"
          md="2"
          lg="2"
          xl="2"
          align="center"
          class="justify-center border-right"
        >
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            coleta
          </span>
        </v-col>
        <v-col
          cols="3"
          sm="1"
          md="1"
          lg="1"
          xl="1"
          align="center"
          class="justify-center border-right"
        >
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            Doca
          </span>
        </v-col>
        <v-col
          cols="3"
          sm="2"
          md="2"
          lg="2"
          xl="2"
          align="center"
          class="justify-center border-right"
        >
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            Baia Expedição
          </span>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="
        !getIsLoading ||
        (Array.isArray(getListingExpedition) && getListingExpedition.length > 0)
      "
    >
      <div v-for="(expedition, index) in getListingExpedition" :key="index">
        <v-hover v-slot="{ hover }">
          <v-row
            align-md="center"
            :class="hover ? 'hoverGlobal' : 'base_dark'"
            class="text--text mx-auto my-auto mt-2 justify-center"
          >
            <v-col cols="6" sm="2" md="2" lg="2" xl="2" align="center">
              <span class="d-md-none d-lg-none d-xl-none font-weight-bold">
                Data
              </span>
              <div class="text-truncate" :title="expedition.dataInicioEmbarque">
                <p class="my-0 mb-n3">
                  {{ expedition.dataInicioEmbarque | formatHour }} -
                  {{ expedition.dataFimEmbarque | formatHour }}
                </p>
                <span class="text-overline font-weight-light text--text">{{
                  expedition.dataInicioEmbarque | formatDate
                }}</span>
              </div>
            </v-col>
            <v-col cols="6" sm="3" md="3" lg="3" xl="3" align="center">
              <span class="d-md-none d-lg-none d-xl-none font-weight-bold">
                Transportadora
              </span>
              <div class="text-break" :title="expedition.transportadoraNome">
                {{ expedition.nomeFantasia }}
              </div>
            </v-col>
            <v-col cols="3" sm="2" md="2" lg="2" xl="2" align="center">
              <span class="d-md-none d-lg-none d-xl-none font-weight-bold">
                Coleta
              </span>
              <div class="text-break" :title="expedition.codigoColeta">
                {{ expedition.coletaCodigo }}
              </div>
            </v-col>
            <v-col cols="3" sm="1" md="1" lg="1" xl="1" align="center">
              <span class="d-md-none d-lg-none d-xl-none font-weight-bold">
                Doca
              </span>
              <div
                class="text-wrap font-weight-bold"
                :title="'Doca ' + expedition.codigoDoca"
              >
                <v-icon
                  size="30"
                  :color="
                    expedition.codigoDoca === 2
                      ? 'yellow darken-2'
                      : expedition.codigoDoca === 5
                      ? 'black'
                      : expedition.corDoca
                  "
                  >{{ `mdi-numeric-${expedition.codigoDoca}-circle-outline` }}
                </v-icon>
              </div>
            </v-col>
            <v-col cols="3" sm="2" md="2" lg="2" xl="2" align="center">
              <span class="d-md-none d-lg-none d-xl-none font-weight-bold">
                Baia Expedição
              </span>
              <div
                class="text-break"
                v-if="expedition.baias && expedition.baias.length > 0"
              >
                <div v-for="(baia, index) in expedition.baias" :key="index">
                  {{ formatBaiaDescription(baia.descricao) }}
                </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="2"
              lg="2"
              xl="2"
              align="center"
              class="pl-0"
            >
              <v-hover v-slot="{ hover }">
                <v-btn
                  block
                  rounded
                  color="primary"
                  class="text-truncate px-2"
                  :class="hover ? 'base--text' : 'navy--text'"
                  style="text-transform: none !important; font-weight: bolder"
                  :disabled="
                    expedition.baias.length == 0 ||
                    expedition.conferenciaBaia == true
                  "
                  @click="openCollectionConference(expedition)"
                  ><span v-if="expedition.conferenciaBaia == false"
                    >Conferir Coleta</span
                  >
                  <span v-if="expedition.conferenciaBaia == true"
                    >Coleta Conferida</span
                  >
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
        </v-hover>
      </div>

      <v-dialog
        v-model="collectionConference"
        max-width="720"
        content-class="hide-overflow-x"
      >
        <collection-conference
          :expedition="expedition"
          :key="reRender"
          @closeCollectionConference="closeCollectionConference"
        />
      </v-dialog>

      <v-row class="justify-center mb-3 mt-3">
        <v-pagination
          v-model="getExpeditionPagination.currentPage"
          :length="getExpeditionPagination.lastPage"
          @input="proxPage(getExpeditionPagination.currentPage)"
          :total-visible="7"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        >
        </v-pagination>
      </v-row>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-col>
  </div>
</template>

<script>
import CollectionConference from "./ModalCollectionConference.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    CollectionConference,
  },
  data: () => ({
    active: false,
    todayDate: "",
    reRender: 0,
    collectionConference: false,
    urlParams: {
      page: 1,
      initialDate: "",
      finalDate: "",
    },
    permissionList: ["admin", "aux-de-logistica", "supervisor"],
  }),
  mounted() {
    this.permissionsUser();
    this.currentData();
  },
  computed: {
    ...mapGetters("expedition", [
      "getListingExpedition",
      "getExpeditionPagination",
      "getIsLoading",
      "getUrlParams",
      "getCollectRequests",
    ]),
    ...mapGetters("login", ["getUserPermission", "getSlug"]),
  },
  methods: {
    ...mapActions("expedition", ["expedition", "setIsLoading", "setUrlParams"]),
    isSpeedDialActive(ref) {
      return this.$refs?.[ref]?.[0]?.isActive;
    },
    async proxPage(page) {
      this.setIsLoading(true);
      let tempUrl = this.getUrlParams;
      this.urlParams = tempUrl;
      this.urlParams.page = page;
      await this.setUrlParams(this.urlParams);
      await this.expedition();
      this.setIsLoading(false);
    },
    async listExpedition() {
      this.setIsLoading(true);
      const urlParams = {
        page: 1,
        initialDate: this.urlParams.initialDate,
        finalDate: this.urlParams.finalDate,
      };
      await this.setUrlParams(urlParams);
      await this.expedition();
      this.setIsLoading(false);
    },

    currentData() {
      const dataAtual = new Date();
      const ano = dataAtual.getFullYear();
      const mes = (dataAtual.getMonth() + 1).toString().padStart(2, "0");
      const dia = dataAtual.getDate().toString().padStart(2, "0");
      this.todayDate = `${ano}-${mes}-${dia}`;
      this.urlParams.initialDate = this.todayDate;
      this.urlParams.finalDate = this.todayDate;
      this.listExpedition();
      return;
    },

    openCollectionConference(expedition) {
      this.reRender += 1;
      this.expedition = expedition;
      this.collectionConference = true;
    },

    closeCollectionConference() {
      this.collectionConference = false;
    },

    permissionsUser() {
      if (this.permissionList.includes(this.getSlug)) {
        this.active = true;
      } else {
        this.active = false;
      }
    },

    formatBaiaDescription(description) {
      return description.replace(" - Expedicao", "");
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  },
};
</script>

<style scoped>
.list-header {
  background-color: #0f2733;
  color: white;
  font-weight: bolder;
  min-height: 30px;
}
.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
.col-1 {
  width: 10% !important;
}
::v-deep .hide-overflow-x {
  overflow-x: hidden;
}
</style>
