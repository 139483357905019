<template>
  <div>
    <recebimento-coleta-cabecalho-superior
      @atualizar-lista="$emit('atualizar-lista')"
      @coletar-assinatura="$emit('coletar-assinatura')"
      @finalizar-coleta="$emit('finalizar-coleta')"
      @checar-volume-com-erro="$emit('checar-volume-com-erro')"
      :coleta="getColetaDetalhes"
      :coletaId="getColetaDetalhes.coletaId"
    />
    <!-- <v-container v-if="!socketIsConnected" v-intersect="onIntersecting">
      <v-row
        class="base_dark text--text box-element justify-center align-center mb-2 rounded-lg elevation-1"
        rounded
      >
        <v-col class="align-self-center ma-5" cols="4">
          <span>Não foi possível conectar com o WebSocket.</span>
        </v-col>
      </v-row>
    </v-container>
    <embarque-cooktop-cabecalho-pedidos
      @getFilter="getFilter"
      :pedidos="getColetaDetalhes.pedidos"
      :volumesRef="volumesRef"
      @emitRef="emitRef"
    /> -->
    <!-- <div>{{socketIsConnected ? 'CONECTADO' : 'DESCONECTADO'}}</div> -->
  </div>
</template>

<script>
import RecebimentoColetaCabecalhoSuperior from "./RecebimentoColetaCabecalhoSuperior.vue";
import { mapGetters, mapActions } from "vuex";
import { coletasSocketService } from "@/services/socket/coletasSocketService";

export default {
  name: "RecebimentoColetaCabecalho",
  components: {
    RecebimentoColetaCabecalhoSuperior,
  },
  props: {
    volumesRef: {},
  },
  computed: {
    ...mapGetters("expedition", ["getColetaDetalhes"]),
    socketIsConnected() {
      return coletasSocketService.isConnected();
    },
  },
  methods: {
    getFilter({ filter, ref }) {
      this.$emit("getFilter", { filter, ref });
    },
    onIntersecting(entries) {
      this.$emit("onIntersecting", entries[0].isIntersecting);
    },
    emitRef(ref) {
      this.$emit("emitRef", ref);
    },
  },
};
</script>

<style scoped></style>
