import { ConfirmationModais } from "../../../repositories/transportadoraUserRepositories/ConfirmationModais";

export default {
  async confirmRemessa({ commit }, remessaId, coletas) {
    const response = await ConfirmationModais.confirmRemessa(
      remessaId,
      coletas
    );
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Remessa confirmada com sucesso.",
      };

      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro ao confirma a remessa.",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
      return response;
    }
  },

  async confirmationOfDivergence({ commit }, remessaId, body) {
    const response = await ConfirmationModais.confirmationOfDivergence(
      remessaId,
      body
    );
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Divergência confirmada com sucesso.",
      };

      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro ao confirmar a divergência.",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async listRemessa({ commit }, remessa) {
    const response = await ConfirmationModais.listRemessa(remessa);
    if (response.status >= 200 && response.status < 300) {
      commit("setListRemessa", response.data.data);
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    }

    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async listDrivsAndVeiches({ commit }, transportadoraId) {
    const response = await ConfirmationModais.listDrivsAndVeiches(
      transportadoraId
    );
    if (response.status >= 200 && response.status < 300) {
      commit("setListDrivers", response.data.data.motorista);
      commit("setListVehicles", response.data.data.placa);
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async confirmationOfDivergenceDataHora({ commit }, body) {
    const response = await ConfirmationModais.confirmationOfDivergenceDataHora(
      body
    );
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Divergência confirmada com sucesso.",
      };

      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro ao confirmar a divergência.",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
      return response;
    }
  },
};
