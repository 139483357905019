<template >
  <v-container>
    <coleta-edit-list-header />
    <coleta-edit-list />
  </v-container>
</template>
<script>
import ColetaEditList from "../../components/defaultUserComponents/coleta/ColetaList.vue";
import ColetaEditListHeader from "../../components/defaultUserComponents/coleta/ColetaListHeader.vue";
export default {
  components: { ColetaEditListHeader, ColetaEditList },
  name: "RemessaEditar",
};
</script>
<style >
</style>