export default {
  setListingMonPedidos(state, response) {
    state.listingMonPedidos = response;
  },
  setMonPedidosPagination(state, response) {
    state.monPedidosPagination = {
      currentPage: response.currentPage,
      lastPage: response.lastPage,
      perPage: response.perPage,
      total: response.total,
    };
  },
  setIsLoading(state, response) {
    state.isLoading = response;
  },
  setHaveFilters(state, response) {
    state.haveFilters = response;
  },
  setUrlParams(state, response) {
    state.urlParams = response;
  },
  setPedidosReenvio(state, response) {
    state.pedidosReenvio = response;
  },
  setReenvioDialog(state, response) {
    state.reenvioDialog = response;
  },
};
