<template>
  <v-card class="overflow-y-auto">
    <v-card-title class="justify-center">
      <span class="text-h6 font-weight-bold navy--text"
        >Ediçao de Coleta - {{ transportadoraNome }}
      </span>
      <span class="text-h6 font-weight-bold navy--text"> </span>
    </v-card-title>
    <div v-if="!isLoading">
      <v-card-text>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-row justify="space-around">
              <v-col cols="5" align="start">
                <span class="text-body-1 font-weight-bold navy--text mx-1"
                  >Hora Inicial:</span
                >
                <span>{{
                  getDataEditColeta.dataInicioEmbarque | formatHour
                }}</span>
              </v-col>
              <v-col cols="5" align="start">
                <span class="text-body-1 font-weight-bold navy--text mx-1"
                  >Hora Final:</span
                >
                <span>{{
                  getDataEditColeta.dataFimEmbarque | formatHour
                }}</span>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col cols="5" align="start">
                <span class="text-body-1 font-weight-bold navy--text mx-1"
                  >Data da Coleta:</span
                >
                <span>{{
                  getDataEditColeta.dataInicioEmbarque | formatDate
                }}</span>
              </v-col>

              <v-col cols="5" align="start">
                <span
                  class="font-weight-bold"
                  style="width: 100%"
                  :color="getDataEditColeta.docaCor"
                >
                  <span class="text-body-1 font-weight-bold navy--text mx-1"
                    >Doca:</span
                  >
                  <v-icon class="mx-1" :color="getDataEditColeta.docaCor"
                    >mdi-circle</v-icon
                  >
                  Doca - {{ getDataEditColeta.docaCodigo }}
                </span>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col cols="5">
                <v-autocomplete
                  ref="veiculoSelected"
                  :error-messages="errorMessages"
                  v-model="vehiclesModel"
                  :items="filteredVehicles"
                  :rules="rules.vehiclesRules"
                  item-text="placa"
                  item-value="id"
                  label="Placa"
                  outlined
                  dense
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="5">
                <v-autocomplete
                  ref="driverSelected"
                  :error-messages="errorMessages"
                  v-model="driversModel"
                  :items="getListDrivers"
                  :rules="rules.driversRules"
                  item-text="nome_completo"
                  item-value="id"
                  label="Motorista"
                  outlined
                  dense
                  required
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="6">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    @click="editColeta"
                    :loading="editButtonLoading"
                    :class="hover ? 'base--text' : 'base--text'"
                    :disabled="disabledField"
                    block
                    rounded
                    class="primaryTransportadora text-truncate"
                    style="text-transform: none !important; font-weight: bolder"
                    >Editar Coleta</v-btn
                  >
                </v-hover>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="54"
      ></v-progress-circular>
    </v-col>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DialogUpdateColeta",
  data: () => ({
    search: "",
    dataDaColeta: "",
    transportadoraNome: "",
    formHasErrors: false,
    errorMessages: "",
    vehiclesModel: null,
    driversModel: null,
    //Loading
    editButtonLoading: false,
    isLoading: false,
    //BodyStart
    coleta: {},
    pedidos: [],
    //Rules
    rules: {
      vehiclesRules: [(v) => !!v || "O campo Veículo é obrigatório"],
      driversRules: [(v) => !!v || "O campo Motorista é obrigatório"],
    },
  }),
  props: {
    coletaObj: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.getDataForModal();
  },
  computed: {
    ...mapGetters({
      getDataEditColeta: "coleta/getDataParaEditColeta",
      getTransportadoraUuid: "login/getTransportadoraUuid",
      getVehiclesType: "vehicles/getVehiclesType",
    }),
    ...mapGetters("coletaTransportadora", [
      "getListDrivers",
      "getListVehicles",
      "getListVehiclesFilter",
    ]),

    form() {
      return {
        veiculoSelected: this.vehiclesModel,
        motoristaSelected: this.driversModel,
      };
    },

    disabledField() {
      if (this.driversModel === null || this.vehicleModel === null) {
        return true;
      } else {
        return false;
      }
    },
    filteredVehicles() {
      if (
        ~~this.coletaObj.tipoVeiculoId === this.getDataEditColeta.tipoVeiculoId
      ) {
        return this.getListVehiclesFilter(~~this.coletaObj.tipoVeiculoId);
      } else {
        return this.getListVehiclesFilter(this.getDataEditColeta.tipoVeiculoId);
      }
    },
  },

  methods: {
    ...mapActions({
      actionListOfDrivers: "coletaTransportadora/listDriversByTransportadora",
      dataEditColeta: "coleta/dataParaEditarColeta",
      zeragemDataCadastro: "coleta/setDataColetaParaCadastro",
      postEditarColeta: "coleta/editarColeta",
      updateVehicleByTransportadora:
        "coletaTransportadora/updateVehicleByTransportadora",
      updateDriverByTransportadora:
        "coletaTransportadora/updateDriverByTransportadora",
      gettingVehicleType: "vehicles/listVehiclesType",
    }),

    ...mapActions("coletaTransportadora", ["listDriversAndVehicles"]),

    async getDataForModal() {
      this.isLoading = true;
      await this.dataEditColeta(this.$attrs.coletaId);
      await this.listDriversAndVehicles(this.getTransportadoraUuid);
      await this.gettingVehicleType();
      this.isLoading = false;
    },

    calculateAreaPedidos(pedido) {
      if (pedido) this.areaPedidosSelected = 0;
      for (var p = 0; p < pedido.length; p++) {
        this.areaPedidosSelected += pedido[p].area; //TODO: alterar para area
      }
    },

    async editColeta() {
      this.editButtonLoading = true;
      this.formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) {
          this.formHasErrors = true;
          this.editButtonLoading = false;
        }
        this.$refs.form.validate(true);
      });
      if (!this.formHasErrors) {
        const modelUpdateVehicleByTransportadora = {
          coletaId: this.$attrs.coletaId,
          veiculoId: this.vehiclesModel,
        };
        const modelUpdateDriverByTransportadora = {
          coletaId: this.$attrs.coletaId,
          motoristaId: this.driversModel,
        };
        await this.updateVehicleByTransportadora(
          modelUpdateVehicleByTransportadora
        );
        await this.updateDriverByTransportadora(
          modelUpdateDriverByTransportadora
        );
        this.editButtonLoading = false;
        this.$emit("closeUpdateColetaDialog");
        this.$emit("coleta");
      }
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  },
  watch: {
    getDataEditColeta: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.vehiclesModel = newValue.veiculoId;
        this.driversModel = newValue.motoristaId;
      },
    },
    name() {
      this.errorMessages = "";
    },
  },
};
</script>
<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
.h {
  font-weight: bolder;
}
::v-deep
  .theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  color: white;
}
</style>
