export default {
  getOrders(state) {
    return state.orders;
  },
  getIsLoadingOrders(state) {
    return state.isLoadingOrders;
  },
  getPagination(state) {
    return state.pagination;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
  getIsBlockingDetailsModalOpen(state) {
    return state.isBlockingDetailsModalOpen;
  },
  getIsBlockingModalOpen(state) {
    return state.isBlockingModalOpen;
  },
  getBlockingReasons(state) {
    return state.blockingReasons;
  },
  getBlockingDetails(state) {
    return state.blockingDetails;
  },
  getIsLoadingDetails(state) {
    return state.isLoadingDetails;
  },
};
