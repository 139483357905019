export default {
  getListRemessa(state) {
    return state.listRemessa;
  },
  getListDrivers(state) {
    return state.listDrivers;
  },
  getListVehicles(state) {
    return state.listVehicles;
  },
  getListVehiclesFilter: (state) => (id) => {
    let resp = state.listVehicles.map(function(placa){
     if(placa.tipo_veiculo_id === id){
       return placa
       } 
     }
   )
   return resp
 },
};
