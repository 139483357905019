import { HttpRestService } from "@/services/http/HttpRestService";

export class ConfirmationModais {
  static confirmRemessa(body) {
    return HttpRestService.post(`/remessa/confirmar`, body);
  }
  static confirmationOfDivergence(body) {
    return HttpRestService.post(`/remessa/divergencia`, body);
  }
  static listRemessa(remessa) {
    return HttpRestService.get(`/remessa/${remessa}/detalhes`);
  }
  static listDrivsAndVeiches(transportadoraId) {
    return HttpRestService.get(
      `/transportadora/veiculo/motorista/${transportadoraId}`
    );
  }
  static confirmationOfDivergenceDataHora(body) {
    return HttpRestService.post(`/remessa/divergencia/horario`, body);
  }
}
