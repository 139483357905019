<template>
  <v-dialog v-if="enabledSync" v-model="enabledSync" max-width="330">
    <v-card>
      <v-card-title class="justify-center">
        <h3 class="mb-6 navy--text">Atenção</h3>
      </v-card-title>
      <v-card-text class="text-h6">
        Deseja confirmar a finalização da coleta de assinatura?
      </v-card-text>

      <v-card-actions class="justify-center">
        <div class="my-4">
          <v-btn color="red darken-1" text large @click="$emit('close')">
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            large
            @click="$emit('confirma-assinatura')"
            :disabled="!signaturePad._data.length"
          >
            Confirmar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EmbarqueColetaDialogConfirmaAssinatura",
  data: () => ({
    loading: false,
  }),
  props: {
    enabled: { type: Boolean, required: true },
    signaturePad: { type: Object, required: true },
  },
  watch: {
    enabledSync(enabledSync) {
      if (enabledSync) {
        this.loadVolumesDetalhes();
      }
    },
  },
  computed: {
    enabledSync: {
      get() {
        return this.enabled;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>
<style lang="scss"></style>
