export default {
  setPickups(state, payload) {
    state.pickups = payload;
  },

  setIsLoadingPickups(state, payload) {
    state.isLoadingPickups = payload;
  },

  setUrlParams(state, payload) {
    state.urlParams = payload;
  },

  setPagination(state, payload) {
    state.pagination = payload;
  },

  setIsLoadingBiparVolume(state, value) {
    state.isLoadingBiparVolume = value;
  },
};
