import { HttpRestService } from "@/services/http/HttpRestService";

export class PalletsRepository {
  static findPellets(url) {
    return HttpRestService.get(url);
  }
  static createPallet(payload) {
    return HttpRestService.post("/pallet/alterar/configuracao", payload);
  }
  static editPallet(payload) {
    return HttpRestService.post("/pallet/alterar/configuracao", payload);
  }
  static getEditPalletDetails(payload) {
    return HttpRestService.get(
      `/pallet/v2/listar/configuracao/${payload.palletId}`
    );
  }
}
