<template>
  <v-card class="my-3 base_dark text--text" ref="pedidos">
    <v-fab-transition>
      <v-hover v-slot="{ hover }">
        <v-btn
          absolute
          top
          right
          fab
          small
          @click="showPedidosRelacao()"
          elevation="2"
          color="btnCorColeta"
          class="text-truncate"
          :class="hover ? 'base--text' : 'navy--text'"
          style="text-transform: none !important; font-weight: bolder"
        >
          <v-icon color="text" v-if="!fab">mdi-plus</v-icon>
          <v-icon color="text" v-else>mdi-close</v-icon>
        </v-btn>
      </v-hover>
    </v-fab-transition>
    <div>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" align="center" justify="center">
            <div
              @click="
                [
                  emitFilter({
                    filter: { type: selected, number: '' },
                  }),
                  (input = ''),
                ]
              "
            >
              <v-btn-toggle
                color="primary"
                mandatory
                rounded
                v-model="selected"
              >
                <v-btn value="pedidos"
                  >Pedidos ({{ pedidosRelacao.length }})</v-btn
                >
                <v-btn
                  v-if="
                    cargasRelacao.some(
                      (volume) => volume.tipoPedido !== 'lote-fixador'
                    )
                  "
                  value="cargas"
                  >Cargas ({{ quantidadeCargas }})</v-btn
                >
                <v-btn
                  v-if="
                    cargasRelacao.some(
                      (volume) => volume.tipoPedido === 'lote-fixador'
                    )
                  "
                  value="lotes"
                  >Lotes ({{ quantidadeLotes }})</v-btn
                >
              </v-btn-toggle>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="fab">
          <v-col cols="6" md="4">
            <v-text-field
              :placeholder="`Pesquisar ${selected.slice(0, -1)}`"
              v-model="input"
              clearable
              dense
              solo
              flat
              :rules="[rules.required]"
              prepend-inner-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
        <v-row v-if="fab" justify="center">
          <v-col cols="12" align="center">
            <v-select
              hide-details
              dense
              filled
              rounded
              v-model="select"
              :items="items"
              item-text="status"
              item-value="status"
              return-object
              disable-lookup
              style="width: 280px"
              @input="
                emitFilter({
                  filter: { type: selected, number: '' },
                })
              "
            >
              <template v-slot:selection="{ item }">
                <span>{{ item.status }}</span>
                <v-icon
                  v-for="(color, index) in item.colors"
                  :key="index"
                  :color="color"
                  >mdi-circle-medium</v-icon
                >
              </template>
              <template v-slot:item="{ item }">
                <span>{{ item.status }}</span>
                <v-icon
                  v-for="(color, index) in item.colors"
                  :key="index"
                  :color="color"
                  >mdi-circle-medium</v-icon
                >
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row class="justify-center pb-5" v-if="fab" style="user-select: none">
          <v-col
            @click="
              emitFilter({
                filter: { type: selected, number: pedido.numeroPedido },
                ref: $refs.pedidos,
              })
            "
            v-for="(pedido, index) in filteredPedidos"
            v-show="
              selected === 'pedidos' &&
              checkStatus(pedido.relacaoVolumes, select.status)
            "
            :key="'p' + index"
            class="rounded text-break ma-1 px-0"
            cols="4"
            sm="3"
            md="3"
            lg="2"
            xl="2"
            :class="pedido.color"
            :style="{
              color: pedido.color === 'yellow darken-2' ? 'black' : 'white',
              cursor: 'pointer',
            }"
            v-ripple
          >
            <span class="d-block">{{ pedido.numeroPedido }}:</span>
            <span>{{ pedido.relacaoVolumes }}</span>
          </v-col>
          <v-col
            @click="
              emitFilter({
                filter: { type: selected, number: carga.cargaId },
                ref: $refs.pedidos,
              })
            "
            v-for="(carga, index) in filteredCargas.filter(
              (volume) => volume.tipoPedido !== 'lote-fixador'
            )"
            v-show="
              selected === 'cargas' &&
              checkStatus(carga.relacaoVolumes, select.status)
            "
            :key="'c' + index"
            class="rounded text-break ma-1"
            :class="carga.color"
            cols="4"
            sm="3"
            md="3"
            lg="2"
            xl="2"
            :style="{
              color: carga.color === 'yellow darken-2' ? 'black' : 'white',
              cursor: 'pointer',
            }"
            v-ripple
          >
            <span class="d-block">{{ carga.cargaId }}:</span>
            <span>{{ carga.relacaoVolumes }}</span>
          </v-col>
          <v-col
            @click="
              emitFilter({
                filter: { type: selected, number: lote.cargaId },
                ref: $refs.pedidos,
              })
            "
            v-for="(lote, index) in filteredCargas.filter(
              (volume) => volume.tipoPedido === 'lote-fixador'
            )"
            v-show="
              selected === 'lotes' &&
              checkStatus(lote.relacaoVolumes, select.status)
            "
            :key="'l' + index"
            class="rounded text-break ma-1"
            cols="4"
            sm="3"
            md="3"
            lg="2"
            xl="2"
            :class="lote.color"
            :style="{
              color: lote.color === 'yellow darken-2' ? 'black' : 'white',
              cursor: 'pointer',
            }"
            v-ripple
          >
            <span class="d-block">{{ lote.cargaId }}:</span>
            <span>{{ lote.relacaoVolumes }}</span>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EmbarqueColetaCabecalhoPedidos",
  data() {
    return {
      selected: "pedidos",
      select: { status: "Mostrar todos" },
      items: [
        {
          status: "Mostrar todos",
          colors: ["red darken-1", "yellow darken-2", "green"],
        },
        { status: "Mostrar não embarcados", colors: ["red darken-1"] },
        { status: "Mostrar em embarque", colors: ["yellow darken-2"] },
        { status: "Mostrar embarcados", colors: ["green"] },
      ],
      pedidosEmbarcados: 0,
      pedidosTotal: 0,
      fab: true,
      input: "",
      rules: {
        required: () => {
          if (
            this.selected === "pedidos" &&
            this.filteredPedidos.length === 0
          ) {
            return "Nenhum resultado encontrado";
          } else if (
            this.selected === "cargas" &&
            this.filteredCargas.filter(
              (volume) => volume.tipoPedido !== "lote-fixador"
            ).length === 0
          ) {
            return "Nenhum resultado encontrado";
          } else if (
            this.selected === "lotes" &&
            this.filteredCargas.filter(
              (volume) => volume.tipoPedido === "lote-fixador"
            ).length === 0
          ) {
            return "Nenhum resultado encontrado";
          }
        },
      },
    };
  },
  props: {
    pedidos: {
      type: Array,
      required: true,
      validator: (pedidos) => {
        return pedidos.every((pedido) => {
          return (
            1 &&
            pedido.pedidoId !== undefined &&
            pedido.numeroPedido !== undefined &&
            pedido.quantidadeVolumesEmbarcados !== undefined &&
            pedido.quantidadeVolumesTotal !== undefined
          );
        });
      },
    },
    volumesRef: {},
  },
  mounted() {
    this.getPedidosTotal();
    this.getPedidosEmbarcados();
    this.$emit("emitRef", this.$refs.pedidos);
  },
  computed: {
    ...mapGetters("expedition", ["getColetaDetalhes"]),
    pedidosRelacao() {
      return this.pedidos.map((p) => ({
        numeroPedido: p.numeroPedido,
        relacaoVolumes: `${p.quantidadeVolumesEmbarcados}/${p.quantidadeVolumesTotal}`,
        color: (() => {
          const embarqueJaIniciou = p.quantidadeVolumesEmbarcados > 0;
          if (embarqueJaIniciou) {
            return p.quantidadeVolumesEmbarcados === p.quantidadeVolumesTotal
              ? "green"
              : "yellow darken-2";
          } else {
            return "red darken-1";
          }
        })(),
      }));
    },
    filteredPedidos() {
      if (this.input && this.selected === "pedidos") {
        return this.pedidosRelacao.filter((pedido) =>
          String(pedido.numeroPedido).includes(this.input)
        );
      }
      return this.pedidosRelacao;
    },
    filteredCargas() {
      if (this.input && this.selected === "cargas") {
        return this.cargasRelacao.filter((carga) =>
          String(carga.cargaId).includes(this.input)
        );
      } else if (this.input && this.selected === "lotes") {
        return this.cargasRelacao.filter((carga) =>
          String(carga.cargaId).includes(this.input)
        );
      }
      return this.cargasRelacao;
    },
    cargasRelacao() {
      const cargas = {};
      this.getColetaDetalhes.volumes.forEach((volume) => {
        if (volume.embarcado === true) {
          if (!cargas[volume.cargaId]) {
            cargas[volume.cargaId] = {
              cargaId: volume.cargaId,
              totalEmbarcado: 1,
              totalPendente: 0,
              tipoPedido: volume.tipoPedido,
              class: "",
            };
          } else {
            cargas[volume.cargaId].totalEmbarcado += 1;
          }
        } else {
          if (!cargas[volume.cargaId]) {
            cargas[volume.cargaId] = {
              cargaId: volume.cargaId,
              totalEmbarcado: 0,
              totalPendente: 1,
              tipoPedido: volume.tipoPedido,
            };
          } else {
            cargas[volume.cargaId].totalPendente += 1;
          }
        }
      });

      const resultado = Object.values(cargas).map((carga) => {
        const totalVolumes = carga.totalEmbarcado + carga.totalPendente;
        const relacaoVolumes = `${carga.totalEmbarcado}/${totalVolumes}`;

        return {
          cargaId: carga.cargaId,
          relacaoVolumes: relacaoVolumes,
          color:
            carga.totalEmbarcado > 0 && carga.totalEmbarcado === totalVolumes
              ? "green"
              : carga.totalEmbarcado === 0 && totalVolumes > 0
              ? "red darken-1"
              : "yellow darken-2",
          tipoPedido: carga.tipoPedido,
        };
      });
      return resultado;
    },
    quantidadeCargas() {
      return this.cargasRelacao.filter(
        (volume) => volume.tipoPedido !== "lote-fixador"
      ).length;
    },
    quantidadeLotes() {
      return this.cargasRelacao.filter(
        (volume) => volume.tipoPedido === "lote-fixador"
      ).length;
    },
  },
  methods: {
    checkStatus(relacaoVolumes, status) {
      const parts = relacaoVolumes.split("/");
      const num1 = Number(parts[0]);
      const num2 = Number(parts[1]);

      switch (status) {
        case "Mostrar embarcados":
          return num1 > 0 && num2 > 0 && num1 === num2;
        case "Mostrar não embarcados":
          return num1 === 0 && num2 >= 0;
        case "Mostrar em embarque":
          return num1 > 0 && num1 < num2;
        default:
          return true;
      }
    },
    emitFilter({ filter, ref }) {
      this.$emit("getFilter", { filter, ref });
      if (filter.number === "") {
        return;
      }
      if (
        !this.getColetaDetalhes.volumes.find(
          (volume) => volume.numeroPedido === filter.number
        ) &&
        filter.type === "pedidos"
      ) {
        return;
      }
      this.$vuetify.goTo(this.volumesRef);
    },
    getPedidosTotal() {
      this.pedidosTotal = this.pedidos.length;
    },
    getPedidosEmbarcados() {
      this.pedidosEmbarcados = this.pedidos.filter(
        (p) => p.quantidadeVolumesEmbarcados > 0
      ).length;
    },
    showPedidosRelacao() {
      this.fab = !this.fab;
      this.input = "";
      this.emitFilter({
        filter: { type: this.selected, number: "" },
      });
    },
  },
};
</script>

<style scoped></style>
