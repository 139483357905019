import Vue from "vue";
import Vuex from "vuex";
import login from "./defaultUserStore/login";
import notifications from "./defaultUserStore/notifications";
import expedition from "./defaultUserStore/expedition";
import reception from "./defaultUserStore/reception";
import protocols from "./defaultUserStore/protocols";
import VuexPersistence from "vuex-persist";
import boarding from "./defaultUserStore/boarding";
import drivers from "./defaultUserStore/drivers";
import vehicles from "./defaultUserStore/vehicles";
import transportadora from "./defaultUserStore/transportadora";
import menu from "./defaultUserStore/menu";
import dashboard from "./defaultUserStore/dashboard";
import remessas from "./defaultUserStore/remessas";
import coleta from "./defaultUserStore/coleta";
import user from "./defaultUserStore/user";
import perfil from "./defaultUserStore/perfil";
import pedidos from "./defaultUserStore/pedidos";
import newUser from "./defaultUserStore/newUser";
import paineis from "./defaultUserStore/paineis";
import patio from "./defaultUserStore/patio";
import previsaoPatio from "./defaultUserStore/previsaoPatio";
import monitoramentoPatio from "./defaultUserStore/monitoramentoPatio";
import conferenciaBaia from "./defaultUserStore/conferenciaBaia";
import orderBlocking from "./defaultUserStore/orderBlocking";
import remessaTransportadora from "./transportadoraStore/remessaTransportadora";
import coletaTransportadora from "./transportadoraStore/coletaTransportadora";
import requisitionForCarrier from "./transportadoraStore/requisitionForCarrier";
import confirmationModals from "./transportadoraStore/confirmationModals";
import pickupReception from "./defaultUserStore/pickupReception";
import bahiaBranch from "./defaultUserStore/bahiaBranch";
import monitoramentoPedidos from "./defaultUserStore/monitoramentoPedidos";
import paletes from "./defaultUserStore/paletes";
import agendamentoPedidos from "./defaultUserStore/agendamentoPedidos";


Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => {
    const excludedModules = ["orderBlocking", "pickupReception", "bahiaBranch"];
    const filteredState = Object.keys(state).reduce((accumulator, module) => {
      if (!excludedModules.includes(module)) {
        accumulator[module] = state[module];
      }
      return accumulator;
    }, {});
    return filteredState;
  },
});

export default new Vuex.Store({
  modules: {
    namespaced: true,
    login,
    notifications,
    expedition,
    reception,
    boarding,
    protocols,
    drivers,
    vehicles,
    transportadora,
    menu,
    remessas,
    dashboard,
    coleta,
    user,
    perfil,
    pedidos,
    remessaTransportadora,
    coletaTransportadora,
    requisitionForCarrier,
    confirmationModals,
    newUser,
    paineis,
    patio,
    previsaoPatio,
    monitoramentoPatio,
    conferenciaBaia,
    orderBlocking,
    pickupReception,
    bahiaBranch,
    monitoramentoPedidos,
    paletes,
    agendamentoPedidos,
  },
  plugins: [vuexLocal.plugin],
});
