export default {
  listingMonPedidos: [],
  monPedidosPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  isLoading: false,
  urlParams: {
    page: 1,
    perPage: 10,
    numeroNota: "",
    numeroPedidoWinthor: "",
    numeroPedidoLoja: "",
    statusIntegracao: "",
    periodoDataDespachoInicio: "",
    periodoDataDespachoFinal: "",
    transportadora: "",
  },
  haveFilters: false,
  pedidosReenvio: [],
  reenvioDialog: false,
};
