
export default {
  getListaColeta(state) {
    return state.listaColeta;
  },
  getColetaPagination(state) {
    return state.coletasPagination;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getColetaCadastro(state) {
    return state.coletaCadastro;
  },
  getPedidosSelected(state) {
    return state.pedidosSelected;
  },
  getDataParaColetaCadastro(state) {
    return state.dataParaColetaCadastro;
  },
  getDataParaEditColeta(state) {
    return state.dataParaEditColeta;
  },
  getDocks(state) {
    return state.docks;
  } 
};
