<template>
  <v-container>
    <expedition-plan-header />
    <expedition-plan-list />
  </v-container>
</template>

<script>
import ExpeditionPlanList from "../../components/defaultUserComponents/expedition/ExpeditionPlanList.vue";
import ExpeditionPlanHeader from "../../components/defaultUserComponents/expedition/ExpeditionPlanHeader.vue";

export default {
  // components: { ExpeditionPlanHeader },
  components: {
    ExpeditionPlanList,
    ExpeditionPlanHeader,
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
