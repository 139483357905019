<template>

  <div></div>
</template>

<script>
export default {
  name: "EmbarqueColetaVolumesBipadosPelaCamera"
}
</script>

<style scoped>

</style>
