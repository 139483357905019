<template>
  <div ref="volumes">
    <v-card
      class="background mt-12"
      :class="volumes.length === 0 ? 'd-none' : ''"
      elevation="0"
      ref="volumes"
    >
      <div v-if="!isLoading">
        <v-row>
          <v-col cols="12">
            <v-text-field
              @keyup.enter="biparVolumeInput($event)"
              :loading="isLoadingBiparVolume"
              placeholder="N Volume"
              outlined
              dense
              solo
              flat
            />
          </v-col>
        </v-row>

        <div class="base pa-2 my-3 elevation-2 rounded-lg">
          <v-card-title>
            <span class="font-weight-bold mb-5"> Últimos Volumes Bipados</span>
          </v-card-title>
          <div>
            <v-row>
              <v-col cols="4" class="font-weight-bold">Pedidos</v-col>
              <v-col cols="4" class="font-weight-bold">Volumes</v-col>
              <v-col cols="4" class="font-weight-bold">Status</v-col>
            </v-row>
            <v-row v-for="(volume, index) in volumesSeparados" :key="index">
              <v-col>
                <span>
                  {{ volume.numeroPedido }}
                </span>
              </v-col>
              <v-col>
                <span :inner-html.prop="volume.codigoBarras | formatVolume">
                </span>
              </v-col>
              <v-col cols="4">
                <v-chip
                  v-if="volume.cargaStatus === 'EMBARCADA'"
                  color="green"
                  text-color="white"
                >
                  EMBARCADO
                </v-chip>
                <v-chip v-else color="red" text-color="white">
                  PENDENTE
                </v-chip>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="my-3">
          <v-progress-circular
            color="primary"
            indeterminate
            size="54"
          ></v-progress-circular>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "EmbarqueCooktopTabelaVolumes",
  data: () => ({
    volumesSeparados: [],
    lotesSeparados: [],
    isLoading: false,
  }),
  props: {
    loadingReloadColetaDetalhes: { type: Boolean, required: true },
    volumes: {
      type: Array,
      required: true,
      validator: (volumes) => {
        return volumes.every((v) => {
          return (
            1 &&
            v.volumeId !== undefined &&
            v.pedidoId !== undefined &&
            v.codigoBarras !== undefined &&
            v.habilitaEditarDetalhes !== undefined &&
            v.permiteBipagem !== undefined &&
            v.numeroPedido !== undefined &&
            v.embarcado !== undefined &&
            v.cargaId !== undefined &&
            v.cargaStatus !== undefined &&
            v.codigoCarga !== undefined
          );
        });
      },
    },
  },
  computed: {
    ...mapState("expedition", ["isLoadingBiparVolume"]),
  },
  methods: {
    ...mapActions("expedition", [
      "biparVolume",
      "biparVolumeLote",
      "volumesComErros",
    ]),
    openDetalhesVolumeDialog(volume) {
      this.$emit("open-volume-detalhes-dialog", volume);
    },
    async biparVolumeInput(event) {
      const serial = event.target.value;
      await this.biparVolume({
        coletaId: this.$route.params.coletaId,
        volume: serial,
      }).then(() => {
        this.$emit("atualizar-lista");
      });
    },
    async biparVolumeInputLote(event) {
      const serial = event.target.value;
      await this.biparVolumeLote({
        coletaId: this.$route.params.coletaId,
        codigo: serial,
      }).then(() => {
        this.$emit("atualizar-lista");
      });
    },
    async separeteVolumesPerCarga(volumes) {
      const volumesEmbarque = [];
      for (const volume of volumes) {
        volumesEmbarque.push(...(volume.embarcado ? [volume] : []));
      }

      this.volumesSeparados = volumesEmbarque;
      return;
    },
    clearLists() {
      this.volumesSeparados = [];
      return Promise.resolve();
    },
  },
  filters: {
    filterEmbarque(value) {
      return value ? "mdi-check" : "mdi-close";
    },
  },
  mounted() {
    this.clearLists().then(() => {
      this.separeteVolumesPerCarga(this.volumes);
    });
    this.$emit("emitRef", this.$refs.volumes);
  },
  watch: {
    volumes: {
      handler: function (volumes) {
        this.separeteVolumesPerCarga(volumes);
      },
      deep: true,
    },
  },
};
</script>

<style>
.theme--light.v-data-table {
  background-color: #dadfe0 !important;
}
.theme--dark.v-data-table {
  background-color: #2b2f31 !important;
}
</style>
