export default {
  isLoading: false,
  listingUsers: [],
  listPerfis: [],
  usersPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  isLoadingSearch: false,
  transportadoraEmails: [],
};
