<template>
  <div>
    <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <v-row class="list-header mx-auto my-auto mt-3">
        <v-col
          cols="12"
          sm="12"
          md="2"
          lg="2"
          xl="2"
          align="center"
          class="justify-center border-right"
        >
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            Data
          </span>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="2"
          lg="2"
          xl="2"
          align="center"
          class="justify-center border-right"
        >
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            Transportadora
          </span>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="1"
          lg="1"
          xl="1"
          align="center"
          class="justify-center border-right d-none d-sm-none d-md-block d-lg-block d-xl-block"
        >
          <span> Coleta </span>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="1"
          lg="1"
          xl="1"
          align="center"
          class="justify-center border-right d-none d-sm-none d-md-block d-lg-block d-xl-block"
        >
          <span> Doca </span>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="2"
          lg="2"
          xl="2"
          class="border-right d-none d-sm-none d-md-block d-lg-block d-xl-block"
          align="center"
        >
          <span> Status </span>
        </v-col>
        <v-col
          cols="1"
          align="center"
          class="border-right d-none d-sm-none d-md-block d-lg-block d-xl-block"
          v-for="(title, i) in titles"
          :title="title.label"
          :key="i"
        >
          <div class="text-truncate">{{ title.label }}</div>
        </v-col>
      </v-row>
    </div>

    <div v-if="!getIsLoading">
      <v-row
        class="list-card base_dark text--text mx-auto my-auto mt-2 justify-center"
        v-for="(coleta, index) in getListaColeta"
        :key="index"
        align="center"
      >
        <v-row
          class="mx-auto my-auto mt-2 align-md-center"
          :class="coleta.status === 'COLETA_CANCELADA' ? 'cancelled-row' : ''"
          align="center"
        >
          <v-col ols="6" sm="3" md="2" lg="2" align="center">
            <span class="d-md-none d-lg-none d-xl-none">
              <b>Data:</b>
            </span>
            <div class="text-break" :title="coleta.dataInicioEmbarque">
              <p class="my-0 mb-n3">
                {{ coleta.dataInicioEmbarque | formatHour }} -
                {{ coleta.dataFimEmbarque | formatHour }}
              </p>
              <span class="text-overline font-weight-light text--text">{{
                coleta.dataInicioEmbarque | formatDate
              }}</span>
            </div>
          </v-col>
          <v-col cols="6" sm="3" md="2" lg="2" xl="2" align="center">
            <span class="d-md-none d-lg-none d-xl-none">
              <b>Transportadora:</b>
            </span>
            <div class="text-break" :title="coleta.transportadoraNome">
              {{ coleta.nomeFantasia }}
            </div>
          </v-col>
          <v-col cols="6" sm="3" md="1" lg="1" xl="1" align="center">
            <span class="d-md-none d-lg-none d-xl-none">
              <b>Coleta:</b>
            </span>
            <div
              class="text-wrap font-weight-bold"
              :title="coleta.codigoColeta"
              :class="
                coleta.status === 'COLETA_CANCELADA'
                  ? 'cancelled-row'
                  : 'navy--text'
              "
            >
              {{ coleta.coletaCodigo }}
            </div>
          </v-col>
          <v-col cols="6" sm="3" md="1" lg="1" xl="1" align="center">
            <span class="d-md-none d-lg-none d-xl-none">
              <b>Doca:</b>
            </span>
            <div
              class="text-wrap font-weight-bold"
              :title="coleta.codigoDoca"
              :style="{
                color:
                  coleta.status === 'COLETA_CANCELADA'
                    ? 'cancelled-row'
                    : coleta.corDoca,
              }"
            >
              <v-icon
                :style="{
                  color:
                    coleta.status === 'COLETA_CANCELADA'
                      ? 'cancelled-row'
                      : coleta.corDoca,
                }"
                >mdi-checkbox-blank-circle
              </v-icon>
              Doca
              {{ coleta.codigoDoca }}
            </div>
          </v-col>
          <v-col cols="6" sm="3" md="2" lg="2" xl="2" align="center">
            <span class="d-md-none d-lg-none d-xl-none">
              <b>Status:</b>
            </span>
            <div class="text-wrap secondary--text font-weight-bold">
              <v-chip
                class="pa-2 text-wrap"
                text-color="white"
                :color="colorChip(coleta.status)"
              >
                {{ coleta.status | filterStatus }}
              </v-chip>
            </div>
          </v-col>
          <v-col cols="6" sm="3" md="1" lg="1" xl="1" align="center">
            <span class="d-md-none d-lg-none d-xl-none">
              <b>Veiculo:</b>
            </span>
            <div class="text-truncate" :title="coleta.descricao">
              {{ coleta.descricao }}
            </div>
          </v-col>
          <v-col cols="4" sm="2" md="1" lg="1" xl="1" align="center">
            <span class="d-md-none d-lg-none d-xl-none">
              <b>Pedidos:</b>
            </span>
            <div class="text-truncate" :title="coleta.qntPedidos">
              {{ coleta.qntPedidos }}
            </div>
          </v-col>
          <v-col cols="4" sm="2" md="1" lg="1" xl="1" align="center">
            <span class="d-md-none d-lg-none d-xl-none">
              <b>Volumes:</b>
            </span>
            <div class="text-truncate" :title="coleta.qntVolumes">
              {{ coleta.qntVolumes }}
            </div>
          </v-col>
          <v-col cols="4" sm="2" md="1" lg="1" xl="1" align="center">
            <v-speed-dial
              :ref="`speed-dial-${index}`"
              :v-model="fab[coleta.coletaId]"
              transition="scale-transition"
              direction="left"
            >
              <template v-slot:activator>
                <v-tooltip
                  top
                  :disabled="isSpeedDialActive('speed-dial-' + index)"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        :v-model="fab[coleta.coletaId]"
                        fab
                        rounded
                        small
                        elevation="3"
                        color="navy"
                        class="text-truncate"
                        :class="hover ? 'base--text' : 'base--text'"
                        :disabled="coleta.status === 'COLETA_CANCELADA'"
                        style="
                          text-transform: none !important;
                          font-weight: bolder;
                        "
                      >
                        <v-icon v-if="!isSpeedDialActive('speed-dial-' + index)"
                          >mdi-plus
                        </v-icon>
                        <v-icon v-else> mdi-close</v-icon>
                      </v-btn>
                    </v-hover>
                  </template>
                  <span>Ver Ações</span>
                </v-tooltip>
              </template>
              <div v-if="!(coleta.status === 'COLETA_CANCELADA')">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        fab
                        small
                        elevation="2"
                        color="navy"
                        style="
                          text-transform: none !important;
                          font-weight: bolder;
                        "
                        class="text-truncate"
                        :disabled="coleta.status !== 'COLETA_FINALIZADA'"
                        :class="hover ? 'base--text' : 'base--text'"
                        @click="
                          generateManifestRelatorio({
                            data: { coletasId: [coleta.coletaId] },
                            useState: 'pdf',
                          })
                        "
                      >
                        <v-icon> mdi-file-download-outline</v-icon>
                      </v-btn>
                    </v-hover>
                  </template>
                  <span>Baixar Minuta</span>
                </v-tooltip>
              </div>
              <div v-if="!(coleta.status === 'COLETA_CANCELADA')">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        fab
                        small
                        elevation="2"
                        color="navy"
                        style="
                          text-transform: none !important;
                          font-weight: bolder;
                        "
                        class="text-truncate"
                        :disabled="coleta.status == 'COLETA_CANCELADA'"
                        :class="hover ? 'base--text' : 'base--text'"
                        @click="openDetailsColetaDialog(coleta.coletaId)"
                      >
                        <v-icon> mdi-text-box-search </v-icon>
                      </v-btn>
                    </v-hover>
                  </template>
                  <span>Detalhes da Coleta</span>
                </v-tooltip>
              </div>
              <div v-if="!(coleta.status === 'COLETA_CANCELADA')">
                <div v-if="coleta.status !== 'COLETA_FINALIZADA'">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          fab
                          small
                          elevation="2"
                          color="navy"
                          style="
                            text-transform: none !important;
                            font-weight: bolder;
                          "
                          class="text-truncate"
                          :disabled="
                            coleta.status == 'COLETA_CANCELADA' ||
                            isUpdateButtonDisabled
                          "
                          :class="hover ? 'base--text' : 'base--text'"
                          @click="
                            openUpdateColetaDialog(coleta.coletaId, coleta)
                          "
                        >
                          <v-icon> mdi-pencil </v-icon>
                        </v-btn>
                      </v-hover>
                    </template>
                    <span>Editar Coleta</span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          fab
                          small
                          elevation="2"
                          color="green"
                          class="text-truncate"
                          :class="hover ? 'base--text' : 'base--text'"
                          style="
                            text-transform: none !important;
                            font-weight: bolder;
                          "
                        >
                          <v-icon> mdi-check </v-icon>
                        </v-btn>
                      </v-hover>
                    </template>
                    <span>Coleta Finalizada</span>
                  </v-tooltip>
                </div>
              </div>
              <div v-if="!(coleta.status === 'COLETA_CANCELADA')">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        fab
                        small
                        elevation="2"
                        color="navy"
                        style="
                          text-transform: none !important;
                          font-weight: bolder;
                        "
                        class="text-truncate"
                        :disabled="coleta.status === 'COLETA_CANCELADA'"
                        :class="hover ? 'base--text' : 'base--text'"
                        @click="downloadProtocol(coleta.coletaId)"
                      >
                        <v-icon> mdi-arrow-down-bold </v-icon>
                      </v-btn>
                    </v-hover>
                  </template>
                  <span>Baixar Protocolo</span>
                </v-tooltip>
              </div>
            </v-speed-dial>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="3" lg="3" xl="3" align="center">
          <v-hover v-slot="{ hover }">
            <v-btn
              block
              rounded
              color="navy"
              class="text-truncate"
              max-height="120px"
              :class="hover ? 'base--text' : 'base--text'"
              style="text-transform: none !important; font-weight: bolder"
              @click="openDetailsColetaDialog(coleta.coletaId)"
              >Detalhes</v-btn
            >
          </v-hover>
        </v-col> -->
        </v-row>
      </v-row>
      <v-row class="justify-center mb-3">
        <v-pagination
          v-model="getColetaPagination.currentPage"
          :length="getColetaPagination.lastPage"
          @input="proxPage(getColetaPagination.currentPage)"
          :total-visible="7"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        >
        </v-pagination>
      </v-row>
      <v-dialog
        v-model="dialogDetailsColeta"
        scrollable
        max-width="720"
        transition="dialog-bottom-transition"
      >
        <details-coleta-dialog
          :key="reRender"
          :coletaId="coletaId"
          @coleta="listColetas"
          @closeDetailsColetaDialog="closeDetailsColetaDialog"
          @listColetas="listColetas"
        />
      </v-dialog>
      <v-dialog
        v-model="dialogUpdateColeta"
        scrollable
        max-width="720"
        transition="dialog-bottom-transition"
      >
        <update-coleta-dialog
          :key="reRender"
          :coletaId="coletaId"
          :coletaObj="coletaObj"
          @coleta="listColetas"
          @closeUpdateColetaDialog="closeUpdateColetaDialog"
        />
      </v-dialog>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-col>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { HttpFileRequestService } from "../../../services/http/HttpFileRequestService";
import DetailsColetaDialog from "./DetailsColetaDialog.vue";
import UpdateColetaDialog from "./UpdateColetaDialog.vue";

export default {
  name: "ColetaList",
  components: {
    DetailsColetaDialog,
    UpdateColetaDialog,
  },
  data: () => ({
    fab: false,
    coletaId: 0,
    reRender: 0,
    dialogDetailsColeta: false,
    dialogUpdateColeta: false,
    isLoading: false,
    coletaObj: {},
    titles: [{ label: "Veículo" }, { label: "Pedidos" }, { label: "Volumes" }],
    urlParams: {
      page: 1,
      remessa: 0,
    },
    placaRules: [
      (v) => !!v || "Placa Obrigatória",
      (v) => v.length == 7 || "Placa precisa ter 7 caracteres",
    ],
    veiculoRules: [(v) => !!v.descricao || "O campo Veículo é obrigatório"],
    transportadoraRules: [
      (v) => !!v.nomeFantasia || "O campo Transportadora é obrigatório",
    ],
  }),

  mounted() {
    this.listColetas();
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
    filterStatus(value) {
      switch (value) {
        case "AGUARDANDO_TRANSPORTADORA":
          return (value = "Aguard. Veículo");
        case "CONFIRMACAO_TRANSPORTADORA":
          return (value = "Aguard. Entrada");
        case "COLETA_INICIADA":
          return (value = "Veículo no Patio");
        case "COLETA_FINALIZADA":
          return (value = "Col. Finalizada");
        case "COLETA_CANCELADA":
          return (value = "Col. Cancelada");
      }
    },
  },
  computed: {
    ...mapGetters("coleta", [
      "getListaColeta",
      "getColetaPagination",
      "getIsLoading",
    ]),

    isUpdateButtonDisabled() {
      return (
        this.$route.params.confirmacao === "Pendente" &&
        this.$route.params.status != "QUANTIDADE_VEICULOS_DIVERGENTE"
      );
    },
  },
  methods: {
    ...mapActions("coleta", ["coleta", "setIsLoading"]),
    ...mapActions("expedition", ["generateManifestRelatorio"]),
    downloadProtocol(coletaId) {
      let url = `/protocolo/pdf/${coletaId}/`;
      const response = HttpFileRequestService.build()
        .setMethod("GET")
        .setUrl(url)
        .request();
    },

    async proxPage(page) {
      this.setIsLoading(true);
      this.urlParams = {
        page: page,
        remessa: this.$route.params.remessaId,
      };
      await this.coleta(this.urlParams);
      this.setIsLoading(false);
    },

    closeDetailsColetaDialog() {
      this.dialogDetailsColeta = false;
    },

    closeUpdateColetaDialog() {
      this.dialogUpdateColeta = false;
    },

    async listColetas() {
      this.urlParams.remessa = this.$route.params.remessaId;
      await this.coleta(this.urlParams);
    },

    openDetailsColetaDialog(coleta) {
      this.coletaId = coleta;
      this.dialogDetailsColeta = true;
      this.reRender += 1;
    },

    openUpdateColetaDialog(coleta, coletaObj) {
      this.coletaObj = coletaObj;
      this.coletaId = coleta;
      this.dialogUpdateColeta = true;
      this.reRender += 1;
    },

    colorChip(value) {
      switch (value) {
        case "AGUARDANDO_TRANSPORTADORA":
          return "red";
        case "CONFIRMACAO_TRANSPORTADORA":
          return "lime darken-1";
        case "COLETA_INICIADA":
          return "orange";
        case "COLETA_FINALIZADA":
          return "green";
        case "COLETA_CANCELADA":
          return "gray";
      }
    },

    isSpeedDialActive(ref) {
      return this.$refs?.[ref]?.[0]?.isActive;
    },
  },
};
</script>
<style scoped>
.cancelled-row {
  color: rgba(149, 149, 149, 0.6);
}
.list-header {
  background-color: #c1c6c7;
  color: #0f2733;
  font-weight: bolder;
  min-height: 30px;
}
.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
.list-card {
  color: black;
  font-weight: normal;
}
.list-card:hover {
  background-color: #ffdfcc;
  color: black;
}
</style>
