import { PanelsRepository } from "../../../repositories/defaultUserRepositories/PanelsRepository";

export default {
  async panels({ commit, state }) {
    const urlParams = state.urlParams;
    let url = `/paineis?page=${urlParams.page}&perPage=${
      urlParams.perPage || 10
    }`;
    if (urlParams.search) {
      url = url.concat(`&nome=${urlParams.search}`);
    }
    if (urlParams.painelId) {
      url = url.concat(`&id=${urlParams.painelId}`);
    }
    commit("setIsLoading", true);
    console.log(url);
    const response = await PanelsRepository.paineis(url);
    if (response.status >= 200 && response.status < 300) {
      commit("setListingPaineis", response.data.data.list);
      commit("setPaineisPagination", response.data.data.pagination);
      commit("setIsLoading", false);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro na listagem",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
    return;
  },

  async panelsWithCubagem({ commit, state }) {
    let url = "/paineis/cubagem";
    commit("setIsLoading", true);
    console.log(url);
    const response = await PanelsRepository.paineis(url);
    if (response.status >= 200 && response.status < 300) {
      commit("setListingPaineisCubagem", response.data.data);
      commit("setIsLoading", false);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro na listagem",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
    return;
  },
  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },
  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },

  async palletListing({ commit }) {
    const response = await PanelsRepository.getPalletListing();
    if (response.status >= 200 && response.status < 300) {
      commit("setListingPallets", response.data.data);
      return;
    }
  },
  async palletAmount({ commit }, tipo_insumo) {
    commit("setPalletsAmount", []);
    const response = await PanelsRepository.getPalletAmount(tipo_insumo);
    if (response.status >= 200 && response.status < 300) {
      commit("setPalletsAmount", response.data.data);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },
  async updateConfigPallet({ commit }, data) {
    const response = await PanelsRepository.updateConfigPallet(data);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
      return response;
    }
  },
};
