<template>
  <v-card>
    <v-col>
      <default-table-head>
        <v-col
          cols="2"
          class="border-right"
          >Data</v-col
        >
        <v-col
          cols="5"
          class="border-right"
          >Motivo</v-col
        >
        <v-col cols="5">Editada por</v-col>
      </default-table-head>
      <default-table-body-row
        v-for="(editHistoryEntry, index) in editHistory"
        :key="index"
      >
        <v-col cols="2"
          ><span class="d-block mb-n2">{{
            editHistoryEntry.criadoEm | formatDate
          }}</span>
          <span class="d-block mb-n2 text-overline font-weight-light">{{
            editHistoryEntry.criadoEm | formatHour
          }}</span></v-col
        >
        <v-col cols="5"
          ><span class="primary--text font-weight-bold">{{
            editHistoryEntry.motivo | formatEditMotive
          }}</span></v-col
        >
        <v-col cols="5">{{ editHistoryEntry.criadoNome }}</v-col>
      </default-table-body-row>
    </v-col>
  </v-card>
</template>

<script>
import DefaultTableBodyRow from "../../defaultComponents/DefaultTableBodyRow.vue";
import DefaultTableHead from "../../defaultComponents/DefaultTableHead.vue";

export default {
  name: "EditHistory",
  components: {
    DefaultTableBodyRow,
    DefaultTableHead,
  },
  props: {
    pickupDetails: {
      type: Array,
      required: true,
    },
  },
  computed: {
    editHistory() {
      return this.pickupDetails[0].historicoEdicao;
    },
  },
  filters: {
    formatEditMotive(editMotive) {
      const editMotives = {
        "Adicionar Pedido": "Adicionar Pedido",
        "Retirar Pedido": "Retirar Pedido",
        Remanejamento: "Remanejar Pedido",
      };
      return editMotives[editMotive] ?? editMotive;
    },
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  },
};
</script>

<style scoped></style>
