import { VehiclesRepository } from "../../../repositories/defaultUserRepositories/VehiclesRepository";

export default {
  async vehicles({ commit, state }) {
    const urlParams = state.urlParams;
    let url = `/veiculo/listar?page=${urlParams.page}&perPage=${10}`;
    if (urlParams.search) {
      url = url.concat(`&search=${urlParams.search}`);
    }
    if (urlParams.transportadora && !urlParams.transportadoraId) {
      url = url.concat(`&transportadora=${urlParams.transportadora}`);
    }
    if (urlParams.transportadoraId) {
      url = url.concat(`&transportadora_id=${urlParams.transportadoraId}`);
    }
    const response = await VehiclesRepository.requestVehicles(url);
    if (response.status >= 200 && response.status < 300) {
      commit("setListingVehicles", response.data.data.list);
      commit("setFilteredVehicles", response.data.data.list);
      commit("setVehiclesPagination", response.data.data.pagination);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro na listagem",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async listVehiclesType({ commit }) {
    const response = await VehiclesRepository.listVehiclesType();
    if (response.status >= 200 && response.status < 300) {
      commit("setVehiclesType", response.data.data);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro na aplicação",
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async listTransportadoras({ commit }) {
    const response = await VehiclesRepository.listTransportadoras();
    if (response.status >= 200 && response.status < 300) {
      commit("setTransportadoras", response.data.data);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro na aplicação",
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async createVehicle({ commit }, modelCreateVehicle) {
    const response = await VehiclesRepository.createVehicle(modelCreateVehicle);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Veículo criado com sucesso!",
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async updateVehicle({ commit }, modelUpdateVehicle) {
    const response = await VehiclesRepository.updateVehicle(modelUpdateVehicle);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Veículo editado com sucesso!",
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },
};
