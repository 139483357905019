<template>
  <div>
    <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <v-row class="list-header mx-auto my-auto mt-3" align="center">
        <v-col
          cols="12"
          sm="12"
          md="2"
          lg="2"
          xl="2"
          align="center"
          class="justify-center border-right"
        >
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            Data
          </span>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="2"
          lg="2"
          xl="2"
          align="center"
          class="justify-center border-right"
        >
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            Transportadora
          </span>
        </v-col>
        <v-col
          cols="1"
          align="center"
          class="border-right d-none d-sm-none d-md-block d-lg-block d-xl-block"
          v-for="(title, i) in titles"
          :title="title.label"
          :key="i"
        >
          <span class="d-block text-truncate">{{ title.label }}</span>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="1"
          lg="1"
          xl="2"
          align="center"
          class="border-right d-none d-sm-none d-md-block d-lg-block d-xl-block"
        >
          <span class="d-block text-truncate">Volumes</span>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="1"
          lg="1"
          xl="2"
          align="center"
          class="justify-center py-0"
        >
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    fab
                    @click="openDownloadCollectionsModal()"
                    elevation="3"
                    color="primary"
                    class="text-truncate mx-2"
                    :class="hover ? 'base--text' : 'navy--text'"
                    style="text-transform: none !important; font-weight: bolder"
                  >
                    <v-icon size="20">mdi-file-download</v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <span>Baixar Minutas</span>
            </v-tooltip>
          </span>
        </v-col>
      </v-row>
    </div>
    <div v-if="!getIsLoading">
      <div v-for="(expedition, index) in getListingExpedition" :key="index">
        <v-hover v-slot="{ hover }">
          <v-row
            align-md="center"
            :class="hover ? 'hoverGlobal' : 'base_dark'"
            class="text--text mx-auto my-auto mt-2 justify-center"
          >
            <v-col cols="3" sm="3" md="2" lg="2" xl="2" align="center">
              <span class="d-md-none d-lg-none d-xl-none font-weight-bold">
                Data
              </span>
              <div class="text-truncate" :title="expedition.dataInicioEmbarque">
                <p class="my-0 mb-n3">
                  {{ expedition.dataInicioEmbarque | formatHour }} -
                  {{ expedition.dataFimEmbarque | formatHour }}
                </p>
                <span class="text-overline font-weight-light text--text">{{
                  expedition.dataInicioEmbarque | formatDate
                }}</span>
              </div>
            </v-col>
            <v-col cols="3" sm="3" md="2" lg="2" xl="2" align="center">
              <span class="d-md-none d-lg-none d-xl-none font-weight-bold">
                Transportadora
              </span>
              <div class="text-break" :title="expedition.transportadoraNome">
                {{ expedition.nomeFantasia }}
              </div>
            </v-col>
            <v-col cols="3" sm="3" md="1" lg="1" xl="1" align="center">
              <span class="d-md-none d-lg-none d-xl-none font-weight-bold">
                Coleta
              </span>
              <div class="text-break" :title="expedition.codigoColeta">
                {{ expedition.coletaCodigo }}
              </div>
            </v-col>
            <v-col cols="3" sm="3" md="1" lg="1" xl="1" align="center">
              <span class="d-md-none d-lg-none d-xl-none font-weight-bold">
                Doca
              </span>
              <div
                class="text-wrap font-weight-bold"
                :title="'Doca ' + expedition.codigoDoca"
              >
                <v-icon
                  size="30"
                  :color="
                    expedition.codigoDoca === 2
                      ? 'yellow darken-2'
                      : expedition.codigoDoca === 5
                      ? 'black'
                      : expedition.corDoca
                  "
                  >{{ `mdi-numeric-${expedition.codigoDoca}-circle-outline` }}
                </v-icon>
              </div>
            </v-col>
            <v-col cols="3" sm="3" md="1" lg="1" xl="1" align="center">
              <span class="d-md-none d-lg-none d-xl-none font-weight-bold">
                Veículo
              </span>
              <div class="text-truncate" :title="expedition.descricao">
                {{ expedition.descricao }}
              </div>
            </v-col>
            <v-col cols="3" sm="3" md="1" lg="1" xl="1" align="center">
              <span class="d-md-none d-lg-none d-xl-none font-weight-bold">
                Pedidos
              </span>
              <div class="text-truncate" :title="expedition.qntPedidos">
                {{ expedition.qntPedidos }}
              </div>
            </v-col>
            <v-col cols="3" sm="3" md="1" lg="1" xl="1" align="center">
              <span class="d-md-none d-lg-none d-xl-none font-weight-bold">
                Volumes
              </span>
              <div class="text-truncate" :title="expedition.qntVolumes">
                {{ expedition.qntVolumes }}
              </div>
            </v-col>
            <v-col cols="3" sm="1" md="1" lg="1" xl="1" align="center">
              <div>
                <v-speed-dial
                  :ref="`speed-dial-${index}`"
                  :v-model="fab[index]"
                  transition="scale-transition"
                  direction="left"
                >
                  <template v-slot:activator>
                    <v-tooltip
                      top
                      :disabled="isSpeedDialActive('speed-dial-' + index)"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            v-on="on"
                            v-bind="attrs"
                            :v-model="fab[index]"
                            fab
                            rounded
                            small
                            elevation="3"
                            color="btnCorColeta"
                            class="text-truncate"
                            :class="hover ? 'navy--text' : 'navy--text'"
                            style="
                              text-transform: none !important;
                              font-weight: bolder;
                            "
                          >
                            <v-icon
                              v-if="!isSpeedDialActive('speed-dial-' + index)"
                              >mdi-plus
                            </v-icon>
                            <v-icon v-else> mdi-close</v-icon>
                          </v-btn>
                        </v-hover>
                      </template>
                      <span>Ver Ações</span>
                    </v-tooltip>
                  </template>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <v-btn
                          v-show="
                            (expedition.status == 'COLETA_PAUSADA' && active) ||
                            (expedition.status == 'COLETA_INICIADA' && active)
                          "
                          v-bind="attrs"
                          v-on="on"
                          fab
                          small
                          elevation="3"
                          color="primary"
                          :disabled="
                            expedition.status == 'COLETA_PAUSADA' || !active
                          "
                          class="text-truncate"
                          :class="hover ? 'base--text' : 'navy--text'"
                          @click="
                            openConfirmPauseCollectionDialog(
                              expedition.coletaId
                            )
                          "
                          style="
                            text-transform: none !important;
                            font-weight: bolder;
                          "
                        >
                          <v-icon> mdi-pause </v-icon>
                        </v-btn>
                      </v-hover>
                    </template>
                    <span>Pausar Coleta</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          fab
                          small
                          elevation="3"
                          color="primary"
                          class="text-truncate"
                          :class="hover ? 'base--text' : 'navy--text'"
                          @click="
                            openPickupInfoModal(
                              expedition.coletaId,
                              expedition.status
                            )
                          "
                          style="
                            text-transform: none !important;
                            font-weight: bolder;
                          "
                        >
                          <v-icon> mdi-text-box-search </v-icon>
                        </v-btn>
                      </v-hover>
                    </template>
                    <span>Detalhes da Coleta</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <v-btn
                          v-show="
                            (expedition.status == 'COLETA_PAUSADA' && active) ||
                            (expedition.status == 'COLETA_INICIADA' && active)
                          "
                          v-bind="attrs"
                          v-on="on"
                          fab
                          small
                          elevation="3"
                          :disabled="!active"
                          color="primary"
                          class="text-truncate"
                          :class="hover ? 'base--text' : 'navy--text'"
                          @click="openExpeditionUser(expedition.coletaId)"
                          style="
                            text-transform: none !important;
                            font-weight: bolder;
                          "
                        >
                          <v-icon>mdi-account-multiple-plus </v-icon>
                        </v-btn>
                      </v-hover>
                    </template>
                    <span>Editar Despachante</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          fab
                          small
                          elevation="3"
                          color="primary"
                          class="text-truncate"
                          :class="hover ? 'base--text' : 'navy--text'"
                          @click="openDetailsProtocol(expedition.coletaId)"
                          style="
                            text-transform: none !important;
                            font-weight: bolder;
                          "
                        >
                          <v-icon>mdi-clipboard-file </v-icon>
                        </v-btn>
                      </v-hover>
                    </template>
                    <span>Detalhes do Protocolo</span>
                  </v-tooltip>
                </v-speed-dial>
              </div>
            </v-col>
            <!-- <v-col
              cols="2"
              sm="1"
              md="1"
              lg="1"
              xl="1"
              align="center"
              class="d-md-none d-lg-none d-xl-none"
              v-show="active"
            >
              <div>
                <v-hover v-slot="{ hover }">
                  <v-btn
                    fab
                    small
                    elevation="3"
                    color="primary"
                    class="text-truncate"
                    @click="openExpeditionUser(expedition.coletaId)"
                    :class="hover ? 'base--text' : 'navy--text'"
                    style="text-transform: none !important; font-weight: bolder"
                  >
                    <v-icon>mdi-account-multiple-plus </v-icon>
                  </v-btn>
                </v-hover>
              </div>
            </v-col> -->
            <v-col
              cols="6"
              sm="6"
              md="2"
              lg="2"
              xl="2"
              align="center"
              class="pl-0"
            >
              <div v-if="expedition.status == 'COLETA_INICIADA'">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    block
                    rounded
                    :disabled="expedition.status == 'COLETA_FINALIZADA'"
                    class="text-truncate px-2"
                    color="btnCorColeta"
                    :class="hover ? 'navy--text' : 'navy--text'"
                    style="text-transform: none !important; font-weight: bolder"
                    @click="
                      openStartBoardingDialog(
                        expedition.coletaId,
                        expedition.status
                      )
                    "
                  >
                    <span class="d-none d-md-inline">Embarq. Iniciado</span>
                    <span class="d-inline d-md-none">Embarque Iniciado</span>
                  </v-btn>
                </v-hover>
              </div>

              <div v-else-if="expedition.bloqueioStatus == 'BLOQUEADO'">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    block
                    rounded
                    :disabled="expedition.bloqueioStatus == 'BLOQUEADO'"
                    class="text-truncate px-2"
                    color="btnCorColeta"
                    :class="hover ? 'navy--text' : 'navy--text'"
                    style="text-transform: none !important; font-weight: bolder"
                    >Coleta Bloqueada</v-btn
                  >
                </v-hover>
              </div>

              <div
                v-else-if="
                  expedition.status == 'AGUARDANDO_TRANSPORTADORA' ||
                  expedition.status == 'CONFIRMACAO_TRANSPORTADORA'
                "
              >
                <v-hover v-slot="{ hover }">
                  <v-btn
                    block
                    rounded
                    color="primary"
                    :disabled="expedition.status == 'COLETA_FINALIZADA'"
                    class="text-truncate px-2"
                    :class="hover ? 'base--text' : 'navy--text'"
                    style="text-transform: none !important; font-weight: bolder"
                    @click="
                      openStartBoardingDialog(
                        expedition.coletaId,
                        expedition.status
                      )
                    "
                    >Iniciar Embarque</v-btn
                  >
                </v-hover>
              </div>

              <div v-else-if="expedition.status == 'COLETA_CANCELADA'">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    block
                    rounded
                    :disabled="expedition.status == 'COLETA_CANCELADA'"
                    class="text-truncate px-2"
                    color="btnCorColeta"
                    :class="hover ? 'navy--text' : 'navy--text'"
                    style="text-transform: none !important; font-weight: bolder"
                    >Coleta Cancelada</v-btn
                  >
                </v-hover>
              </div>

              <div v-else-if="expedition.status == 'COLETA_PAUSADA'">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    block
                    rounded
                    class="text-truncate px-2"
                    color="btnCorColeta"
                    :class="hover ? 'navy--text' : 'navy--text'"
                    style="text-transform: none !important; font-weight: bolder"
                    @click="
                      openStartBoardingDialog(
                        expedition.coletaId,
                        expedition.status
                      )
                    "
                  >
                    <span class="d-none d-md-inline">Continuar Embq.</span>
                    <span class="d-inline d-md-none">Continuar Embarque</span>
                  </v-btn>
                </v-hover>
              </div>

              <div v-else>
                <v-hover v-slot="{ hover }">
                  <v-btn
                    block
                    rounded
                    color="primary"
                    :disabled="expedition.status == 'COLETA_FINALIZADA'"
                    class="text-truncate px-2"
                    :class="hover ? 'base--text' : 'navy--text'"
                    style="text-transform: none !important; font-weight: bolder"
                    @click="
                      openStartBoardingDialog(
                        expedition.coletaId,
                        expedition.status
                      )
                    "
                  >
                    <span class="d-none d-md-inline">Embq. Finalizado</span>
                    <span class="d-inline d-md-none">Embarque Finalizado</span>
                  </v-btn>
                </v-hover>
              </div>
            </v-col>
          </v-row>
        </v-hover>
      </div>

      <v-dialog v-model="detailsDialogProtocol" max-width="850">
        <protocols-details
          :coletaId="coletaId"
          :key="reRenderDetailsProtocol"
          @closeModalProtocol="closeModalProtocol"
        />
      </v-dialog>
      <pickup-info
        v-model="isPickupInfoModalOpen"
        :key="'pickupInfoModalKey' + pickupInfoModalKey"
        :pickupId="coletaId"
        :pickupStatus="selectedColetaStatus"
      />
      <v-dialog
        v-model="boardingDialog"
        scrollable
        max-width="720"
        transition="dialog-bottom-transition"
      >
        <start-boarding
          :coletaId="coletaId"
          :coletaStatus="selectedColetaStatus"
          :key="reRenderBoarding"
          @closeStartBoardingDialog="closeStartBoardingDialog"
          @listExpedition="listExpedition"
        />
      </v-dialog>
      <v-dialog
        v-model="expeditionUser"
        scrollable
        max-width="720"
        transition="dialog-bottom-transition"
      >
        <expedition-user-modal
          :coletaId="coletaId"
          :key="reRenderExpeditionUser"
          @listExpedition="listExpedition"
          @closeExpeditionUser="closeExpeditionUser"
        />
      </v-dialog>
      <v-dialog
        v-model="downloadModal"
        max-width="720"
        transition="dialog-bottom-transition"
        content-class="hide-overflow-x"
      >
        <collections-download-modal
          :key="reRenderDownloadModal"
          @closeDownloadModal="closeDownloadModal"
        />
      </v-dialog>
      <v-dialog
        v-model="confirmPauseCollectionDialog"
        max-width="330"
        transition="dialog-bottom-transition"
      >
        <confirm-pause-collection-dialog
          :key="reRenderConfirmPauseCollection"
          :coletaId="coletaId"
          @listExpedition="listExpedition"
          @closeConfirmPauseCollectionDialog="closeConfirmPauseCollectionDialog"
        />
      </v-dialog>
      <v-row class="justify-center mb-3 mt-3">
        <v-pagination
          v-model="getExpeditionPagination.currentPage"
          :length="getExpeditionPagination.lastPage"
          @input="proxPage(getExpeditionPagination.currentPage)"
          :total-visible="7"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        >
        </v-pagination>
      </v-row>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-col>
  </div>
</template>

<script>
import PickupInfo from "./PickupInfo.vue";
import StartBoarding from "./StartBoarding.vue";
import { mapGetters, mapActions } from "vuex";
import ExpeditionUserModal from "./ExpeditionUserModal.vue";
import CollectionsDownloadModal from "./CollectionsDownloadModal.vue";
import ConfirmPauseCollectionDialog from "./ConfirmPauseCollectionDialog.vue";
import ProtocolsDetails from "../expedition/ProtocolDetails.vue";

export default {
  components: {
    PickupInfo,
    StartBoarding,
    ExpeditionUserModal,
    CollectionsDownloadModal,
    ConfirmPauseCollectionDialog,
    ProtocolsDetails,
  },
  data: () => ({
    active: false,
    ProtocolsDetailsive: "",
    coletaId: 0,
    selectedColetaStatus: "",
    reRenderBoarding: 0,
    pickupInfoModalKey: 0,
    reRenderDetailsProtocol: 0,
    reRenderDownloadModal: 0,
    reRenderConfirmPauseCollection: 0,
    reRenderExpeditionUser: 0,
    isPickupInfoModalOpen: false,
    detailsDialogProtocol: false,
    confirmPauseCollectionDialog: false,
    downloadModal: false,
    expeditionUser: false,
    boardingDialog: false,
    fab: false,
    show: true,
    titles: [
      { label: "Coleta" },
      { label: "Doca" },
      { label: "Veículo" },
      { label: "Pedidos" },
    ],
    urlParams: {
      page: 1,
      search: "",
      initialDate: "",
      finalDate: "",
    },
    permissionList: ["admin", "aux-de-logistica", "supervisor"],
  }),
  mounted() {
    this.listExpedition();
    this.timer = setInterval(this.reloadExpedition, 300000);
    this.permissionsUser();
  },
  computed: {
    ...mapGetters("expedition", [
      "getListingExpedition",
      "getExpeditionPagination",
      "getIsLoading",
      "getUrlParams",
    ]),
    ...mapGetters("login", ["getUserPermission", "getSlug"]),
  },
  methods: {
    ...mapActions("expedition", [
      "expedition",
      "setIsLoading",
      "setUrlParams",
      "collectRequests",
    ]),
    isSpeedDialActive(ref) {
      return this.$refs?.[ref]?.[0]?.isActive;
    },
    async proxPage(page) {
      this.setIsLoading(true);
      let tempUrl = this.getUrlParams;
      this.urlParams = tempUrl;
      this.urlParams.page = page;
      await this.setUrlParams(this.urlParams);
      await this.expedition();
      this.setIsLoading(false);
    },
    async listExpedition() {
      this.setIsLoading(true);
      await this.setUrlParams(this.urlParams);
      await this.expedition(this.urlParams);
      this.setIsLoading(false);
    },

    async reloadExpedition() {
      await this.setUrlParams(this.urlParams);
      await this.expedition(this.urlParams);
    },

    openStartBoardingDialog(coletaId, coletaStatus) {
      this.coletaId = coletaId;
      this.selectedColetaStatus = coletaStatus;
      this.reRenderBoarding += 1;
      this.boardingDialog = true;
    },

    closeStartBoardingDialog() {
      this.boardingDialog = false;
    },

    openConfirmPauseCollectionDialog(coletaId) {
      this.reRenderConfirmPauseCollection += 1;
      this.coletaId = coletaId;
      this.confirmPauseCollectionDialog = true;
    },

    closeConfirmPauseCollectionDialog() {
      this.confirmPauseCollectionDialog = false;
    },

    openPickupInfoModal(pickupId, pickupStatus) {
      this.pickupInfoModalKey++;
      this.coletaId = pickupId;
      this.selectedColetaStatus = pickupStatus;
      this.collectRequests(pickupId);
      this.isPickupInfoModalOpen = true;
    },

    openExpeditionUser(item) {
      this.reRenderExpeditionUser += 1;
      this.coletaId = item;
      this.expeditionUser = true;
    },
    openDetailsProtocol(coletaId) {
      this.reRenderDetailsProtocol += 1;
      this.coletaId = coletaId;
      this.detailsDialogProtocol = true;
    },
    closeModalProtocol() {
      this.detailsDialogProtocol = false;
    },

    closeExpeditionUser() {
      this.expeditionUser = false;
    },

    openDownloadCollectionsModal() {
      this.reRenderDownloadModal += 1;
      this.downloadModal = true;
    },

    closeDownloadModal() {
      this.downloadModal = false;
    },
    permissionsUser() {
      if (this.permissionList.includes(this.getSlug)) {
        this.active = true;
      } else {
        this.active = false;
      }
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  },
};
</script>

<style scoped>
.list-header {
  background-color: #0f2733;
  color: white;
  font-weight: bolder;
  min-height: 30px;
}
.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
.col-1 {
  width: 10% !important;
}
::v-deep .hide-overflow-x {
  overflow-x: hidden;
}
</style>
