<template>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    left
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :title="formatedDate"
        readonly
        dense
        :clearable="clearable"
        outlined
        :hide-details="hideDetails"
        flat
        :label="label"
        :value="formatedDate"
        v-bind="attrs"
        v-on="on"
        background-color="base"
        @click:clear="$emit('update:picker-date', $event.target.value)"
        :rules="rules"
        :disabled="disabled"
        :error="error"
        :error-messages="errorMessages"
      >
      </v-text-field>
    </template>
    <v-date-picker
      :key="reRender"
      color="primary"
      :value="pickerDate"
      @input="[$emit('update:picker-date', $event), (isMenuOpen = false)]"
      @change="$emit('onClickingDate')"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DefaultDatePicker",
  props: {
    pickerDate: {},
    clearable: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {},
  },
  data() {
    return {
      isMenuOpen: false,
      reRender: 0,
    };
  },
  computed: {
    formatedDate() {
      return this.pickerDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/, "$3/$2/$1");
    },
  },
  watch: {
    formatedDate(date) {
      if (date === "") {
        this.reRender += 1;
      }
    },
  },
};
</script>

<style scoped></style>
