import { HttpRestService } from "@/services/http/HttpRestService";

export class VehiclesRepository {  
   static requestVehicles(url) {
        return  HttpRestService.get(url);
    }
    static listVehiclesType() {
        return HttpRestService.get(`/veiculo/tipo/listar`);
    }
    static listTransportadoras() {
        return HttpRestService.get(`/transportadora/listar`);
    }
    static createVehicle(modelCreateVehicle) {
        return HttpRestService.post(`/veiculo/save`, modelCreateVehicle);
    }
    static updateVehicle(modelUpdateVehicle) {
        return HttpRestService.post(`/veiculo/editar`, modelUpdateVehicle);
    }
}
