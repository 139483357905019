<template>
  <v-card class="pa-4" elevation="0">
    <v-form ref="form" lazy-validation>
      <v-row justify="space-around">
        <v-col cols="5">
          <v-text-field
            ref="horaInicio"
            :error-messages="errorMessages"
            v-model="dataInicio"
            :rules="rules.hora"
            label="Hora Inicial"
            type="time"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-text-field
            ref="horaFim"
            :error-messages="errorMessages"
            type="time"
            v-model="dataFinal"
            :rules="rules.hora"
            label="Hora Final"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col cols="5">
          <v-select
            ref="docaSelected"
            class="text-center"
            outlined
            dense
            :rules="rules.doca"
            required
            :error-messages="errorMessages"
            :loading="loading"
            :disabled="showRedespacho"
            :items="docasSelectList"
            label="Doca"
            v-model="docaId"
            :color="cor"
          >
            <template v-slot:item="{ item }">
              <span class="d-flex justify-center" style="width: 100%">
                Doca - {{ item.text }} -
                <v-icon :color="item.color">mdi-circle</v-icon>
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span class="d-flex justify-center" style="width: 100%">
                Doca - {{ item.text }}
              </span>
            </template>
            <template v-slot:prepend>
              <v-icon :color="cor">mdi-circle</v-icon>
            </template>
          </v-select>
        </v-col>
        <v-col cols="5" class="text-start">
          <v-autocomplete
            outlined
            dense
            required
            :rules="rules.veiculoRules"
            :error-messages="errorMessages"
            :loading="loading"
            :items="tiposVeiculoSelectList"
            label="Tipo de veículo"
            v-model="tipoVeiculoId"
          />
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col cols="5">
          <v-menu
            v-model="menuColeta"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="dataColeta"
                :error-messages="errorMessages"
                placeholder="Data da Coleta"
                :rules="rules.dataColeta"
                outlined
                clearable
                readonly
                dense
                solo
                flat
                v-bind="attrs"
                v-on="on"
                v-model="dataColeta"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dataColeta"
              @input="menuColeta = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="5">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="dataRemessa"
                :error-messages="errorMessages"
                placeholder="Data da Remessa"
                :rules="rules.dataRemessa"
                outlined
                clearable
                readonly
                dense
                solo
                flat
                v-bind="attrs"
                v-on="on"
                v-model="dataRemessa"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dataRemessa"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row justify="space-around" class="mb-3 mt-0">
        <v-col cols="5" class="text-start pa-1">
          <div class="base_dark rounded-xl px-4">
            <span class="navy--text">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span>Coletas Agendadas:</span>
                  <v-icon class="ma-2" color="primary" v-bind="attrs" v-on="on"
                    >mdi-dolly</v-icon
                  >
                </template>
                <span>Coletas Agendadas</span>
              </v-tooltip>
              <span class="font-weight-bold">{{ coletas.length }} </span>
            </span>
          </div>
        </v-col>
        <v-col cols="4" class="text-start pa-0">
          <div class="base_dark rounded-xl pa-1 pr-2" style="position: fixed">
            <span class="navy--text">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ma-2" color="primary" v-bind="attrs" v-on="on"
                    >mdi-truck-outline</v-icon
                  >
                </template>
                <span>Comprimento do Caminhão</span>
              </v-tooltip>
              <span class="font-weight-bold"
                >{{ areaTruckSelected.toFixed(2) }}m
              </span>
            </span>
            <span class="navy--text">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ma-2" color="primary" v-bind="attrs" v-on="on"
                    >mdi-package-variant-closed-check
                  </v-icon>
                </template>
                <span>Comprimento dos Pedidos</span>
              </v-tooltip>
              <span class="font-weight-bold"
                >{{ areaPedidosSelected.toFixed(2) }}m
              </span>
            </span>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="space-around" align="center" class="mx-4 my-3">
      <v-col cols="4" sm="4" md="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Número Pedido"
          outlined
          clearable
          dense
          solo
          single-line
        ></v-text-field>
      </v-col>
      <v-col cols="4" sm="4" md="4">
        <v-select
          v-model="selectedFilterPedidos"
          :items="tiposDePedidos"
          :readonly="this.coletas.length > 0"
          ref="selectOrderTypeFilter"
          @mouseup="onTypeAlertMessage"
          label="Filtrar por pedidos"
          dense
          small-chips
        >
        </v-select>
      </v-col>
      <v-col cols="4" sm="4" md="4">
        <v-select
          v-model="selectedTipoColeta"
          :items="tipoColetaSelecionada"
          :rules="rules.tipoColeta"
          label="Tipo Coleta"
          dense
          small-chips
          clearable
        >
        </v-select>
      </v-col>
    </v-row>
    <!-- <v-row
      justify="space-around"
      align="center"
      class="mx-4 my-3"
      v-show="showRedespacho"
    >
      <v-col cols="12">
        <v-select
          v-model="selectedTipoTransportadora"
          :disabled="!showRedespacho"
          :items="getTransportadorasFilhas"
          item-text="razaoSocial"
          item-value="transportadoraId"
          label="Transportadora"
          outlined
          dense
          required
        >
        </v-select>
      </v-col>
    </v-row> -->
    <div v-if="!loadingTable">
      <div v-show="!showRedespacho">
        <v-data-table
          @item-selected="evaluateIfPedidoWasAlreadySelected"
          @toggle-select-all="evaluateIfSomePedidoWasAlreadyBeenSelected"
          aria-label="Pedidos"
          v-model="coletaCadastroPedidosSync"
          :headers="headers"
          :items="pedidosFilter"
          item-class="primary"
          :loading="loading"
          :single-select="false"
          item-key="numeroPedido"
          show-select
          class="elevation-1"
          disable-pagination
          hide-default-footer
          dense
          :search="search"
          :key="reRender"
        >
          <template v-slot:[`item.dataProducao`]="{ item }">
            {{ item.dataProducao | formatDate }}
            {{ item.dataProducao | formatHour }}
          </template>
        </v-data-table>
      </div>
      <div v-show="showRedespacho">
        <v-data-table
          @item-selected="evaluateIfPedidoWasAlreadySelected"
          @toggle-select-all="evaluateIfSomePedidoWasAlreadyBeenSelected"
          aria-label="Pedidos"
          v-model="coletaCadastroPedidosSync"
          :headers="headers"
          :items="getPedidosTransportadoraFilha.pedidos"
          item-class="primary"
          :loading="loading"
          :single-select="false"
          item-key="numeroPedido"
          show-select
          class="elevation-1"
          disable-pagination
          hide-default-footer
          dense
          :search="search"
          :key="reRender"
        >
          <template v-slot:[`item.dataProducao`]="{ item }">
            {{ item.dataProducao | formatDate }}
            {{ item.dataProducao | formatHour }}
          </template>
        </v-data-table>
      </div>
    </div>

    <default-spinner v-else />
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DefaultSpinner from "./../../../defaultComponents/DefaultSpinner.vue";

export default {
  name: "RemessaColetaCadastroCard",
  components: {
    DefaultSpinner,
  },
  data: () => ({
    // tipos de pedidos
    selectedFilterPedidos: ["web", "ploomes"],
    selectedTipoColeta: "",
    selectedTipoTransportadora: "",
    tiposDePedidos: [
      {
        value: ["web", "ploomes"],
        text: "Web + Ploomes",
      },
      {
        value: ["express", "lote", "lote-fixador"],
        text: "Ágil",
      },
    ],
    tipoColetaSelecionada: [
      {
        value: "EMBARQUE_PADRAO",
        text: "Padrao",
      },
      {
        value: "EMBARQUE_DINAMICO",
        text: "Dinamico",
      },
      {
        value: "EMBARQUE_ENCAMINHAMENTO",
        text: "Encaminhamento",
      },
      {
        value: "EMBARQUE_REDESPACHO",
        text: "Redespacho",
      },
    ],

    errorMessages: "",
    cor: "",
    menu: false,
    menuColeta: false,
    areaTruckSelected: 0,
    areaPedidosSelected: 0,
    //Rules
    rules: {
      hora: [(v) => !!v || "Hora é obrigatório"],
      data: [(v) => !!v || "Data é obrigatório"],
      doca: [(v) => !!v || "Doca é obrigatório"],
      veiculoRules: [(v) => !!v || "Tipo Veículo é obrigatório"],
      pedidoRules: [(v) => !!v || "Tipo de Pedido é obrigatório"],
      dataColeta: [(v) => !!v || "Data da Coleta é obrigatório"],
      dataRemessa: [(v) => !!v || "Data da Remessa é obrigatório"],
      tipoColeta: [(v) => !!v || "Tipo de Coleta é obrigatório"],
    },
    //DataTable
    reRender: 0,
    pedidosFilter: [],
    search: "",
    singleSelect: false,
    selected: [],
    headers: [
      {
        text: "Tipo pedido",
        value: "tipoPedido", // add tipo de pedido
        sortable: false,
        filterable: true,
        align: "start",
      },
      {
        text: "Núm. pedido",
        value: "numeroPedido",
        sortable: false,
        filterable: true,
        align: "start",
      },
      { text: "Compr. (m)", value: "area", sortable: true, align: "start" },
      {
        text: "Qtd. caminhões",
        value: "quantidadeCaminhoesAlocados",
        sortable: true,
        filterable: false,
        align: "start",
      },
      {
        text: "Qtd. volumes",
        value: "quantidadeVolumes",
        sortable: true,
        filterable: false,
        align: "start",
      },
      {
        text: "Qtd. painéis",
        value: "quantidadePaineis",
        sortable: true,
        filterable: false,
        align: "start",
      },
      {
        text: "Data produção",
        value: "dataProducao",
        sortable: true,
        filterable: false,
        align: "start",
      },
    ],
    coletaCadastroPedidos: [],
    loadingTable: false,
  }),
  props: {
    coletas: { type: Array, required: true },
    loading: { type: Boolean, required: true },
    docas: {
      type: Array,
      required: true,
      validator: (docas) =>
        docas.every(({ docaId, codigo, cor }) => {
          return (
            docaId !== undefined && codigo !== undefined && cor !== undefined
          );
        }),
    },
    tiposVeiculo: {
      type: Array,
      required: true,
      validator: (tiposVeiculos) =>
        tiposVeiculos.every(({ tipoVeiculoId, descricao }) => {
          return tipoVeiculoId !== undefined && descricao !== undefined;
        }),
    },
    pedidos: {
      type: Array,
      required: true,
      validator: (pedidos) =>
        pedidos.every(
          ({
            pedidoId,
            numeroPedido,
            area,
            quantidadeCaminhoesAlocados,
            quantidadeVolumes,
            quantidadePaineis,
            tipoPedido,
          }) => {
            return (
              pedidoId !== undefined &&
              numeroPedido !== undefined &&
              area !== undefined &&
              quantidadeCaminhoesAlocados !== undefined &&
              quantidadeVolumes !== undefined &&
              quantidadePaineis !== undefined &&
              tipoPedido !== undefined
            );
          }
        ),
    },
  },
  computed: {
    ...mapGetters("remessas", [
      "getRemessaCadastro",
      "getPedidosSelected",
      "getDataRemessa",
      "getDataColeta",
      "getTipoColeta",
      "getTransportadorasFilhas",
      "getPedidosTransportadoraFilha",
    ]),
    ...mapState("remessas", ["transportadoraData"]),
    docasSelectList() {
      return this.docas.map(({ docaId, codigo, cor }) => ({
        text: codigo,
        codigo: codigo,
        color: cor.toUpperCase(),
        value: docaId,
        disabled: false,
        divider: false,
        header: "",
      }));
    },
    tiposVeiculoSelectList() {
      return this.tiposVeiculo.map(({ tipoVeiculoId, descricao }) => ({
        text: descricao,
        value: tipoVeiculoId,
        disabled: false,
        divider: false,
        header: "",
      }));
    },
    dataInicio: {
      get() {
        return this.getRemessaCadastro.dataInicio;
      },
      set(dataInicio) {
        this.setRemessaCadastro({
          ...this.getRemessaCadastro,
          dataInicio,
        });
      },
    },
    dataFinal: {
      get() {
        return this.getRemessaCadastro.dataFinal;
      },
      set(dataFinal) {
        this.setRemessaCadastro({
          ...this.getRemessaCadastro,
          dataFinal,
        });
      },
    },
    docaId: {
      get() {
        return this.getRemessaCadastro.docaId;
      },
      set(docaId) {
        this.setRemessaCadastro({
          ...this.getRemessaCadastro,
          docaId,
        });
        this.cor = this.docas.find((d) => d.docaId === docaId).cor;
      },
    },
    tipoVeiculoId: {
      get() {
        return this.getRemessaCadastro.tipoVeiculoId;
      },
      set(tipoVeiculoId) {
        switch (tipoVeiculoId) {
          /* carreta: 15 x 2,6 m
        truck: 8 x 2,6 m
        toco: 8 x 2,6 m*/
          case 1:
            this.getRemessaCadastro.area = 15;
            this.getRemessaCadastro.tipoVeiculoId = tipoVeiculoId;
            break;
          case 2:
            this.getRemessaCadastro.area = 8;
            this.getRemessaCadastro.tipoVeiculoId = tipoVeiculoId;
            break;
          default:
            this.getRemessaCadastro.area = 7;
            this.getRemessaCadastro.tipoVeiculoId = tipoVeiculoId;
            break;
        }
        this.$emit("area-truck", +this.getRemessaCadastro.area);
        this.areaTruckSelected = +this.getRemessaCadastro.area;
        this.setRemessaCadastro({
          ...this.getRemessaCadastro,
          tipoVeiculoId,
        });
      },
    },
    dataRemessa: {
      get() {
        return this.formatDate(this.getDataRemessa.dataRemessa);
      },
      set(dataRemessa) {
        this.setDataRemessa({
          dataRemessa,
        });
      },
    },
    dataColeta: {
      get() {
        return this.formatDate(this.getDataColeta.dataColeta);
      },
      set(dataColeta) {
        this.setDataColeta({
          dataColeta,
        });
      },
    },
    coletaCadastroPedidosSync: {
      get() {
        return this.getPedidosSelected;
      },
      set(coletaCadastroPedidos) {
        this.calculateAreaPedidos(coletaCadastroPedidos);
        this.setPedidosSelected(coletaCadastroPedidos);
        // this.coletaCadastroPedidos = coletaCadastroPedidos;
        this.setRemessaCadastro({
          ...this.getRemessaCadastro,
          pedidos: this.getPedidosSelected,
        });
      },
    },
    tipoColeta: {
      get() {
        return this.getTipoColeta;
      },
      set(selectedTipoColeta) {
        this.setTipoColeta(selectedTipoColeta);
      },
    },
    showRedespacho() {
      return this.getTipoColeta === "EMBARQUE_REDESPACHO" ? true : false;
    },
  },
  watch: {
    selectedFilterPedidos: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.filtroTipoPedido(newValue);
      },
    },
    selectedTipoColeta: {
      async handler(value) {
        this.setTipoColeta({ value });
        this.filtroTipoPedido(this.selectedFilterPedidos);
      },
    },
    pedidos: {
      deep: true,
      immediate: true,
      handler() {
        this.filtroTipoPedido(this.selectedFilterPedidos);
      },
    },
    showRedespacho: {
      async handler(value) {
        if (this.showRedespacho) {
          this.loadingTable = true;
          await this.pedidosTransportadoraFilha(
            this.transportadoraData.transportadoraId
          );
          this.setTransportadoraFilhaId(
            this.transportadoraData.transportadoraId
          );
          this.loadingTable = false;
        }
      },
    },

    getPedidosTransportadoraFilha: {
      deep: true,
      immediate: true,
      handler(value) {
        this.filtroTipoPedido(value);
      },
    },
  },
  methods: {
    ...mapActions("remessas", [
      "setRemessaCadastro",
      "mostrarMensagemErro",
      "setRemessaCadastro",
      "setPedidosSelected",
      "setDataRemessa",
      "setDataColeta",
      "setTipoColeta",
      "setTypeAlertMessage",
      "pedidosTransportadoraFilha",
      "setTransportadoraFilhaId",
    ]),
    onTypeAlertMessage() {
      this.$refs["selectOrderTypeFilter"].isReadonly === false
        ? ""
        : this.setTypeAlertMessage();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    calculateAreaPedidos(pedido) {
      if (pedido) {
        this.areaPedidosSelected = 0;
        this.$emit("area-pedidos", 0);
      }
      for (var p = 0; p < pedido.length; p++) {
        this.areaPedidosSelected += pedido[p].area;
        this.$emit("area-pedidos", this.areaPedidosSelected);
      }
    },

    evaluateIfPedidoWasAlreadySelected(event) {
      if (~~event.item.quantidadeCaminhoesAlocados > 0) {
        this.mostrarMensagemErro(
          "Esse pedido já foi selecionado antes para outra coleta. Certifique-se de que você tem certeza que deseja fazer isso."
        );
      }
    },
    evaluateIfSomePedidoWasAlreadyBeenSelected(event) {
      const pedidosComMaisDeUmCaminhao = event.items
        .filter(
          ({ quantidadeCaminhoesAlocados }) => quantidadeCaminhoesAlocados > 0
        )
        .map(({ numeroPedido }) => numeroPedido);
      if (pedidosComMaisDeUmCaminhao.length) {
        const numerosPedido = pedidosComMaisDeUmCaminhao.join(",");
        this.mostrarMensagemErro(
          `Os pedidos ${numerosPedido} estão com mais de um caminhão alocados. Certifique-se de que você tem certeza que deseja fazer isso.`
        );
      }
    },
    filtroTipoPedido(arrayTipoPedido) {
      this.pedidosFilter = [];
      this.setPedidosSelected([]);
      if (this.showRedespacho) {
        this.pedidosFilter = this.getPedidosTransportadoraFilha.pedidos.filter(
          (p) => {
            if (arrayTipoPedido.includes(p.tipoPedido)) {
              return p;
            }
          }
        );
      } else {
        this.pedidosFilter = this.pedidos.filter((p) => {
          if (arrayTipoPedido.includes(p.tipoPedido)) {
            return p;
          }
        });
      }

      this.reRender += 1;
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  },
  created() {
    this.coletaCadastroPedidosSync = [];
    this.setDataRemessa("");
    this.setDataColeta("");
    this.setTipoColeta("");
  },
};
</script>

<style>
v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.h {
  font-weight: bolder;
}
.v-list .v-list-item:hover {
  background-color: var(docasSelectList) !important;
}
</style>
