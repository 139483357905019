var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{ref:"pedidos",staticClass:"my-3 base_dark text--text"},[_c('v-fab-transition',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-truncate",class:hover ? 'base--text' : 'navy--text',staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"absolute":"","top":"","right":"","fab":"","small":"","elevation":"2","color":"btnCorColeta"},on:{"click":function($event){return _vm.showPedidosRelacao()}}},[(!_vm.fab)?_c('v-icon',{attrs:{"color":"text"}},[_vm._v("mdi-plus")]):_c('v-icon',{attrs:{"color":"text"}},[_vm._v("mdi-close")])],1)]}}])})],1),_c('div',[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('div',{on:{"click":function($event){[
                _vm.emitFilter({
                  filter: { type: _vm.selected, number: '' },
                }),
                (_vm.input = '') ]}}},[_c('v-btn-toggle',{attrs:{"color":"primary","mandatory":"","rounded":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-btn',{attrs:{"value":"pedidos"}},[_vm._v("Pedidos ("+_vm._s(_vm.pedidosRelacao.length)+")")]),(
                  _vm.cargasRelacao.some(
                    function (volume) { return volume.tipoPedido !== 'lote-fixador'; }
                  )
                )?_c('v-btn',{attrs:{"value":"cargas"}},[_vm._v("Cargas ("+_vm._s(_vm.quantidadeCargas)+")")]):_vm._e(),(
                  _vm.cargasRelacao.some(
                    function (volume) { return volume.tipoPedido === 'lote-fixador'; }
                  )
                )?_c('v-btn',{attrs:{"value":"lotes"}},[_vm._v("Lotes ("+_vm._s(_vm.quantidadeLotes)+")")]):_vm._e()],1)],1)])],1),(_vm.fab)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-text-field',{attrs:{"placeholder":("Pesquisar " + (_vm.selected.slice(0, -1))),"clearable":"","dense":"","solo":"","flat":"","rules":[_vm.rules.required],"prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1)],1):_vm._e(),(_vm.fab)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-select',{staticStyle:{"width":"280px"},attrs:{"hide-details":"","dense":"","filled":"","rounded":"","items":_vm.items,"item-text":"status","item-value":"status","return-object":"","disable-lookup":""},on:{"input":function($event){return _vm.emitFilter({
                filter: { type: _vm.selected, number: '' },
              })}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.status))]),_vm._l((item.colors),function(color,index){return _c('v-icon',{key:index,attrs:{"color":color}},[_vm._v("mdi-circle-medium")])})]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.status))]),_vm._l((item.colors),function(color,index){return _c('v-icon',{key:index,attrs:{"color":color}},[_vm._v("mdi-circle-medium")])})]}}],null,false,705874492),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1)],1):_vm._e(),(_vm.fab)?_c('v-row',{staticClass:"justify-center pb-5",staticStyle:{"user-select":"none"}},[_vm._l((_vm.filteredPedidos),function(pedido,index){return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.selected === 'pedidos' &&
            _vm.checkStatus(pedido.relacaoVolumes, _vm.select.status)
          ),expression:"\n            selected === 'pedidos' &&\n            checkStatus(pedido.relacaoVolumes, select.status)\n          "},{name:"ripple",rawName:"v-ripple"}],key:'p' + index,staticClass:"rounded text-break ma-1 px-0",class:pedido.color,style:({
            color: pedido.color === 'yellow darken-2' ? 'black' : 'white',
            cursor: 'pointer',
          }),attrs:{"cols":"4","sm":"3","md":"3","lg":"2","xl":"2"},on:{"click":function($event){return _vm.emitFilter({
              filter: { type: _vm.selected, number: pedido.numeroPedido },
              ref: _vm.$refs.pedidos,
            })}}},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(pedido.numeroPedido)+":")]),_c('span',[_vm._v(_vm._s(pedido.relacaoVolumes))])])}),_vm._l((_vm.filteredCargas.filter(
            function (volume) { return volume.tipoPedido !== 'lote-fixador'; }
          )),function(carga,index){return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.selected === 'cargas' &&
            _vm.checkStatus(carga.relacaoVolumes, _vm.select.status)
          ),expression:"\n            selected === 'cargas' &&\n            checkStatus(carga.relacaoVolumes, select.status)\n          "},{name:"ripple",rawName:"v-ripple"}],key:'c' + index,staticClass:"rounded text-break ma-1",class:carga.color,style:({
            color: carga.color === 'yellow darken-2' ? 'black' : 'white',
            cursor: 'pointer',
          }),attrs:{"cols":"4","sm":"3","md":"3","lg":"2","xl":"2"},on:{"click":function($event){return _vm.emitFilter({
              filter: { type: _vm.selected, number: carga.cargaId },
              ref: _vm.$refs.pedidos,
            })}}},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(carga.cargaId)+":")]),_c('span',[_vm._v(_vm._s(carga.relacaoVolumes))])])}),_vm._l((_vm.filteredCargas.filter(
            function (volume) { return volume.tipoPedido === 'lote-fixador'; }
          )),function(lote,index){return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.selected === 'lotes' &&
            _vm.checkStatus(lote.relacaoVolumes, _vm.select.status)
          ),expression:"\n            selected === 'lotes' &&\n            checkStatus(lote.relacaoVolumes, select.status)\n          "},{name:"ripple",rawName:"v-ripple"}],key:'l' + index,staticClass:"rounded text-break ma-1",class:lote.color,style:({
            color: lote.color === 'yellow darken-2' ? 'black' : 'white',
            cursor: 'pointer',
          }),attrs:{"cols":"4","sm":"3","md":"3","lg":"2","xl":"2"},on:{"click":function($event){return _vm.emitFilter({
              filter: { type: _vm.selected, number: lote.cargaId },
              ref: _vm.$refs.pedidos,
            })}}},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(lote.cargaId)+":")]),_c('span',[_vm._v(_vm._s(lote.relacaoVolumes))])])})],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }