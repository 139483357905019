<template>
  <div class="mt-3">
    <v-row class="justify-space-between my-3 mx-2 text--text">
      <v-col sm="6" md="6" lg="6" xl="6" cols="6">
        <v-row align="center">
          <h4>
            Transportadora:
            {{
              this.$route.params.nomeFantasia ||
              this.getTransportadoraData.nomeFantasia
            }}
          </h4>
        </v-row>
      </v-col>
      <v-col sm="6" md="6" lg="6" xl="6" cols="6">
        <v-row align="center">
          <h4>
            CNPJ:
            {{ this.$route.params.cnpj || this.getTransportadoraData.cnpj }}
          </h4>
        </v-row>
      </v-col>
    </v-row>
    <v-row no gutters class="mx-auto">
      <v-col cols="1" sm="1" md="1" lg="1" xl="1">
        <v-hover v-slot="{ hover }">
          <v-btn
            fab
            small
            elevation="3"
            color="navy"
            class="text-truncate"
            :class="hover ? 'base--text' : 'base--text'"
            @click="backToRemessas"
            style="text-transform: none !important; font-weight: bolder"
          >
            <v-icon> mdi-arrow-left-bold </v-icon>
          </v-btn>
        </v-hover>
      </v-col>
      <v-col cols="11" sm="11" md="11" lg="11" xl="11">
        <v-text-field
          label="Pesquisar Coleta"
          :append-icon="searchIcon"
          v-model="urlParams.search"
          @click:append="search"
          @keydown.enter.prevent="search"
          dense
          solo
          flat
        >
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ColetaListHeader",
  components: {},
  data: () => ({
    searchIcon: "mdi-magnify",
    setIsLoadingSearch: false,
    urlParams: {
      page: 1,
      search: "",
    },
  }),
  computed: {
    ...mapGetters("remessas", ["getTransportadoraData"]),
  },
  methods: {
    ...mapActions("coleta", ["coleta", "setIsLoading"]),

    async search() {
      this.searchIcon = "mdi-loading mdi-spin";
      this.setIsLoadingSearch = true;
      this.urlParams.remessa = this.$route.params.remessaId;
      await this.coleta(this.urlParams);
      this.setIsLoadingSearch = false;
      this.searchIcon = "mdi-magnify";
    },

    backToRemessas() {
      this.$router.push({
        name: "Remessas",
        params: {
          cnpj: this.$route.params.cnpj,
          nomeFantasia: this.$route.params.nomeFantasia,
        },
      });
    },
  },
};
</script>
<style scoped >
::v-deep
  .theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  color: white;
}
</style>
