<template>
  <default-modal
    v-model="isModalOpen"
    width="800"
  >
    <span
      ref="header"
      v-intersect="onIntersectingHeader"
    ></span>
    <v-tabs
      centered
      v-model="activeTab"
    >
      <v-tab
        ><span
          >Detalhes da Coleta
          <span class="font-weight-bold text-body-1">
            {{ pickupId }}</span
          ></span
        ></v-tab
      >
      <v-tab :disabled="!hasEditHistory">{{
        hasEditHistory ? "Histórico de Edição" : "Sem Histórico de Edição"
      }}</v-tab>
      <v-tabs-items v-model="activeTab">
        <template v-if="!getIsLoadingPickupDetails">
          <template v-if="getCollectRequests.length > 0">
            <v-tab-item>
              <pickup-details :pickup-details="getCollectRequests" />
            </v-tab-item>
            <v-tab-item>
              <edit-history :pickup-details="getCollectRequests" />
            </v-tab-item>
          </template>
        </template>
        <v-col
          class="ma-5"
          v-else
        >
          <default-spinner />
        </v-col>
      </v-tabs-items>
    </v-tabs>
    <div
      v-if="activeTab === 0"
      justify="center"
      style="position: sticky; bottom: 0; overflow: hidden !important"
      class="base"
    >
      <v-divider />
      <v-row
        class="py-3"
        justify="center"
      >
        <v-col cols="5">
          <v-menu
            offset-y
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <default-button
                v-bind="attrs"
                v-on="on"
                :loading="isLoadingButton"
                :disabled="!isPickupFinished"
                >Baixar Minuta de Faturamento</default-button
              >
            </template>
            <v-list>
              <v-list-item
                link
                @click="generateReport('pdf')"
              >
                <v-list-item-title>PDF</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                @click="generateReport('xlsx')"
              >
                <v-list-item-title>XLSX</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <span
          v-show="!isHeaderVisible"
          style="position: absolute !important; bottom: 12px; right: 12px"
          @click="$refs.header.scrollIntoView()"
        >
          <back-to-top />
        </span>
      </v-row>
    </div>
  </default-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BackToTop from "../../defaultComponents/BackToTop.vue";
import DefaultButton from "../../defaultComponents/DefaultButton.vue";
import DefaultModal from "../../defaultComponents/DefaultModal.vue";
import DefaultSpinner from "../../defaultComponents/DefaultSpinner.vue";
import EditHistory from "./EditHistory.vue";
import PickupDetails from "./PickupDetails.vue";

export default {
  name: "PickupInfo",
  components: {
    BackToTop,
    DefaultButton,
    DefaultModal,
    DefaultSpinner,
    EditHistory,
    PickupDetails,
  },
  data: () => ({
    isLoadingButton: false,
    isHeaderVisible: true,
    activeTab: 0,
  }),
  props: {
    pickupId: { required: true, type: Number },
    pickupStatus: { required: true, type: String },
    value: {},
  },
  computed: {
    ...mapGetters("expedition", [
      "getCollectRequests",
      "getIsLoadingPickupDetails",
    ]),
    isPickupFinished() {
      return this.pickupStatus === "COLETA_FINALIZADA";
    },
    hasEditHistory() {
      return this.getCollectRequests[0]?.historicoEdicao?.length > 0;
    },
    isModalOpen: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    ...mapActions("expedition", [
      "collectRequests",
      "generateManifestRelatorio",
      "generateReport",
    ]),
    async generateReport(fileFormat) {
      this.isLoadingButton = true;
      await this.$store.dispatch("expedition/generateReport", {
        body: { coletasId: [this.pickupId] },
        fileFormat,
        fileName: "Minuta de faturamento da coleta " + this.pickupId,
      });
      this.isLoadingButton = false;
    },
    onIntersectingHeader(entries) {
      if (entries.length > 1) {
        return;
      }
      this.isHeaderVisible = entries[0].isIntersecting;
    },
  },
};
</script>

<style scoped></style>
