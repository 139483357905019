import { HttpRestService } from "@/services/http/HttpRestService";

export class UsersRepository {
  static requestUsers(url) {
    return HttpRestService.get(url);
  }
  static listingUsers() {
    return HttpRestService.get(`/users`);
  }
  static createUser(modelCreateUser) {
    return HttpRestService.post(`/users/requisicao/cadastro`, modelCreateUser);
  }
  static createTransportadora(modelCreateTransportadora) {
    return HttpRestService.post(
      `/transportadora/requisicao/cadastro`,
      modelCreateTransportadora
    );
  }
  static updateUser(modelUpdateUser) {
    return HttpRestService.put(`/users`, modelUpdateUser);
  }
  static enableUser(userId) {
    return HttpRestService.patch(`/users/${userId}/enable`);
  }
  static disableUser(userId) {
    return HttpRestService.patch(`/users/${userId}/disable`);
  }
  static listPerfis() {
    return HttpRestService.get(`/perfil/listing`);
  }

  static transportadoraEmails(modelTransportadoraEmails) {
    return HttpRestService.get(
      `/transportadora/email/nao-cadastrado/listar?transportadora=${modelTransportadoraEmails}`
    );
  }
}
