export default {
  setListingPrevisaoPatio(state, response) {
    state.listingPrevisaoPatio = response;
  },
  setFilteredPrevisaoPatio(state, response) {
    state.filteredPrevisaoPatio = response;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setUrlParams(state, urlParams) {
    state.urlParams.page = urlParams.page;
    state.urlParams.search = urlParams.search;
  },
};
