export default {
  listingExpedition: [],
  expeditionPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  collectionRequest: [],
  isLoadingPickupDetails: false,
  forklifts: [],
  docks: {},
  drivers: {},
  isLoading: false,
  protocolDetails: [],
  coletaDetalhes: {},
  isLoadingBiparVolume: false,
  isLoadingReload: false,
  urlParams: {
    page: 1,
    search: "",
    codigo: "",
    currentDay: "",
    initialDate: "",
    finalDate: "",
  },
  volumesComErros: [],
  modalVolumesComErros: false,
  dataExpeditionCurrentDay: "",
  dataExpeditionInicial: "",
  dataExpeditionFinal: "",
  dataFaturamentoInicial: "",
  dataFaturamentoFinal: "",
  listExpeditionUserList: [],
  listExpeditionUserListByColetaId: [],
  listingManifest: [],
  checkedList: [],
  protocolDataList: [],
  pickupOrdersUrlParams: {
    page: 1,
    perPage: 10,
    numeroPedido: "",
    pendente: 0,
    embarcado: 1,
    emAndamento: 0,
  },
  pickupOrders: [],
  pickupOrdersPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  isLoadingPickupOrders: false,
  isPickupOrdersModalOpen: false,
  detailsPedido: {},
};
