export default {
  setPalletsPagination(state, response) {
    state.palletsPagination.total = response.total;
    state.palletsPagination.perPage = response.perPage;
    state.palletsPagination.currentPage = response.currentPage;
    state.palletsPagination.lastPage = response.lastPage;
    state.palletsPagination.nextPage = response.nextPage;
  },
  setUrlParamsPallet(state, urlParams) {
    state.urlParams.page = urlParams.page;
    state.urlParams.search = urlParams.search;
    state.urlParams.painelId = urlParams.painelId;
    state.urlParams.status = urlParams.status;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setListingPallets(state, response) {
    state.listingPallets = response;
  },
};
