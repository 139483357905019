<template>
  <v-text-field
    v-on="{ ...$listeners }"
    v-bind="{ ...$attrs }"
    outlined
    dense
    :label="label"
    :clearable="clearable"
    flat
    :hide-details="hideDetails"
    :title="isPassword ? '' : value"
    :prepend-inner-icon="prependInnerIcon"
    background-color="base"
    :rules="rules"
    :type="type"
    v-model="inputModel"
  />
</template>

<script>
export default {
  name: "DefaultTextField",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: { type: String },
    prependInnerIcon: {
      type: String,
      default: "",
    },
    rules: {},
    hideDetails: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "text",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    inputModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue ?? "");
      },
    },
    isPassword() {
      return this.type === "password";
    },
  },
};
</script>

<style scoped></style>
