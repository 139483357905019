export default {
  setListingConfPatio(state, response) {
    state.listingConfPatio = response;
  },
  setFilteredConfPatio(state, response) {
    state.filteredConfPatio = response;
  },

  setConfPatioPagination(state, response) {
    state.confPatioPagination.total = response.total;
    state.confPatioPagination.perPage = response.perPage;
    state.confPatioPagination.currentPage = response.currentPage;
    state.confPatioPagination.lastPage = response.lastPage;
    state.confPatioPagination.nextPage = response.nextPage;
  },

  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setUrlParams(state, urlParams) {
    state.urlParams.page = urlParams.page;
    state.urlParams.search = urlParams.search;
  },
};
