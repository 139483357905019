<template>
  <v-container fluid>
    <the-header />
    <the-listing />
  </v-container>
</template>

<script>
import TheHeader from "../../components/defaultUserComponents/bahiaBranch/TheHeader.vue";
import TheListing from "../../components/defaultUserComponents/bahiaBranch/TheListing.vue";

export default {
  name: "BahiaBranch",
  components: {
    TheHeader,
    TheListing,
  },
};
</script>

<style scoped></style>
