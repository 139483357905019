export default {
  getLogin(state) {
    return state.isLogged;
  },

  getToken(state) {
    return state.token;
  },

  getUserName(state) {
    return state.userData.userName;
  },

  getUserPermission: (state) => state.userData.roleName,

  getUserUuid(state) {
    return state.userData.uuid;
  },
  getRoleName(state) {
    return state.userData.roleName;
  },
  //TODO: remover abaixo
  // getRoleUuid(state) {
  //   return state.meRole.uuid;
  // },

  getMyPermissions(state) {
    return state.myPermissions;
  },
  //TODO: remover abaixo
  // getAdministrator(state) {
  //   return state.isAdministrator;
  // },

  getTransportadoraData(state) {
    return state.transportadoraData;
  },

  getTransportadoraUuid(state) {
    return state.transportadoraData.idTransportadora;
  },

  getTransportadoraName(state) {
    return state.transportadoraData.nomeFantasia;
  },

  getSlug(state) {
    return state.userData.roleSlug;
  },
};
