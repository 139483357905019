export default{
    setListingProtocols(state, response){
        state.listingProtocols = response
    },
    setProtocolsPagination(state, response){
        state.protocolsPagination.total = response.total;
        state.protocolsPagination.perPage = response.perPage;
        state.protocolsPagination.currentPage = response.currentPage;
        state.protocolsPagination.lastPage = response.lastPage;
        state.protocolsPagination.nextPage = response.nextPage;
    },
    setIsLoadingButton(state, response){
        state.IsLoadingButton = response
    },
    setIsLoadingCreate(state,response){
        state.isLoadingCreate = response
    },
    addCheckedList(state, response){
        state.checkedList.push(response) ;
    },
    cleanCheckedList(state){
        state.checkedList = [] ;
    },
    removeCheckedList(state, response){
        const index = state.checkedList.indexOf(response);
        if (index > -1) {
            state.checkedList.splice(index, 1);
        }
    }
}