var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"volumes"},[_c('v-card',{ref:"volumes",staticClass:"background mt-12",class:_vm.volumes.length === 0 ? 'd-none' : '',attrs:{"elevation":"0"}},[(!_vm.isLoading)?_c('div',[_c('div',[_c('h3',{staticClass:"text--text font-weight-bold text-uppercase"},[_vm._v("Volumes")])]),_vm._l((_vm.lotesSeparados),function(lote,i){return _c('div',{key:i,staticClass:"base_dark pa-2 my-3 elevation-2 rounded-lg"},[_c('v-card-title',[_c('span',[_vm._v(" Lote "+_vm._s(lote.cargaId)+" - Pedido - "+_vm._s(lote.volumes[0].numeroPedido)+" "),(lote.cargaStatus === 'EMBARCADA')?_c('v-chip',{attrs:{"color":"green","text-color":"white"}},[_vm._v(" "+_vm._s(lote.cargaStatus))]):_c('v-chip',{attrs:{"color":"red","text-color":"white"}},[_vm._v(" PENDENTE ")])],1)]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","hide-default-footer":"","headers":_vm.headers,"items":lote.volumes,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.embarcado",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticStyle:{"font-size":"30px !important"},attrs:{"color":item.embarcado ? 'green' : 'red'}},[_vm._v(_vm._s(_vm._f("filterEmbarque")(item.embarcado))+" ")])]}},{key:"item.bipagem",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":item.embarcado,"loading":_vm.isLoadingBiparVolume,"value":item.codigoCarga},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.biparVolumeInputLote($event)}}})]}},{key:"item.detalhes",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center mr-4"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(item.embarcado),expression:"item.embarcado"}],staticClass:"text-truncate",class:hover ? 'base--text' : 'navy--text',staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"fab":"","small":"","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.openDetalhesVolumeDialog(item)}}},[_c('v-icon',[_vm._v(" mdi-text-box-search ")])],1)]}}],null,true)})],1)]}}],null,true)})],1)],1)}),_vm._l((_vm.volumesSeparados),function(volume,index){return _c('div',{key:index,staticClass:"base_dark pa-2 my-3 elevation-2 rounded-lg"},[_c('v-card-title',[_c('span',[_vm._v(" Carga "+_vm._s(volume.cargaId)+" - Pedido - "+_vm._s(volume.volumes[0].numeroPedido)+" "),(volume.cargaStatus === 'EMBARCADA')?_c('v-chip',{attrs:{"color":"green","text-color":"white"}},[_vm._v(" "+_vm._s(volume.cargaStatus))]):_c('v-chip',{attrs:{"color":"red","text-color":"white"}},[_vm._v(" PENDENTE ")])],1)]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","hide-default-footer":"","headers":_vm.headers,"items":volume.volumes,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.embarcado",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticStyle:{"font-size":"30px !important"},attrs:{"color":item.embarcado ? 'green' : 'red'}},[_vm._v(_vm._s(_vm._f("filterEmbarque")(item.embarcado))+" ")])]}},{key:"item.bipagem",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":item.embarcado,"loading":_vm.isLoadingBiparVolume,"value":item.codigoBarras},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.biparVolumeInput($event)}}})]}},{key:"item.detalhes",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center mr-4"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(item.embarcado),expression:"item.embarcado"}],staticClass:"text-truncate",class:hover ? 'base--text' : 'navy--text',staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"fab":"","small":"","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.openDetalhesVolumeDialog(item)}}},[_c('v-icon',[_vm._v(" mdi-text-box-search ")])],1)]}}],null,true)})],1)]}}],null,true)})],1)],1)})],2):_c('div',[_c('div',{staticClass:"my-3"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"54"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }