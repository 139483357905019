<template>
  <default-table-body-row>
    <v-col cols="12" sm="6" md="2">
      <span class="d-md-none font-weight-bold">Redespacho</span>
      <span class="d-block mb-n2">{{
        pickup.dataInicioEmbarque | formatDate
      }}</span>
      <span class="d-block mb-n2 text-overline font-weight-light">{{
        pickup.dataInicioEmbarque | formatHour
      }}</span>
    </v-col>
    <v-col cols="12" sm="6" md="1">
      <span class="d-md-none font-weight-bold">Coleta</span>
      <span class="d-block mb-n2 primary--text font-weight-bold">{{
        pickup.coletaCodigo
      }}</span>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <span class="d-md-none font-weight-bold">Transportadora</span>
      <span class="d-block mb-n2">{{ pickup.nomeFantasia }}</span>
    </v-col>
    <v-col cols="12" sm="6" md="1">
      <span class="d-md-none font-weight-bold">Veículo</span>
      <span class="d-block mb-n2">{{ pickup.descricao }}</span>
    </v-col>
    <v-col cols="12" sm="6" md="1">
      <span class="d-md-none font-weight-bold">Pedidos</span>
      <span class="d-block mb-n2">{{ pickup.qntPedidos }}</span>
    </v-col>
    <v-col cols="12" sm="6" md="1">
      <span class="d-md-none font-weight-bold">Volumes</span>
      <span class="d-block mb-n2">{{ pickup.qntVolumes }}</span>
    </v-col>
    <!-- <v-col
      cols="12"
      sm="6"
      md="1"
    >
      <v-speed-dial
        transition="scale-transition"
        direction="left"
      >
        <template v-slot:activator>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  fab
                  rounded
                  small
                  elevation="3"
                  color="btnCorColeta"
                  class="text-truncate"
                  :class="hover ? 'navy--text' : 'navy--text'"
                  style="text-transform: none !important; font-weight: bolder"
                >
                  <v-icon v-if="true">mdi-plus </v-icon>
                  <v-icon v-else> mdi-close</v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>Ver Ações</span>
          </v-tooltip>
        </template>
      </v-speed-dial>
    </v-col> -->
    <v-col cols="12" sm="6" md="3">
      <default-button
        :disabled="isButtonDisabled"
        :color="buttonColor"
        @click="$emit('openStartShippingModal', pickup)"
      >
        <span>{{ buttonLabel }}</span>
      </default-button>
    </v-col>
  </default-table-body-row>
</template>

<script>
import { mapActions } from "vuex";
import DefaultButton from "../../defaultComponents/DefaultButton.vue";
import DefaultTableBodyRow from "../../defaultComponents/DefaultTableBodyRow.vue";

export default {
  name: "ListingRow",
  components: {
    DefaultButton,
    DefaultTableBodyRow,
  },
  props: {
    pickup: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    ...mapActions("bahiaBranch", ["setIsStartShippingModalOpen"]),
  },
  computed: {
    isButtonDisabled() {
      switch (this.pickup.status) {
        case "AGUARDANDO_TRANSPORTADORA":
        case "CONFIRMACAO_TRANSPORTADORA":
        case "COLETA_INICIADA":
        case "COLETA_PAUSADA":
          return this.isPickupBlocked;
        default:
          return true;
      }
    },
    buttonLabel() {
      const pickupStatuses = {
        AGUARDANDO_TRANSPORTADORA: "Iniciar Embarque",
        CONFIRMACAO_TRANSPORTADORA: "Iniciar Embarque",
        COLETA_INICIADA: "Embarq. Iniciado",
        COLETA_PAUSADA: "Continuar Embq.",
        COLETA_FINALIZADA: "Embarq. Finalizado",
        COLETA_CANCELADA: "Coleta Cancelada",
      };
      return this.isPickupBlocked
        ? "Coleta Bloqueada"
        : pickupStatuses[this.pickup.status] ?? this.pickup.status;
    },
    buttonColor() {
      switch (this.pickup.status) {
        case "COLETA_INICIADA":
        case "COLETA_PAUSADA":
          return "btnCorColeta text--text";
        default:
          return "";
      }
    },
    isPickupBlocked() {
      return this.pickup.bloqueioStatus === "BLOQUEADO";
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  },
};
</script>
