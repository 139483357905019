<template>
  <v-form ref="form">
    <v-row
      @keyup.enter="filter"
      dense
      class="mb-3"
    >
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <default-text-field
          label="Coleta"
          v-model="pickup"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <default-text-field
          label="Transportadora"
          v-model="carrier"
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="2"
      >
        <default-date-picker
          label="Data Inicial"
          :picker-date.sync="startDate"
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="2"
      >
        <default-date-picker
          label="Data Final"
          :picker-date.sync="endDate"
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="2"
      >
        <default-button @click="filter">Filtrar</default-button>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultButton from "../../defaultComponents/DefaultButton.vue";
import DefaultDatePicker from "../../defaultComponents/DefaultDatePicker.vue";
import DefaultTextField from "../../defaultComponents/DefaultTextField.vue";

export default {
  name: "TheHeader",
  components: {
    DefaultButton,
    DefaultDatePicker,
    DefaultTextField,
  },
  computed: {
    ...mapGetters("bahiaBranch", ["getUrlParams"]),
    pickup: {
      get() {
        return this.getUrlParams.codigo;
      },
      set(newValue) {
        this.setUrlParams({ ...this.getUrlParams, codigo: newValue });
      },
    },
    carrier: {
      get() {
        return this.getUrlParams.search;
      },
      set(newValue) {
        this.setUrlParams({
          ...this.getUrlParams,
          search: newValue,
        });
      },
    },
    startDate: {
      get() {
        return this.getUrlParams.initialDate;
      },
      set(newValue) {
        this.setUrlParams({ ...this.getUrlParams, initialDate: newValue });
      },
    },
    endDate: {
      get() {
        return this.getUrlParams.finalDate;
      },
      set(newValue) {
        this.setUrlParams({ ...this.getUrlParams, finalDate: newValue });
      },
    },
  },
  methods: {
    ...mapActions("bahiaBranch", [
      "pickups",
      "setUrlParams",
      "setCurrentPageToFirstPage",
    ]),
    filter() {
      this.setCurrentPageToFirstPage();
      this.pickups();
    },
  },
};
</script>

<style scoped></style>
