import { HttpRestService } from "@/services/http/HttpRestService";
import { HttpFileRequestService } from "@/services/http/HttpFileRequestService";

export class AgendamentoPedidosRepository {
  static requestAgendamentoPedidos(urlParams) {
    return HttpRestService.post(`/agendamento/listar`, urlParams);
  }

  static requestAgendarEntrega(payload) {
    return HttpRestService.post(
      `/pedido/agendamento/intelipost/agendamento-manual`,
      payload
    );
  }

  static generateOrdersReport(body, fileName, fileFormat) {
    return HttpFileRequestService.build()
      .setMethod("POST")
      .setUrl(`/agendamento/relatorio`, body)
      .setBody(body)
      .request(fileFormat, fileName);
  }
}
