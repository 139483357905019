export default {
  receptionData: [],
  receptionList: [],
  receptionDetails: {},
  isLoading: false,
  isLoadingButton: false,
  dataInicial: "",
  urlParams: {
    page: 1,
    transportadora: "",
    placa: "",
    coleta:""
  },
  receptionPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
};
