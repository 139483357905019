import { NewUserRegistration } from "../../../repositories/defaultUserRepositories/NewUserRegistration";
import router from "@/router";

export default {
  async tokenPass({ commit }, token) {
    const response = await NewUserRegistration.tokenPass(token);

    if (response.status >= 200 && response.status < 300) {
      commit("setTokenPass", response.data.data.email);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async registerUserSou({ commit }, modelCreateUser) {
    const response = await NewUserRegistration.registerUserSou(modelCreateUser);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Usuário cadastrado com sucesso!",
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      router.push("/");
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },
};
