<template>
  <div>
    <v-container class="mt-12">
      <div>
        <v-row no-gutters class="justify-center">
          <v-col cols="6" align="center">
            <v-img
              src="../../assets/LOGO-Sou Out.svg"
              max-width="300px"
            ></v-img>
          </v-col>
        </v-row>
      </div>
      <v-form>
        <div>
          <v-row no-gutters class="justify-center mt-2 mb-1">
            <v-col cols="6" align="start">
              <div class="navy--text font-weight-bold">Nome</div>
            </v-col>
          </v-row>
          <v-row no-gutters class="justify-center">
            <v-col cols="6">
              <v-text-field
                label="Nome"
                placeholder="Nome"
                :rules="[confirm.name]"
                solo
                flat
                v-model="name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="justify-center mt-2 mb-1">
            <v-col cols="6" align="start">
              <div class="navy--text font-weight-bold">
                Login/Transportadora
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="justify-center">
            <v-col cols="6">
              <v-text-field
                label="Login/Transportadora"
                placeholder="Login/Transportadora"
                solo
                flat
                readonly
                v-model="login"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="justify-center mb-1">
            <v-col cols="4" sm="3" md="3" class="px-1" align="start">
              <div class="navy--text font-weight-bold">Senha</div>
            </v-col>
            <v-col cols="4" sm="3" md="3" class="px-1" align="start">
              <div class="navy--text font-weight-bold">Confirmar Senha</div>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row no-gutters class="justify-center">
            <v-col cols="4" sm="3" md="3" class="px-1">
              <v-text-field
                label="Senha"
                placeholder="Senha"
                :type="show4 ? 'text' : 'password'"
                :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show4 = !show4"
                solo
                flat
                v-model="key"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="3" md="3" class="px-1">
              <v-text-field
                label="Confirmar Senha"
                placeholder="Confirmar Senha"
                :type="show3 ? 'text' : 'password'"
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="rules"
                @click:append="show3 = !show3"
                solo
                flat
                v-model="confirmKey"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row no-gutters class="justify-center mt-6 mb-6">
            <v-col cols="4">
              <v-hover v-slot="{ hover }">
                <v-btn
                  rounded
                  :color="hover ? 'secondary' : 'primary'"
                  x-large
                  :loading="isLoading"
                  :disabled="disabledField()"
                  block
                  class="m-texto"
                  style="text-transform: none !important; font-weight: bolder"
                  max-width="220px"
                  @click="validate"
                  >Cadastrar
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
        </div>
      </v-form>
      <div>
        <v-row no-gutters class="justify-center">
          <v-col cols="6" align="center">
            <v-img
              src="../../assets/Logo Sou Energy - Horizontal.svg"
              max-width="120px"
            ></v-img>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    isLoading: false,
    name: "",
    key: "",
    confirmKey: "",
    login: "",
    show4: false,
    show3: false,

    confirm: {
      name: (value) => !!value || "O campo Nome não pode ser vazio.",
    },
  }),

  computed: {
    ...mapGetters("requisitionForCarrier", [
      "getTokenValidation",
      "getRegisterUser",
    ]),
    rules() {
      const rules = [];

      if (this.key != this.confirmKey) {
        const rule = (v) =>
          (!!v && v) === this.key || "As senhas deverar ser indenticas";

        rules.push(rule);
      }

      return rules;
    },
  },

  methods: {
    ...mapActions("requisitionForCarrier", ["tokenValidation", "registerUser"]),
    validate() {
      if (this.name !== "" && this.key !== "" && this.confirmKey !== "")
        return this.onGetLoginData();
      else {
        this.$refs.form.validate();
      }
    },
    async onGetLoginData() {
      this.isLoading = true;
      const modelCreateUser = {
        name: this.name,
        password: this.key,
        password_confirmation: this.confirmKey,
        token: this.$route.params.token,
      };
      await this.registerUser(modelCreateUser);
      this.isLoading = false;
    },

    disabledField() {
      if (
        this.name !== "" &&
        this.key !== "" &&
        this.confirmKey !== "" &&
        this.confirmKey == this.key
      ) {
        return false;
      } else {
        return true;
      }
    },
    async receiveToken() {
      const token = this.$route.params.token;
      await this.tokenValidation(token);
      this.returnEmail();
    },
    returnEmail() {
      this.login = this.getTokenValidation;
    },
  },
  mounted() {
    this.receiveToken();
  },
};
</script>

<style scoped>
.larguraTotal {
  max-width: 50%;
  justify-content: center;
}
.m-texto {
  color: #002233 !important;
}
.m-texto:hover {
  transition: 300ms all ease-in-out;
  color: #ffffff !important;
  font-weight: bold;
}
.drectd {
  padding: 10%;
}
.drect {
  width: 37%;
}
</style>
