var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',_vm._b({attrs:{"top":"","disabled":_vm.disableTooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.isTransportadora
            ? hover
              ? 'primary--text'
              : 'base--text'
            : hover
            ? 'base--text'
            : 'navy--text',attrs:{"fab":"","elevation":"2","small":"","color":!_vm.color
            ? _vm.isTransportadora
              ? 'primaryTransportadora'
              : 'primary'
            : _vm.color}},'v-btn',Object.assign({}, attrs, _vm.$attrs),false),Object.assign({}, on, _vm.$listeners)),[_vm._t("default")],2)]}}],null,true)})]}}])},'v-tooltip',_vm.$attrs,false),[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])}
var staticRenderFns = []

export { render, staticRenderFns }