<template>
  <v-card class="overflow-hidden">
    <div class="justify-center mt-5">
      <v-row>
        <v-col class="text-wrap secondary--text font-weight-bold" align="center"
          >Remessa : {{ remessaId }}</v-col
        >
      </v-row>

      <span class="font-weight-bold navy--text"
        >Deseja confirmar divergencia?</span
      >
    </div>
    <div v-if="!isLoadingModal">
      <v-card-text>
        <v-container>
          <v-row>
            <v-row justify="start">
              <v-col align="start">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    @click="onConfirmDivergence"
                    :loading="isLoadingBtn"
                    :color="hover ? 'secondary' : 'primary'"
                    block
                    rounded
                    class="navy--text text-truncate"
                    style="text-transform: none !important; font-weight: bolder"
                    >Sim</v-btn
                  >
                </v-hover>
              </v-col>
              <v-col align="end">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    @click="$emit('closeDivergenceDialog')"
                    :color="hover ? 'secondary' : 'deep-orange'"
                    block
                    rounded
                    class="navy--text text-truncate"
                    style="text-transform: none !important; font-weight: bolder"
                    >Não</v-btn
                  >
                </v-hover>
              </v-col>
            </v-row>
          </v-row>
        </v-container>
      </v-card-text>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-col>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    isLoadingModal: true,
    isLoadingBtn: false,
    urlParams: {
      page: 1,
      remessa: 0,
    },
    coletas: [],
  }),
  props: {
    remessaId: { type: Number, required: true },
    remessaStatus: { type: String, required: true },
  },
  mounted() {
    this.listColetas();
    if (this.remessaStatus !== "QUANTIDADE_VEICULOS_DIVERGENTE") {
      this.detalhesDivergenciaHorario(this.remessaId);
    }
  },
  computed: {
    ...mapGetters("remessas", [
      "getColetaByRemessas",
      "getDetalhesDivergenciaHorario",
    ]),
  },
  watch: {
    getColetaByRemessas: {
      handler() {
        this.coletas = this.getColetaByRemessas.map((i) => i.coletaId);
      },
    },
  },
  methods: {
    ...mapActions("remessas", [
      "coletaByRemessa",
      "confirmDivergence",
      "confirmDivergenceHorario",
      "detalhesDivergenciaHorario",
    ]),

    async listColetas() {
      this.isLoadingModal = true;
      this.urlParams.remessa = this.remessaId;
      await this.coletaByRemessa(this.urlParams);
      this.isLoadingModal = false;
    },

    async onConfirmDivergence() {
      this.isLoadingBtn = true;
      const coletaByRemessasList = this.getColetaByRemessas.map(
        (c) => c.coletaId
      );

      if (this.remessaStatus === "DIVERGENCIA_HORARIO_DATA") {
        const coletasIdDivergenciaHora = this.getDetalhesDivergenciaHorario.map(
          (c) => c.coletaId
        );
        const coletasSemDuplicatas = coletasIdDivergenciaHora.filter(
          (item, index) => coletasIdDivergenciaHora.indexOf(item) === index
        );

        const coletasPertencentesRemessa = coletasSemDuplicatas.filter((item) =>
          coletaByRemessasList.includes(item)
        );

        const modelConfirmDivergence = {
          remessaId: this.remessaId,
          coletaId: coletasPertencentesRemessa,
        };
        await this.confirmDivergenceHorario(modelConfirmDivergence);
      } else {
        const modelConfirmDivergence = {
          remessaId: this.remessaId,
          coletas: coletaByRemessasList,
        };
        await this.confirmDivergence(modelConfirmDivergence);
      }
      this.$emit("closeDivergenceDialog");
      this.$emit("listItens");
      this.isLoadingBtn = false;
    },
  },
};
</script>
<style scoped></style>
