import { HttpRestService } from "@/services/http/HttpRestService";

export class PerfisRepository {
  static requestPerfis() {
    return HttpRestService.get(`/perfil/listing`);
  }
  static whiteList() {
    return HttpRestService.get(`/permissoes/listar`);
  }
  static whiteListEdit() {
    return HttpRestService.get(`/permissoes/perfil/listar`);
  }

  static createPerfil(modelCreatePerfil) {
    return HttpRestService.post(`/perfil/create`, modelCreatePerfil);
  }
  static updatePerfil(modelUpdatePerfil) {
    return HttpRestService.put(`/perfil/update`, modelUpdatePerfil);
  }
}
