export default {
  setOrders(state, payload) {
    state.orders = payload;
  },
  setIsLoadingOrders(state, payload) {
    state.isLoadingOrders = payload;
  },
  setPagination(state, payload) {
    state.pagination = payload;
  },
  setUrlParams(state, payload) {
    state.urlParams = payload;
  },
  setIsBlockingModalOpen(state, payload) {
    state.isBlockingModalOpen = payload;
  },
  setIsBlockingDetailsModalOpen(state, payload) {
    state.isBlockingDetailsModalOpen = payload;
  },
  setBlockingReasons(state, payload) {
    state.blockingReasons = payload;
  },
  setBlockingDetails(state, payload) {
    state.blockingDetails = payload;
  },
  setIsLoadingDetails(state, payload) {
    state.isLoadingDetails = payload;
  },
};
