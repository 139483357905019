<template>
  <div ref="volumes">
    <v-card
      class="background mt-12"
      :class="volumes.length === 0 ? 'd-none' : ''"
      elevation="0"
      ref="volumes"
    >
      <div v-if="!isLoading">
        <div>
          <h3 class="text--text font-weight-bold text-uppercase">Volumes</h3>
        </div>
        <div
          v-for="(lote, i) in lotesSeparados"
          :key="i"
          class="base_dark pa-2 my-3 elevation-2 rounded-lg"
        >
          <v-card-title>
            <span>
              Lote {{ lote.cargaId }} - Pedido -
              {{ lote.volumes[0].numeroPedido }}
              <v-chip
                v-if="lote.cargaStatus === 'EMBARCADA'"
                color="green"
                text-color="white"
              >
                {{ lote.cargaStatus }}</v-chip
              >
              <v-chip v-else color="red" text-color="white">
                PENDENTE
              </v-chip></span
            >
          </v-card-title>
          <v-card-text>
            <v-data-table
              dense
              hide-default-footer
              :headers="headers"
              :items="lote.volumes"
              disable-pagination
            >
              <template v-slot:[`item.embarcado`]="{ item }">
                <v-icon
                  style="font-size: 30px !important"
                  :color="item.embarcado ? 'green' : 'red'"
                  >{{ item.embarcado | filterEmbarque }}
                </v-icon>
              </template>
              <template v-slot:[`item.bipagem`]="{ item }">
                <v-text-field
                  :disabled="item.embarcado"
                  @keyup.enter="biparVolumeInputLote($event)"
                  :loading="isLoadingBiparVolume"
                  :value="item.codigoCarga"
                />
              </template>
              <template v-slot:[`item.detalhes`]="{ item }">
                <div class="text-center mr-4">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      fab
                      small
                      elevation="0"
                      color="primary"
                      class="text-truncate"
                      :class="hover ? 'base--text' : 'navy--text'"
                      style="
                        text-transform: none !important;
                        font-weight: bolder;
                      "
                      v-show="item.embarcado"
                      @click="openDetalhesVolumeDialog(item)"
                    >
                      <v-icon> mdi-text-box-search </v-icon>
                    </v-btn>
                  </v-hover>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </div>
        <div
          v-for="(volume, index) in volumesSeparados"
          :key="index"
          class="base_dark pa-2 my-3 elevation-2 rounded-lg"
        >
          <v-card-title>
            <span>
              Carga {{ volume.cargaId }} - Pedido -
              {{ volume.volumes[0].numeroPedido }}
              <v-chip
                v-if="volume.cargaStatus === 'EMBARCADA'"
                color="green"
                text-color="white"
              >
                {{ volume.cargaStatus }}</v-chip
              >
              <v-chip v-else color="red" text-color="white">
                PENDENTE
              </v-chip></span
            >
          </v-card-title>
          <v-card-text>
            <v-data-table
              dense
              hide-default-footer
              :headers="headers"
              :items="volume.volumes"
              disable-pagination
            >
              <template v-slot:[`item.embarcado`]="{ item }">
                <v-icon
                  style="font-size: 30px !important"
                  :color="item.embarcado ? 'green' : 'red'"
                  >{{ item.embarcado | filterEmbarque }}
                </v-icon>
              </template>
              <template v-slot:[`item.bipagem`]="{ item }">
                <v-text-field
                  :disabled="item.embarcado"
                  @keyup.enter="biparVolumeInput($event)"
                  :loading="isLoadingBiparVolume"
                  :value="item.codigoBarras"
                />
              </template>
              <template v-slot:[`item.detalhes`]="{ item }">
                <div class="text-center mr-4">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      fab
                      small
                      elevation="0"
                      color="primary"
                      class="text-truncate"
                      :class="hover ? 'base--text' : 'navy--text'"
                      style="
                        text-transform: none !important;
                        font-weight: bolder;
                      "
                      v-show="item.embarcado"
                      @click="openDetalhesVolumeDialog(item)"
                    >
                      <v-icon> mdi-text-box-search </v-icon>
                    </v-btn>
                  </v-hover>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </div>
      </div>
      <div v-else>
        <div class="my-3">
          <v-progress-circular
            color="primary"
            indeterminate
            size="54"
          ></v-progress-circular>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "EmbarqueColetaTabelaVolumes",
  data: () => ({
    headers: [
      { text: "Pedido", value: "numeroPedido" },
      { text: "Embarcado", value: "embarcado" },
      { text: "Codigo de Barras", value: "bipagem", sortable: false },
      { text: "", value: "detalhes", sortable: false },
    ],
    volumesSeparados: [],
    lotesSeparados: [],
    isLoading: false,
  }),
  props: {
    loadingReloadColetaDetalhes: { type: Boolean, required: true },
    volumes: {
      type: Array,
      required: true,
      validator: (volumes) => {
        return volumes.every((v) => {
          return (
            1 &&
            v.volumeId !== undefined &&
            v.pedidoId !== undefined &&
            v.codigoBarras !== undefined &&
            v.habilitaEditarDetalhes !== undefined &&
            v.permiteBipagem !== undefined &&
            v.numeroPedido !== undefined &&
            v.embarcado !== undefined &&
            v.cargaId !== undefined &&
            v.cargaStatus !== undefined &&
            v.codigoCarga !== undefined
          );
        });
      },
    },
  },
  computed: {
    ...mapState("expedition", ["isLoadingBiparVolume"]),
  },
  methods: {
    ...mapActions("expedition", [
      "biparVolume",
      "biparVolumeLote",
      "volumesComErros",
    ]),
    openDetalhesVolumeDialog(volume) {
      this.$emit("open-volume-detalhes-dialog", volume);
    },
    async biparVolumeInput(event) {
      const serial = event.target.value;
      await this.biparVolume({
        coletaId: this.$route.params.coletaId,
        volume: serial,
      }).then(() => {
        this.$emit("atualizar-lista");
      });
    },
    async biparVolumeInputLote(event) {
      const serial = event.target.value;
      await this.biparVolumeLote({
        coletaId: this.$route.params.coletaId,
        codigo: serial,
      }).then(() => {
        this.$emit("atualizar-lista");
      });
    },
    async separeteVolumesPerCarga(volumes) {
      const cargas = new Map();
      const lotes = new Map();
      for (const volume of volumes) {
        const { tipoPedido, cargaId, cargaStatus } = volume;
        if (tipoPedido === "lote-fixador") {
          // if (lotes.has(cargaId)) {
          // lotes.get(cargaId).volumes.push(volume);
          // } else {
          lotes.set(cargaId, {
            cargaId,
            cargaStatus,
            volumes: [volume],
          });
          // }
        } else {
          if (cargas.has(cargaId)) {
            cargas.get(cargaId).volumes.push(volume);
          } else {
            cargas.set(cargaId, {
              cargaId,
              cargaStatus,
              volumes: [volume],
            });
          }
        }
      }

      this.lotesSeparados = Array.from(lotes.values());
      this.volumesSeparados = Array.from(cargas.values());
      return;
    },
    clearLists() {
      this.volumesSeparados = [];
      this.lotesSeparados = [];
      return Promise.resolve();
    },
  },
  filters: {
    filterEmbarque(value) {
      return value ? "mdi-check" : "mdi-close";
    },
  },
  mounted() {
    this.clearLists().then(() => {
      this.separeteVolumesPerCarga(this.volumes);
    });
    this.$emit("emitRef", this.$refs.volumes);
  },
  watch: {
    volumes: {
      handler: function (volumes) {
        this.separeteVolumesPerCarga(volumes);
      },
      deep: true,
    },
  },
};
</script>

<style>
.theme--light.v-data-table {
  background-color: #dadfe0 !important;
}
.theme--dark.v-data-table {
  background-color: #2b2f31 !important;
}
</style>
