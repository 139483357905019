<template>
  <v-card class="">
    <v-card-title class="justify-center">
      <v-row>
        <v-col>
          <span class="text-h6 font-weight-bold navy--text"
            >Editar Despachante</span
          >
        </v-col>
        <v-col cols="1" align="center">
          <v-hover v-slot="{ hover }">
            <v-btn
              fab
              small
              elevation="2"
              @click="addFieldlistDespachante()"
              color="primary"
              class="text-truncate"
              :class="hover ? 'base--text' : 'navy--text'"
              style="text-transform: none !important; font-weight: bolder"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </v-hover>
        </v-col>
      </v-row>
    </v-card-title>
    <div v-if="!isLoadingModal">
      <v-card-text>
        <v-row class="list-header mb-2">
          <v-col cols="10" align="start" class="border-right">
            <span class="">Despachante</span>
          </v-col>
          <v-col cols="2" align="center" class="">
            <span class="">Edição </span>
          </v-col>
        </v-row>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          v-if="
            getListExpeditionUserListByColetaId.length || listDespachante.length
          "
        >
          <v-row
            class=""
            v-for="(despachante, i) in getListExpeditionUserListByColetaId"
            :key="i"
            align="center"
          >
            <v-col cols="10" class="">
              <v-autocomplete
                ref="emailRules"
                :items="getListExpeditionUserList.list"
                item-text="despachanteNome"
                item-value="despachantePcemprId"
                :v-model="userExpedition"
                disabled
                :rules="emailRules"
                hide-details=""
                outlined
                :label="despachante.despachanteNome"
                dense
                required
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-hover v-slot="{ hover }">
                <v-btn
                  fab
                  small
                  elevation="2"
                  color="primary"
                  @click="removeDespachanteColeta(despachante)"
                  class="text-truncate"
                  :class="hover ? 'base--text' : 'navy--text'"
                  style="text-transform: none !important; font-weight: bolder"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
          <v-row
            class=""
            v-for="(email, i) in listDespachante"
            :key="i"
            align="center"
          >
            <v-col cols="10" class="">
              <v-autocomplete
                ref="emailRules"
                :items="getListExpeditionUserList.list"
                item-text="userName"
                item-value="userId"
                v-model="userSelected[i]"
                :rules="emailRules"
                return-object
                hide-details=""
                outlined
                label="Despachante"
                dense
                required
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-hover v-slot="{ hover }">
                <v-btn
                  fab
                  small
                  elevation="2"
                  color="primary"
                  @click="removeDespachanteField(email)"
                  class="text-truncate"
                  :class="hover ? 'base--text' : 'navy--text'"
                  style="text-transform: none !important; font-weight: bolder"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
        </v-form>
        <div v-else>
          <v-col>
            <div class="py-4 font-weight-bold navy--text">
              <span> Não existe despachante para esta coleta. </span>
            </div>
          </v-col>
        </div>
        <v-row justify="center">
          <v-col cols="6">
            <v-hover v-slot="{ hover }">
              <v-btn
                @click="updateDespachanteDaColeta()"
                :loading="isLoadingBtn"
                :color="hover ? 'secondary' : 'primary'"
                block
                rounded
                class="navy--text text-truncate"
                style="text-transform: none !important; font-weight: bolder"
                >Salvar</v-btn
              >
            </v-hover>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="54"
      ></v-progress-circular>
    </v-col>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    valid: true,
    isLoadingModal: false,
    isLoadingBtn: false,
    userExpedition: [],
    userSelected: [],
    emailRules: [(v) => !!v || "Email Obrigatória"],
    listDespachante: [],
    bodyDespachante: {
      coletaId: 0,
      despachantes: [],
    },
  }),
  props: {
    coletaId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    ...mapActions("expedition", [
      "listExpeditionUserList",
      "listExpeditionUserListByColetaId",
      "updateDespaschantesDaColeta",
    ]),
    addFieldlistDespachante() {
      this.listDespachante.push({
        despachanteNome: "",
        despachantePcemprId: 0,
      });
    },
    removeDespachanteField(email) {
      this.listDespachante.splice(this.listDespachante.indexOf(email), 1);
    },
    removeDespachanteColeta(despachante) {
      this.getListExpeditionUserListByColetaId.splice(
        this.getListExpeditionUserListByColetaId.indexOf(despachante),
        1
      );
    },
    async updateDespachanteDaColeta() {
      this.isLoadingBtn = true;
      this.bodyDespachante = {
        coletaId: this.coletaId,
        despachantes: [],
      };
      for (
        let i = 0;
        i < this.getListExpeditionUserListByColetaId.length;
        i++
      ) {
        this.bodyDespachante.despachantes.push({
          pcemprId: 0,
          nome: "",
        });
        this.bodyDespachante.despachantes[i].pcemprId =
          this.getListExpeditionUserListByColetaId[i].despachantePcemprId;
        this.bodyDespachante.despachantes[i].nome =
          this.getListExpeditionUserListByColetaId[i].despachanteNome;
      }
      if (this.userSelected) {
        for (let i = 0; i < this.userSelected.length; i++) {
          this.userExpedition;
          this.bodyDespachante.despachantes.push({
            pcemprId: 0,
            nome: "",
          });
          this.bodyDespachante.despachantes[
            this.bodyDespachante.despachantes.length - 1
          ].pcemprId = this.userSelected[i].userId;
          this.bodyDespachante.despachantes[
            this.bodyDespachante.despachantes.length - 1
          ].nome = this.userSelected[i].userName;
        }
      }
      //Remove duplicados
      this.bodyDespachante.despachantes =
        this.bodyDespachante.despachantes.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.pcemprId === item.pcemprId &&
                t.nome === item.nome &&
                t.pcemprId !== 0 &&
                t.nome !== ""
            )
        );
      let response = await this.updateDespaschantesDaColeta(
        this.bodyDespachante
      );
      if (response.status >= 200 && response.status < 300) {
        this.$emit("listExpedition");
        this.$emit("closeExpeditionUser");
        this.isLoadingBtn = false;
      }
      this.isLoadingBtn = false;
    },
    insertUserDespachante(despachante) {
      return this.userExpedition.push(despachante);
    },
  },
  computed: {
    ...mapGetters("expedition", [
      "getListExpeditionUserList",
      "getListExpeditionUserListByColetaId",
    ]),
  },
  async mounted() {
    this.isLoadingModal = true;
    this.listExpeditionUserList();
    await this.listExpeditionUserListByColetaId(this.coletaId);
    this.isLoadingModal = false;
  },
  watch: {
    listDespachante: {
      handler(newVal) {
        this.listDespachante = newVal;
      },
      immediate: true,
    },
    userSelected: {
      handler(newVal) {
        this.userSelected = newVal;
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.h {
  font-weight: bolder;
}
.list-header {
  background-color: #0f2733;
  color: white;
  font-weight: bolder;
}
.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
.list-card {
  color: black;
  font-weight: normal;
}

.list-card:hover {
  background-color: #ffdfcc;
  color: black;
}
</style>