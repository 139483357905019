<template>
  <div>
    <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <v-row class="list-header mx-auto my-auto mt-3">
        <v-col cols="2" align="center" class="justify-center border-right">
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            Data Agendada
          </span>
        </v-col>
        <v-col cols="3" align="center" class="justify-center border-right">
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            Transportadora
          </span>
        </v-col>
        <v-col cols="2" align="center" class="justify-center border-right">
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            Placa
          </span>
        </v-col>
        <v-col cols="2" align="center" class="justify-center border-right">
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            Coleta
          </span>
        </v-col>
        <v-col cols="2" align="center" class="justify-center border-right">
          <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            Status
          </span>
        </v-col>
      </v-row>
    </div>
    <div v-if="!getIsLoading">
      <div align="center" v-for="(reception, i) in getReceptionList" :key="i">
        <v-hover v-slot="{ hover }">
          <v-row
            class="text--text mx-auto my-auto mt-2"
            :class="hover ? 'hoverGlobal' : 'base_dark'"
            align="center"
          >
            <v-col cols="4" sm="2" md="2" lg="2" xl="2" align="center">
              <span class="d-md-none d-lg-none d-xl-none">
                <b>Data:</b>
              </span>
              <div class="text-wrap text--text">
                {{ reception.horarioAgendado | formatDate }}
              </div>
              <div class="text-wrap text--text">
                {{ reception.horarioAgendado | formatHour }}
              </div>
            </v-col>
            <v-col cols="4" sm="2" md="3" lg="3" xl="3" align="center">
              <span class="d-md-none d-lg-none d-xl-none">
                <b>Transportadora:</b>
              </span>
              <div class="text-wrap secondary--text font-weight-bold">
                {{ reception.transportadoraNome }}
              </div>
            </v-col>
            <v-col cols="4" sm="2" md="2" lg="2" xl="2" align="center">
              <span class="d-md-none d-lg-none d-xl-none">
                <b>Placa:</b>
              </span>
              <div class="text-wrap text--text">{{ reception.placa }}</div>
            </v-col>

            <v-col cols="4" sm="2" md="2" lg="2" xl="2" align="center">
              <span class="d-md-none d-lg-none d-xl-none">
                <b>Coleta:</b>
              </span>
              <div class="text-wrap secondary--text font-weight-bold">
                {{ reception.numeroColeta }}
              </div>
            </v-col>
            <v-col cols="4" sm="3" md="2" lg="2" xl="2" align="center">
              <span class="d-md-none d-lg-none d-xl-none">
                <b>Status:</b>
              </span>
              <div
                class="text-wrap font-weight-bold"
                v-if="!reception.coletaAtrasada"
              >
                <v-chip
                  class="pa-2 text-truncate"
                  text-color="white"
                  :color="colorChip(reception.status)"
                >
                  {{ reception.status | filterStatus }}
                </v-chip>
              </div>
              <div class="text-wrap font-weight-bold" v-else>
                <v-chip
                  class="pa-2 text-truncate"
                  text-color="white"
                  color="red darken-1"
                >
                  Col.Atrasada
                </v-chip>
              </div>
            </v-col>
            <v-col cols="4" sm="1" md="1" lg="1" xl="1" align="center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      fab
                      small
                      elevation="3"
                      color="primary"
                      class="text-truncate"
                      :class="hover ? 'base--text' : 'navy--text'"
                      @click="
                        openReceptionDetailsDialog(
                          reception.numeroColeta,
                          reception
                        )
                      "
                      style="
                        text-transform: none !important;
                        font-weight: bolder;
                      "
                    >
                      <v-icon> mdi-text-box-search </v-icon>
                    </v-btn>
                  </v-hover>
                </template>
                <span>Detalhes da Coleta</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-hover>
      </div>
      <v-dialog
        v-model="receptionDetailsDialog"
        max-width="720"
        transition="dialog-bottom-transition"
      >
        <reception-details-dialog
          :key="reRenderReceptionDetailsDialog"
          :coletaId="selectedColetaId"
          :coleta="selectedColeta"
          @closeReceptionDetailsDialog="closeReceptionDetailsDialog"
        />
      </v-dialog>
      <v-row class="justify-center my-3">
        <v-pagination
          v-model="getReceptionPagination.currentPage"
          :length="getReceptionPagination.lastPage"
          @input="proxPage(getReceptionPagination.currentPage)"
          :total-visible="7"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        >
        </v-pagination>
      </v-row>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReceptionDetailsDialog from "./ReceptionDetailsDialog.vue";

export default {
  components: {
    ReceptionDetailsDialog,
  },
  data: () => ({
    timer: "",
    urlParams: {
      page: 1,
    },
    reRenderReceptionDetailsDialog: 0,
    selectedColetaId: 0,
    receptionDetailsDialog: false,
    selectedColeta: {},
  }),
  mounted() {
    this.listReception();
    this.timer = setInterval(this.listReception, 200000);
  },
  computed: {
    ...mapGetters("reception", [
      "getReceptionList",
      "getReceptionPagination",
      "getLoadingReception",
      "getIsLoading",
    ]),
  },
  methods: {
    ...mapActions("reception", ["receptionList", "setLoadingReception"]),

    async proxPage(page) {
      this.setLoadingReception(true);
      this.urlParams = {
        page: page,
      };
      await this.receptionList(this.urlParams);
      this.setLoadingReception(false);
    },
    async listReception() {
      this.setLoadingReception(true);
      await this.receptionList(this.urlParams);
      this.setLoadingReception(false);
    },

    openReceptionDetailsDialog(coletaId, coleta) {
      this.reRenderReceptionDetailsDialog += 1;
      this.selectedColetaId = coletaId;
      this.selectedColeta = coleta;
      this.receptionDetailsDialog = true;
    },

    closeReceptionDetailsDialog() {
      this.reRenderReceptionDetailsDialog += 1;
      this.receptionDetailsDialog = false;
      this.listReception();
    },

    colorChip(value) {
      switch (value) {
        case "AGUARDANDO_TRANSPORTADORA":
          return "blue darken-2";
        case "CONFIRMACAO_TRANSPORTADORA":
          return "lime darken-1";
        case "COLETA_INICIADA":
          return "orange";
        case "COLETA_FINALIZADA":
          return "green";
        case "COLETA_CANCELADA":
          return "red darken-1";
        case "COLETA_PAUSADA":
          return "orange darken-2";
        default:
          return "blue";
      }
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
    filterStatus(value) {
      switch (value) {
        case "AGUARDANDO_TRANSPORTADORA":
          return (value = "Aguard. Veículo");
        case "CONFIRMACAO_TRANSPORTADORA":
          return (value = "Veic. no Patio");
        case "COLETA_INICIADA":
          return (value = "Col. Iniciada");
        case "COLETA_FINALIZADA":
          return (value = "Col. Expedida");
        case "COLETA_CANCELADA":
          return (value = "Col. Cancelada");
        case "COLETA_PAUSADA":
          return (value = "Col. Pausada");
      }
    },
  },
};
</script>

<style scoped>
.list-header {
  background-color: #0f2733;
  color: white;
  font-weight: bolder;
  min-height: 30px;
}
.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
</style>
