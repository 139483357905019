<template>
  <v-dialog
    :width="width"
    v-model="dialogModel"
    v-on="{ ...$listeners }"
    v-bind="{ ...$attrs }"
  >
    <v-card class="pt-5">
      <slot></slot
      ><v-icon
        v-if="!hideCloseButton"
        size="28"
        color="primary"
        style="
          position: absolute !important;
          top: 4px !important;
          right: 4px !important;
        "
        @click="$emit('input', false)"
        >mdi-close-circle-outline</v-icon
      ></v-card
    >
  </v-dialog>
</template>

<script>
export default {
  name: "DefaultModal",
  props: {
    width: {
      type: String,
      required: false,
      default: "500",
    },
    value: {},
    hideCloseButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    dialogModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
