export default {
  isLoading: false,
  listingConfPatio: [],
  confPatioPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  filteredConfPatio: [],
  isLoadingSearch: false,
  urlParams: {
    page: 1,
    search: "",
  },
};
