var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.coleta.coletaStatus !== 'COLETA_FINALIZADA')?_c('v-card',{staticClass:"base_dark text--text mb-5"},[_c('div',[_c('v-container',[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"mt-5",attrs:{"align":"start"}},[_c('p',[_c('b',[_vm._v("Despachante:")]),_vm._v(" "+_vm._s(_vm.despachante))]),_c('p',[_c('b',[_vm._v("Transportadora:")]),_vm._v(" "+_vm._s(_vm.coleta.transportadoraNomeFantasia || "")+" ")]),_c('p',[_c('b',[_vm._v("Data:")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.coleta.dataInicioEmbarque || ""))+" ")]),_c('v-col',{staticClass:"base text--navy rounded text-break",attrs:{"md":"6"}},[_c('p',{staticClass:"pa-0 ma-0"},[_c('b',[_vm._v("Hora inicial: ")]),_vm._v(_vm._s(_vm._f("formatHour")(_vm.coleta.dataInicioEmbarque))+" "),_c('b',[_vm._v("Hora final: ")]),_vm._v(_vm._s(_vm._f("formatHour")(_vm.coleta.dataFimEmbarque))+" ")])])],1),_c('v-col',{staticClass:"mt-5 pb-5",attrs:{"align":"start"}},[_c('p',[_c('b',[_vm._v("Coleta:")]),_vm._v(" "),_c('b',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.coleta.coletaId))])]),_c('p',[_c('b',[_vm._v("Motorista:")]),_vm._v(" "+_vm._s(_vm.coleta.motoristaNome || ""))]),_c('p',[_c('b',[_vm._v("Placa:")]),_vm._v(" "+_vm._s(_vm.coleta.veiculoPlaca || ""))]),_c('v-col',{staticClass:"base text--navy rounded text-break",attrs:{"md":"6","align-self":"end"}},[_c('p',{staticClass:"pa-0 ma-0"},[_c('b',[_vm._v("Pedidos:")]),_vm._v(" "+_vm._s(_vm.coleta.quantidadePedidos || "")+" "),_c('b',[_vm._v("Volumes:")]),_vm._v(" "+_vm._s(_vm.relacaoVolumes || "")+" ")])])],1),_c('v-col',{},[_c('div',{staticClass:"pa-4"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"navy--text text-truncate",class:hover ? 'base--text' : 'navy--text',staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"block":"","rounded":"","loading":_vm.isLoadingReload},on:{"click":_vm.atualizarListagemVolumes}},[_vm._v("Atualizar Listagem de Volumes ")])]}}],null,false,4152940964)})],1),_c('div',{staticClass:"pa-4"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"navy--text text-truncate",staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"block":"","rounded":"","disabled":!_vm.coleta.permiteColetaAssinaturaMotorista,"color":hover ? 'primary' : 'secondary'},on:{"click":_vm.coletarAssinatura}},[_vm._v(" Ass. Responsável pela Coleta")])]}}],null,false,2749523178)})],1),_c('div',{staticClass:"pa-4"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"navy--text text-truncate",staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"block":"","rounded":"","disabled":_vm.coleta.coletaStatus == 'COLETA_FINALIZADA' ||
                  !_vm.coleta.permiteFinalizarColeta ||
                  _vm.coleta.assinaturaMotoristaUrl == '',"color":hover ? 'primary' : 'secondary'},on:{"click":_vm.finalizarColeta}},[_vm._v(" Finalizar Remessa")])]}}],null,false,1899621179)})],1),_c('div',{staticClass:"pa-4"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-btn',{staticClass:"navy--text text-truncate",staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"block":"","rounded":"","color":hover ? 'primary' : 'secondary'},on:{"click":_vm.openPickupOrdersModal}},[_vm._v(" Pedidos da Coleta")])]}}],null,false,2094297419)})],1)])],1)],1)],1),_c('pickup-orders',{key:'modalKey' + _vm.modalKey,attrs:{"pickup-id":_vm.getColetaDetalhes.coletaId,"carrier":_vm.getColetaDetalhes.transportadoraNomeFantasia,"date":_vm.getColetaDetalhes.dataInicioEmbarque},model:{value:(_vm.isPickupOrdersModalOpen),callback:function ($$v) {_vm.isPickupOrdersModalOpen=$$v},expression:"isPickupOrdersModalOpen"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }