<template>
  <v-card>
    <v-card-title class="justify-center">
      <v-row>
        <v-col>
          <span class="text-h6 font-weight-bold navy--text"
            >Detalhes da Divergência</span
          >
        </v-col>
      </v-row>
    </v-card-title>
    <div v-if="!isLoading">
      <div v-if="getDetalhesDivergencia.veiculos.length">
        <v-card-text>
          <div class="mb-5 text-start">
            <span class="font-weight-bold navy--text"> Remessa: </span>
            <span class="text-wrap secondary--text font-weight-bold">
              {{ getDetalhesDivergencia.codigoRemessa }}
            </span>
          </div>
          <v-row class="list-header mx-auto mb-2">
            <!-- <v-col cols="2" align="start" class="border-right">
              <span class="">Código</span>
            </v-col> -->
            <v-col cols="8" align="start" class="border-right">
              <span class="">Veiculos</span>
            </v-col>
            <v-col cols="4" align="center" class="">
              <span class="">Qtd. </span>
            </v-col>
          </v-row>
          <v-row
            class="list-card base_dark text--text mx-auto my-auto mt-2"
            v-for="(divergencia, i) in getDetalhesDivergencia.veiculos"
            :key="i"
          >
            <!-- <v-col cols="2" align="start">
              <div class="text-wrap">
                {{ divergencia.codigo }}
              </div>
            </v-col> -->
            <v-col cols="8" align="start">
              <div class="text-wrap">
                {{ divergencia.tipo_veiculo }}
              </div>
            </v-col>
            <v-col cols="4" align="center">
              <div class="text-wrap secondary--text font-weight-bold">
                <span> {{ divergencia.quantidade_veiculos }}</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <div v-else>
        <v-row class="py-6" justify="center">
          <v-col cols="6" align="center">
            <span>Não foi possível carregar os detalhes da coleta.</span>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="54"
      ></v-progress-circular>
    </v-col>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DivergenteModal",
  data: () => ({
    isLoading: false,
  }),
  props: {
    remessasId: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.getListDivergencia();
  },
  computed: {
    ...mapGetters("remessas", ["getDetalhesDivergencia"]),
  },
  methods: {
    ...mapActions("remessas", ["detalhesDivergencia"]),

    async getListDivergencia() {
      this.isLoading = true;
      await this.detalhesDivergencia(this.remessasId);
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.h {
  font-weight: bolder;
}
.list-header {
  background-color: #0f2733;
  color: white;
  font-weight: bolder;
  min-height: 30px;
}
.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
.list-card {
  color: black;
  font-weight: normal;
}

.list-card:hover {
  background-color: #ffdfcc;
  color: black;
}
</style>