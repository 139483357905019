<template>
  <v-card class="overflow-y-auto">
    <v-card-title class="justify-center">
      <span class="text-h6 font-weight-bold navy--text"
        >Criação de Remessa -
        <span v-if="transportadoraNome">{{ transportadoraNome }}</span>
        <span v-else>
          <v-progress-circular
            class="ml-1"
            color="primary"
            indeterminate
            size="25"
          ></v-progress-circular>
        </span>
      </span>
    </v-card-title>
    <div v-if="!isLoadingModal">
      <remessa-coleta-cadastro-card
        :key="reRenderCard"
        :loading="loading"
        :docas="docas"
        :pedidos="pedidos"
        :coletas="coletas"
        :tipos-veiculo="tiposVeiculo"
        @area-truck="areaTruck"
        @area-pedidos="areaPedidos"
      />
      <div class="">
        <v-card-text>
          <v-row
            justify="center"
            v-show="this.areaPedidosSelected > this.areaTruckSelected"
            class="pa-0 ma-0"
          >
            <v-col cols="6" class="pa-0 ma-0">
              <v-checkbox
                v-model="checkboxArea"
                label="Estou ciente que o Comprimento dos pedidos selecionados é maior que o Comprimento do veículo."
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-hover v-slot="{ hover }">
                <v-btn
                  :disabled="!habilitarBtnDetalhesRemessa"
                  @click="openDialogDetalhes"
                  rounded
                  :color="hover ? 'secondary' : 'primary'"
                  class="navy--text text-truncate"
                  style="text-transform: none !important; font-weight: bolder"
                  >Detalhes</v-btn
                >
              </v-hover>
            </v-col>
            <v-col>
              <v-hover v-slot="{ hover }">
                <v-btn
                  @click="fecharColeta"
                  :disabled="!habilitateButton"
                  rounded
                  :color="hover ? 'secondary' : 'primary'"
                  class="navy--text text-truncate"
                  style="text-transform: none !important; font-weight: bolder"
                  >Fechar coleta</v-btn
                >
              </v-hover>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-hover v-slot="{ hover }">
                <v-btn
                  rounded
                  :color="hover ? 'secondary' : 'primary'"
                  class="navy--text text-truncate"
                  style="text-transform: none !important; font-weight: bolder"
                  :loading="loadingFinalizarRemessa"
                  @click="finalizarRemessa"
                  v-if="habilitarFinalizarRemessa"
                  >Finalizar Remessa</v-btn
                >
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </div>
    <v-dialog
      scrollable
      transition="dialog-bottom-transition"
      v-model="dialogDetalhesRemessa"
    >
      <remessa-coleta-cadastro-detalhes
        :coletas="coletas"
        :pedidos="pedidos"
        :pedidosTransportadoraFilha="getPedidosTransportadoraFilha.pedidos"
        :tiposVeiculo="tiposVeiculo"
        @delete-item="deleteItem"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import RemessaColetaCadastroCard from "@/components/defaultUserComponents/remessas/cadastro/RemessaColetaCadastroCard";
import { mapActions, mapGetters } from "vuex";
import RemessaColetaCadastroDetalhes from "@/components/defaultUserComponents/remessas/cadastro/RemessaColetaCadastroDetalhes";

export default {
  name: "RemessaCadastro",
  components: { RemessaColetaCadastroDetalhes, RemessaColetaCadastroCard },
  data: () => ({
    isLoadingModal: false,
    areaPedidosSelected: 0,
    areaTruckSelected: 0,
    checkboxArea: false,
    reRenderCard: 0,
    loading: false,
    dialogDetalhesRemessa: false,
    loadingFinalizarRemessa: false,
    docas: [],
    tiposVeiculo: [],
    pedidos: [],
    coletas: [],
    coletaEmCadastro: {},
    transportadoraNome: "",
  }),

  mounted() {
    this.startLists();
    this.listarDadosParaCadastro();
  },

  computed: {
    ...mapGetters("remessas", [
      "getRemessaCadastro",
      "getDataRemessa",
      "getDataColeta",
      "getListaRemessas",
      "getTipoColeta",
      "getPedidosTransportadoraFilha",
      "getTransportadoraFilhaId",
    ]),
    getTransportadoraId() {
      return ~~this.$route.params.transportadoraId;
    },
    showRedespacho() {
      return this.getTipoColeta === "EMBARQUE_REDESPACHO" ? true : false;
    },

    habilitarBtnFecharColeta() {
      if (this.showRedespacho) {
        return (
          !!1 &&
          !!this.getRemessaCadastro.tipoVeiculoId &&
          !!(this.getRemessaCadastro.pedidos || []).length &&
          !this.loadingFinalizarRemessa &&
          !!this.getTipoColeta
        );
      } else {
        return (
          !!1 &&
          !!this.getRemessaCadastro.docaId &&
          !!this.getRemessaCadastro.tipoVeiculoId &&
          !!(this.getRemessaCadastro.pedidos || []).length &&
          !!this.getRemessaCadastro.dataInicio &&
          !!this.getRemessaCadastro.dataFinal &&
          !this.loadingFinalizarRemessa &&
          !!this.getDataRemessa.dataRemessa &&
          !!this.getTipoColeta
        );
      }
    },

    requestFinalizarRemessa() {
      if (this.showRedespacho) {
        return {
          transportadoraId: this.getTransportadoraId,
          dataRemessa: this.getDataRemessa.dataRemessa ?? "1",
          coletas: this.coletas.map((c) => ({
            ...c,
            transportadoraId: this.getTransportadoraFilhaId,
            docaId: 1,
          })),
        };
      } else {
        return {
          transportadoraId: this.getTransportadoraId,
          dataRemessa: this.getDataRemessa.dataRemessa,
          coletas: this.coletas,
          // .map((c) => ({
          //   ...c,
          //   dataInicioEmbarque:
          //     this.getDataRemessa.dataRemessa + " " + c.dataInicioEmbarque,
          //   dataFinalEmbarque:
          //     this.getDataRemessa.dataRemessa + " " + c.dataFinalEmbarque,
          // })),
        };
      }
    },

    habilitarFinalizarRemessa() {
      return 1 && this.coletas.length > 0;
    },

    habilitarBtnDetalhesRemessa() {
      return this.coletas.length > 0;
    },

    habilitateButton() {
      if (
        this.areaTruckSelected > this.areaPedidosSelected &&
        this.habilitarBtnFecharColeta
      ) {
        return true;
      } else {
        if (this.checkboxArea && this.habilitarBtnFecharColeta) {
          return true;
        } else {
          return false;
        }
      }
    },
  },

  methods: {
    ...mapActions("remessas", [
      "listDataParaCadastroRemessa",
      "cadastrarRemessa",
      "successCadastro",
      "mostrarMensagemErro",
      "setRemessaCadastro",
      "setPedidosSelected",
      "setDataColeta",
      "setTipoColeta",
      "listaRemessas",
      "listarTransportadoras",
      "cadastrarRedespacho",
    ]),

    async listarDadosParaCadastro() {
      this.setRemessaCadastro({});
      this.reRenderCard++;
      this.docas = [];
      this.tiposVeiculo = [];
      this.pedidos = [];
      this.loading = true;
      const response = await this.listDataParaCadastroRemessa(
        this.getTransportadoraId
      );
      this.loading = false;
      if (!response) {
        await this.mostrarMensagemErro(
          "Ocorreu um erro durante o processamento da sua solicitação."
        );
        return;
      }
      const { docas, pedidos, tiposVeiculo, transportadoraNome } = response;
      this.transportadoraNome = transportadoraNome;
      this.docas = docas;
      this.pedidos = pedidos;
      this.tiposVeiculo = tiposVeiculo;
    },

    fecharColeta() {
      this.coletas.push({
        dataInicioEmbarque:
          this.getDataColeta.dataColeta +
          " " +
          this.getRemessaCadastro.dataInicio,
        dataFinalEmbarque:
          this.getDataColeta.dataColeta +
          " " +
          this.getRemessaCadastro.dataFinal,
        dataRemessa: this.getDataRemessa.dataRemessa,
        docaId: this.getRemessaCadastro.docaId,
        tipoVeiculoId: this.getRemessaCadastro.tipoVeiculoId,
        pedidos: this.getRemessaCadastro.pedidos.map(
          ({ pedidoId }) => pedidoId
        ),
        tipoColeta: this.getTipoColeta,
      });

      if (this.showRedespacho) {
        this.fecharColetaRedespacho();
      } else {
        this.fecharColetaNormal();
      }
    },

    fecharColetaNormal() {
      for (const pedido of this.pedidos) {
        if (
          this.getRemessaCadastro.pedidos.find(
            ({ pedidoId }) => pedidoId === pedido.pedidoId
          )
        ) {
          pedido.quantidadeCaminhoesAlocados++;
        }
      }
      this.setPedidosSelected([]);
      this.setDataColeta("");
      this.setRemessaCadastro({});
    },

    fecharColetaRedespacho() {
      for (const pedido of this.getPedidosTransportadoraFilha.pedidos) {
        if (
          this.getRemessaCadastro.pedidos.find(
            ({ pedidoId }) => pedidoId === pedido.pedidoId
          )
        ) {
          pedido.quantidadeCaminhoesAlocados++;
        }
      }
      this.setPedidosSelected([]);
      this.setDataColeta("");
      this.setRemessaCadastro({});
    },

    async finalizarRemessa() {
      this.loadingFinalizarRemessa = true;
      if (this.showRedespacho) {
        await this.cadastrarRedespacho(this.requestFinalizarRemessa);
      } else {
        await this.cadastrarRemessa(this.requestFinalizarRemessa);
      }
      this.loadingFinalizarRemessa = false;
      this.$emit("closeCadastrarRemessa");
    },

    openDialogDetalhes() {
      this.dialogDetalhesRemessa = true;
    },

    deleteItem(item) {
      if (this.showRedespacho) {
        for (const pedido of this.getPedidosTransportadoraFilha.pedidos) {
          if (pedido.pedidoId === item.pedidoId) {
            pedido.quantidadeCaminhoesAlocados--;
          }
        }
        for (let c = 0; c < this.coletas.length; c++) {
          for (let p = 0; p < this.coletas[c].pedidos.length; p++) {
            if (this.coletas[c].pedidos[p] === item.pedidoId) {
              this.coletas[c].pedidos.splice(p, 1);
              if (this.coletas[c].pedidos.length === 0) {
                this.coletas.splice(c, 1);
              }
            }
          }
        }
      } else {
        for (const pedido of this.pedidos) {
          if (pedido.pedidoId === item.pedidoId) {
            pedido.quantidadeCaminhoesAlocados--;
          }
        }
        for (let c = 0; c < this.coletas.length; c++) {
          for (let p = 0; p < this.coletas[c].pedidos.length; p++) {
            if (this.coletas[c].pedidos[p] === item.pedidoId) {
              this.coletas[c].pedidos.splice(p, 1);
              if (this.coletas[c].pedidos.length === 0) {
                this.coletas.splice(c, 1);
              }
            }
          }
        }
      }
    },
    areaTruck(item) {
      this.areaTruckSelected = item;
    },
    areaPedidos(item) {
      this.areaPedidosSelected = item;
    },
    async startLists() {
      this.isLoadingModal = true;
      await this.listaRemessas;
      await this.listarTransportadoras(this.getTransportadoraId);
      this.isLoadingModal = false;
    },
  },
  created() {
    this.loadingFinalizarRemessa = false;
  },
};
</script>

<style scoped>
::v-deep
  .theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  color: white;
}
</style>
