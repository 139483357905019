export default {
  setListRemessa(state, value) {
    state.listRemessa = value;
  },
  setListDrivers(state, value) {
    state.listDrivers = value;
  },
  setListVehicles(state, value) {
    state.listVehicles = value;
  },
};
