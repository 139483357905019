import { render, staticRenderFns } from "./ListColetasHeader.vue?vue&type=template&id=619a2bd6&scoped=true&"
import script from "./ListColetasHeader.vue?vue&type=script&lang=js&"
export * from "./ListColetasHeader.vue?vue&type=script&lang=js&"
import style0 from "./ListColetasHeader.vue?vue&type=style&index=0&id=619a2bd6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "619a2bd6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VHover } from 'vuetify/lib/components/VHover';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VDatePicker,VHover,VMenu,VRow,VTextField})
