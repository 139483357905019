import { ErrorNotifier } from "../../../services/messages/ErrorNotifier";
import { ConferenciaBaiaRepository } from "../../../repositories/defaultUserRepositories/ConferenciaBaiaRepository";

export default {
  async volumeDetails({ commit, dispatch }, payload) {
    commit("setVolumeDetails", {});
    commit("setIsLoadingVolumeDetails", true);
    try {
      const response = await ConferenciaBaiaRepository.volumeDetails(payload);
      if (response.status >= 200 && response.status < 300) {
        commit("setVolumeDetails", response.data.data[0]);
        commit("setIsLoadingVolumeDetails", false);
        return;
      } else {
        commit("setIsLoadingVolumeDetails", false);
        throw response;
      }
    } catch (error) {
      commit("setIsLoadingVolumeDetails", false);
      await dispatch("errorMessage", error);
    }
  },
  async volumeConfirm({ commit, dispatch }, payload) {
    let successObj = {
      successStatus: true,
      successMessage: "Pedido Confirmado com sucesso.",
    };
    commit("setIsLoading", false);
    commit("notifications/setSuccessNotification", successObj, {
      root: true,
    });
    return true;
  },

  setIsBlockOrderModalOpen({ commit }, payload) {
    commit("setIsBlockOrderModalOpen", payload);
  },

  resetVolumeDetails({ commit }) {
    commit("setVolumeDetails", {});
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },

  setInitialDate({ commit }, value) {
    commit("setInitialDate", value);
  },

  setFinalDate({ commit }, value) {
    commit("setFinalDate", value);
  },
  errorMessage({ commit, dispatch }, value) {
    ErrorNotifier.run(commit, dispatch, value);
    return;
  },
  checkToken({ dispatch }, value) {
    if (value.data.status === "O token é inválido.") {
      value.data.message = "O token é inválido.";
      value.status = "O token é inválido.";
      dispatch("errorMessage", value);
      return;
    }
    return;
  },
};
