<template>
  <v-card>
    <v-container fluid>
      <default-table-head>
        <v-col
          cols="2"
          class="border-right"
          >Pedido</v-col
        >
        <v-col
          cols="2"
          class="border-right"
          >NF</v-col
        >
        <v-col
          cols="4"
          class="border-right"
          >Cliente</v-col
        >
        <v-col
          cols="2"
          class="border-right"
          >Tipo</v-col
        >
        <v-col cols="2">Status</v-col>
      </default-table-head>
      <default-table-body-row
        v-for="(order, index) in pickupDetails"
        :key="index"
      >
        <v-col
          cols="2"
          class="primary--text font-weight-bold"
          >{{ order.numeroPedido }}</v-col
        >
        <v-col cols="2">{{ order.numeroNota }}</v-col>
        <v-col cols="4">{{ order.clienteNome }}</v-col>
        <v-col
          cols="2"
          class="text-uppercase"
          >{{ order.tipo }}</v-col
        >
        <v-col cols="2"
          ><order-status :order-status="order.bloqueioStatus"
        /></v-col>
      </default-table-body-row>
    </v-container>
  </v-card>
</template>

<script>
import DefaultTableBodyRow from "../../defaultComponents/DefaultTableBodyRow.vue";
import DefaultTableHead from "../../defaultComponents/DefaultTableHead.vue";
import OrderStatus from "../../defaultComponents/OrderStatus.vue";

export default {
  name: "PickupDetails",
  components: {
    DefaultTableBodyRow,
    DefaultTableHead,
    OrderStatus,
  },
  props: {
    pickupDetails: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
