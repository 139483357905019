<template>
  <default-table-head class="d-none d-md-flex">
    <v-col cols="2" class="border-right"><span>Redespacho</span></v-col>
    <v-col cols="1" class="border-right"><span>Coleta</span> </v-col>
    <v-col cols="3" class="border-right"><span>Transportadora</span></v-col>
    <v-col cols="1" class="border-right"><span>Veículo</span> </v-col>
    <v-col cols="1" class="border-right text-truncate"
      ><span title="Pedidos">Pedidos</span>
    </v-col>
    <v-col cols="1" class="border-right text-truncate"
      ><span title="Volumes">Volumes</span></v-col
    >
  </default-table-head>
</template>

<script>
import DefaultTableHead from "../../defaultComponents/DefaultTableHead.vue";

export default {
  name: "ListingHead",
  components: {
    DefaultTableHead,
  },
};
</script>
