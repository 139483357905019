import { HttpRestService } from "@/services/http/HttpRestService";

export class PatioRepository {
  static requestConfPatio(url) {
    return HttpRestService.get(url);
  }

  static createConfPatio(modelCreateConfPatio) {
    return HttpRestService.post("/baia/patio/criar", modelCreateConfPatio);
  }

  static patioEdit(modelCreateConfPatio) {
    return HttpRestService.put(
      `/baia/patio/alterar/${modelCreateConfPatio.id}`,
      modelCreateConfPatio
    );
  }
}
