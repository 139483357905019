<template>
  <v-card class="overflow-hidden">
    <v-row>
      <v-col
        class="justify-center mt-4 mb-4 text-h6 font-weight-bold navy--text"
        cols="12"
      >
        <span class="secondary--text"
          >Deseja Confirmar Conferência?</span
        ></v-col
      >
    </v-row>
    <v-row justify="center mb-4">
      <v-col cols="3" align="center" class="py-2">
        <v-hover v-slot="{ hover }">
          <v-btn
            block
            rounded
            color="green"
            class="text-truncate mb-1 mt-0"
            :class="hover ? 'base--text' : 'navy--text'"
            style="text-transform: none !important; font-weight: bolder"
            @click="requestConfirmation()"
            >Sim</v-btn
          >
        </v-hover>
      </v-col>
      <v-col cols="3" align="center" class="py-2">
        <v-hover v-slot="{ hover }">
          <v-btn
            block
            rounded
            color="primary"
            class="text-truncate mb-1 mt-0"
            :class="hover ? 'base--text' : 'navy--text'"
            style="text-transform: none !important; font-weight: bolder"
            @click="$emit('closeCollectionConfeirmation')"
            >Não</v-btn
          >
        </v-hover>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data: () => ({
    isLoadingModal: false,
    buttonClicked: false,
    urlParams: {
      page: 1,
    },
  }),
  props: { coletaId: { required: true, type: Number } },
  mounted() {},
  computed: {
    ...mapState("expedition", ["isLoading"]),
  },
  methods: {
    ...mapActions("expedition", ["requestConfirm", "expedition", "urlParams"]),

    async requestConfirmation() {
      this.isLoadingModal = true;
      this.$emit("closeCollectionConfeirmation");
      this.$emit("closeCollectionConference");
      await this.requestConfirm(this.coletaId);
      await this.expedition(this.urlParams);
      this.isLoadingModal = false;
    },
  },
};
</script>
<style scoped>
.h {
  font-weight: bolder;
}
.list-header {
  background-color: #0f2733;
  color: white;
  font-weight: bolder;
  min-height: 30px;
}
.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
.list-card {
  color: black;
  font-weight: normal;
}

.list-card:hover {
  background-color: #ffdfcc;
  color: black;
}
.green {
  background-color: rgb(245, 200, 164);
  color: white;
}
</style>
