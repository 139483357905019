export default {
  setListingMonPatio(state, response) {
    state.listingMonPatio = response;
  },
  setFilteredMonPatio(state, response) {
    state.filteredMonPatio = response;
  },

  setMonPatioPagination(state, response) {
    state.monPatioPagination.total = response.total;
    state.monPatioPagination.perPage = response.perPage;
    state.monPatioPagination.currentPage = response.currentPage;
    state.monPatioPagination.lastPage = response.lastPage;
    state.monPatioPagination.nextPage = response.nextPage;
  },

  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setUrlParams(state, urlParams) {
    state.urlParams.page = urlParams.page;
    state.urlParams.pedido = urlParams.pedido;
    state.urlParams.transportadora = urlParams.transportadora;
  },
  setBaiaDetails(state, response) {
    state.baiaDetails = response;
  },
  setIsLoadingBaiaList(state, value) {
    state.isLoadingBaiaList = value;
  },
};
