<template>
  <div>
    <v-form ref="form">
      <v-container @keyup.enter="onGetLoginData(modelLogin.login, modelLogin.password)" class="mt-12">
        <div>
          <v-row no-gutters class="justify-center">
            <v-col cols="6" align="center">
              <v-img src="../../assets/LOGO-Sou Out.svg" max-width="300px"></v-img>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row no-gutters class="justify-center mt-2 mb-1">
            <v-col cols="6" align="start">
              <div class="navy--text font-weight-bold">Login</div>
            </v-col>
          </v-row>
          <v-row no-gutters class="justify-center">
            <v-col cols="6">
              <v-text-field
                autofocus
                @focus="setBorder"
                @blur="removeBorder"
                :rules="[rules.required]"
                label="Digite seu login"
                solo
                flat
                v-model="modelLogin.login"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="justify-center mb-1">
            <v-col cols="6" align="start">
              <div class="navy--text font-weight-bold">Senha</div>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row no-gutters class="justify-center">
            <v-col cols="6">
              <v-text-field
                @focus="setBorder"
                @blur="removeBorder"
                label="Digite sua senha"
                :type="show4 ? 'text' : 'password'"
                :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                @click:append="show4 = !show4"
                solo
                flat
                v-model="modelLogin.password"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row no-gutters class="justify-center mt-6 mb-6">
            <v-col cols="4">
              <v-hover v-slot="{ hover }">
                <v-btn
                  rounded
                  :color="hover ? 'secondary' : 'primary'"
                  x-large
                  :loading="isLoading"
                  block
                  class="m-texto"
                  style="text-transform: none !important; font-weight: bolder"
                  max-width="220px"
                  @click="onGetLoginData(modelLogin.login, modelLogin.password)"
                  >Login
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row no-gutters class="justify-center">
            <v-col cols="6" align="center">
              <v-img
                src="../../assets/Logo Sou Energy - Horizontal.svg"
                max-width="120px"
              ></v-img>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({
    modelLogin: {
      login: "",
      password: "",
    },
    show4: false,
    senha: "Password",
    rules: {
      required: (value) => !!value || "O campo não pode ficar vazio",
    },
  }),
  computed: {
    ...mapState("login", ["isLoading"]),
    form() {
      return {
        login: this.modelLogin.login,
        senha: this.modelLogin.password
      }
    },
  },
  methods: {
    ...mapActions("login", ["login"]),
    onGetLoginData() {
      if (Object.values(this.form).some((value) => !value)) {
        this.$refs.form.validate(false);
        return;
      }
      this.login(this.modelLogin);
    },
    setBorder(event) {
      event.target.parentElement.parentElement.style.border = "1px #ED8130 solid";
    },
    removeBorder(event) {
      event.target.parentElement.parentElement.style.border = "";
    }
  },
};
</script>

<style scoped>
.m-texto {
  color: #002233 !important;
}
.m-texto:hover {
  transition: 300ms all ease-in-out;
  color: #ffffff !important;
  font-weight: bold;
}
.drectd {
  padding: 10%;
}
.drect {
  width: 37%;
}
</style>
