import { HttpRestService } from "@/services/http/HttpRestService";

export class MonitoramentoPatioRepository {
  static requestMonPatio(url) {
    return HttpRestService.get(url);
  }

  static createMonPatio(modelCreateMonPatio) {
    return HttpRestService.post("", modelCreateMonPatio);
  }

  static monPatioEdit(modelEditMonPatio) {
    return HttpRestService.put(`${modelEditMonPatio.id}`, modelEditMonPatio);
  }
  static updatePedidoBaia(request) {
    return HttpRestService.put("/baia/patio/mover/pedido", request);
  }
}
