<template >
  <v-dialog
    v-if="enabledSync && getVolumesComErros.length"
    v-model="enabledSync"
    persistent
    max-width="820"
  >
    <v-card>
      <v-card-title class="justify-center">
        <h3 class="navy--text">Erro ao Embarcar Pedido</h3>
      </v-card-title>
      <v-card-text>
        <v-row class="ma-5 text--text">
          <v-col cols="12">
            <h3><b>Remova o Pedido não pertencente a esta coleta!</b></h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              v-model="pedidosComErro"
              :headers="headers"
              :items="getVolumesComErros"
              :single-select="false"
              item-key="id"
              show-select
              class="elevation-1"
              disable-pagination
              hide-default-footer
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-col class="align-self-center mr-3" cols="3">
          <v-hover v-slot="{ hover }">
            <v-btn
              :loading="loadingBtn"
              rounded
              :disabled="checkPedidosComErro"
              :color="hover ? 'secondary' : 'primary'"
              x-large
              block
              class="navy--text"
              style="text-transform: none !important; font-weight: bolder"
              max-width="220px"
              @click="removerPedido"
              >Remover Volume</v-btn
            >
          </v-hover>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "EmbarqueColetaModalError",
  data: () => ({
    loadingBtn: false,
    pedidosComErro: [],
    headers: [
      {
        width: "20%",
        text: "Id da Coleta",
        value: "coletaId",
        align: "center",
      },
      { width: "40%", text: "Num. Volume", value: "volume", align: "center" },
      { width: "40%", text: "Status", value: "status", align: "center" },
    ],
    urlParams: { coletaId: 0, volumes: [] },
  }),
  props: {
    enabled: { type: Boolean, required: true },
  },
  methods: {
    ...mapActions("expedition", ["removeVolumesComErros", "volumesComErros"]),
    onColect() {
      this.$emit("close");
    },
    async removerPedido() {
      this.loadingBtn = true;
      this.urlParams.volumes.splice(0, this.urlParams.volumes.length);
      console.log(this.urlParams);
      this.urlParams.coletaId = ~~this.$route.params.coletaId;
      for (let i = 0; i < this.pedidosComErro.length; i++) {
        this.urlParams.volumes.push(this.pedidosComErro[i].volume);
      }
      console.log(this.urlParams);
      await this.removeVolumesComErros(this.urlParams);
      this.loadingBtn = false;
      let response = await this.volumesComErros(~~this.$route.params.coletaId);
      if (response) this.$emit("close");
    },
    init() {
      console.log("Iniciar modal");
    },
  },
  computed: {
    ...mapGetters("expedition", ["getVolumesComErros"]),
    enabledSync: {
      get() {
        return this.enabled;
      },
      set() {
        this.$emit("close");
      },
    },
    checkPedidosComErro() {
      return this.pedidosComErro.length === 0;
    },
  },
  watch: {
    enabledSync(enabledSync) {
      if (enabledSync) {
        this.init();
      }
    },
  },
};
</script>
<style lang="">
</style>