<template>
  <div>
    <v-card class="overflow-hidden">
      <div>
        <v-row class="justify-center mt-3">
          <v-col class="text-h6 font-weight-bold navy--text"
            >Conferência de Expedição</v-col
          >
        </v-row>
        <div v-if="!isLoadingModal">
          <div v-if="getCollectRequests.length">
            <v-card-text>
              <v-row class="text-start mx-auto my-auto mt-3">
                <v-col cols="12">
                  <span class="font-weight-bold navy--text">
                    Transportadora:
                  </span>
                  <span class="text-wrap secondary--text font-weight-bold">
                    {{ this.expedition.nomeFantasia || "" }}</span
                  >
                </v-col>
                <v-col cols="auto">
                  <span class="font-weight-bold navy--text"> Coleta: </span>
                  <span class="text-wrap secondary--text font-weight-bold">
                    {{ this.expedition.coletaId || "" }}</span
                  ></v-col
                >
                <v-col cols="auto">
                  <span class="font-weight-bold navy--text"> Data: </span>
                  <span class="text-wrap font-weight-bold">{{
                    expedition.dataInicioEmbarque | formatDate
                  }}</span>
                </v-col>
                <v-col cols="auto">
                  <span class="font-weight-bold navy--text"> Hora: </span>
                  <span class="text-wrap font-weight-bold">
                    {{ expedition.dataInicioEmbarque | formatHour }} -
                    {{ expedition.dataFimEmbarque | formatHour }}
                  </span>
                </v-col>
              </v-row>

              <v-row class="list-header mx-auto mb-1">
                <v-col
                  cols="12"
                  class="justify-center border-right text-truncate"
                >
                  <span> Pedidos a serem conferidos </span>
                </v-col>
              </v-row>
              <v-row
                class="list-card base_dark text--text mx-auto my-auto mt-3"
                :key="index"
                v-for="(pedidos, index) in chunkedCollectRequests"
              >
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  lg="3"
                  xl="3"
                  v-for="pedido in pedidos"
                  :key="pedido.pedidoId"
                >
                  <v-btn
                    rounded
                    block
                    :title="pedido.tipo"
                    :style="{
                      'background-color': pedido.clicado ? '#4caf50' : '',
                      color: pedido.clicado ? 'white' : '',
                    }"
                    @click="statusButton(pedido)"
                  >
                    {{ pedido.numeroPedido }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </div>

          <div v-else>
            <v-row class="py-6" justify="center">
              <v-col cols="6" align="center">
                <span>Não a pedidos para essa coleta.</span>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="mb-6" v-else>
          <v-progress-circular
            color="primary"
            indeterminate
            size="54"
          ></v-progress-circular>
        </div>
      </div>
    </v-card>
    <v-row justify="center" style="position: sticky; bottom: 0" class="base">
      <v-col cols="3" align="center" class="py-2">
        <v-hover v-slot="{ hover }">
          <v-btn
            block
            rounded
            color="primary"
            class="text-truncate mb-1 mt-0"
            :class="hover ? 'base--text' : 'navy--text'"
            style="text-transform: none !important; font-weight: bolder"
            :disabled="!todosPedidosClicados || !getCollectRequests.length"
            @click="openCollectionConfirmation()"
            >Finalizar Conferência</v-btn
          >
        </v-hover>
      </v-col>
    </v-row>
    <v-dialog
      v-model="collectionConfirm"
      max-width="500"
      content-class="hide-overflow-x"
    >
      <modal-confirmation-conf
        :coletaId="this.expedition.coletaId"
        :key="reRender"
        @closeCollectionConference="$emit('closeCollectionConference')"
        @closeCollectionConfeirmation="closeCollectionConfeirmation"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import ModalConfirmationConf from "./ModalConfirmationConf.vue";

export default {
  components: {
    ModalConfirmationConf,
  },
  data: () => ({
    reRender: 0,
    collectionConfirm: false,
    isLoadingModal: false,
  }),
  props: {
    expedition: { required: true, type: Array },
  },
  mounted() {
    this.startOrder();
  },
  computed: {
    ...mapGetters("expedition", ["getCollectRequests"]),
    ...mapState("expedition", ["isLoading"]),
    chunkedCollectRequests() {
      const chunkSize = 4;
      const arrayCopy = [...this.getCollectRequests];
      const resultArray = [];

      while (arrayCopy.length > 0) {
        resultArray.push(arrayCopy.splice(0, chunkSize));
      }

      return resultArray;
    },
    todosPedidosClicados() {
      return this.getCollectRequests.every((pedido) => pedido.clicado);
    },
  },
  methods: {
    ...mapActions("expedition", ["collectRequests"]),

    async startOrder() {
      this.isLoadingModal = true;
      await this.collectRequests(this.expedition.coletaId);
      this.getCollectRequests.forEach((pedido) => {
        this.$set(pedido, "clicado", false);
      });
      this.isLoadingModal = false;
    },
    statusButton(pedido) {
      this.$set(pedido, "clicado", !pedido.clicado);
    },
    openCollectionConfirmation() {
      this.reRender += 1;
      this.expedition.coletaId;
      this.collectionConfirm = true;
    },

    closeCollectionConfeirmation() {
      this.collectionConfirm = false;
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  },
};
</script>
<style scoped>
.h {
  font-weight: bolder;
}
.list-header {
  background-color: #0f2733;
  color: white;
  font-weight: bolder;
  min-height: 30px;
}
.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
.list-card {
  color: black;
  font-weight: normal;
}

.list-card:hover {
  background-color: #ffdfcc;
  color: black;
}
.green {
  background-color: rgb(245, 200, 164);
  color: white;
}
</style>
