import { HttpRestService } from "@/services/http/HttpRestService";

export class NewUserRegistration {
  static tokenPass(token) {
    return HttpRestService.get(`/users/requisicao/${token}`);
  }
  static registerUserSou(modelCreateUser) {
    return HttpRestService.post(`/users/store/role`, modelCreateUser);
  }
}
