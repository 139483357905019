<template>
  <v-card>
    <v-card-title class="justify-center">
      <v-row>
        <v-col>
          <span class="text-h6 font-weight-bold navy--text"
            >Minuta de Faturamento</span
          >
        </v-col>
      </v-row>
    </v-card-title>

    <div v-if="!isLoadingModal">
      <v-row class="justify-center align-center mx-0" align="center">
        <v-col cols="4">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="dataExpeditionInicial"
                label="Data Inicial"
                placeholder="Data Inicial"
                hide-details
                readonly
                clearable
                outlined
                dense
                solo
                flat
                v-bind="attrs"
                v-on="on"
                :value="formatDate(urlParams.initialDate)"
                @click:clear="urlParams.initialDate = ''"
              >
              </v-text-field>
            </template>
            <v-date-picker
              color="primary"
              v-model="urlParams.initialDate"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="dataFaturamentoFinal"
                label="Data Final"
                placeholder="Data Final"
                hide-details
                outlined
                readonly
                clearable
                dense
                solo
                flat
                v-bind="attrs"
                v-on="on"
                :value="formatDate(urlParams.finalDate)"
                @click:clear="urlParams.finalDate = ''"
              >
              </v-text-field>
            </template>
            <v-date-picker
              color="primary"
              v-model="urlParams.finalDate"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-hover v-slot="{ hover }">
            <v-btn
              rounded
              block
              @click="startDetails()"
              :loading="isLoadingModal"
              :color="hover ? 'secondary' : 'primary'"
              class="top navy--text text-truncate"
              style="text-transform: none !important; font-weight: bolder"
              >Filtrar</v-btn
            >
          </v-hover>
        </v-col>
      </v-row>
      <v-card-text class="mt-3">
        <v-row class="list-header mx-auto" align="center">
          <v-col
            cols="1"
            align="center"
            class="pa-4 justify-center border-right d-flex"
          >
            <input
              id="select-all-checkbox"
              type="checkbox"
              @change="selectAll()"
              :disabled="!getListingManifest.length"
              :indeterminate.prop="
                selectedColetas.length < getListingManifest.length &&
                selectedColetas.length > 0
              "
              :checked="
                selectedColetas.length === getListingManifest.length &&
                getListingManifest.length > 0
              "
              class="pt-7"
            />
          </v-col>
          <v-col
            cols="2"
            align="center"
            class="border-right d-flex justify-center"
          >
            <span> Coleta </span>
          </v-col>
          <v-col cols="3" align="center" class="border-right">
            <div class="text-truncate">Data</div>
          </v-col>
          <v-col cols="6" align="start" class="justify-center">
            <span> Transportadora </span>
          </v-col>
        </v-row>
        <div v-if="getListingManifest.length" class="mb-2">
          <v-row
            align="center"
            class="list-card base_dark text--text mx-auto my-auto mt-2"
            v-for="(expedition, index) in getListingManifest"
            :key="index"
            :id="'expedition-' + expedition.coletaId"
            @click="selectOne(expedition.coletaId)"
          >
            <v-col cols="1" class="d-flex justify-center">
              <input
                type="checkbox"
                v-model="selectedColetas"
                :value="expedition.coletaId"
              />
            </v-col>
            <v-col cols="2" align="center">
              <div
                class="text-wrap font-weight-bold primary--text"
                :title="expedition.numeroPedido"
              >
                {{ expedition.coletaId }}
              </div>
            </v-col>
            <v-col cols="3" align="center">
              <div class="text-truncate" :title="expedition.dataInicioEmbarque">
                <span class="d-block my-0 mb-n3">{{
                  expedition.dataInicioEmbarque | formatDate
                }}</span>
                <span class="text-overline font-weight-light text--text">
                  {{ expedition.dataInicioEmbarque | formatHour }} -
                  {{ expedition.dataFimEmbarque | formatHour }}
                </span>
              </div>
            </v-col>
            <v-col cols="6" align="start">
              <div class="text-break" :title="expedition.transportadoraNome">
                {{ expedition.nomeFantasia }}
              </div>
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <v-row class="py-6 mt-1" justify="center">
            <v-col cols="12" align="center">
              <span class="font-weight-bold"
                >Por favor, selecione as datas para filtragem.</span
              >
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-row
        v-if="getListingManifest.length"
        justify="center"
        class="base"
        style="position: sticky; bottom: 0"
        align="center"
      >
        <v-col cols="10" sm="5">
          <v-hover v-slot="{ hover }">
            <v-btn
              block
              rounded
              :disabled="!selectedColetas.length"
              :loading="isLoadingBtn"
              color="primary"
              class="text-truncate"
              :class="hover ? 'base--text' : 'navy--text'"
              style="text-transform: none !important; font-weight: bolder"
              @click="gerarRelatorio('pdf')"
              >Gerar PDF</v-btn
            >
          </v-hover>
        </v-col>
        <v-col cols="10" sm="5">
          <v-hover v-slot="{ hover }">
            <v-btn
              block
              rounded
              max-width="20"
              :disabled="!selectedColetas.length"
              :loading="isLoadingBtnXml"
              color="primary"
              class="text-truncate"
              :class="hover ? 'base--text' : 'navy--text'"
              style="text-transform: none !important; font-weight: bolder"
              @click="gerarRelatorio('xlsx')"
              >Gerar XLSX</v-btn
            >
          </v-hover>
        </v-col>

        <v-col
          class="font-italic text-right text--text text-center"
          cols="12 "
          v-if="selectedColetas.length"
        >
          <span v-if="selectedColetas.length === 1"
            ><span class="font-weight-bold">1</span> coleta selecionada</span
          >
          <span v-else
            ><span class="font-weight-bold">{{ selectedColetas.length }}</span>
            coletas selecionadas</span
          >
        </v-col>
      </v-row>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="54"
        class="mb-5"
      ></v-progress-circular>
    </v-col>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CollectionsDownloadModal",
  data: () => ({
    selectedColetas: [],
    isLoadingModal: false,
    isLoadingBtn: false,
    isLoadingBtnXml: false,
    menu1: false,
    menu2: false,
    urlParams: {
      page: 1,
      search: "",
      initialDate: "",
      finalDate: "",
    },
  }),
  mounted() {
    this.cleanListingManifest();
  },
  computed: {
    ...mapGetters("expedition", ["getListingManifest"]),
  },
  methods: {
    formatDate(date) {
      const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
      return date.replace(regex, "$3/$2/$1");
    },
    ...mapActions("expedition", [
      "generateManifest",
      "cleanListingManifest",
      "generateManifestRelatorio",
    ]),
    async startDetails() {
      this.selectedColetas = [];
      this.isLoadingModal = true;
      await this.generateManifest(this.urlParams);
      this.isLoadingModal = false;
    },
    async gerarRelatorio(useState) {
      if (useState == "xlsx") {
        this.isLoadingBtnXml = true;
      }
      if (useState == "pdf") {
        this.isLoadingBtn = true;
      }
      const coletasId = this.getListingManifest.map(
        (coleta) => coleta.coletaId
      );
      let body = {
        coletasId: coletasId.filter((coletaId) =>
          this.selectedColetas.includes(coletaId)
        ),
      };
      const response = await this.generateManifestRelatorio({
        data: body,
        useState,
      }).then(() => {
        this.isLoadingBtn = false;
        this.isLoadingBtnXml = false;
      });
    },
    selectOne(coletaId) {
      if (this.selectedColetas.find((id) => id === coletaId)) {
        this.selectedColetas = this.selectedColetas.filter(
          (id) => id !== coletaId
        );
        document.getElementById(`expedition-${coletaId}`).style = "";
      } else {
        this.selectedColetas.push(coletaId);
        document.getElementById(`expedition-${coletaId}`).style =
          "border-bottom: 0.1rem solid #FF5C01 !important";
      }
    },
    selectAll() {
      if (this.selectedColetas.length < this.getListingManifest.length) {
        this.selectedColetas = [];
        let arrayOfColetas = [];
        for (let i = 0; i < this.getListingManifest.length; i++) {
          arrayOfColetas.push(this.getListingManifest[i].coletaId);
          document.getElementById(
            `expedition-${this.getListingManifest[i].coletaId}`
          ).style = "border-bottom: 0.1rem solid #FF5C01 !important";
        }
        this.selectedColetas = arrayOfColetas;
      } else {
        for (let i = 0; i < this.getListingManifest.length; i++) {
          document.getElementById(
            `expedition-${this.getListingManifest[i].coletaId}`
          ).style = "";
        }
        this.selectedColetas = [];
      }
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  },
};
</script>
<style scoped>
.h {
  font-weight: bolder;
}

.list-header {
  background-color: #0f2733;
  color: white;
  font-weight: bolder;
  min-height: 30px;
  margin-bottom: 0.1rem;
}

.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
.list-card {
  color: black;
  font-weight: normal;
}

.list-card:hover {
  background-color: #ffdfcc;
  color: black;
}

input[type="checkbox"] {
  accent-color: #ff5c01;
  height: 15px;
  width: 15px;
  cursor: pointer;
}
</style>
