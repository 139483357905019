export default {
  setListingPaineis(state, response) {
    state.listingPaineis = response;
  },
  setListingPaineisCubagem(state, response) {
    state.listingPaineisCubagem = response;
  },
  setPaineisPagination(state, response) {
    state.paineisPagination.total = response.total;
    state.paineisPagination.perPage = response.perPage;
    state.paineisPagination.currentPage = response.currentPage;
    state.paineisPagination.lastPage = response.lastPage;
    state.paineisPagination.nextPage = response.nextPage;
  },
  setUrlParams(state, urlParams) {
    state.urlParams.page = urlParams.page;
    state.urlParams.search = urlParams.search;
    state.urlParams.painelId = urlParams.painelId;
    state.urlParams.perPage = urlParams.perPage;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setListingPallets(state, response) {
    state.listingPallets = response;
  },
  setPalletsAmount(state, response) {
    state.listingPalletsAmount = response;
  },
};
