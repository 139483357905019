export default {
  listaColeta: [],
  coletasPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  pedidosSelected: [],
  isLoading: false,
  dataParaColetaCadastro: {},
  dataParaEditColeta: {},
  docks: [],
  isLoading: false,
  driversByTransportadora: [],
  listDrivers: [],
  listVehicles: [],
};
