var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{},[_c('v-card-title',{staticClass:"justify-center"},[_c('v-row',[_c('v-col',[_c('span',{staticClass:"text-h6 font-weight-bold navy--text"},[_vm._v("Editar Despachante")])]),_c('v-col',{attrs:{"cols":"1","align":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-truncate",class:hover ? 'base--text' : 'navy--text',staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"fab":"","small":"","elevation":"2","color":"primary"},on:{"click":function($event){return _vm.addFieldlistDespachante()}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}])})],1)],1)],1),(!_vm.isLoadingModal)?_c('div',[_c('v-card-text',[_c('v-row',{staticClass:"list-header mb-2"},[_c('v-col',{staticClass:"border-right",attrs:{"cols":"10","align":"start"}},[_c('span',{},[_vm._v("Despachante")])]),_c('v-col',{attrs:{"cols":"2","align":"center"}},[_c('span',{},[_vm._v("Edição ")])])],1),(
          _vm.getListExpeditionUserListByColetaId.length || _vm.listDespachante.length
        )?_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_vm._l((_vm.getListExpeditionUserListByColetaId),function(despachante,i){return _c('v-row',{key:i,attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-autocomplete',{ref:"emailRules",refInFor:true,attrs:{"items":_vm.getListExpeditionUserList.list,"item-text":"despachanteNome","item-value":"despachantePcemprId","v-model":_vm.userExpedition,"disabled":"","rules":_vm.emailRules,"hide-details":"","outlined":"","label":despachante.despachanteNome,"dense":"","required":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-truncate",class:hover ? 'base--text' : 'navy--text',staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"fab":"","small":"","elevation":"2","color":"primary"},on:{"click":function($event){return _vm.removeDespachanteColeta(despachante)}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)})],1)],1)}),_vm._l((_vm.listDespachante),function(email,i){return _c('v-row',{key:i,attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-autocomplete',{ref:"emailRules",refInFor:true,attrs:{"items":_vm.getListExpeditionUserList.list,"item-text":"userName","item-value":"userId","rules":_vm.emailRules,"return-object":"","hide-details":"","outlined":"","label":"Despachante","dense":"","required":""},model:{value:(_vm.userSelected[i]),callback:function ($$v) {_vm.$set(_vm.userSelected, i, $$v)},expression:"userSelected[i]"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-truncate",class:hover ? 'base--text' : 'navy--text',staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"fab":"","small":"","elevation":"2","color":"primary"},on:{"click":function($event){return _vm.removeDespachanteField(email)}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)})],1)],1)})],2):_c('div',[_c('v-col',[_c('div',{staticClass:"py-4 font-weight-bold navy--text"},[_c('span',[_vm._v(" Não existe despachante para esta coleta. ")])])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('v-btn',{staticClass:"navy--text text-truncate",staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"loading":_vm.isLoadingBtn,"color":hover ? 'secondary' : 'primary',"block":"","rounded":""},on:{"click":function($event){return _vm.updateDespachanteDaColeta()}}},[_vm._v("Salvar")])]}}],null,false,3731278387)})],1)],1)],1)],1):_c('v-col',[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"54"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }