<template>
  <div>
    <listing-head />
    <template v-if="!getIsLoadingPickups">
      <template v-if="getPickups.length > 0">
        <listing-row
          v-for="(pickup, index) in getPickups"
          :key="'pickup' + index"
          :pickup="pickup"
          @openStartShippingModal="openStartShippingModal"
        />
        <v-col>
          <default-pagination
            v-model="currentPage"
            :length="getPagination.lastPage"
            @input="changePage"
          />
        </v-col>
      </template>
    </template>
    <v-col v-else>
      <default-spinner />
    </v-col>
    <start-shipping
      :pickup="pickup"
      v-model="isStartShippingModalOpen"
      :key="startShippingModalKey"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultPagination from "../../defaultComponents/DefaultPagination.vue";
import DefaultSpinner from "../../defaultComponents/DefaultSpinner.vue";
import ListingHead from "../bahiaBranch/ListingHead.vue";
import ListingRow from "../bahiaBranch/ListingRow.vue";
import StartShipping from "./StartShipping.vue";

export default {
  name: "TheListing",
  data() {
    return {
      pickup: {},
      startShippingModalKey: 0,
    };
  },
  components: {
    DefaultPagination,
    DefaultSpinner,
    ListingHead,
    ListingRow,
    StartShipping,
  },
  created() {
    this.resetUrlParams();
    this.pickups();
  },
  computed: {
    ...mapGetters("bahiaBranch", [
      "getPickups",
      "getIsLoadingPickups",
      "getPagination",
      "getUrlParams",
      "getIsStartShippingModalOpen",
    ]),
    currentPage: {
      get() {
        return this.getUrlParams.page;
      },
      set(newValue) {
        this.setUrlParams({ ...this.getUrlParams, page: newValue });
      },
    },
    isStartShippingModalOpen: {
      get() {
        return this.getIsStartShippingModalOpen;
      },
      set(newValue) {
        this.setIsStartShippingModalOpen(newValue);
      },
    },
  },
  methods: {
    ...mapActions("bahiaBranch", [
      "pickups",
      "setUrlParams",
      "resetUrlParams",
      "setIsStartShippingModalOpen",
    ]),
    changePage() {
      this.pickups();
    },
    openStartShippingModal(pickup) {
      this.pickup = pickup;
      this.startShippingModalKey++;
      this.setIsStartShippingModalOpen(true);
    },
  },
};
</script>

<style scoped></style>
