<template>
  <v-card>
    <v-card-title class="justify-start">
      <h3 class="">Dados da Coleta</h3>
    </v-card-title>
    <v-card-text>
      <v-row class="list-header mx-auto my-auto mt-3">
        <v-col cols="3" align="start" class="justify-center border-right">
          <span class=""> Quantidade</span>
        </v-col>
        <v-col cols="6" align="start" class="justify-center">
          <span class=""> Descricao do Insumo </span>
        </v-col>
      </v-row>
    </v-card-text>
    <div v-if="!isLoading">
      <v-row
        lass="list-header mx-auto my-auto mt-3"
        v-for="(boarding, i) in getListaInsumos"
        :key="i"
      >
        <v-col cols="3" align="center" class="justify-center border-right">
          <span class="text-truncate">{{ boarding.quantidadeInsumo }}</span>
        </v-col>
        <v-col cols="6" align="start" class="justify-center">
          <span class="text-truncate">{{ boarding.descricaoInsumo }}</span>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="getListaInsumos.data">
      <v-row
        class="box-element justify-center align-center my-auto mt-3 rounded-lg"
        rounded
      >
        <v-col class="align-self-center ma-5" cols="4">
          <span
            >Não foi possível carregar os detalhes. Favor entrar em contato com
            Suporte</span
          >
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-progress-circular color="primary" indeterminate size="50" class="my-4">
      </v-progress-circular>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    isLoading: true,
  }),
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.listItens();
  },
  computed: {
    ...mapGetters("boarding", ["getListaInsumos"]),
  },
  methods: {
    ...mapActions("boarding", ["listaInsumos"]),
    async listItens() {
      await this.listaInsumos({ id: 584 });
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.h {
  font-weight: bolder;
}
.list-header {
  background-color: #0f2733;
  color: white;
  font-weight: bolder;
  min-height: 30px;
}
.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
.list-card {
  color: black;
  font-weight: normal;
}

.list-card:hover {
  background-color: #ffdfcc;
  color: black;
}
</style>
<style scoped>
.color {
  background: #002233;
  color: white;
}
</style>
