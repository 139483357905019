var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-none d-sm-none d-md-block d-lg-block d-xl-block"},[_c('v-row',{staticClass:"list-header mx-auto my-auto mt-3",attrs:{"align":"center"}},[_c('v-col',{staticClass:"justify-center border-right",attrs:{"cols":"12","sm":"2","md":"2","lg":"2","xl":"2","align":"center"}},[_c('span',{staticClass:"d-none d-sm-none d-md-block d-lg-block d-xl-block"},[_vm._v(" Data ")])]),_c('v-col',{staticClass:"justify-center border-right",attrs:{"cols":"12","sm":"3","md":"3","lg":"3","xl":"3","align":"center"}},[_c('span',{staticClass:"d-none d-sm-none d-md-block d-lg-block d-xl-block"},[_vm._v(" Transportadora ")])]),_c('v-col',{staticClass:"justify-center border-right",attrs:{"cols":"3","sm":"2","md":"2","lg":"2","xl":"2","align":"center"}},[_c('span',{staticClass:"d-none d-sm-none d-md-block d-lg-block d-xl-block"},[_vm._v(" coleta ")])]),_c('v-col',{staticClass:"justify-center border-right",attrs:{"cols":"3","sm":"1","md":"1","lg":"1","xl":"1","align":"center"}},[_c('span',{staticClass:"d-none d-sm-none d-md-block d-lg-block d-xl-block"},[_vm._v(" Doca ")])]),_c('v-col',{staticClass:"justify-center border-right",attrs:{"cols":"3","sm":"2","md":"2","lg":"2","xl":"2","align":"center"}},[_c('span',{staticClass:"d-none d-sm-none d-md-block d-lg-block d-xl-block"},[_vm._v(" Baia Expedição ")])])],1)],1),(
      !_vm.getIsLoading ||
      (Array.isArray(_vm.getListingExpedition) && _vm.getListingExpedition.length > 0)
    )?_c('div',[_vm._l((_vm.getListingExpedition),function(expedition,index){return _c('div',{key:index},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('v-row',{staticClass:"text--text mx-auto my-auto mt-2 justify-center",class:hover ? 'hoverGlobal' : 'base_dark',attrs:{"align-md":"center"}},[_c('v-col',{attrs:{"cols":"6","sm":"2","md":"2","lg":"2","xl":"2","align":"center"}},[_c('span',{staticClass:"d-md-none d-lg-none d-xl-none font-weight-bold"},[_vm._v(" Data ")]),_c('div',{staticClass:"text-truncate",attrs:{"title":expedition.dataInicioEmbarque}},[_c('p',{staticClass:"my-0 mb-n3"},[_vm._v(" "+_vm._s(_vm._f("formatHour")(expedition.dataInicioEmbarque))+" - "+_vm._s(_vm._f("formatHour")(expedition.dataFimEmbarque))+" ")]),_c('span',{staticClass:"text-overline font-weight-light text--text"},[_vm._v(_vm._s(_vm._f("formatDate")(expedition.dataInicioEmbarque)))])])]),_c('v-col',{attrs:{"cols":"6","sm":"3","md":"3","lg":"3","xl":"3","align":"center"}},[_c('span',{staticClass:"d-md-none d-lg-none d-xl-none font-weight-bold"},[_vm._v(" Transportadora ")]),_c('div',{staticClass:"text-break",attrs:{"title":expedition.transportadoraNome}},[_vm._v(" "+_vm._s(expedition.nomeFantasia)+" ")])]),_c('v-col',{attrs:{"cols":"3","sm":"2","md":"2","lg":"2","xl":"2","align":"center"}},[_c('span',{staticClass:"d-md-none d-lg-none d-xl-none font-weight-bold"},[_vm._v(" Coleta ")]),_c('div',{staticClass:"text-break",attrs:{"title":expedition.codigoColeta}},[_vm._v(" "+_vm._s(expedition.coletaCodigo)+" ")])]),_c('v-col',{attrs:{"cols":"3","sm":"1","md":"1","lg":"1","xl":"1","align":"center"}},[_c('span',{staticClass:"d-md-none d-lg-none d-xl-none font-weight-bold"},[_vm._v(" Doca ")]),_c('div',{staticClass:"text-wrap font-weight-bold",attrs:{"title":'Doca ' + expedition.codigoDoca}},[_c('v-icon',{attrs:{"size":"30","color":expedition.codigoDoca === 2
                    ? 'yellow darken-2'
                    : expedition.codigoDoca === 5
                    ? 'black'
                    : expedition.corDoca}},[_vm._v(_vm._s(("mdi-numeric-" + (expedition.codigoDoca) + "-circle-outline"))+" ")])],1)]),_c('v-col',{attrs:{"cols":"3","sm":"2","md":"2","lg":"2","xl":"2","align":"center"}},[_c('span',{staticClass:"d-md-none d-lg-none d-xl-none font-weight-bold"},[_vm._v(" Baia Expedição ")]),(expedition.baias && expedition.baias.length > 0)?_c('div',{staticClass:"text-break"},_vm._l((expedition.baias),function(baia,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm.formatBaiaDescription(baia.descricao))+" ")])}),0):_vm._e()]),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","sm":"12","md":"2","lg":"2","xl":"2","align":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-truncate px-2",class:hover ? 'base--text' : 'navy--text',staticStyle:{"text-transform":"none !important","font-weight":"bolder"},attrs:{"block":"","rounded":"","color":"primary","disabled":expedition.baias.length == 0 ||
                  expedition.conferenciaBaia == true},on:{"click":function($event){return _vm.openCollectionConference(expedition)}}},[(expedition.conferenciaBaia == false)?_c('span',[_vm._v("Conferir Coleta")]):_vm._e(),(expedition.conferenciaBaia == true)?_c('span',[_vm._v("Coleta Conferida")]):_vm._e()])]}}],null,true)})],1)],1)]}}],null,true)})],1)}),_c('v-dialog',{attrs:{"max-width":"720","content-class":"hide-overflow-x"},model:{value:(_vm.collectionConference),callback:function ($$v) {_vm.collectionConference=$$v},expression:"collectionConference"}},[_c('collection-conference',{key:_vm.reRender,attrs:{"expedition":_vm.expedition},on:{"closeCollectionConference":_vm.closeCollectionConference}})],1),_c('v-row',{staticClass:"justify-center mb-3 mt-3"},[_c('v-pagination',{attrs:{"length":_vm.getExpeditionPagination.lastPage,"total-visible":7,"prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right"},on:{"input":function($event){return _vm.proxPage(_vm.getExpeditionPagination.currentPage)}},model:{value:(_vm.getExpeditionPagination.currentPage),callback:function ($$v) {_vm.$set(_vm.getExpeditionPagination, "currentPage", $$v)},expression:"getExpeditionPagination.currentPage"}})],1)],2):_c('v-col',[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }