export default {
  listingProtocols:[],
  protocolsPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  isLoadingButton: false,
  checkedList:[],
  isLoadingCreate:false,
};
