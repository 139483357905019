import { HttpRestService } from "@/services/http/HttpRestService";

export class ColetaRepository {
  static requestColeta(url) {
    return HttpRestService.get(url);
  }

  static dataColetaCadastro(transportadoraId) {
    return HttpRestService.get(
      `/transportadora/${transportadoraId}/remessa/cadastro`
    );
  }

  static cadastrarColeta(payload) {
    return HttpRestService.post(`/coleta/criar`, payload);
  }

  static editarColeta(payload) {
    return HttpRestService.post(`/coleta/editar`, payload);
  }

  static dataParaEditarColeta(coletaId) {
    return HttpRestService.get(`/coleta/${coletaId}`);
  }

  static listDocks() {
    return HttpRestService.get(`/docas/listar`);
  }

  static requestConfirmCancellation(modelConfirmCancellation) {
    return HttpRestService.post(`/coleta/cancelar`, modelConfirmCancellation);
  }
}
