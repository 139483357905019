export default {
  setDataReception(state, response) {
    state.receptionData = response;
  },
  setIsLoading(state, response) {
    state.isLoadingButton = response;
  },
  setReceptionList(state, response) {
    state.receptionList = response;
  },
  setReceptionDetails(state, response) {
    state.receptionDetails = response;
  },
  setReceptionPagination(state, response) {
    state.receptionPagination.total = response.total;
    state.receptionPagination.perPage = response.perPage;
    state.receptionPagination.currentPage = response.currentPage;
    state.receptionPagination.lastPage = response.lastPage;
    state.receptionPagination.nextPage = response.nextPage;
  },
  setDataInicial(state, value) {
    state.dataInicial = value;
  },
  setUrlParams(state, urlParams) {
    state.urlParams.page = urlParams.page;
    state.urlParams.transportadoraNome = urlParams.transportadora;
    state.urlParams.placa = urlParams.placa;
    state.urlParams.coleta = urlParams.coleta;
  },
  setLoadingReception(state, value) {
    state.isLoading = value;
  },
};
