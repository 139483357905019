<template>
  <v-card flat>
    <v-navigation-drawer
      width="280"
      floating
      :temporary="miniResponsive"
      app
      color="base_dark"
      id="sidebar"
      v-model="drawerModel"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="mt-2">
            <v-img
              src="/img/logo2.svg"
              class="mt-0 mb-5 justify-center"
              contain
              height="50"
            />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list dense nav>
        <v-list-item-group mandatory :value="selectedMenuItem" color="primary">
          <div v-for="(sidebarView, index) in shownSidebarViews" :key="index">
            <template v-if="!sidebarView.children">
              <v-list-item :to="sidebarView.path" dense>
                <v-list-item-icon class="mr-4">
                  <v-icon>{{ sidebarView.meta.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="line-height: 1.2rem"
                    class="font-weight-medium text-body-1 text-left align-self-start"
                  >
                    {{ sidebarView.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-group
              v-else
              :prepend-icon="sidebarView.meta.icon"
              no-action
              :value="
                new RegExp(`^${sidebarView.path}/`).test($route.path + '/')
              "
            >
              <template v-slot:activator>
                <v-list-item-content class="ml-n4">
                  <v-list-item-title
                    style="line-height: 1.2rem"
                    class="font-weight-medium text-body-1 text-left align-self-start"
                    v-text="sidebarView.name"
                  />
                </v-list-item-content>
              </template>
              <div
                class="text-left"
                v-for="(childView, index) in sidebarView.children"
                :key="index"
              >
                <v-list-item
                  dense
                  :to="sidebarView.path + '/' + childView.path"
                  v-if="getMyPermissions.includes(childView.meta.permission)"
                >
                  <v-list-item-title class="ml-11">
                    {{ childView.name }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list-group>
          </div>
          <v-list-item dense class="mt-5" @click="logout()">
            <v-list-item-icon class="mr-4 pl-0">
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="font-weight-medium text-body-1 text-start align-self-center"
              >Sair</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { sidebarViews } from "../../../router";

export default {
  name: "SibeBarComponent",
  component: {},
  data() {
    return {
      model: 0,
      modelTransportadora: 0,
    };
  },
  props: {
    relative: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions("login", ["logout"]),
  },
  computed: {
    ...mapGetters("login", [
      // "getAdministrator",
      "getMyPermissions",
      "getRoleName",
    ]),
    ...mapGetters("menu", ["getDrawer"]),
    miniResponsive() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    drawerModel: {
      get() {
        return this.$store.state.menu.drawer;
      },
      set(value) {
        this.$store.dispatch("menu/setDrawer", value);
      },
    },
    selectedMenuItem() {
      return this.shownSidebarViews.findIndex((sidebarView) =>
        new RegExp(`^${sidebarView.path}/`).test(this.$route.path + "/")
      );
    },
    sidebarViews() {
      return sidebarViews;
    },
    shownSidebarViews() {
      return sidebarViews.filter((sidebarView) => {
        const routeNeedsPermission = sidebarView?.meta?.needsPermission
          ? true
          : false;
        const isRouteSubmenu = sidebarView.children ? true : false;
        if (isRouteSubmenu) {
          return sidebarView.children.some((child) =>
            this.getMyPermissions.includes(child.meta.permission)
          );
        } else {
          if (routeNeedsPermission) {
            return this.getMyPermissions.includes(sidebarView.meta.permission);
          }
          return true;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.v-sidebar-menu {
  background-color: #dadfe0 !important;
}
.v-sidebar-menu.vsm_expanded {
  max-width: 280px !important;
  .logo {
    display: block;
    max-width: 125px;
    margin-bottom: 3rem;
    margin-top: 1rem;
    margin-left: 2rem;
  }
  .petalas {
    display: none;
  }
}
.v-sidebar-menu.vsm_collapsed {
  .logo {
    display: none;
  }
  .petalas {
    max-width: 35px;
    display: block;
    margin: 0.5rem;
  }
}
.v-sidebar-menu .vsm--title {
  color: #0f2733;
}
.v-sidebar-menu .vsm--toggle-btn {
  background-color: #dadfe0 !important;
}
</style>
