import { render, staticRenderFns } from "./DefaultButton.vue?vue&type=template&id=8dd8778a&scoped=true&"
import script from "./DefaultButton.vue?vue&type=script&lang=js&"
export * from "./DefaultButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dd8778a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
installComponents(component, {VBtn,VHover})
