<template>
  <div>
    <v-card-text style="padding: 0px !important">
      <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
        <v-row class="list-header mx-auto my-auto mt-3">
          <v-col
            cols="1"
            align="start"
            class="justify-center border-right d-none d-sm-none d-md-block d-lg-block d-xl-block"
          >
            <span>Remessa</span>
          </v-col>
          <v-col
            cols="1"
            align="start"
            class="justify-center border-right d-none d-sm-none d-md-block d-lg-block d-xl-block"
          >
            <span> Veículos</span>
          </v-col>
          <v-col
            cols="1"
            align="center"
            class="justify-center border-right d-none d-sm-none d-md-block d-lg-block d-xl-block text-truncate"
          >
            <span>Data</span>
          </v-col>
          <v-col
            cols="2"
            align="center"
            class="justify-center border-right d-none d-sm-none d-md-block d-lg-block d-xl-block"
          >
            <span>Status</span>
          </v-col>
          <v-col
            cols="2"
            align="center"
            class="justify-center border-right d-none d-sm-none d-md-block d-lg-block d-xl-block"
          >
            <span>Data Confirmação</span>
          </v-col>
          <v-col
            cols="2"
            align="center"
            class="justify-center border-right d-none d-sm-none d-md-block d-lg-block d-xl-block"
          >
            <span>Data Reenvio</span>
          </v-col>
          <v-col cols="1" class="justify-center"> </v-col>
        </v-row>
      </div>
      <div v-if="!getIsLoading">
        <v-row
          class="list-card base_dark text--text mx-auto my-auto mt-2"
          :style="
            statusRemessaDivergente.includes(remessas.remessaStatusDescricao)
              ? `border-bottom: 0.1rem solid #ff5c01 !important`
              : 'base_dark'
          "
          v-for="(remessas, i) in getListaRemessas"
          :key="i"
          align="center"
        >
          <v-row
            class="mx-auto my-auto mt-2"
            :class="
              remessas.remessaStatusDescricao === 'CANCELADO'
                ? 'cancelled-row'
                : ''
            "
          >
            <v-col cols="6" sm="3" md="1" lg="1" xl="1" align="center"
              ><span class="d-md-none d-lg-none d-xl-none"><b>Remessa</b></span>
              <div
                class="text-truncate font-weight-bold"
                :class="
                  remessas.remessaStatusDescricao === 'CANCELADO'
                    ? 'cancelled-row'
                    : 'primary--text'
                "
              >
                {{ remessas.remessaId }}
              </div></v-col
            >
            <v-col cols="6" sm="2" md="1" lg="1" xl="1" align="center"
              ><span class="d-md-none d-lg-none d-xl-none"
                ><b>Veículos</b></span
              >

              <div v-if="remessas.remessaStatusDescricao === 'EMAIL_ENVIADO'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <span class="text-truncate">{{ remessas.veiculos }}</span>
                      <v-badge dot inline color="blue"> </v-badge>
                    </div>
                  </template>
                  <span>Aguardando Confirmação</span>
                </v-tooltip>
              </div>
              <div v-else class="text-truncate">
                <span class="mr-4">{{ remessas.veiculos }}</span>
              </div>
            </v-col>
            <v-col cols="6" sm="3" md="1" lg="1" xl="1" align="center"
              ><span class="d-md-none d-lg-none d-xl-none"
                ><b>Data da Remessa</b></span
              >
              <div class="text-wrap">
                {{ remessas.data | formatDate }}
              </div></v-col
            >
            <v-col cols="6" sm="4" md="2" lg="2" xl="2" align="center"
              ><span class="d-md-none d-lg-none d-xl-none"><b>Status</b></span>
              <div class="text-wrap secondary--text font-weight-bold">
                <div
                  v-if="
                    statusRemessaDivergente.includes(
                      remessas.remessaStatusDescricao
                    )
                  "
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        class="pa-2 text-truncate elevation-1"
                        text-color="white"
                        @click="openDialogDivergencia(remessas.remessaId)"
                        :color="colorChip(remessas.remessaStatusDescricao)"
                        v-on="on"
                      >
                        {{ remessas.remessaStatusDescricao | filterStatus }}
                      </v-chip>
                    </template>
                    <span>Detalhes da Divergência</span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <v-chip
                    class="pa-2 text-truncate"
                    :text-color="
                      remessas.remessaStatusDescricao === 'CANCELADO'
                        ? 'grey'
                        : 'white'
                    "
                    :color="colorChip(remessas.remessaStatusDescricao)"
                  >
                    {{ remessas.remessaStatusDescricao | filterStatus }}
                  </v-chip>
                </div>
              </div>
            </v-col>
            <v-col
              cols="6"
              sm="3"
              md="2"
              lg="2"
              xl="2"
              align="center "
              v-if="remessas.dataConfirmacao === 'Pendente'"
              ><span class="d-md-none d-lg-none d-xl-none"
                ><b>Dt. Confirmação</b></span
              >
              <div class="text-wrap secondary--text font-weight-bold">
                <v-chip
                  class="pa-2 text-truncate"
                  :text-color="
                    remessas.remessaStatusDescricao === 'CANCELADO'
                      ? 'grey'
                      : 'white'
                  "
                  :color="
                    remessas.remessaStatusDescricao === 'CANCELADO'
                      ? 'cancelled-row'
                      : 'orange'
                  "
                >
                  {{ remessas.dataConfirmacao }}
                </v-chip>
              </div>
            </v-col>
            <v-col cols="6" sm="3" md="2" lg="2" xl="2" align="center " v-else>
              <span class="d-md-none d-lg-none d-xl-none"
                ><b>Dt. Confirmação</b></span
              >
              <div class="text-truncate">
                {{ remessas.dataConfirmacao | formatDate }}
                {{ remessas.dataConfirmacao | formatHour }}
              </div>
            </v-col>
            <v-col cols="6" sm="3" md="2" lg="2" xl="2" align="center"
              ><span class="d-md-none d-lg-none d-xl-none"
                ><b>Dt. Reenvio</b></span
              >
              <div class="text-truncate">
                {{ remessas.dataEnvioEmail | formatDate }}
                {{ remessas.dataEnvioEmail | formatHour }}
                <!-- {{ remessas.dataEnvioEmail  }} -->
              </div>
            </v-col>
            <v-col cols="2" sm="2" md="1" lg="1" xl="1" align="center">
              <div>
                <v-hover v-slot="{ hover }">
                  <v-btn
                    fab
                    small
                    elevation="2"
                    color="primary"
                    class="text-truncate"
                    :class="hover ? 'base--text' : 'navy--text'"
                    v-if="
                      statusRemessaDivergente.includes(
                        remessas.remessaStatusDescricao
                      )
                    "
                    @click="
                      openDivergence(
                        remessas.remessaId,
                        remessas.remessaStatusDescricao
                      )
                    "
                    :disabled="
                      habilitateDivergenceButton(remessas.atualizadoEm)
                    "
                    style="text-transform: none !important; font-weight: bolder"
                  >
                    <v-icon> mdi-exclamation-thick </v-icon>
                  </v-btn>
                </v-hover>
              </div>
            </v-col>
            <v-col
              :cols="
                statusRemessaDivergente.includes(
                  remessas.remessaStatusDescricao
                )
                  ? '10'
                  : '12'
              "
              sm="4"
              md="2"
              lg="2"
              xl="2"
              align-self="center"
            >
              <v-hover v-slot="{ hover }">
                <v-btn
                  block
                  rounded
                  :disabled="remessas.remessaStatusDescricao == 'CANCELADO'"
                  color="primary"
                  class="text-truncate"
                  :class="hover ? 'base--text' : 'navy--text'"
                  max-width="160px"
                  style="text-transform: none !important; font-weight: bolder"
                  @click="
                    abrirTelaEdicaoRemessas(
                      remessas.remessaId,
                      remessas.remessaStatusDescricao,
                      remessas.remessaStatusId
                    )
                  "
                >
                  Editar Remessa
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
        </v-row>
      </div>
      <div v-else-if="getListaRemessas.length">
        <v-row
          class="box-element justify-center align-center my-auto mt-3 rounded-lg"
          rounded
        >
          <v-col class="align-self-center ma-5" cols="4">
            <span
              >Não foi possível carregar os dados de Remessa. Favor entrar em
              contato com Suporte</span
            >
          </v-col>
        </v-row>
      </div>
      <div v-else class="ma-3">
        <v-progress-circular
          color="primary"
          indeterminate
          size="54"
        ></v-progress-circular>
      </div>
    </v-card-text>
    <v-row class="justify-center my-3">
      <v-pagination
        v-model="getRemessasPagination.currentPage"
        :length="getRemessasPagination.lastPage"
        @input="proxPage(getRemessasPagination.currentPage)"
        :total-visible="7"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
      >
      </v-pagination>
    </v-row>
    <v-dialog
      v-model="divergenceDialog"
      scrollable
      max-width="280"
      transition="dialog-bottom-transition"
    >
      <divergence-dialog
        :key="reRender"
        :remessaId="remessaId"
        :remessaStatus="remessaStatus"
        @closeDivergenceDialog="closeDivergenceDialog"
        @listItens="listItens"
      />
    </v-dialog>
    <v-dialog
      v-model="divergenciaDialog"
      scrollable
      max-width="390"
      transition="dialog-bottom-transition"
    >
      <divergente-modal :remessasId="remessasId" :key="reRenderDivergencia" />
    </v-dialog>
    <v-dialog
      v-model="divergenciaDialogTime"
      scrollable
      max-width="390"
      transition="dialog-bottom-transition"
    >
      <divergence-time-modal
        :key="reRenderDivergenciaTime"
        :remessasId="remessasId"
      />
    </v-dialog>
  </div>
</template>

<script>
import DivergenceDialog from "./DivergenceDialog.vue";
import { mapGetters, mapActions } from "vuex";
import DivergenteModal from "./DivergenteModal.vue";
import DivergenceTimeModal from "./DivergenceTimeModal.vue";
export default {
  components: { DivergenteModal, DivergenceDialog, DivergenceTimeModal },
  data: () => ({
    statusRemessaDivergente: [
      "QUANTIDADE_VEICULOS_DIVERGENTE",
      "DIVERGENCIA_HORARIO_DATA",
    ],
    divergenceDialog: false,
    divergenciaDialogTime: false,
    remessaId: 0,
    remessasObj: {},
    reRender: 0,
    remessasId: 0,
    remessaStatus: "",
    dialog: false,
    isLoading: false,
    hover: true,
    divergenciaDialog: false,
    reRenderDivergencia: 0,
    reRenderDivergenciaTime: 0,
    urlParams: {
      page: 1,
      search: "",
      selecaoStatus: "",
      selecaoDate: "",
      selecaoTipo: 0,
      transportadoraId: 0,
    },
  }),
  mounted() {
    this.getListaRemessas;
    if (
      this.$route.params.cnpj &&
      this.$route.params.nomeFantasia
      // &&
      // this.$route.params.remessaId
    ) {
      let data = {
        remessaId: this.$route.params.remessaId,
        cnpj: this.$route.params.cnpj,
        nomeFantasia: this.$route.params.nomeFantasia,
        transportadoraId: this.$route.params.transportadoraId,
      };
      this.setTransportadoraData(data);
    }
    this.urlParams.transportadoraId = this.$route.params.transportadoraId;
    this.listItens();
  },
  computed: {
    ...mapGetters("remessas", [
      "getListaRemessas",
      "getRemessasPagination",
      "getTransportadoraData",
      "getIsLoading",
      "getUrlParams",
    ]),
  },
  methods: {
    ...mapActions("remessas", [
      "listarRemessas",
      "setTransportadoraData",
      "setIsLoading",
      "setUrlParams",
    ]),

    habilitateDivergenceButton(lastEditedDate) {
      var actualTime = new Date();
      var updatedTime = new Date(lastEditedDate);

      var differenceInMinutes =
        (actualTime.getTime() - updatedTime.getTime()) / 1000 / 60;
      return differenceInMinutes <= 10 ? false : true;
    },

    async proxPage(page) {
      this.setIsLoading(true);
      let tempUrl = this.getUrlParams;
      this.urlParams = tempUrl;
      this.urlParams.page = page;
      await this.setUrlParams(this.urlParams);
      await this.listarRemessas();
      this.setIsLoading(false);
    },
    async listItens() {
      this.setIsLoading(true);
      this.urlParams.transportadoraId =
        this.getTransportadoraData.transportadoraId;
      await this.setUrlParams(this.urlParams);
      await this.listarRemessas();
      this.setIsLoading(false);
    },
    abrirTelaEdicaoRemessas(remessaId, remessaStatus, remessaStatusId) {
      this.$router.push({
        name: "Edição de Coletas",
        params: {
          remessaId: remessaId,
          transportadoraId: this.getTransportadoraData.transportadoraId,
          cnpj: this.getTransportadoraData.cnpj,
          nomeFantasia: this.getTransportadoraData.nomeFantasia,
        },
        query: {
          remessaStatus: remessaStatus,
          remessaStatusId: remessaStatusId,
        },
      });
    },
    openDivergence(remessaId, remessaStatus) {
      this.remessaId = remessaId;
      this.remessaStatus = remessaStatus;
      this.divergenceDialog = true;
      this.reRender += 1;
    },

    closeDivergenceDialog() {
      this.divergenceDialog = false;
    },
    openDialogDivergencia(remessaId) {
      this.getListaRemessas.forEach((remessas) => {
        if (remessas.remessaStatusDescricao == "DIVERGENCIA_HORARIO_DATA") {
          this.divergenciaDialogTime = true;
        } else if (
          remessas.remessaStatusDescricao == "QUANTIDADE_VEICULOS_DIVERGENTE"
        ) {
          this.divergenciaDialog = true;
        }
      });
      this.reRenderDivergenciaTime += 1;
      this.reRenderDivergencia += 1;
      this.remessasId = remessaId;
    },

    colorChip(value) {
      switch (value) {
        case "PENDENTE_ENVIO_EMAIL":
          return "orange";
        case "FALHA_ENVIO_EMAIL":
          return "red";
        case "EMAIL_ENVIADO":
          return "blue";
        case "QUANTIDADE_VEICULOS_DIVERGENTE":
        case "DIVERGENCIA_HORARIO_DATA":
          return "red";
        case "CONFIRMADO_PELA_TRANSPORTADORA":
          return "green";
        case "EM_ANDAMENTO":
          return "orange";
        case "CONCLUIDO":
          return "green";
        case "CANCELADO":
          return "gray";
      }
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
    filterStatus(value) {
      switch (value) {
        case "PENDENTE_ENVIO_EMAIL":
          return "Pendente";
        case "FALHA_ENVIO_EMAIL":
          return "Falha Email";
        case "EMAIL_ENVIADO":
          return "Email Enviado";
        case "QUANTIDADE_VEICULOS_DIVERGENTE":
          return "Veículo Divergente";
        case "DIVERGENCIA_HORARIO_DATA":
          return "Tempo Divergente";
        case "CONFIRMADO_PELA_TRANSPORTADORA":
          return "Confirmado";
        case "EM_ANDAMENTO":
          return "Em Andamento";
        case "CONCLUIDO":
          return "Concluído";
        case "CANCELADO":
          return "Cancelado";
      }
    },
  },
};
</script>

<style scoped>
.cancelled-row {
  color: rgba(149, 149, 149, 0.6);
}
.list-header {
  background-color: #0f2733;
  color: white;
  font-weight: bolder;
  min-height: 30px;
}
.border-right {
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
}
.list-card {
  color: black;
  font-weight: normal;
}
.list-card:hover {
  background-color: #ffdfcc;
  color: black;
}
.base-error {
  border-bottom: 0.1rem solid #ff5c01 !important;
}
</style>
