export default {
  getListingPrevisaoPatio(state) {
    return state.listingPrevisaoPatio;
  },

  getIsLoading(state) {
    return state.isLoading;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
};
