export default {
  errorNotification: {
    errorStatus: false,
    errorMessage: "",
  },
  successNotification: {
    successStatus: false,
    successMessage: "",
  },
  systemNotification: {
    systemStatus: false,
    systemMessage: "",
  },
  apiResponseNotification: "",
};
