<template>
  <default-modal v-model="dialogModel" width="550">
    <v-container class="py-0">
      <v-form ref="form">
        <v-card-title class="justify-center font-weight-bold">{{
          modalTitle
        }}</v-card-title>
        <v-card-subtitle
          ><span
            >Coleta
            <span>{{ pickup.coletaId }}</span>
          </span></v-card-subtitle
        >
        <v-row dense>
          <v-col
            ><default-text-field
              class="mb-n2"
              v-model="protocol"
              label="Protocolo"
              :hide-details="false"
              :rules="[rules.required]"
          /></v-col>
        </v-row>
        <v-col cols="8" offset="2"
          ><default-button :loading="isLoading" @click="checkProtocol"
            >Conferir Protocolo</default-button
          ></v-col
        >
      </v-form>
    </v-container>
  </default-modal>
</template>

<script>
import { mapActions } from "vuex";
import DefaultButton from "../../defaultComponents/DefaultButton.vue";
import DefaultModal from "../../defaultComponents/DefaultModal.vue";
import DefaultTextField from "../../defaultComponents/DefaultTextField.vue";

export default {
  name: "StartShipping",
  data() {
    return {
      protocol: "",
      isLoading: false,
    };
  },
  components: {
    DefaultButton,
    DefaultModal,
    DefaultTextField,
  },
  props: {
    value: {},
    pickup: {},
  },
  methods: {
    async checkProtocol() {
      this.isLoading = true;
      if (this.isFormValid) {
        const success = await this.$store.dispatch(
          "bahiaBranch/checkProtocol",
          {
            protocol: this.protocol,
            pickupId: this.pickup.coletaId,
          }
        );

        if (success) {
          this.dialogModel = false;
          await this.$router.push({
            name: "Embarque de Coleta (Filial Bahia)",
            params: { coletaId: this.pickup.coletaId },
          });
        }
      }
      this.isLoading = false;
    },
  },
  computed: {
    dialogModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    rules() {
      return {
        required: (input) => !!input || "Campo obrigatório",
      };
    },
    isFormValid() {
      return this.$refs.form.validate();
    },
    modalTitle() {
      const pickupStatuses = {
        AGUARDANDO_TRANSPORTADORA: "Iniciar Embarque",
        CONFIRMACAO_TRANSPORTADORA: "Iniciar Embarque",
        COLETA_INICIADA: "Embarque Iniciado",
        COLETA_PAUSADA: "Continuar Embarque",
      };
      return pickupStatuses[this.pickup.status] ?? this.pickup.status;
    },
  },
};
</script>

<style scoped></style>
