export default {
  setListingExpedition(state, response) {
    state.listingExpedition = response;
  },

  setExpeditionPagination(state, response) {
    state.expeditionPagination.total = response.total;
    state.expeditionPagination.perPage = response.perPage;
    state.expeditionPagination.currentPage = response.currentPage;
    state.expeditionPagination.lastPage = response.lastPage;
    state.expeditionPagination.nextPage = response.nextPage;
  },

  setCollectRequests(state, response) {
    state.collectionRequest = response;
  },

  setIsLoading(state, value) {
    state.isLoading = value;
  },

  setForklifts(state, response) {
    state.forklifts = response;
  },

  setDocks(state, response) {
    state.docks = response;
  },

  setDrivers(state, response) {
    state.drivers = response;
  },

  setProtocolDetails(state, response) {
    state.protocolDetails = response;
  },

  setColetaDetalhes(state, coletaDetalhes) {
    console.log({ coletaDetalhes });
    state.coletaDetalhes = coletaDetalhes;
  },

  setIsLoadingBiparVolume(state, value) {
    state.isLoadingBiparVolume = value;
  },

  setIsLoadingReload(state, value) {
    state.isLoadingReload = value;
  },

  setUrlParams(state, urlParams) {
    state.urlParams.page = urlParams.page;
    state.urlParams.search = urlParams.search;
    state.urlParams.codigo = urlParams.codigo;
    state.urlParams.currentDay = urlParams.currentDay;
    state.urlParams.initialDate = urlParams.initialDate;
    state.urlParams.finalDate = urlParams.finalDate;
  },

  setVolumesComErros(state, response) {
    state.volumesComErros = response;
  },

  setModalVolumesComErros(state, value) {
    state.modalVolumesComErros = value;
  },
  setDataExpeditionCurrentDay(state, value) {
    state.dataExpeditionCurrentDay = value;
  },
  setDataExpeditionInicial(state, value) {
    state.dataExpeditionInicial = value;
  },
  setDataExpeditionFinal(state, value) {
    state.dataExpeditionFinal = value;
  },

  setDataFaturamentoInicial(state, value) {
    state.dataFaturamentoInicial = value;
  },
  setDataFaturamentoFinal(state, value) {
    state.dataFaturamentoFinal = value;
  },

  setListExpeditionUserList(state, response) {
    state.listExpeditionUserList = response;
  },

  setListExpeditionUserListByColetaId(state, response) {
    state.listExpeditionUserListByColetaId = response;
  },

  setListingManifest(state, response) {
    state.listingManifest = response;
  },
  addCheckedList(state, response) {
    state.checkedList.push(response);
  },
  cleanCheckedList(state) {
    state.checkedList = [];
  },

  setProtocolData(state, response) {
    state.protocolDataList = response;
  },

  removeCheckedList(state, response) {
    for (var i = 0; i < state.checkedList.length; i++) {
      if (state.checkedList[i] === response) {
        state.checkedList.splice(i, 1);
      }
    }
  },

  setShowPendingVolumes(state, payload) {
    state.pickupOrdersUrlParams.pendente = payload;
  },

  setShowLoadedVolumes(state, payload) {
    state.pickupOrdersUrlParams.embarcado = payload;
  },

  setShowAllVolumes(state, payload) {
    state.pickupOrdersUrlParams.emAndamento = payload;
  },

  setPickupOrders(state, payload) {
    state.pickupOrders = payload;
  },

  setPickupOrdersPagination(state, payload) {
    state.pickupOrdersPagination = payload;
  },

  setPickupOrdersUrlParams(state, payload) {
    state.pickupOrdersUrlParams = payload;
  },

  setIsLoadingPickupOrders(state, payload) {
    state.isLoadingPickupOrders = payload;
  },

  setIsPickupOrdersModalOpen(state, payload) {
    state.isPickupOrdersModalOpen = payload;
  },

  setDetailsPedido(state, payload) {
    state.detailsPedido = payload;
  },

  setIsLoadingPickupDetails(state, payload) {
    state.isLoadingPickupDetails = payload;
  },
};
