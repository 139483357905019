import { HttpRestService } from "@/services/http/HttpRestService";

export class ReceptionRepository {
  static requestReception(payload) {
    return HttpRestService.get(`/protocolo/detalhes/${payload}`);
  }

  static requestReceptionDetails(coletaId) {
    return HttpRestService.get(`/coleta/detalhes/protocolo/${coletaId}`);
  }

  static confirmDriver(coletaId) {
    return HttpRestService.post(`/motorista/confirma/${coletaId}`);
  }
  static receptionList(url) {
    return HttpRestService.get(url);
  }
  static sendMotivoAtraso(data) {
    return HttpRestService.put("/coleta/atraso/motivar", data);
  }
}
