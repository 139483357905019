export default {
  getErrorNotification(state) {
    return state.errorNotification;
  },

  getSuccessNotification(state) {
    return state.successNotification;
  },

  getSystemNotification(state) {
    return state.systemNotification;
  },

  getResponseApiNotification(state) {
    return state.apiResponseNotification;
  },
};
