export default {
  getListingMonPatio(state) {
    return state.listingMonPatio;
  },

  getMonPatioPagination(state) {
    return state.monPatioPagination;
  },

  getIsLoading(state) {
    return state.isLoading;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
  getBaiaDetails(state) {
    return state.baiaDetails;
  },
  getIsLoadingBaiaList(state) {
    return state.isLoadingBaiaList;
  },
};
