<template>
  <v-app id="app">
    <div class="background" style="height: 100%">
      <navbar-component
        v-if="
          !['Login'].includes($route.name) &&
          !['RegistroTransportadora'].includes($route.name) &&
          !['CadastroDeUsuario'].includes($route.name)
        "
        :name="$route.name"
      />
      <sidebar-component
        v-if="
          !['Login'].includes($route.name) &&
          !['RegistroTransportadora'].includes($route.name) &&
          !['CadastroDeUsuario'].includes($route.name)
        "
      />
      <div>
        <v-main>
          <router-view />
        </v-main>
      </div>
    </div>
    <div class="overflow-hidden float-end teste">
      <error-alert />
      <success-alert />
      <notification-component />
    </div>
  </v-app>
</template>

<script>
import NavbarComponent from "@/components/defaultUserComponents/menu/NavBarComponent.vue";
import SidebarComponent from "@/components/defaultUserComponents/menu/SideBarComponent.vue";
import ErrorAlert from "@/components/defaultUserComponents/messages/ErrorAlert.vue";
import SuccessAlert from "@/components/defaultUserComponents/messages/SuccessAlert.vue";
import NotificationComponent from "./components/defaultUserComponents/messages/NotificationComponent.vue";

export default {
  name: "App",
  components: {
    NavbarComponent,
    SidebarComponent,
    ErrorAlert,
    SuccessAlert,
    NotificationComponent,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // background-color: #eeeeee;
  text-transform: none !important;
}
.container {
  max-width: 100% !important;
}
.teste {
  position: absolute;
  right: 0%;
  top: 0%;
  z-index: 2000;
}
</style>
