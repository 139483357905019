export default {
  orders: [],
  isLoadingOrders: false,
  pagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  isBlockingDetailsModalOpen: false,
  isBlockingModalOpen: false,
  blockingReasons: [],
  urlParams: {
    page: 1,
    perPage: 10,
    numeroPedido: "",
    pedidoWeb: "",
    transportadoraNome: "",
    // os filtros abaixo são placeholders
    // o backend ainda vai criá-los
    requester: "",
    integrator: "",
    startDate: "",
    endDate: "",
  },
  blockingDetails: {},
  isLoadingDetails: false,
};
