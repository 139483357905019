import { NotificationRepository } from "../../../repositories/defaultUserRepositories/NotificationRepository";

export default {
  dismissErrorNotification({ commit }) {
    let payload = {
      errorStatus: false,
      errorMessage: "",
    };
    commit("setErrorNotification", payload);
    return;
  },

  dismissSuccessNotification({ commit }) {
    let payload = {
      errorStatus: false,
      errorMessage: "",
    };
    commit("setSuccessNotification", payload);
    return;
  },

  async dismissSystemNotification({ commit, dispatch }, payload) {
    let bodySend = {
      notificacaoId: payload.id,
    };
    const response = await NotificationRepository.dismissNotification(bodySend);
    if (response.status >= 200 && response.status < 300) {
      // commit("showingSystemNotification", false);
      // dispatch("getNotificationList");
      return;
    } else {
      commit("setErrorNotification", {
        errorStatus: true,
        errorMessage: "Erro ao tentar remover notificação",
      });

      return;
    }
    // commit("setSystemNotification", payload);
    // return;
  },

  showListNotification({ commit }, payload) {
    commit("showingSystemNotification", payload);
    return;
  },

  async getNotificationList({ commit }) {
    const notificationConfig = {
      "Coleta Criada": {
        color: "info",
        icon: "mdi-calendar-clock",
      },
      "Coleta Editada": {
        color: "warning",
        icon: "mdi-pencil-box-outline",
      },
      "Coleta Pausada": {
        color: "warning",
        icon: "mdi-pause-circle-outline",
      },
      "Divergência Criada": {
        color: "red",
        icon: "mdi-alert-circle-outline",
      },
      "Coleta Cancelada": {
        color: "red",
        icon: "mdi-close-circle-outline",
      },
      "Veículo Alterado": {
        color: "warning",
        icon: "mdi-truck",
      },
      "Coleta Agendada": {
        color: "success",
        icon: "mdi-calendar-clock",
      },
      "Coleta Finalizada": {
        color: "primary",
        icon: "mdi-check-circle-outline",
      },
      "Divergência Criada": {
        color: "red",
        icon: "mdi-alert-circle-outline",
      },
      "Remessa Criada": {
        color: "info",
        icon: "mdi-package-variant-closed",
      },
      "Remessa Cancelada": {
        color: "dark",
        icon: "mdi-package-variant-closed",
      },
      "Remessa Confirmada": {
        color: "success",
        icon: "mdi-check-all",
      },
      "Motorista Editado": {
        color: "warning",
        icon: "mdi-account-edit-outline",
      },
    };
    commit("setSystemNotification", []);
    const response = await NotificationRepository.getNotificationList();
    if (response.status >= 200 && response.status < 300) {
      const notificacao = response.data.data;
      const windowHeight = window.innerHeight;
      const notificationHeight = windowHeight / 9;

      const notification = notificacao.map((item) => {
        const config = notificationConfig[item.titulo] || {
          color: "primary",
          icon: "mdi-bell",
        };
        return {
          data_criacao: item.data_criacao,
          descricao: item.descricao,
          destinatario: item.destinatario,
          id: item.id,
          titulo: item.titulo,
          visible: false,
          height: notificationHeight,
          timeout: 0,
          ...config,
        };
      });

      commit("setSystemNotification", notification);
    }
  },
};
