import { DashboardRepository } from "../../../repositories/defaultUserRepositories/DashboardRepository";
import { ErrorNotifier } from "../../../services/messages/ErrorNotifier";

export default {
  async dispatchMonitoring({ commit }, setIsError) {
    commit("setDispatchMonitoring", {});
    try {
      const response = await DashboardRepository.getDispatchMonitoring();
      if (response.status === 200) {
        const dispatchMonitoring = response.data.data[0];
        commit("setDispatchMonitoring", dispatchMonitoring);
        return;
      }
      setIsError(true);
    } catch (error) {
      console.log(error);
    }
  },
  async docks({ commit }, setIsError) {
    commit("setDocks", []);
    try {
      const response = await DashboardRepository.getDocks();
      if (response.status === 200) {
        const docks = response.data.data;
        commit("setDocks", docks);
        return;
      }
      setIsError(true);
    } catch (error) {
      console.log(error);
    }
  },
  async dailyOrders({ commit }, setIsError) {    
    commit("setDailyOrders", {});
    try {
      const response = await DashboardRepository.getDailyOrders();
      if (response.status === 200) {
        const dailyOrders = response.data.data[0];
        commit("setDailyOrders", dailyOrders);
        return;
      }
      setIsError(true);
    } catch (error) {
      console.log(error);
    }
  },
  /// Performance Individual
  async requestIndividualPerformance({ commit, dispatch }) {
    try {
      const response = await DashboardRepository.requestIndividualPerformance();
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        commit("setIndividualPerformance", response.data.data);
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async carriersOrders({ commit }, setIsError) {
    commit("setCarrierOrders", []);
    try {
      const response = await DashboardRepository.getCarrierOrders();
      if (response.status === 200) {
        const carriersOrders = response.data.data;
        commit("setCarrierOrders", carriersOrders);
        return;
      }
      setIsError(true);
    } catch (error) {
      console.log(error);
    }
  },
  async duoPickups({ commit }, setIsError) {
    commit("setDuoPickups", []);
    try {
      const response = await DashboardRepository.getDuoPickups();
      if (response.status === 200) {
        const duoPickups = response.data.data.data;
        commit("setDuoPickups", duoPickups);
        return;
      }
      setIsError(true);
    } catch (error) {
      console.log(error);
    }
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },

  setInitialDate({ commit }, value) {
    commit("setInitialDate", value);
  },

  setFinalDate({ commit }, value) {
    commit("setFinalDate", value);
  },
  errorMessage({ commit, dispatch }, value) {
    ErrorNotifier.run(commit, dispatch, value);
    return;
  },
  checkToken({ dispatch }, value) {
    if (value.data.status === "O token é inválido.") {
      value.data.message = "O token é inválido.";
      value.status = "O token é inválido.";
      dispatch("errorMessage", value);
      return;
    }
    return;
  },
};
