<template>
  <v-row justify="end" class="overflow-visible teste">
    <v-col cols="auto">
      <v-snackbar
        v-for="(snackbar, index) in getSystemNotification"
        :style="{ 'margin-bottom': calcMargin(index) }"
        :key="index"
        bottom
        width="300"
        :height="snackbar.height"
        right
        timeout="-1"
        :color="snackbar.color"
        :multi-line="snackbar.mode === 'multi-line'"
        class="navy--text"
        v-model="snackbar.visible"
        @input="onSnackbarInput"
      >
        <v-layout align-center>
          <v-icon class="pr-3" dark>{{ snackbar.icon }}</v-icon>
          <v-layout column>
            <div>
              <strong>{{ snackbar.titulo }}</strong>
            </div>
            <div class="text-wrap" style="max-width: 15rem">
              {{ snackbar.descricao }}
            </div>
          </v-layout>
          <v-btn icon>
            <v-icon dark @click="markAsARead(snackbar, index)"
              >mdi-close</v-icon
            >
          </v-btn>
        </v-layout>
      </v-snackbar>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NotificationComponent",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("notifications", ["getSystemNotification"]),
  },
  methods: {
    ...mapActions("notifications", ["dismissSystemNotification"]),
    calcMargin(i) {
      return i * 80 + "px";
    },
    async markAsARead(snackbar, index) {
      this.$store.state.notifications.systemNotification[index].visible = false;

      await this.dismissSystemNotification(snackbar);
    },

    onSnackbarInput() {
      this.$nextTick(() => {
        const snackbars = this.$refs.snackbars;
        if (snackbars) {
          for (let i = 0; i < snackbars.length; i++) {
            const snackbar = snackbars[i];
            snackbar.style.marginBottom = this.calcMargin(i);
          }
        }
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.notification {
  position: relative;
  height: 4rem; /* altura padrão */
}

.teste {
  position: absolute;
  right: 2%;
  top: 1%;
  z-index: 2000;
}
</style>