export default {
  dispatchMonitoring: {},
  docks: [],
  dailyOrders: {},
  carriersOrders: [],
  duoPickups: [],
  listingBar: [],
  pedidosPerType: [],
  individualPerformance: [],
  placasPerWeek: [],
  collectsAndPanelsByDispatcher: [],
  ordersPerShippingCompany: [],
  statusOfTrucksOnArrival: [],
  awaitingEntry: [],
  shippingByCarrier: [],
  lateCollections: [],
  isLoading: false,
  initialDate: "",
  finalDate: "",
};
