export default {
  getDispatchMonitoring(state) {
    return state.dispatchMonitoring;
  },

  getDocks(state) {
    return state.docks;
  },

  getDailyOrders(state) {
    return state.dailyOrders;
  },

  getCarriersOrders(state) {
    return state.carriersOrders;
  },

  getDuoPickups(state) {
    return state.duoPickups;
  },

  getIsLoading(state) {
    return state.isLoading;
  },

  getInitialDate(state) {
    return state.initialDate;
  },

  getFinalDate(state) {
    return state.finalDate;
  },

  getPedidosPerWeek: (state) => state.pedidosPerWeek,

  getPedidosPerWeek: (state) => state.pedidosPerWeek,

  getIndividualPerformance: (state) => state.individualPerformance,

  getPlacasPerWeek: (state) => state.placasPerWeek,

  getCollectsAndPanelsByDispatcher: (state) =>
    state.collectsAndPanelsByDispatcher,

  getOrdersPerShippingCompany: (state) => state.ordersPerShippingCompany,

  getStatusOfTrucksOnArrival: (state) => state.statusOfTrucksOnArrival,

  getAwaitingEntry: (state) => state.awaitingEntry,

  getShippingByCarrier: (state) => state.shippingByCarrier,

  getLateCollections: (state) => state.lateCollections,
};
