import { BoardingRepository } from "../../../repositories/defaultUserRepositories/BoardingRepository"

export default {
  async listaInsumos({ commit }, urlParams) {  
    const response = await BoardingRepository.listaInsumos(urlParams);
    if (response.status >= 200 && response.status < 300) {
      console.log(response.data.data);
      commit("setListaInsumos", response.data.data);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async listaVolumes({ commit }, urlParams) {   
    const response = await BoardingRepository.listaVolumes(urlParams);
    if (response.status >= 200 && response.status < 300) {
      commit("setListaVolumes", response.data.data);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async detailsOfManifest({ commit }, urlParams) {   
    const response = await BoardingRepository.detailsOfManifest(urlParams);
    if (response.status >= 200 && response.status < 300) {
      console.log(response.data.data);
      commit("setDetailsOfManifest", response.data.data);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },
};
