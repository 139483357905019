export default {
  listingDrivers: [],
  driversPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  isLoading: false,
  listingCompanys: [],
  filteredDriver: [],
  urlParams: {
    page: 1,
    search: "",
    transportadora: "",
    transportadoraId: "",
  },
};
