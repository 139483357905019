import { HttpRestService } from "@/services/http/HttpRestService";
import { HttpFileRequestService } from "../../services/http/HttpFileRequestService";
export class ExpeditionRepository {
  static requestExpedition(url) {
    return HttpRestService.get(url);
  }

  static collectRequests(coletaId) {
    return HttpRestService.get(`/coleta/${coletaId}/pedidos`);
  }

  static requestConfirm(coletaId) {
    return HttpRestService.post(`/coleta/confirmar/conferencia/${coletaId}`);
  }

  static listForklifts() {
    return HttpRestService.get(`/users?page=1&perPage=10&roleId=5`);
  }

  static listDocks() {
    return HttpRestService.get(`/docas/listar?perPage=9999&page=1`);
  }

  static listDrivers() {
    return HttpRestService.get(`/motorista/listar`);
  }

  static sendBoarding(data) {
    return HttpRestService.post("/coleta/iniciar/embarque", data);
  }

  static requestContinueBoarding(modelContinueBoarding) {
    return HttpRestService.post("/coleta/retomar", modelContinueBoarding);
  }

  static protocolDetails(protocol) {
    return HttpRestService.get(`/protocolo/detalhes/${protocol}`);
  }

  static findColetaDetalhesByColetaId(coletaId) {
    return HttpRestService.get(`/coleta/${coletaId}`);
  }

  static findColetaDetalhesRecebimento(coletaId) {
    return HttpRestService.get(
      `/recebimento/coleta/detalhe/${coletaId}/listar`
    );
  }

  static findColetaDetalhesByColetaIdEmbarque(coletaId) {
    return HttpRestService.get(`/coleta/embarque/${coletaId}`);
  }

  static findColetaDetalhesRedespacho(coletaId) {
    return HttpRestService.get(`/redespacho/detalhe/${coletaId}/listar`);
  }

  static getColetaDetalhesRecebimento(coletaId) {
    return HttpRestService.get(
      `/recebimento/pedido/listar/pedidos-pendentes`,
      coletaId
    );
  }

  static findVolumeDetalhesByVolumeId(volumeId) {
    return HttpRestService.get(`/volume/detalhes/${volumeId}`);
  }

  static biparVolume(data) {
    return HttpRestService.post("/volume/bipagem", data);
  }

  static biparVolumeLote(data) {
    return HttpRestService.post("/carga/bipar", data);
  }

  static finalizarColeta(body) {
    return HttpRestService.post(`/coleta/finalizar/embarque`, body);
  }

  static finalizaRecebimento(conferenciaRecebimentoId) {
    return HttpRestService.post(
      `/recebimento/coleta/finalizar/${conferenciaRecebimentoId}`
    );
  }

  static capturaBipagem(capturarCamera) {
    return HttpRestService.post(`/camera/capturar`, capturarCamera);
  }

  static volmesComErros(coletaId) {
    return HttpRestService.get(`/volume/error/listing/${coletaId}`);
  }

  static removeVolumesComErros(urlParams) {
    return HttpRestService.delete(`/volume/error/delete`, urlParams);
  }

  static listExpeditionUserList() {
    return HttpRestService.get(`/users?page=1&perPage=10&isActive=1&roleId=13`);
  }

  static listExpeditionUserListByColetaId(coletaId) {
    return HttpRestService.get(`/despachante/coleta/listar/${coletaId}`);
  }

  static updateDespaschantesDaColeta(data) {
    return HttpRestService.post(`/despachante/criacao`, data);
  }

  static requestConfirmPauseCollection(modelPauseCollection) {
    return HttpRestService.post(`/coleta/pausar`, modelPauseCollection);
  }

  static protocolData(coletaId) {
    return HttpRestService.get(`/coleta/detalhes/protocolo/${coletaId}`);
  }

  static getPickupOrders(urlParams) {
    return HttpRestService.get(
      `/pedido/listar/pedidos-pendentes-embarque`,
      urlParams
    );
  }

  static getPickupOrdersRedespacho(urlParams) {
    return HttpRestService.get(
      `/pedido/listar/pedidos-pendentes-redespacho`,
      urlParams
    );
  }

  static requestExpeditionRelatorio(data, useState) {
    return HttpFileRequestService.build()
      .setMethod("POST")
      .setUrl(
        useState === "pdf"
          ? `/coleta/pdf/manifesto`
          : `/coleta/minuta/faturamento`
      )
      .setBody(data)
      .request(useState);
  }

  static detailsPedido(numeroPedido) {
    return HttpRestService.get(`/volume/${numeroPedido}/pedido`);
  }

  static receptionDetails(body) {
    return HttpRestService.post(`/recebimento/coleta/iniciar`, body);
  }

  static generateReport(body, fileFormat, fileName) {
    const pickupId = body.coletasId;
    return fileFormat === "pdf"
      ? HttpFileRequestService.build()
          .setMethod("GET")
          .setUrl(`/coleta/${pickupId}/manifesto`)
          .request(fileFormat, fileName)
      : HttpFileRequestService.build()
          .setMethod("POST")
          .setUrl(`/coleta/minuta/faturamento`)
          .setBody(body)
          .request(fileFormat, fileName);
  }
}
