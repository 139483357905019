import { CarrierRegistrationRequest } from "../../../repositories/transportadoraUserRepositories/CarrierRegistrationRequest";
import router from "@/router";

export default {
  async tokenValidation({ commit }, token) {
    const response = await CarrierRegistrationRequest.tokenValidation(token);

    if (response.status >= 200 && response.status < 300) {
      commit("setTokenValidation", response.data.data.email);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async registerUser({ commit }, modelCreateUser) {
    const response = await CarrierRegistrationRequest.registerUser(
      modelCreateUser
    );
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Usuário cadastrado com sucesso!",
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      // commit("setRegisterUser", response.data.data);
      router.push("/");
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },
};
