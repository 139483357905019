<template>
  <v-card class="overflow-hidden">
    <div class="justify-center mt-5">
      <v-row>
        <v-col class="text-wrap secondary--text font-weight-bold" align="center"
          >Coleta : {{ this.coletaId }}</v-col
        >
      </v-row>

      <span class="font-weight-bold navy--text"
        >Deseja cancelar esta coleta?</span
      >
    </div>
    <div v-if="!isLoadingModal">
      <v-card-text>
        <v-container>
          <v-row>
            <v-row justify="start">
              <v-col align="start">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    @click="onConfirmCancellation"
                    :loading="isLoadingBtn"
                    :color="hover ? 'secondary' : 'primary'"
                    block
                    rounded
                    class="navy--text text-truncate"
                    style="text-transform: none !important; font-weight: bolder"
                    >Sim</v-btn
                  >
                </v-hover>
              </v-col>
              <v-col align="end">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    @click="$emit('closeCancellationDialog')"
                    :color="hover ? 'secondary' : 'deep-orange'"
                    block
                    rounded
                    class="navy--text text-truncate"
                    style="text-transform: none !important; font-weight: bolder"
                    >Não</v-btn
                  >
                </v-hover>
              </v-col>
            </v-row>
          </v-row>
        </v-container>
      </v-card-text>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-col>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    isLoadingModal: false,
    isLoadingBtn: false,
  }),
  props: {
    coletaId: { type: Number, required: true },
    remessaId: { required: true },
  },
  methods: {
    ...mapActions("coleta", ["confirmCancellation"]),

    async onConfirmCancellation() {
      this.isLoadingBtn = true;
      const modelConfirmCancellation = {
        remessaId: this.remessaId,
        coletaId: this.coletaId,
      };
      await this.confirmCancellation(modelConfirmCancellation);
      this.$emit("closeCancellationDialog");
      this.$emit("listColetas");
      this.isLoadingBtn = false;
    },
  },
};
</script>
<style scoped></style>
