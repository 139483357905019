<template>
  <div class="mt-3">
    <v-row no gutters class="justify-center align-center mt-3">
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-row no-gutters>
          <v-text-field
            placeholder="Transportadora"
            v-model="urlParams.search"
            clearable
            dense
            solo
            flat
          >
          </v-text-field>
        </v-row>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-row no-gutters>
          <v-text-field
            placeholder="Buscar Coletas"
            v-model="urlParams.codigo"
            clearable
            dense
            solo
            flat
          >
          </v-text-field>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <v-row no-gutters>
          <v-col class="mr-2">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="dataExpeditionInicial"
                  label="Data Inicial"
                  placeholder="Inicial"
                  readonly
                  clearable
                  dense
                  solo
                  flat
                  v-bind="attrs"
                  v-on="on"
                  v-model="dataExpeditionInicial"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="dataExpeditionInicial"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="dataExpeditionFinal"
                  label="Data Final"
                  placeholder="Final"
                  readonly
                  clearable
                  dense
                  solo
                  flat
                  v-bind="attrs"
                  v-on="on"
                  v-model="dataExpeditionFinal"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="dataExpeditionFinal"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" sm="6" md="2" lg="2" xl="2">
        <v-row no-gutters>
          <v-hover v-slot="{ hover }">
            <v-btn
              rounded
              block
              :loading="isLoadingBtn"
              :color="hover ? 'secondary' : 'primary'"
              class="top navy--text text-truncate"
              style="text-transform: none !important; font-weight: bolder"
              @click="doASearch"
              >Filtrar</v-btn
            >
          </v-hover>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ExpeditionPlanHeader",
  data: () => ({
    menu1: false,
    menu2: false,
    isLoadingBtn: false,
    selectItems: [],
    select: { title: "", value: "" },
    urlParams: {
      page: 1,
      search: "",
      codigo: "",
      initialDate: "",
      finalDate: "",
    },
  }),
  computed: {
    ...mapGetters("expedition", [
      "getListingExpedition",
      "getExpeditionPagination",
      "getDataExpeditionInicial",
      "getDataExpeditionFinal",
    ]),
    isCurrentPage() {
      return this.$store.getters["expedition/getExpeditionPagination"]
        .currentPage;
    },
    dataExpeditionInicial: {
      get() {
        return this.formatDate(
          this.getDataExpeditionInicial.dataExpeditionInicial
        );
      },
      set(dataExpeditionInicial) {
        this.setDataExpeditionInicial({
          dataExpeditionInicial,
        });
      },
    },
    dataExpeditionFinal: {
      get() {
        return this.formatDate(this.getDataExpeditionFinal.dataExpeditionFinal);
      },
      set(dataExpeditionFinal) {
        this.setDataExpeditionFinal({
          dataExpeditionFinal,
        });
      },
    },
    dataSemFiltroInicial: {
      get() {
        return this.getDataExpeditionInicial.dataExpeditionInicial;
      },
      set(dataSemFiltroInicial) {
        this.setDataSemFiltro({
          dataSemFiltroInicial,
        });
      },
    },
    dataSemFiltroFinal: {
      get() {
        return this.getDataExpeditionFinal.dataExpeditionFinal;
      },
      set(dataSemFiltroFinal) {
        this.setDataExpeditionFinal({
          dataSemFiltroFinal,
        });
      },
    },
  },

  methods: {
    ...mapActions("expedition", [
      "expedition",
      "setIsLoading",
      "setUrlParams",
      "setDataExpeditionInicial",
      "setDataExpeditionFinal",
    ]),
    ...mapActions("remessas", ["setDataColeta"]),

    async doASearch() {
      this.setIsLoading(true);
      const urlParams = {
        page: 1,
        search: this.urlParams.search,
        codigo: this.urlParams.codigo,
        initialDate: (this.urlParams.initialDate = this.dataSemFiltroInicial),
        finalDate: (this.urlParams.finalDate = this.dataSemFiltroFinal),
      };
      await this.setUrlParams(urlParams);
      await this.expedition();
      this.setIsLoading(false);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  created() {
    (this.dataExpeditionInicial = ""), (this.dataExpeditionFinal = "");
  },
};
</script>

<style scoped>
.top {
  margin-bottom: 1.6rem;
}
</style>
