import { HttpRestService } from "@/services/http/HttpRestService";

export class PrevisaoPatioRepository {
  static requestPrevisaoPatio(date) {
    return HttpRestService.get(`/baia/patio/previsao/${date}`);
  }
  static createPrevisaoPatio(modelCreatePrevisaoPatio) {
    return HttpRestService.post("", modelCreatePrevisaoPatio);
  }
}
