<template>
  <v-dialog v-if="enabledSync" v-model="enabledSync" max-width="1200">
    <v-card color="base">
      <v-card-title class="justify-start">
        <h3 class="navy--text">Assine o seu Nome Completo</h3>
      </v-card-title>
      <v-card-text>
        <div v-if="!isLoading">
          <v-row justify="center" class="mx-4 mt-2 text-truncate">
            <canvas
              id="signaturePadCanvas"
              class="signaturePadCanvas signature"
              ref="signaturePadCanvas"
              width="1200"
              height="350"
            ></canvas>
          </v-row>
        </div>
        <div v-else>
          <v-progress-circular
            color="primary"
            indeterminate
            size="50"
            class="my-4"
          ></v-progress-circular>
        </div>
      </v-card-text>
      <v-card-actions class="justify-space-between mt-6">
        <v-row no-gutters class="justify-space-between">
          <v-col class="align-self-center ml-3" cols="3">
            <v-hover v-slot="{ hover }">
              <v-btn
                rounded
                :color="hover ? 'secondary' : 'base'"
                x-large
                block
                class="navy--text"
                style="text-transform: none !important; font-weight: bolder"
                max-width="220px"
                @click="clearSignature"
                >Limpar</v-btn
              >
            </v-hover>
          </v-col>
          <v-col class="align-self-center mr-3" cols="3">
            <v-hover v-slot="{ hover }">
              <v-btn
                :loading="isLoadingBtn"
                rounded
                :color="hover ? 'secondary' : 'primary'"
                x-large
                block
                class="navy--text"
                style="text-transform: none !important; font-weight: bolder"
                max-width="220px"
                @click="openConfirmaAssinaturaDialog"
                >Coletar</v-btn
              >
            </v-hover>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <confirma-assinatura
      @close="dialogConfirmaAssinatura.enabled = false"
      @confirma-assinatura="onColect"
      :key="dialogConfirmaAssinaturaKey"
      :enabled="dialogConfirmaAssinatura.enabled"
      :signaturePad="signaturePad"
    />
  </v-dialog>
</template>

<script>
import SignaturePad from "signature_pad";
import EmbarqueColetaDialogConfirmaAssinatura from "./EmbarqueColetaDialogConfirmaAssinatura";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EmbarqueColetaRecolherAssinaturaMotorista",
  components: {
    ConfirmaAssinatura: EmbarqueColetaDialogConfirmaAssinatura,
  },
  data: () => ({
    isLoading: false,
    isLoadingBtn: false,
    signaturePad: {},
    dialogConfirmaAssinaturaKey: "B",
    dialogConfirmaAssinatura: { enabled: false, volume: {}, key: 0 },
  }),
  props: {
    enabled: { type: Boolean, required: true },
  },
  mounted() {
    const canvas = this.$refs.signaturePadCanvas;
    const signaturePad = new SignaturePad(canvas, {
      minWidth: 1,
      maxWidth: 1,
      penColor: "rgb(0, 0, 0)",
      velocityFilterWeight: 0.7,
      backgroundColor: "white",
    });
    this.signaturePad = signaturePad;
  },
  watch: {
    enabledSync(enabledSync) {
      if (enabledSync) {
        this.init();
      }
    },
  },
  computed: {
    ...mapGetters("expedition", ["getColetaDetalhes"]),

    enabledSync: {
      get() {
        return this.enabled;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  methods: {
    ...mapActions("drivers", ["sendSignature"]),

    init() {
      console.log("Iniciar modal");
    },

    clearSignature() {
      return this.signaturePad.clear();
    },

    openConfirmaAssinaturaDialog() {
      this.dialogConfirmaAssinaturaKey + 1;
      this.dialogConfirmaAssinatura.enabled = true;
    },

    async onColect() {
      this.isLoadingBtn = true;
      const signature = this.signaturePad.toDataURL();
      const modelSendSignature = {
        fileContent: signature,
        motoristaId: this.getColetaDetalhes.motoristaId,
        coletaId: this.getColetaDetalhes.coletaId,
      };
      await this.sendSignature(modelSendSignature);
      this.isLoadingBtn = false;
      this.$emit("atualiza-assinatura");
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.signature {
  border: solid 2px transparent;
  border-radius: 20px;
}
</style>
