export default {
  getDataFromProtocol(state) {
    return state.receptionData;
  },
  getReceptionList(state) {
    return state.receptionList;
  },
  getReceptionDetails(state) {
    return state.receptionDetails;
  },
  getLoadingReception(state) {
    return state.isLoading;
  },
  getReceptionPagination(state) {
    return state.receptionPagination;
  },
  getDataInicial(state) {
    return state.dataInicial;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
};
