export default {
  isLoading: false,
  whiteList: [],
  whiteListEdit: [],
  filteredPerfis: [],
  listingPerfis: [],
  isLoadingSearch: false,
  visiblePermissions: {
    VER_LISTA_MOTORISTA: {
      id: 4,
      name: "Visualizar Motoristas",
    },
    VER_LISTA_DE_VEICULOS: {
      id: 11,
      name: "Visualizar Veículos",
    },
    VER_LISTA_DE_COLETA: {
      id: 16,
      name: "Visualizar Coletas Agendadas",
    },
    VER_LISTA_DE_TRANSPORTADORA: {
      id: 28,
      name: "Visualizar Transportadoras",
    },
    VER_DETALHES_DE_PROTOCOLOS: {
      id: 30,
      name: "Visualizar Recepção",
    },
    VER_PEDIDOS_DO_DASHBOARD: {
      id: 43,
      name: "Visualizar Dashboard",
    },
    VER_LISTA_DE_PERFIL: {
      id: 50,
      name: "Visualizar Perfis",
    },
    VER_LISTA_DE_USUARIOS: {
      id: 66,
      name: "Visualizar Usuários",
    },
    VER_REGISTROS: {
      id: 72,
      name: "Visualizar Registros",
    },
    VER_LISTA_REMESSAS_TRANSPORTADORA: {
      id: 75,
      name: "Visualizar Remessas (transportadora)",
    },
    VER_PAINEIS: {
      id: 78,
      name: "Visualizar Painéis",
    },
    VER_PREVISAO_DE_PATIO: {
      id: 79,
      name: "Visualizar Previsão de Pátio",
    },
    VER_MONITORAMENTO_PATIO: {
      id: 80,
      name: "Visualizar Monitoramento de Pátio",
    },
    VER_CONFERENCIA_COLETA: {
      id: 81,
      name: "Visualizar Gerenciamento de Coletas",
    },
    VER_TELA_PATIO: {
      id: 85,
      name: "Visualizar Pátio",
    },
    TELA_BLOQUEIO_PEDIDO: {
      id: 105,
      name: "Tela Bloqueio Pedido",
    },
    VER_LISTA_RECEBIMENTO: {
      id: 107,
      name: "Ver Lista Recebimento",
    },
    VER_LISTA_REDESPACHO: {
      id: 108,
      name: "Ver Lista Redespacho",
    },
    VER_LISTAGEM_MONITORAMENTO: {
      id: 109,
      name: "Ver Listagem Monitoramento",
    },
    VER_LISTAGEM_AGENDAMENTO: {
      id: 110,
      name: "Ver Listagem Agendamento",
    },
    VER_LISTAGEM_CONFERENCIA_DE_BAIA: {
      id: 111,
      name: "Ver Listagem Conferencia de Baia",
    },
  },
};
