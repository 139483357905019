import { HttpRestService } from "@/services/http/HttpRestService";

export class CarrierRegistrationRequest {
  static tokenValidation(token) {
    return HttpRestService.get(`/users/${token}`);
  }
  static registerUser(modelCreateUser) {
    return HttpRestService.post(`/users/store/transportadora`, modelCreateUser);
  }
}
