<template>
  <v-dialog v-if="enabledSync" v-model="enabledSync" max-width="720">
    <v-card>
      <v-card-title class="justify-center">
        <h3 class="mb-6">Dados do Volume</h3>
      </v-card-title>
      <v-card-text v-if="loading">
        <v-progress-circular indeterminate size="80" />
      </v-card-text>
      <div v-else>
        <v-data-table
          dense
          :headers="headers"
          :items="volumeDetalhes.insumos"
          item-key="volumeId"
          class="elevation-1"
        ></v-data-table>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "EmbarqueColetaVolumeDetalhes",
  data: () => ({
    loading: false,
    volumeDetalhes: {},
    headers: [
      {
        width: "20px",
      },
      { width: "50%", text: "Insumo", value: "descricao" },
      { width: "30%", text: "Linha", value: "linha" },
      { width: "40%", text: "Serial", value: "serial" },
      {
        width: "20px",
      },
    ],
  }),
  props: {
    enabled: { type: Boolean, required: true },
    volume: { type: Object, required: true },
  },
  mounted() {
    this.loadVolumesDetalhes();
  },
  computed: {
    enabledSync: {
      get() {
        return this.enabled;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  methods: {
    ...mapActions("expedition", ["findVolumeDetalhesByVolumeId"]),
    async loadVolumesDetalhes() {
      const { volumeId } = this.volume;
      this.volumeDetalhes = { insumos: [] };
      if (!volumeId) {
        return;
      }
      this.loading = true;
      try {
        this.volumeDetalhes = await this.findVolumeDetalhesByVolumeId(volumeId);
      } catch (e) {
        console.error({ e });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-weight: bolder !important;
  font-size: 12px !important;
  color: #0f2733;
}
.v-data-table .v-data-table-header tr th {
  font-size: 12px !important;
}
.v-data-table .v-data-table-header tr th:hover {
  color: #f9820b !important;
}
tbody {
  tr:hover {
    background-color: #ffdfcc !important;
  }
}
::v-deep
  .theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  color: white;
}
</style>
